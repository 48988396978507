import { FaShapes, } from 'react-icons/fa';
import terminator from '../../assets/images/terminator.jpeg'
import Decision from '../../assets/images/decision.jpeg'
import inout from '../../assets/images/inout.jpeg'
import on from '../../assets/images/on-page.jpeg'
import off from '../../assets/images/off-page.jpeg'
import prep from '../../assets/images/preparation.jpeg'
import proccess from '../../assets/images/process.jpeg'
import manual from '../../assets/images/manual.jpeg'
import doc from '../../assets/images/document.jpeg'
import display from '../../assets/images/display.jpeg'
import pre from '../../assets/images/pre.jpeg'


export const dataButton = [
  { id: 1, shape: 'add', type: 'Terminator', icon: FaShapes, image: terminator,},
  { id: 2, shape: 'diamond', type: 'Decision', icon: FaShapes, image: Decision, },
  // { id: 3, shape: "arrow-rect", type: "Off-Page Connector", icon: BiShapePolygon },/// -- ini belom
  { id: 4, shape: 'parallelogram', type: 'Input/Output', icon: FaShapes, image: inout, },
  // { id: 5, shape: "database", type: "Database", icon: FaShapes},// -- ini belom 
  { id: 6, shape: 'circle', type: 'On-Page Reference', icon: FaShapes, image: on,},
  { id: 7, shape: 'hexagon', type: 'Preparation', icon: FaShapes, image: prep, },
  // { id: 7, shape: "reference", type: "predefined proccess", icon: CgShapeHexagon },//-- buat off-page reference
  { id: 9, shape: 'process', type: 'Proccess', icon: FaShapes, image:proccess, },
  { id: 10, shape: 'manual-operation', type: 'manual-operation', icon: FaShapes, image:manual,},
  { id: 11, shape: 'document', type: 'Document', icon: FaShapes, image:doc, },
  { id: 12, shape: 'predefined-process', type: 'predefined proccess', icon: FaShapes , image: pre,},
  { id: 13, shape: 'display', type: 'display', icon: FaShapes, image: display, },
  { id: 14, shape: 'offpage-reference', type: 'offpage-reference', icon: FaShapes, image: off, },


];

export const dataColor = [
  'red',
  'yellow',
  'green',
  'blue',
  'black',
  'grey',
  'orange',
  'purple',
  'pink',
  'brown',
  'peru',
  'coral',
  'salmon',
  '#fdc606',
  '#ffb884',
  '#d9ead3',
  '#f17924',
  '#7030a1',

]