/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import moment from 'moment';

import { FaRegCalendar } from 'react-icons/fa';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { formatFrice } from '../../../Utils/Helper';
import { getUserByUid } from '../../../Utils/getUser';
import { useTranslation } from 'react-i18next';

const BillingsPage = () => {
  const { t } = useTranslation();
  const globalState = useUserStore();
  const itemsPerPage = 10; // Jumlah data per halaman

  const [dataSearchContact] = useState([]);
  const [inputSearch] = useState('');

  const modalFilterDate = useDisclosure();

  const [selectedDateRange, setSelectedDateRange] = useState();
  const [billingList, setBillingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const toast = useToast();

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState?.currentCompany },
      { field: 'uid', operator: '==', value: globalState?.uid },
    ];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      const contacts = await getCollectionFirebase(
        'billings',
        conditions,
        sortBy,
        limitValue
      );

      const contactWithUserName = await Promise.all(
        contacts.map(async (contact) => {
          const user = await getUserByUid(globalState.users, contact.uid);
          return { ...contact, username: user?.email };
        })
      );

      setBillingList(contactWithUserName);
      setTotalPages(Math.ceil(1000 / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getFilteredData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      {
        field: 'createdAt',
        operator: '>=',
        value: selectedDateRange.startDate,
      },
    ];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      const contacts = await getCollectionFirebase(
        'billings',
        conditions,
        sortBy,
        limitValue
      );
      setBillingList(contacts);

      const totalContacts = await getCollectionFirebase('billings', conditions);
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };


  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1); // Pindahkan ke halaman berikutnya saat tombol "Load More" diklik
  };

  useEffect(() => {
    if (!selectedDateRange) {
      getData();
    } else {
      getFilteredData();
    }
  }, [globalState.currentProject, currentPage, selectedDateRange]);


  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const tableHeader = [
    'name',
    'type',
    'status',
    'user',
    'bank',
    'method',
    'amount',
    'last activity',
  ];

  let tableData = '';

  if (inputSearch === '') {
    tableData = billingList?.map((data) => {
      const name = data?.name || '';
      const type = data?.type || '';
      const status = data?.transaction_status || '';
      const user = data?.username || data?.uid || '';
      const bank = data?.bank || '';
      const method = data?.payment_type || '';
      const amount = data?.gross_amount
        ? `${data?.currency}${formatFrice(data?.gross_amount)}`
        : 0;
      const lastActivity = moment(data?.lastUpdated?.seconds * 1000).format('DD-MM-YYYY HH:mm');

      return {
        data,
        name: name,
        type: type,
        status: status,
        user: user,
        bank: bank,
        method: method,
        amount: amount,
        'last activity': lastActivity,
      };
    });
  } else {
    tableData = dataSearchContact?.map((data) => {
      const name = data?.name || '';
      const type = data?.type || '';
      const status = data?.transaction_status || '';
      const user = data?.username || data?.uid || '';
      const bank = data?.bank || '';
      const method = data?.payment_type || '';
      const amount = data?.gross_amount
        ? `Rp ${formatFrice(data?.gross_amount)}`
        : 'Rp 0';
      const lastActivity = moment(data?.lastUpdated?.seconds * 1000).format('DD-MM-YYYY HH:mm');

      return {
        data,
        name: name,
        type: type,
        status: status,
        user: user,
        bank: bank,
        method: method,
        amount: amount,
        'last activity': lastActivity,
      };
    });
  }

  // const handleRead = (row) => {
  //   // Tambahkan logika hapus sesuai kebutuhan Anda
  //   handleDetail(row.data);
  // };

  return (
    <Stack>
      <Stack spacing={4}>
        <HStack gap={5}>
          <Heading size={'md'} fontWeight="bold">
            {t('billing')}
          </Heading>
          <Spacer />
          <HStack>
            <Box>
              <DynamicButton
                action={'custom'}
                color={'yellow'}
                title={t('filterDate')} 
                icon={FaRegCalendar}
                variant={'solid'}
                onClick={modalFilterDate.onOpen}
              />
            </Box>
          </HStack>
        </HStack>

        <DynamicTable
          header={tableHeader}
          data={tableData}
          // onRead={handleRead}
        />

        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              {t('loadMore')}
            </Button>
          )}
        </Stack>
      </Stack>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter {t('date')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>{t('from')}: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format('LLL')}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>{t('to')}:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format('LLL')}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                {t('clear')} Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default BillingsPage;
