import {
  FcBriefcase,
  FcCollaboration,
  FcFile,
  FcMindMap,
  FcManager,
  FcSportsMode,
  FcDisplay,
  FcDocument,
  FcOpenedFolder,
  FcWorkflow,
  FcClock,
  FcTodoList,
  FcRules,
  FcEngineering,
  FcTimeline,
  FcDatabase,
  FcFlashOn,
  FcGrid,
  FcOrgUnit,
  FcBarChart,
} from 'react-icons/fc';

export const data = [
  {
    name: 'score-board',
    icon: FcDisplay,
    // link: "https://productivity.deoapp.com/",
    link: '/management/score',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'People',
        icon: FcManager,
        link: '/management/score/people',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Tasks',
        icon: FcTodoList,
        link: '/management/score/tasks',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'task',
    icon: FcTodoList,
    link: '/management/task',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Activity',
        icon: FcClock,
        link: '/productivity',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Task Management',
        icon: FcOrgUnit,
        link: '/productivity',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
        submenu: [
          {
            name: 'Kanban',
            icon: FcDatabase,
            link: '/kanban',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
    
          {
            name: 'Table',
            icon: FcGrid,
            link: '/table',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },

          {
            name: 'Gantt',
            icon: FcBarChart,
            link: '/gantt',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
    
        ]
      },

      
      {
        name: 'Minutes of Meeting',
        icon: FcRules,
        link: '/mom',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Operation',
        icon: FcEngineering,
        link: '/operation',
        status: 'beta',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Line',
        icon: FcTimeline,
        link: '/line',
        status: 'beta',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

    ],
  },

  // {
  //   name: 'sop',
  //   icon: FcRules,
  //   link: '/management/sop',
  //   description:
  //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //   submenu: [
  //     {
  //       name: 'SOP',
  //       icon: FcTodoList,
  //       link: '/sop',
  //       status: 'beta',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //       submenu: [
  //         {
  //           name: 'SOP Lists',
  //           icon: FcTodoList,
  //           link: '/sop/list',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'SOP Template',
  //           icon: FcTemplate,
  //           link: '/sop/template',
  //           status: 'beta',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'AI SOP Master',
  //           icon: FcFlashOn,
  //           link: '/sop/ai',
  //           status: 'beta',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'KPI',
  //       icon: FcDisplay,
  //       link: '/kpi',
  //       status: 'beta',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //       submenu: [
  //         {
  //           name: 'KPI',
  //           icon: FcDisplay,
  //           link: '/kpi/list',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'KPI Template',
  //           icon: FcTemplate,
  //           link: '/kpi/template',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'KPI Simulator',
  //           icon: FcCalculator,
  //           link: '/kpi/simulator',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'OKR',
  //       icon: FcElectricalSensor,
  //       link: '/okr',
  //       status: 'beta',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //       submenu: [
  //         {
  //           name: 'OKR Lists',
  //           icon: FcElectricalSensor,
  //           link: '/okr/list',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },


  //     {
  //       name: 'Workflow',
  //       icon: FcParallelTasks,
  //       link: '/workflow',
  //       status: 'beta',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },

  //   ],
  // },

  {
    name: 'assets',
    icon: FcFile,
    // link: "https://productivity.deoapp.com/",
    link: '/management/assets',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Docs & Files',
        icon: FcDocument,
        link: '/docs',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Digital Assets',
        icon: FcOpenedFolder,
        link: '/assets',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'tools',
    icon: FcSportsMode,
    // link: "https://productivity.deoapp.com/",
    link: '/management/tools',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Mind Map',
        icon: FcMindMap,
        link: '/mindmap',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Flow Chart',
        icon: FcWorkflow,
        link: '/flowchart',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Automation',
        icon: FcFlashOn,
        link: '/automation',
        status: 'beta',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'configuration',
    icon: FcCollaboration,
    link: '/configuration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Projects',
        icon: FcBriefcase,
        link: '/configuration/projects',
      },

      {
        name: 'Users',
        icon: FcManager,
        link: '/configuration/users',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  // batas sampai sini
];
