export function addGoogleTagManager(gtmId) {
  const dataLayer = document?.createElement('script');
  dataLayer.textContent = 'window.dataLayer = window.dataLayer || [];';
  document?.body?.appendChild(dataLayer);

  const script = document.createElement('script');
  script.textContent = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
    `;
  document?.body?.appendChild(script);
}

export function addGoogleTagAnalytics(gaId) {
  const script1 = document?.createElement('script');
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  script1.async = true;
  document?.head?.appendChild(script1);

  const script2 = document?.createElement('script');
  script2.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gaId}');
  `;
  document?.head?.appendChild(script2);
}

export const sendEventTag = async (eventName, conversionData) => {
  if (window?.gtag) {
    try {
      const res = await window?.gtag('event', eventName, {
        ...conversionData,
      });
      return res;
    } catch (error) {
      return { error: error.message };
    }
  }
  return { error: 'Google Tag Manager not found' }; // Tambahkan ini jika window.gtag tidak terdefinisi
};

export const sendEventLayer = async (eventName, conversionData) => {
  if (window?.dataLayer) {
    try {
      const res = await window?.dataLayer?.push('event', eventName, {
        ...conversionData,
      });
      return res;
    } catch (error) {
      return { error: error.message };
    }
  }
  return { error: 'Google Tag Manager not found' }; // Tambahkan ini jika window.gtag tidak terdefinisi
};