/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import {
  Box,
  Stack,
  HStack,
  Tag,
  Badge,
  Text,
  Tooltip,
  Avatar,
  Button,
  AvatarGroup,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import { useParams } from 'react-router-dom';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import {
  getCollectionFirebaseV4,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import { FiTag } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { MdEmail, MdOutlineFlashOn } from 'react-icons/md';
import moment from 'moment';
import DynamicButton from '../Buttons/DynamicButton';
import SingleCalendarComponent from '../Card/SingleCalendarComponent';
import { readMore2 } from '../../Utils/Helper';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { createHistoryCard } from '../../Utils/historyCardUtil';

const MyKanbanTableComponent = ({ kanbanData, filterData }) => {
  const {t} = useTranslation()
  const param = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { userDisplay } = useKanbanStore();
  useKanbanStore((state) => state.setFunctionAddData);
  const setSomeFunction = useKanbanStore((state) => state?.setFunctionAddData);
  const setTaskData = useKanbanStore((state) => state?.setTaskData);
  const globalState = useUserStore();

  const [columnsData, setColumnsData] = useState([]);
  const [columnsData2, setColumnsData2] = useState([]);
  const [sortBy, setSortBy] = useState({
    direction: 'desc',
  });

  const handleLoad = () => {
    const conditions = [
      { field: 'asignee', operator: 'array-contains', value: globalState.uid },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = '';
    if (columnsData2.length > 0) {
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated;
      if (filterData?.projectId)
        conditions.push({
          field: 'projectId',
          operator: '==',
          value: filterData?.projectId,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
    } else {
      // console.log('pertama kali')
      startAfter = columnsData[columnsData.length - 1].lastUpdated;
      if (filterData?.category)
        conditions.push({
          field: 'projectId',
          operator: '==',
          value: filterData?.projectId,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
    }

    getCollectionFirebaseV4(
      'kanban',
      { conditions },
      { sortBy },
      { limitValue },
      { startAfterData: startAfter }
    ).then((x) => {
      const updateData = [...columnsData2, ...x];
      setColumnsData2(updateData);
    });
  };

  const handleTypesenseSearch = (q) => {
    // console.log(q)
    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `asignee: [${globalState.uid}] || createdBy:${globalState.uid}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('kanban')
      .documents()
      .search(searchParameters)
      .then((x) => {
        // console.log(x)
        const newData = x?.hits?.map((y) => {
          return { ...y.document };
        });
        setColumnsData(newData);
      });
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (filterData?.search)
      setTimeout(function () {
        handleTypesenseSearch(filterData.search);
      }, 300);

    if (globalState.currentCompany && !filterData?.search) {
      let collectionRef = collection(db, 'kanban');
      collectionRef = query(
        collectionRef,
        where('asignee', 'array-contains', globalState?.uid)
      );
      collectionRef = query(
        collectionRef,
        where('companyId', '==', globalState?.currentCompany)
      );

      if (filterData?.category)
        collectionRef = query(
          collectionRef,
          where('category', '==', filterData?.category)
        );

      if (filterData?.projectId)
        collectionRef = query(
          collectionRef,
          where('projectId', '==', filterData?.projectId)
        );

      if (filterData?.asignee)
        collectionRef = query(
          collectionRef,
          where('asignee', 'array-contains-any', filterData.asignee)
        );

      if (filterData?.label)
        collectionRef = query(
          collectionRef,
          where('label', '==', filterData.label)
        );

      // if (filterData?.type === 'automation')
      //   collectionRef = query(
      //     collectionRef,
      //     orderBy('automationId', 'desc')
      //   );

      collectionRef = query(collectionRef, orderBy('lastUpdated', 'desc'));
      collectionRef = query(collectionRef, limit(5));

      unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        const addTask = [];

        querySnapshot.forEach((doc) => {
          addTask.push({ id: doc.id, ...doc.data() });
        });

        // console.log(querySnapshot.length, 'kodok')
        setColumnsData(addTask);
      });
    }

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [globalState.currentCompany, filterData]);

  const handleSelectLabel = async (id, value) => {
    let updateData = {};

    if (value === 'completed') {
      updateData = {
        label: value,
        time_completed_at: new Date(),
      };
      toast({
        status: 'success',
        duration: 9000,
        title: 'Good job, Team !!',
        isClosable: true,
      });
    } else {
      updateData = {
        label: value,
      };
    }

    

    const comments = `has edit label to ${value}`
    createHistoryCard(id, comments, globalState)


    await updateDocumentFirebase('kanban', id, updateData);

    const updatedColumnsData2 = columnsData2.filter((item) => item.id !== id);
    setColumnsData2(updatedColumnsData2);
  };

  const handleSort = (type) => {
    const newSortDirection =
      sortBy.direction === 'asc' && sortBy.field === type ? 'desc' : 'asc';

    const sortedData = [...columnsData].sort((a, b) => {
      if (type === 'title') {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        return newSortDirection === 'asc'
          ? titleA.localeCompare(titleB)
          : titleB.localeCompare(titleA);
      } else if (type === 'score') {
        return newSortDirection === 'asc'
          ? a.score - b.score
          : b.score - a.score;
      }
      return 0;
    });

    setColumnsData(sortedData);
    setSortBy({ direction: newSortDirection, field: type });
  };

  const handleEditTable = (taskId, filesId, task) => {
    navigate(
      `/kanban/view/${filesId}/MyKanban/${taskId}`,
      { state: { type: 'existing' } }
    );
    // setSomeFunction(props?.setData);
    setTaskData(task);
  };

  return (
    <>
      <Box maxH={'70vh'} overflowY={'auto'}>
        <TableContainer
          border={'1px'}
          borderRadius={'sm'}
          borderColor={'gray.100'}
          boxShadow={'base'}
        >
          <Table variant={'striped'} overflow={'auto'}>
            <Thead>
              <Tr>
                <Th>
                  <HStack>
                    <Text
                      onClick={() => handleSort('title')}
                      cursor={'pointer'}
                    >
                      {t('title')}
                      {sortBy.direction === 'asc' &&
                      sortBy.field === 'title' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </Text>
                  </HStack>
                </Th>

                <Th>Label</Th>
                <Th>{t('creator')}</Th>
                <Th>{t('assigmee')}</Th>
                <Th>{t('pic')}</Th>
                <Th>
                  <HStack>
                    <Text
                      onClick={() => handleSort('score')}
                      cursor={'pointer'}
                    >
                      {t('score')}
                      {sortBy.direction === 'asc' &&
                      sortBy.field === 'score' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </Text>
                  </HStack>
                </Th>
                <Th>{t('dueOn')}</Th>
                <Th>{t('action')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {[...columnsData, ...columnsData2].map((task, index) => (
                <Tr key={index}>
                  <Td>
                    {' '}
                    <Stack spacing={1} my={2}>
                      <HStack>
                        {task?.category && (
                          <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
                            {JSON.stringify(task?.category)}
                          </Tag>
                        )}
                        {task.label ? (
                          <Badge
                            colorScheme={
                              task.label === 'help'
                                ? 'yellow'
                                : task.label === 'pending'
                                ? 'gray'
                                : task.label === 'blocked'
                                ? 'red'
                                : task.label === 'completed'
                                ? 'green'
                                : task.label === 'priority'
                                ? 'blue'
                                : 'orange'
                            }
                          >
                            <HStack>
                              <FiTag />
                              <Text>{task.label}</Text>
                            </HStack>
                          </Badge>
                        ) : (
                          <></>
                        )}
                        {task?.automationId && (
                          <Tag colorScheme={'yellow'} size="sm" fontSize={'xs'}>
                            <MdOutlineFlashOn />
                          </Tag>
                        )}
                        {task?.email_status && (
                          <Tag colorScheme={'red'} size="sm" fontSize={'xs'}>
                            <MdEmail />
                          </Tag>
                        )}
                      </HStack>
                      <Text fontWeight="semibold" isTruncated>
                        {readMore2(task.title, 50)}
                      </Text>
                      <HStack>
                        {task?.lastUpdated?.seconds ? (
                          <Text textAlign="left" fontSize="2xs">
                            {t('updated')}
                            {moment.unix(task?.lastUpdated?.seconds).fromNow()}
                          </Text>
                        ) : (
                          <></>
                        )}
                        <Text fontSize="3xs">ID: {task.id}</Text>
                      </HStack>
                    </Stack>
                  </Td>
                  <Td>
                    <Select
                      w={'fit-content'}
                      defaultValue={task?.label}
                      placeholder="Label"
                      onChange={(e) => {
                        handleSelectLabel(task.id, e.target.value);
                      }}
                    >
                      <option value="help">Help</option>
                      <option value="priority">Priority</option>
                      <option value="urgent">Urgent</option>
                      <option value="pending">Pending</option>
                      <option value="blocked">Blocked</option>
                      {task?.time_backlog_at !== undefined && (
                      <option value="completed">completed</option>
                    )}
                    </Select>
                  </Td>
                  <Td textAlign={'center'}>
                    <HStack spacing={1}>
                      {task?.createdBy ? (
                        <Tooltip
                          label={
                            userDisplay?.find((z) => z.id === task?.createdBy)
                              ?.name || task?.createdBy
                          }
                          aria-label="Name"
                        >
                          {task?.createdBy === 'Deoapp Automation' ? (
                            <Stack
                              w="30px"
                              borderRadius={'full'}
                              h="30px"
                              bgColor={'yellow.400'}
                              alignItems={'center'}
                              justifyContent="center"
                            >
                              <MdOutlineFlashOn />
                            </Stack>
                          ) : (
                            <Avatar
                              m="0"
                              size="sm"
                              name={
                                userDisplay?.find(
                                  (z) => z.id === task?.createdBy
                                )?.name || task?.createdBy
                              }
                              image={
                                userDisplay?.find(
                                  (x) => x.id === task?.createdBy
                                )?.image
                              }
                            />
                          )}
                        </Tooltip>
                      ) : (
                        <Text> {t('noCreator')}</Text>
                      )}
                    </HStack>
                  </Td>
                  <Td>
                    <AvatarGroup size="sm" max={1}>
                      {task?.asignee ? (
                        task?.asignee?.sort()?.map((x, i) => {
                          const name = userDisplay?.find(
                            (y) => y.id === x
                          )?.name;
                          return (
                            <Tooltip key={i} label={name} aria-label="Name">
                              <Avatar size="sm" name={name} />
                            </Tooltip>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </AvatarGroup>
                  </Td>
                  <Td>
                    <AvatarGroup size="sm" max={1}>
                      {task?.pic ? (
                        task?.pic.map((picId, i) => {
                          const name = kanbanData?.usersDisplay?.find(
                            (user) => user.id === picId
                          )?.name;
                          return (
                            <Tooltip key={i} label={name} aria-label="Name">
                              <Avatar size="sm" name={name} />
                            </Tooltip>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </AvatarGroup>
                  </Td>
                  <Td>
                    <Stack
                      w="30px"
                      borderRadius={'full'}
                      h="30px"
                      bgColor={task?.score > 0 ? 'blue.400' : 'red.400'}
                      alignItems={'center'}
                      justifyContent="center"
                    >
                      <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
                        {task?.score || 0}
                      </Text>
                    </Stack>
                  </Td>
                  <Td>
                    {task.dueOn ? (
                      <SingleCalendarComponent
                        data={task?.dueOn}
                        date={moment.unix(task?.dueOn).format('DD')}
                        month={moment.unix(task?.dueOn).format('MMM')}
                      />
                    ) : (
                      <></>
                    )}
                  </Td>
                  <Td>
                    <DynamicButton
                      action={'update'}
                      size={'sm'}
                      onClick={() =>
                        handleEditTable(task.id, task.filesId, task)
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {columnsData?.length > 3 && columnsData2?.length === 0 ? (
        <Flex justifyContent={'center'} mt={5}>
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {' '}
            {t('loadMore')}
          </Button>
        </Flex>
      ) : columnsData2?.length > 3 ? (
        <Flex justifyContent={'center'} mt={5}>
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {' '}
            {t('loadMore')}
          </Button>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyKanbanTableComponent;
