/* eslint-disable no-lone-blocks */

export function formatFrice(value) {
  if (!value) return 0;
  const val = (value / 1).toFixed(0).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function readMore(val) {
  if (!val) return '';
  val = val.replace(/\s{2,}/g, ' ');
  const strLength = val.length;
  if (strLength > 18) {
    {
      val = val.slice(0, 18) + '...';
    }
  }
  return val
    .toLowerCase()
    .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
}

export function readMore2(val) {
  if (!val) return '';
  val = val.replace(/\s{2,}/g, ' ');
  const strLength = val.length;
  if (strLength > 50) {
    {
      val = val.slice(0, 50) + '...';
    }
  }
  return val
    .toLowerCase()
    .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
}

export function removeSymbols(text) {
  if (!text) return undefined;
  return text.replace(/[^a-zA-Z0-9]/g, ''); // Menghapus semua karakter selain huruf dan angka
}

const bankList = [
  {
    value: 'uob',
    label: 'UOB Non Flip',
    fee: 4000,
    queue: 24985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mandiri',
    label: 'Mandiri',
    fee: 4000,
    queue: 24985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bri',
    label: 'BRI',
    fee: 4000,
    queue: 111680,
    status: 'OPERATIONAL',
  },
  {
    value: 'bni',
    label: 'BNI',
    fee: 4000,
    queue: 33507,
    status: 'OPERATIONAL',
  },
  {
    value: 'bca',
    label: 'BCA',
    fee: 4000,
    queue: 343831,
    status: 'OPERATIONAL',
  },
  {
    value: 'bsm',
    label: 'BSI (Bank Syariah Indonesia)',
    fee: 4000,
    queue: 32339,
    status: 'OPERATIONAL',
  },
  {
    value: 'cimb',
    label: 'CIMB Niaga/CIMB Niaga Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'muamalat',
    label: 'Muamalat',
    fee: 4000,
    queue: 4791,
    status: 'OPERATIONAL',
  },
  {
    value: 'danamon',
    label: 'Danamon / Danamon Syariah',
    fee: 4000,
    queue: 38,
    status: 'OPERATIONAL',
  },
  {
    value: 'permata',
    label: 'Bank Permata',
    fee: 4000,
    queue: 139735,
    status: 'OPERATIONAL',
  },
  {
    value: 'bii',
    label: 'Maybank Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'panin',
    label: 'Panin Bank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'uob',
    label: 'TMRW/UOB',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'ocbc',
    label: 'OCBC NISP',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'citibank',
    label: 'Citibank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'artha',
    label: 'Bank Artha Graha Internasional',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'tokyo',
    label: 'Bank of Tokyo Mitsubishi UFJ',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'dbs',
    label: 'DBS Indonesia',
    fee: 4000,
    queue: 1245510,
    status: 'OPERATIONAL',
  },
  {
    value: 'standard_chartered',
    label: 'Standard Chartered Bank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'capital',
    label: 'Bank Capital Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'anz',
    label: 'ANZ Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'boc',
    label: 'Bank of China (Hong Kong) Limited',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bumi_arta',
    label: 'Bank Bumi Arta',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'hsbc',
    label: 'HSBC Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'rabobank',
    label: 'Rabobank International Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mayapada',
    label: 'Bank Mayapada',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bjb',
    label: 'BJB',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'dki',
    label: 'Bank DKI Jakarta',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'daerah_istimewa',
    label: 'BPD DIY',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'jawa_tengah',
    label: 'Bank Jateng',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'jawa_timur',
    label: 'Bank Jatim',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'jambi',
    label: 'Bank Jambi',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sumut',
    label: 'Bank Sumut',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sumatera_barat',
    label: 'Bank Sumbar (Bank Nagari)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'riau_dan_kepri',
    label: 'Bank Riau Kepri',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sumsel_dan_babel',
    label: 'Bank Sumsel Babel',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'lampung',
    label: 'Bank Lampung',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'kalimantan_selatan',
    label: 'Bank Kalsel',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'kalimantan_barat',
    label: 'Bank Kalbar',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'kalimantan_timur',
    label: 'Bank Kaltimtara',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'kalimantan_tengah',
    label: 'Bank Kalteng',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sulselbar',
    label: 'Bank Sulselbar',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sulut',
    label: 'Bank SulutGo',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'nusa_tenggara_barat',
    label: 'Bank NTB Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bali',
    label: 'BPD Bali',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'nusa_tenggara_timur',
    label: 'Bank NTT',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'maluku',
    label: 'Bank Maluku',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'papua',
    label: 'Bank Papua',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bengkulu',
    label: 'Bank Bengkulu',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sulawesi',
    label: 'Bank Sulteng',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sulawesi_tenggara',
    label: 'Bank Sultra',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'nusantara_parahyangan',
    label: 'Bank Nusantara Parahyangan',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'india',
    label: 'Bank of India Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mestika_dharma',
    label: 'Bank Mestika Dharma',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sinarmas',
    label: 'Bank Sinarmas',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'maspion',
    label: 'Bank Maspion Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'ganesha',
    label: 'Bank Ganesha',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'icbc',
    label: 'ICBC Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'qnb_kesawan',
    label: 'QNB Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'btn',
    label: 'BTN/BTN Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'woori',
    label: 'Bank Woori Saudara',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'tabungan_pensiunan_nasional',
    label: 'BTPN / Jenius / BTPN Wow!',
    fee: 4000,
    queue: 10072,
    status: 'OPERATIONAL',
  },
  {
    value: 'bri_syr',
    label: 'BRI (Bank Rakyat Indonesia) Syariah',
    fee: 4000,
    queue: 1275,
    status: 'OPERATIONAL',
  },
  {
    value: 'bjb_syr',
    label: 'BJB Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mega',
    label: 'Bank Mega',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bukopin',
    label: 'Wokee/Bukopin',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'jasa_jakarta',
    label: 'Bank Jasa Jakarta',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'hana',
    label: 'LINE Bank/KEB Hana',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mnc_internasional',
    label: 'Motion/MNC Bank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'agroniaga',
    label: 'BRI Agroniaga',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sbi_indonesia',
    label: 'SBI Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'royal',
    label: 'Blu/BCA Digital',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'nationalnobu',
    label: 'Nobu (Nationalnobu) Bank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mega_syr',
    label: 'Bank Mega Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'ina_perdana',
    label: 'Bank Ina Perdana',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'sahabat_sampoerna',
    label: 'Bank Sahabat Sampoerna',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'kesejahteraan_ekonomi',
    label: 'Seabank/Bank BKE',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bca_syr',
    label: 'BCA (Bank Central Asia) Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'artos',
    label: 'Jago/Artos',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mayora',
    label: 'Bank Mayora Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'index_selindo',
    label: 'Bank Index Selindo',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'victoria_internasional',
    label: 'Bank Victoria International',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'agris',
    label: 'Bank IBK Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'chinatrust',
    label: 'CTBC (Chinatrust) Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'commonwealth',
    label: 'Commonwealth Bank',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'ccb',
    label: 'Bank China Construction Bank Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'danamon_syr',
    label: 'Bank Danamon Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'victoria_syr',
    label: 'Bank Victoria Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'banten',
    label: 'BPD Banten',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mutiara',
    label: 'Bank Mutiara',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'panin_syr',
    label: 'Panin Dubai Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'gv',
    label: 'Gudang Voucher',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'aceh',
    label: 'Bank Aceh Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'btpn_syr',
    label: 'Bank BTPN Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'dinar',
    label: 'Bank Dinar Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'harda',
    label: 'Allo Bank/Bank Harda Internasional',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'e2pay',
    label: 'E2Pay',
    fee: 4000,
    queue: 8,
    status: 'OPERATIONAL',
  },
  {
    value: 'mas',
    label: 'Bank Multi Arta Sentosa (Bank MAS)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'prima',
    label: 'Bank Prima Master',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'yudha_bakti',
    label: 'Neo Commerce/Yudha Bhakti',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'linkaja',
    label: 'LinkAja',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'dompetku',
    label: 'Dompetku',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'shinhan',
    label: 'Bank Shinhan Indonesia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'bukopin_syr',
    label: 'Bank Bukopin Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'cnb',
    label: 'Bank CNB (Centratama Nasional Bank)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'atmb_lsb',
    label: 'ATMB LSB (BPR LSB)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'atmb_plus',
    label: 'ATMB PLUS',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'antardaerah',
    label: 'Bank Antardaerah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'mantap',
    label: 'BANK MANTAP (Mandiri Taspen)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'eka',
    label: 'BPR EKA (Bank Eka)',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'doku',
    label: 'DOKU',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'finnet',
    label: 'FINNET',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'gopay',
    label: 'GoPay',
    fee: 4000,
    queue: 10,
    status: 'OPERATIONAL',
  },
  { value: 'ovo', label: 'OVO', fee: 4000, queue: 10, status: 'OPERATIONAL' },
  { value: 'dana', label: 'DANA', fee: 4000, queue: 10, status: 'OPERATIONAL' },
  {
    value: 'shopeepay',
    label: 'ShopeePay',
    fee: 4000,
    queue: 10,
    status: 'OPERATIONAL',
  },
  {
    value: 'sakuku',
    label: 'SAKUKU',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'aladin',
    label: 'Bank Aladin Syariah',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'dutamoney',
    label: 'DUTAMONEY',
    fee: 4000,
    queue: 293,
    status: 'OPERATIONAL',
  },
  {
    value: 'tokopedia',
    label: 'Tokopedia',
    fee: 4000,
    queue: 43985,
    status: 'OPERATIONAL',
  },
  {
    value: 'midtrans',
    label: 'Midtrans',
    fee: 4000,
    queue: 97,
    status: 'OPERATIONAL',
  },
  {
    value: 'other',
    label: 'Other',
    fee: 0,
    queue: 19121992,
    status: 'OPERATIONAL',
  },
];

export { bankList };
