import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import ManagementRouter from './ManagementRouter';
import ProductivityRouter from './ProductivityRouter';
import ProductivityFlowchartCanvasPage from '../Pages/Productivity/ProductivityFlowcharCanvasPage';
import ProductivityMindMapIndex from '../Pages/Productivity/ProductivityMindMapIndex';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import Layout from '../Layouts';
import MomRouter from './MomRouter';
import KanbanOperationRouter from './KanbanOperationRouter';
// import SopRouter from './SopRouter';
// import KpiRouter from './KpiRouter';
// import OkrRouter from './OkrRouter';
import ConfigurationRouter from './ConfigurationRouter';
import SopPrintPdf from '../Pages/Sop/Component/SopPrintTemplate';
// import WorkflowRouter from './WorkflowRouter';
import WorkflowDetailPage from '../Pages/Workflow/WorkflowDetailPage';

function MainRouter() {
  const location = useLocation();
  const navigate = useNavigate();

  const allRouter = [
    ...AuthenticationMainRouter,
    ...ConfigurationRouter,
    ...HomeRouter,
    ...SettingRouter,
    ...ManagementRouter,
    ...ProductivityRouter,
    ...MomRouter,
    ...KanbanOperationRouter,
    // ...SopRouter,
    // ...KpiRouter,
    // ...OkrRouter,
    // ...WorkflowRouter
  ];

  useEffect(() => {
    const path = location.pathname;

    if (path === '/billings') {
      return navigate('/');
    }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/mindmap/:id" element={<ProductivityMindMapIndex />} />
      <Route
        path="/flowchart/:id"
        element={<ProductivityFlowchartCanvasPage />}
      />
      <Route
        path="/workflow/:id"
        element={<WorkflowDetailPage />}
      />
      <Route path="/print-sop/:id" element={<SopPrintPdf />}/>

      {allRouter.map((item, index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        );
      })}
    </Routes>
  );
}

export default MainRouter;
