/* eslint-disable react/prop-types */
import { Badge, Box, Button, Fade, Heading, Stack } from '@chakra-ui/react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { getCollectionFirebaseV4 } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import { db } from '../../Config/firebase';
import { useDrop } from 'react-dnd';
import MyTaskCardComponent from '../Card/MyTaskCardComponent';
import { useTranslation } from 'react-i18next';

const ColumnColorScheme = {
  BACKLOG: 'red',
  TODOS: 'blue',
  PROGRESS: 'green',
  REVIEW: 'yellow',
  DONE: 'gray',
};

function MyKanbanColumnsComponent({
  allowedDropEffect,
  column,
  kanbanData,
  filterData,
  handleModalOpen,
  stateRefresh,
}) {
  const {t} = useTranslation()
  const globalState = useUserStore();
  const [columnsData, setColumnsData] = useState([]);
  const [columnsData2, setColumnsData2] = useState([]);
  useKanbanStore((state) => state.setFunctionAddData);

  const handleLoad = () => {
    const conditions = [
      { field: 'asignee', operator: 'array-contains', value: globalState.uid },
      { field: 'column', operator: '==', value: column },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = '';
    if (columnsData2.length > 0) {
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated;
      if (filterData?.projectId)
        conditions.push({
          field: 'projectId',
          operator: '==',
          value: filterData?.projectId,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
    } else {
      // console.log('pertama kali')
      startAfter = columnsData[columnsData.length - 1].lastUpdated;
      if (filterData?.category)
        conditions.push({
          field: 'projectId',
          operator: '==',
          value: filterData?.projectId,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
    }

    getCollectionFirebaseV4(
      'kanban',
      { conditions },
      { sortBy },
      { limitValue },
      { startAfterData: startAfter }
    ).then((x) => {
      const updateData = [...columnsData2, ...x];
      setColumnsData2(updateData);
    });
  };

  const handleTypesenseSearch = (q) => {
    // console.log(q)
    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `asignee: [${globalState.uid}] || createdBy:${globalState.uid} && column:${column} `,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('kanban')
      .documents()
      .search(searchParameters)
      .then((x) => {
        // console.log(x)
        const newData = x?.hits?.map((y) => {
          return { ...y.document };
        });
        setColumnsData(newData);
      });
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (filterData?.search)
      setTimeout(function () {
        handleTypesenseSearch(filterData.search);
      }, 300);

    if (globalState.currentCompany && !filterData?.search) {
      let collectionRef = collection(db, 'kanban');
      collectionRef = query(
        collectionRef,
        where('asignee', 'array-contains', globalState?.uid)
      );
      collectionRef = query(collectionRef, where('column', '==', column));
      collectionRef = query(
        collectionRef,
        where('companyId', '==', globalState?.currentCompany)
      );

      if (filterData?.category)
        collectionRef = query(
          collectionRef,
          where('category', '==', filterData?.category)
        );

      if (filterData?.projectId)
        collectionRef = query(
          collectionRef,
          where('projectId', '==', filterData?.projectId)
        );

      if (filterData?.asignee)
        collectionRef = query(
          collectionRef,
          where('asignee', 'array-contains-any', filterData.asignee)
        );

      if (filterData?.label)
        collectionRef = query(
          collectionRef,
          where('label', '==', filterData.label)
        );
      // if (filterData?.type === 'automation')
      //   collectionRef = query(
      //     collectionRef,
      //     orderBy('automationId', 'desc')
      //   );

      collectionRef = query(collectionRef, orderBy('lastUpdated', 'desc'));
      collectionRef = query(collectionRef, limit(5));

      unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        const addTask = [];

        querySnapshot.forEach((doc) => {
          addTask.push({ id: doc.id, ...doc.data() });
        });

        // console.log(querySnapshot.length, 'kodok')
        setColumnsData(addTask);
      });
    }

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [globalState.currentCompany, filterData]);

  useEffect(() => {
    const updateColumnsData2 = () => {
      if (
        Array.isArray(columnsData2) &&
        stateRefresh &&
        stateRefresh.length > 0
      ) {
        const newData = columnsData2.filter(
          (item) => !stateRefresh.includes(item?.id)
        );
        setColumnsData2(newData);
      }
    };

    if (stateRefresh && stateRefresh.length > 0) {
      updateColumnsData2();
    }
  }, [stateRefresh]);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect]
  );

  const ColumnTasks = (datas, type, handleModalOpen) =>
    datas?.map((x, index) => {
      // console.log(x, 'ini datanya di columntask')
      if (x?.id)
        return (
          <Fade in={true} initialscale={0.9} key={index}>
            <MyTaskCardComponent
              key={index}
              index={index}
              task={x}
              setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
              columnsData={datas}
              kanbanData={kanbanData}
              handleModalOpen={handleModalOpen}
              // onDropHover={swapTasks}
              //   onUpdate={updateTask}
              //   onDelete={deleteTask}
            />
          </Fade>
        );
    });

  return (
    <Box maxW="full" m="1">
      <Heading fontSize="md" mb={4} letterSpacing="wide" textAlign="center">
        <Badge
          w="full"
          fontSize="xl"
          px={2}
          py={1}
          rounded="lg"
          colorScheme={ColumnColorScheme[column.toUpperCase()]}
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction="column"
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? 'red' : null}
        rounded="lg"
        boxShadow="md"
        overflow="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            w: '2',
            h: '3',
          },
          '&::-webkit-scrollbar-track': {
            w: '6',
            h: '3',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10',
            bg: 'gray.300',
          },
        }}
        // scrollSnapStop={false}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? (
          ColumnTasks(columnsData2, 'manual', handleModalOpen)
        ) : (
          <></>
        )}
        {columnsData?.length > 3 && columnsData2?.length === 0 ? (
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {' '}
            {t('loadMore')}
          </Button>
        ) : columnsData2?.length > 3 ? (
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {' '}
            {t('LoadMore')}
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
}

export default MyKanbanColumnsComponent;
