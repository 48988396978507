/* eslint-disable react/prop-types */
import React from 'react';
import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';
import {
  Switch,
  Stack,
  Text,
  HStack,
  Flex,
  Badge,
  Select,
} from '@chakra-ui/react';
export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  return (
    <Flex justifyContent={'space-between'} mt={6} mb={4}>
      <Stack spacing={0}>
        <Text fontSize={'sm'} fontWeight={'semibold'}>
          Bar Color:{' '}
        </Text>
        <HStack justifyContent={'flex-start'}>
          <Badge borderRadius={'md'} fontSize={'md'} colorScheme="blue">
            Todos
          </Badge>
          <Badge borderRadius={'md'} fontSize={'md'} colorScheme="green">
            Progress
          </Badge>
          <Badge borderRadius={'md'} fontSize={'md'} colorScheme="yellow">
            Review
          </Badge>
          <Badge borderRadius={'md'} fontSize={'md'}>
            Done
          </Badge>
        </HStack>
      </Stack>
      <HStack flexDir={{ base: 'column', md: 'row' }}>
        <Select
          borderColor={'gray.200'}
          size={'md'}
          w={'fit-content'}
          onChange={(e) => onViewModeChange(e.target.value)}
        >
          <option value={ViewMode.QuarterDay}>Quarter of Day</option>
          <option value={ViewMode.HalfDay}>Half of Day</option>
          <option value={ViewMode.Day}>Day</option>
          <option value={ViewMode.Week}>Week</option>
          <option value={ViewMode.Month}>Month</option>
        </Select>
        <Flex
          alignItems={'center'}
          gap={2}
          border={'1px'}
          borderColor={'gray.200'}
          p={2}
          borderRadius={'md'}
        >
          <Text fontSize={'md'}>View Assignee</Text>
          <Switch
            size={'md'}
            isChecked={isChecked}
            onChange={() => onViewListChange(!isChecked)}
          />
        </Flex>
      </HStack>
    </Flex>
  );
};
