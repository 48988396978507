/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Checkbox, Divider, HStack, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';

function KanbanViewOtomation({ stateTo, setStateTo, fileData}) {

  const globalState = useUserStore();
  const toast = useToast()
  const [selectedIds, setSelectedIds] = useState([]);




  const handleCheckboxChange = (id, title) => {
    const updatedSelectedIds = [...selectedIds];

    const existingIndex = updatedSelectedIds.findIndex((item) => item.id === id);

    if (existingIndex !== -1) {
      // Jika item dengan id yang sama sudah ada, maka hapus item tersebut
      updatedSelectedIds.splice(existingIndex, 1);
    } else {
      // Jika tidak ada, tambahkan objek baru dengan id dan title
      updatedSelectedIds.push({ id, title });
    }

    setSelectedIds(updatedSelectedIds);
    setStateTo(updatedSelectedIds); // Menyimpan ke dalam setStateTo
  };

  return (
    <Stack spacing={5}>
      <Text fontWeight={600} fontSize={17} textAlign="center">
        kanban folder
      </Text>
      {fileData.length > 0 &&
        <Stack>
          {fileData.map((x, index) => {
            const isChecked = stateTo.some((item) => item.id === x.id);

            return (
              <Stack key={index}>
                <HStack spacing={2}>

                  <Stack spacing={1}>
                    <Text fontWeight={500}>{x.title}</Text>
                    <Text fontSize={'x-small'} >Type: {x.type}</Text>
                  </Stack>
                  <Spacer />
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => handleCheckboxChange(x.id, x.title)}
                  />

                </HStack>
                <Divider />
              </Stack>
            )
          })}
        </Stack>


      }
    </Stack>
  )
}

export default KanbanViewOtomation