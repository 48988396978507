/* eslint-disable react/prop-types */
import { Stack, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2'
import { updateDocumentFirebase } from '../../../Api/firebaseApi'
import { useTranslation } from 'react-i18next'
import useUserStore from '../../../Hooks/Zustand/Store'

function DeleteCardComponent({ cardId, cardData }) {

  const globalState = useUserStore();
  const { t } = useTranslation();

  const [alertDelete, setAlertDelete] = useState(false)
  const toast = useToast()

  const handleDeleteKpi = async () => {

    if (cardData.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
                globalState.roleProject === 'user'
      ) 
      {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    try {
      await updateDocumentFirebase('kanban', cardId, {
        lastUpdated: new Date(),
        column: 'archived',
        archivedBy: globalState?.uid || ''
      })
      toast({
        status: 'success',
        duration: 9000,
        title: 'Delete Card !!',
        isClosable: true,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  }

  const handleAlertOpen = () => {
    setAlertDelete(true)
  }

  const handleAlertClose = () => {
    setAlertDelete(false)
  }

  return (
    <Stack>
      <DynamicButton
        title={'Deleted Card'}
        action="delete"
        variant={'solid'}
        onClick={() => handleAlertOpen()}
      />

      <AlertDialogV2
        isOpen={alertDelete}
        onClose={handleAlertClose}
        onAction={handleDeleteKpi}
        title={'Would you like to delete this card data view in kanban'}
      />
    </Stack>
  )
}

export default DeleteCardComponent