/* eslint-disable react/no-children-prop */
import {
  Box,
  Button,
  Input,
  Select,
  InputLeftAddon,
  InputGroup,
  Avatar,
  Tooltip,
  HStack,
  Text,
  Flex,
  Heading,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spacer,
  useToast,
  Image,
  Grid,
  SimpleGrid,
  Textarea
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import { IoMdClose } from 'react-icons/io';
import useUserStore from '../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase, updateDocumentFirebase, arrayUnionFirebase, arrayRemoveFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import { db } from '../../Config/firebase';
import { doc, onSnapshot } from '@firebase/firestore';
import TaskCardAddComment from '../../Components/Card/TaskCardAddComment';
import TaskHistoryComponent from '../../Components/Card/TaskHistoryComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ProgressCircle from '../../Components/Progress/ProgressCircle';
import CardFile from '../../Components/Accounting/CardFile'
import { decryptToken } from '../../Utils/encrypToken';
import { FcAudioFile, FcFolder, FcVideoFile } from 'react-icons/fc';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { IoShareSocialSharp } from 'react-icons/io5';
import ModalShareCard from '../../Components/Modals/ModalShareCard';
import { MdOutlineAccessTimeFilled, MdOutlineFlashOn } from 'react-icons/md';
import AutomationLineSet from '../../Components/Automations/AutomationLineSet';
import { useTranslation } from 'react-i18next';
import DeleteCardComponent from '../Productivity/Components/DeleteCardComponent';
import { createHistoryCard } from '../../Utils/historyCardUtil';
import UploadFileToAwsAndDropbox from '../../Components/DropBox/UploadFileToAwsAndDropbox';





const LineTaskViewPage = () => {
  const { t } = useTranslation()
  const param = useParams();
  const id = param.taskId;
  const globalState = useUserStore();
  const [data, setData] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({});
  const [asignee, setAsignee] = useState([]);
  const [pic, setPic] = useState([]);
  const [category, setCategory] = useState({ open: false });
  const [addCategory, setAddCategory] = useState('');
  const [mainData, setMainData] = useState('');

  const [modalShare, setModalShare] = useState(false)

  const [fileData, setFileData] = useState([])

  const [typeFile, setTypeFile] = useState('operations')



  const navigate = useNavigate();
  const toast = useToast()

  const [progress, setProgress] = useState(0)


  const [score, setScore] = useState(data?.score || 0);

  const handleChange = (value) => setScore(value)


  const handleEditData = (type, value) => {
    const newData = data;
    if (type === 'dueOn') {
      value = moment(value).unix();


      const comments = `has change due on to ${moment(value * 1000).format('LLL')}`
      createHistoryCard(id, comments, globalState)
    }
    newData[type] = value;
    setDataSubmit({ ...newData });
  };

  const saveCategory = () => {
    let catData = [];
    if (category?.name) catData = category?.name || '';

    handleEditData('category', addCategory);
    catData.push(addCategory);

    updateDocumentFirebase('files', param.id, { category: catData })
      .then((x) => {
        toast({
          title: 'Deoapp.com',
          description: `succes add file ${x}`,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });

    setCategory({ name: catData, open: false });
  };

  const handleSave = () => {

    const uniqueDataAssignee = [...new Set(asignee)];

    updateDocumentFirebase('kanban', id, {
      ...dataSubmit,
      asignee: uniqueDataAssignee,
      pic,
      score: parseInt(score) !== 0 ? parseInt(score) : parseInt(data?.score) ? parseInt(data?.score) : parseInt(mainData?.default_score || 0),
    })
      .then(() => {
        navigate(-1);
        const comments = 'has update data card'

        createHistoryCard(id, comments, globalState)
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });
      
  };



  const handleSelectLabel = async (value) => {
    if (value === 'completed') {
      await updateDocumentFirebase('kanban', id, {
        time_completed_at: new Date()
      })
      toast({
        status: 'success',
        duration: 9000,
        title: 'Good job, Team !!',
        isClosable: true,
      });
    }
    handleEditData('label', value)

    const comments = `has edit label to ${value}`
    createHistoryCard(id, comments, globalState)


  }

  const onSelectFile = async (arg) => {
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToAwsAndDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );


      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = id;
        const field = 'files';
        const values = [dropboxLink];

        await arrayUnionFirebase(collectionName, docName, field, values);


        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });

      }




    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }

  }

  const handleDelete = async (arg) => {

    const collectionName = 'kanban';
    const docName = id;
    const field = 'files';
    const values = [arg];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Success delete your files card.',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const getDataKanban = () => {
    const conditions = [
      { field: 'type', operator: '==', value: typeFile },
      {
        field: 'operationId',
        operator: '==',
        value: mainData?.operationId,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'asc' };
    const limitValue = 999

    getCollectionFirebase('files', conditions, sortBy, limitValue)
      .then((x) => {
        setFileData(x);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });
  }


  useEffect(() => {
    if (mainData?.operationId) {
      getDataKanban()
    }


    return () => {
    }
  }, [mainData?.operationId])





  useEffect(() => {
    //onsnapshot here...
    const unsubCategory = onSnapshot(doc(db, 'files', param.id), (doc) => {
      if (doc.data()?.category) {
        setCategory({ name: doc?.data()?.category || '', open: false });
        setMainData(doc.data());
      } else {
        setCategory({ open: false });
        setMainData();
      }
    });

    const unsubTask = onSnapshot(doc(db, 'kanban', param.taskId), (doc) => {
      setData(doc?.data());
      setScore(doc?.data()?.score || 0)
      setAsignee(doc?.data()?.asignee ? doc.data()?.asignee : []);
      setPic(doc?.data()?.pic ? doc.data()?.pic : []);
    });

    return () => {
      unsubTask();
      unsubCategory();
      setCategory();
      setAsignee([])
      setMainData('')
    };
  }, []);

  const handleOpenModalShare = () => {
    setModalShare(true)

  }

  const handleCloseModalShare = () => {
    setModalShare(false)

  }

  const handleOpenAutomation = (id) => {
    navigate(`/automation/${id}`)
  }



  return (
    <Stack w={['auto', 'auto', 'auto']} overflowX="scroll">
      {data ? (
        <Stack
          p={[3, 1, 10]}
          w={['auto', 'auto', 'auto']}
          overflowX="scroll"
          fontSize={['sm', 'auto', 'auto']}
        >
          <HStack
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Button colorScheme={'red'} size="md" onClick={() => navigate(-1)}>
              <IoMdClose />
            </Button>
            <Stack spacing={1}>
              <Heading size="md">{data?.title}</Heading>
              <Text fontSize="3xs"> ID: {param.taskId}</Text>
            </Stack>
            <Spacer />
            <HStack spacing={2}>
              <Stack>
                <DynamicButton
                  title={'Save Card'}
                  action="create"
                  variant={'solid'}
                  onClick={() => handleSave()}
                />
              </Stack>

              {data?.automationId ? (
                <DynamicButton
                  title={'See Action'}
                  action="custom"
                  icon={MdOutlineFlashOn}
                  color="yellow"
                  variant={'solid'}
                  onClick={() => handleOpenAutomation(data?.automationId)}
                />
              ) : (
                <AutomationLineSet
                  setTypeFile={setTypeFile}
                  fileData={fileData}
                  dataSet={{
                    ...data,
                    asignee: [...new Set(asignee)],
                    pic,
                    score:
                      parseInt(score) !== 0
                        ? parseInt(score)
                        : parseInt(data?.score)
                          ? parseInt(data?.score)
                          : parseInt(mainData?.default_score || 0),
                  }}
                />
              )}

              {data?.column !== 'done' && (
                <Stack>
                  <DeleteCardComponent cardId={param?.taskId} cardData={data} />
                </Stack>

              )}


              <Stack>
                <DynamicButton
                  color={'blue'}
                  icon={IoShareSocialSharp}
                  action="custom"
                  variant={'solid'}
                  onClick={() => handleOpenModalShare()}
                />
              </Stack>
            </HStack>
          </HStack>

          <Box>
            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={3}>
              <Stack spacing={3}>
                <Input
                  type="text"
                  placeholder={data?.title === 'New Card' ? '' : data?.title}
                  onChange={(e) => handleEditData('title', e.target.value)}
                />

                <InputGroup>
                  <InputLeftAddon children="Last Updated" fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      moment
                        .unix(data?.lastUpdated?.seconds)
                        .format('DD/MM/YYYY') ?? moment().format('yyyy-MM-DD')
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children="Created By" fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      mainData?.usersDisplay?.find(
                        (x) => x.id === data.createdBy
                      )?.name
                    }
                  />
                </InputGroup>

                <HStack spacing={2}>
                  <InputGroup>
                    <InputLeftAddon children={t('category')} fontWeight={500} />

                    <Select
                      placeholder={t('category')}
                      defaultValue={data?.category ? data.category : <></>}
                      onChange={(e) =>
                        handleEditData('category', e.target.value)
                      }
                    >
                      {category?.name?.sort()?.map((x, i) => (
                        <option key={i} value={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  {category?.open ? (
                    <></>
                  ) : (
                    <DynamicButton
                      action={'create'}
                      variant="solid"
                      onClick={() => {
                        setCategory({ ...category, open: true });
                      }}
                    />
                  )}
                </HStack>

                {category?.open ? (
                  <HStack spacing={2}>
                    <InputGroup>
                      <InputLeftAddon
                        children={t('category')}
                        fontWeight={500}
                        placeholder={t('name')}
                      />
                      <Input
                        type="text"
                        onChange={(e) => setAddCategory(e.target.value)}
                      />
                    </InputGroup>
                    <DynamicButton
                      title={t('save')}
                      action={'create'}
                      onClick={() => saveCategory()}
                      variant="solid"
                    />
                    <DynamicButton
                      action={'delete'}
                      onClick={() => setCategory({ ...category, open: false })}
                      variant="solid"
                    />
                  </HStack>
                ) : (
                  <></>
                )}

                {asignee?.length > 0 ? (
                  <InputGroup>
                    <InputLeftAddon
                      children={t('assigneeTo')}
                      fontWeight={500}
                    />
                    <HStack ml="2" gap="0">
                      {[...new Set(asignee)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;
                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = asignee.filter(
                                    (z) => z !== x
                                  );
                                  setAsignee(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                ) : (
                  <></>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('assigneeTo')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder={t('assigneeTo')}
                    textTransform={'capitalize'}
                    onChange={(e) => {
                      setAsignee([...asignee, e.target.value]);
                    }}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                {pic?.length > 0 ? (
                  <InputGroup>
                    <InputLeftAddon children={t('pic')} fontWeight={500} />
                    <HStack ml="1" gap="0">
                      {[...new Set(pic)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;

                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = pic.filter((z) => z !== x);
                                  setPic(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                ) : (
                  <></>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('pic')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder={t('user')}
                    textTransform={'capitalize'}
                    onChange={(e) => {
                      setPic([...pic, e.target.value]);
                    }}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children="Label" fontWeight={500} />
                  <Select
                    onChange={(e) => handleSelectLabel(e.target.value)}
                    defaultValue={data?.label}
                    placeholder="Label"
                  >
                    <option value="help">help</option>
                    <option value="priority">priority</option>
                    <option value="urgent">urgent</option>
                    <option value="pending">pending</option>
                    <option value="blocked">blocked</option>
                    {data?.time_backlog_at !== undefined && (
                      <option value="completed">completed</option>
                    )}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children={t('dueOn')} fontWeight={500} />
                  <Input
                    type="date"
                    onChange={(e) => handleEditData('dueOn', e.target.value)}
                    defaultValue={
                      data?.dueOn
                        ? moment.unix(data?.dueOn).format('yyyy-MM-DD')
                        : moment(new Date()).format('yyyy-MM-DD')
                    }
                  />
                </InputGroup>

                <Stack>
                  <Text fontWeight={'bold'}>{t('score')} : </Text>
                  <Flex flexDirection={['column', 'row', 'row']}>
                    <NumberInput
                      maxW="100px"
                      mr="2rem"
                      min={0}
                      max={10}
                      value={score}
                      onChange={handleChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Slider
                      flex="1"
                      focusThumbOnChange={false}
                      value={score}
                      min={0}
                      max={10}
                      onChange={handleChange}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb
                        fontSize="sm"
                        boxSize="32px"
                        children={score}
                      />
                    </Slider>
                  </Flex>
                </Stack>

                <Stack>
                  <HStack>
                    <MdOutlineAccessTimeFilled />
                    <Text fontWeight={'bold'}>Time Duration: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.time_backlog_at?.seconds && (
                      <Text>Start : {moment(data?.time_backlog_at.seconds * 1000).format('LLL')}</Text>

                    )}

                    {data?.time_completed_at?.seconds && (
                      <Text>End : {moment(data?.time_completed_at.seconds * 1000).format('LLL')}</Text>

                    )}
                  </Stack>
                </Stack>

              </Stack>
              <Stack>
                <Stack alignItems="end" justifyContent="end" pt={3}>
                  <Text textAlign={'end'} fontWeight={800} fontSize="lg">
                    {t('score')} : {data?.score || 0}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontWeight={'bold'}>{t('cardSize')}</Text>
                  <Input
                    type="number"
                    placeholder={data?.size}
                    onChange={(e) => handleEditData('size', e.target.value)}
                  />
                </Stack>
                <Stack>
                  <Text fontWeight={'bold'}>{t('note')}</Text>
                  <Textarea
                    type="text"
                    defaultValue={data?.notes}
                    onChange={(e) => handleEditData('notes', e.target.value)}
                    minH="150px"
                  />
                </Stack>
                <Stack>
                  <Text fontWeight={'bold'}>{t('thumbnail')}: </Text>

                  <CardFile onChange={onSelectFile} />
                  {data?.files?.length > 0 && (
                    <SimpleGrid
                      h={'250px'}
                      overflow={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          w: '2',
                          h: '2',
                        },
                        '&::-webkit-scrollbar-track': {
                          w: '6',
                          h: '5',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '10',
                          bg: 'gray.200',
                        },
                      }}
                      py={3}
                      spacing={4}
                      columns={[2]}
                    >
                      {data?.files?.map((x, index) => {
                        const dataView =
                          x.type === 'image' ? (
                            <Image
                              src={x.link}
                              objectFit="contain"
                              w={'200px'}
                              borderRadius={'md'}
                              shadow="md"
                            />
                          ) : x.type === 'audio' ? (
                            <FcAudioFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : x.type === 'video' ? (
                            <FcVideoFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : (
                            <FcFolder
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          );
                        return (
                          <Stack
                            key={index}
                            borderWidth={1}
                            position="relative"
                            alignItems="center"
                            justifyContent={'center'}
                            borderRadius="md"
                            minH={'120px'}
                          >
                            <HStack
                              flexDirection={['column', 'row', 'row']}
                              position={'absolute'}
                              top={-2}
                              right={-2}
                              spacing={2}
                            >
                              <DynamicButton
                                action={'read'}
                                size="sm"
                                variant={'solid'}
                                onClick={() => window.open(x.link, '_blank')}
                              />
                              <DynamicButton
                                action={'delete'}
                                onClick={() => handleDelete(x)}
                                size="sm"
                                variant={'solid'}
                              />
                            </HStack>
                            <Stack overflow={'hidden'}>
                              {dataView && dataView}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </SimpleGrid>
                  )}
                  {data?.files?.length > 2 && (
                    <Stack alignItems={'center'} justifyContent="center">
                      <BsFillArrowDownCircleFill />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Box>

          <Flex flexDirection={['column', 'row', 'row']}>
            <Box flex="1">
              <TaskCardAddComment id={id} title={data?.title} />
            </Box>

            {showHistory ? (
              <Box maxW="xs" p={2}>
                <TaskHistoryComponent
                  setShowHistory={setShowHistory}
                  id={id}
                />
              </Box>
            ) : (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="green"
                onClick={() => setShowHistory(!showHistory)}
              >
                {showHistory ? t('hideHistory') : t('showHistory')}
              </Button>
            )}
          </Flex>
        </Stack>
      ) : (
        <></>
      )}

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}

      {modalShare === true && (
        <ModalShareCard
          handleCloseModal={handleCloseModalShare}
          modalShare={modalShare}
          fileData={fileData}
          kanbanData={mainData}
          cardData={data}
          globalState={globalState}
          typeFile={typeFile}
          setTypeFile={setTypeFile}
        />
      )}
    </Stack>
  );

};

export default LineTaskViewPage;
