import {
  Divider,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import moment from 'moment';
import {
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

function AutomationDetailPage() {
  const {t} = useTranslation()
  const { state } = useLocation();
  const param = useParams();
  const [data, setData] = useState(state || {});
  const toast = useToast();
  const navigate = useNavigate();
  const alertDelete = useDisclosure();

  const getData = async () => {
    try {
      const result = await getSingleDocumentFirebase('automation', param?.id);
      setData(result);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDelete = async () => {
    const collectionName = 'automation';
    const docName = data.id;

    const collectionNameFrom = data?.type;
    const docNameFrom = data?.typeId;
    const dataUpdate = {
      automationId: '',
    };

    try {
      const res = await deleteDocumentFirebase(collectionName, docName);
      if (res && data.action === 'repeat') {
        await updateDocumentFirebase(
          collectionNameFrom,
          docNameFrom,
          dataUpdate
        );
      }

      toast({
        title: 'Deoapp.com',
        description: 'Success delete automation',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      navigate(-1);
      alertDelete?.onClose();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();

    return () => {};
  }, [param?.id]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <Stack spacing={5}>
        <HStack>
          <BackButtons />
          <Spacer />
          <DynamicButton
            action="delete"
            title={t('delete')}
            variant="solid"
            onClick={handleOpenAlertDelete}
          />
          <DynamicButton action="update" title={t('edit')} variant="solid" />
        </HStack>

        <Stack spacing={5}>
          <Stack p={5} shadow="md" borderRadius="md">
            <HStack>
              <Text fontWeight={600} fontSize={25}>
                {t('Automation')}
              </Text>
              <Spacer />
              <Stack>
                <Tag
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  colorScheme={data.isActive === true ? 'green' : 'red'}
                  fontSize="xl"
                  fontWeight={700}
                  p={1}
                  px={2}
                >
                  {data.isActive === true ? 'Active' : 'Inactive'}
                </Tag>
                <Text fontSize={'xs'}>
                  {data?.timeActionSchedule
                    ? moment(data?.timeActionSchedule * 1000).format('LLLL')
                    : 'as soon...'}
                </Text>
              </Stack>
            </HStack>
            <Divider />
            <Stack>
              <Text>
                {t('type')} : {data?.type}
              </Text>
              <Text>
                {t('type')} ID : {data?.typeId}
              </Text>
              <Text>
                {t('porject')} : {data?.projectId}
              </Text>
              <Text>
                {t('lastActivity')} :{' '}
                {data?.timeActionSchedule
                  ? moment(data?.timeActionSchedule * 1000).format('LLLL')
                  : 'as soon...'}
              </Text>
              <Text>
                {t('expired')} :{' '}
                {moment(data?.untilAction * 1000).format('LLLL')}
              </Text>
            </Stack>
          </Stack>

          <SimpleGrid columns={[1, 3, 3]} gap={5}>
            <Stack p={5} shadow="md" borderRadius="md">
              <Text fontWeight={600} fontSize={23}>
                {t('dataFrom')}
              </Text>
              <Divider />
              <Stack>
                <Text>
                  {t('title')} : {data?.dataAutomation?.title}
                </Text>
                <Text>
                  {t('column')} : {data?.dataAutomation?.column}
                </Text>
                <Text>
                  {t('score')} : {data?.dataAutomation?.score}
                </Text>
                <Text>
                  {t('dueOn')} :{' '}
                  {moment(data?.dataAutomation?.dueOn * 1000).format('LLLL')}
                </Text>
              </Stack>
            </Stack>

            <Stack p={5} shadow="md" borderRadius="md">
              <Text fontWeight={600} fontSize={23}>
                {t('action')}
              </Text>
              <Divider />
              <Stack>
                <Text>
                  {t('action')} : {data?.action}
                </Text>
                <Text>
                  {t('time')} : {data?.timeAction}
                </Text>
                <Text>{t('day')} : </Text>
                <Stack p={2}>
                  {data?.dayAction?.length > 0 &&
                    data?.dayAction?.map((x, index) => {
                      return (
                        <Text key={index} textTransform="capitalize">
                          - {x}
                        </Text>
                      );
                    })}
                </Stack>
              </Stack>
            </Stack>

            <Stack p={5} shadow="md" borderRadius="md">
              <Text fontWeight={600} fontSize={23}>
                {t('to2')}
              </Text>
              <Divider />

              <Stack>
                <Text>
                  {' '}
                  {t('file')} : {data?.fileTo}
                </Text>
                <Text> {t('file')} data : </Text>
                <Stack>
                  {data?.fileToId?.length > 0 &&
                    data?.fileToId?.map((x, index) => {
                      return (
                        <Stack key={index} p={2}>
                          <Text>
                            {' '}
                            {t('title')} : {x.title}
                          </Text>
                          <Text>ID : {x.id}</Text>
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Stack>
      <AlertDialogV2
        isOpen={alertDelete?.isOpen}
        onClose={alertDelete?.onClose}
        title={`${t('delete')} ${t('Automation')} ${data?.dataAutomation?.title}`}
        onAction={handleDelete}
      />
    </Stack>
  );
}

export default AutomationDetailPage;
