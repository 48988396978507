import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  Stack,
  Center,
  VStack,
  Divider,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcFlashOn } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { db } from '../../Config/firebase';
import BackButtons from '../../Components/Buttons/BackButtons';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import CardAutomation from '../../Components/Card/CardAutomation';
import { useTranslation } from 'react-i18next';

function AutomationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { resetTaskData, resetFilterData } = useKanbanStore((state) => state);
  const [dataKanban, setDataKanban] = useState([]);
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const [editor, setEditor] = useState([]);
  const [filterOwner, setFilterOwner] = useState(false);

  const [modal, setModal] = useState({ delete: false, user: false });

  const itemsPerPage = 8;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    let conditions = [
      //   { field: 'type', operator: '==', value: 'mom' },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      //   { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        // { field: 'type', operator: '==', value: 'kanban' },
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
      ];
    }

    try {
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase(
        'automation',
        conditions,
        sortBy,
        limitValue
      );
      setDataKanban(res);

      const collectionRef = collection(db, 'automation');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(
        collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject)
        // where('type', '==', 'mom'),
        // where('users', 'array-contains', globalState.uid),
      );
      if (filterOwner === true) {
        countQuery = query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany)
          //   where('type', '==', 'mom')
        );
      }

      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [globalState?.currentProject, currentPage, filterOwner]);

  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  const handleNavigate = (x) => {
    navigate(`/automation/${x.id}`, { state: x });
    resetTaskData();
    resetFilterData();
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersDisplay?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  return (
    <>
      <Stack spacing={8}>
        <HStack m="2" flexDirection={['column', 'column', 'row']}>
          <BackButtons />
          <Heading size={'md'}>{t('Automation')}</Heading>
          <Spacer />

          <DynamicButton
            title={t('seeAll')}
            action={'read'}
            onClick={handleOpenFilter}
            variant={'solid'}
          />
        </HStack>
        {dataKanban?.length > 0 ? (
          <SimpleGrid columns={[1, 2, 4]} gap="3">
            {dataKanban?.map((x, i) => (
              <Stack key={i}>
                <CardAutomation
                  data={x}
                  title={x.type}
                  globalState={globalState}
                  onNavigate={() => handleNavigate(x)}
                  timeCreated={x?.lastUpdated?.seconds}
                  modalTeam={() => modalTeam(x)}
                  owner={x?.users}
                  Icon={FcFlashOn}
                />
              </Stack>
            ))}
          </SimpleGrid>
        ) : (
          <AlertArrayNull titleData={'Automation'} action="Add Automation" />
        )}

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button
                colorScheme={'blue'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                {t('loadMore')}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('team')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x?.name === data?.usersDisplay[0]?.name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              {t('creator')}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <Stack align={'flex-start'} spacing={0}>
                            <Text
                              fontWeight={500}
                              textTransform="capitalize"
                              fontSize={'sm'}
                            >
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          {data?.users?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action="delete"
                                size={'sm'}
                                // onClick={() => handleRemoveUser(x, data)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AutomationPage;
