//format number

// format ini untuk semua price rates, tinggal ditambahkan currency nya Ex : Rp. $. DLL
export function formatFrice(value,) {
  if (typeof value === 'number') {
    if (value < 1000) {
      let val = value.toFixed(2,);
      if (val === '0.00') {
        val = '0';
      } else {
        val = parseFloat(val,).toString();
      }
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',',);
    } else {
      return value.toFixed(0,).replace(/\B(?=(\d{3})+(?!\d))/g, ',',);
    }
  } else {
    return '0';
  }
}

export function formatNumberKpi(number) {
  if (Number.isInteger(number)) {
    return number.toLocaleString();
  } else {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}

export const formatNumbertoK = (num) => {
  if (num >= 1000) {
    return `${(num / 1000).toFixed(0)}k`; // Converts values 1000 and above to 'k'
  }
  return num; // Keeps values below 1000 as they are
};

//format IDR
//format USD
