export const getFileSizeInMB = (file) => {
  const fileSizeInBytes = file.size;
  // Convert bytes to megabytes
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMB;
};

export const getVideoDimensions = async (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight
      });
    };
    video.onerror = reject;
    video.src = URL.createObjectURL(file);
  });
};