/* eslint-disable react/react-in-jsx-scope */
import {
  AspectRatio,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useRef, useState } from 'react';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  FcAudioFile,
  FcBinoculars,
  FcFile,
  FcImageFile,
  FcShare,
  FcVideoFile,
} from 'react-icons/fc';
import { HiOutlineFolderOpen } from 'react-icons/hi';
import { FiCopy, FiDownload } from 'react-icons/fi';
import DropboxUploaderAsset from '../../Components/DropBox/DropboxUploaderAsset';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import UploadFileToDropboxV2 from '../../Components/DropBox/UploadFileToDropboxV2';

function ProductivityAssetsDetailPage() {
  const { t } = useTranslation();
  const param = useParams();
  const location = useLocation();
  const dataParam = location.state;
  const globalState = useUserStore();

  const [typeActive, setTypeActive] = useState('audio');
  const [dataFile, setDataFile] = useState(null);
  const [modalUpload, setModalUpload] = useState(false);
  const [path, setPath] = useState('');
  const [dataNew, setDataNew] = useState({});

  const [modalView, setModalView] = useState(false);
  const [fileView, setFileView] = useState('');
  const [fileCategory, setFileCategory] = useState('');

  const fileInputRef = useRef();
  const [fileContainer, setFileContainer] = useState([])
  const [singleImageUpload, setSingleImageUpload] = useState([])
  const [multipleFilePreview, setMultipleFilePreview] = useState([])
  const [progress, setProgress] = useState(0);
  const [filePreview, setFilePreview] = useState(null);
  const [shareLink, setShareLink] = useState('');

  const [searchInput, setSearchInput] = useState('');
  const [dataSearchFile, setDataSearchFile] = useState([]);

  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [modalProjectUser, setModalProjectUser] = useState(false);
  const [searchResult, setSearchResult] = useState('');
  const [access, setAccess] = useState('visitor');
  const [fileData, setFileData] = useState([]);

  const dataType = ['audio', 'image', 'video', 'file'];

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;

  const toast = useToast();

  

  const parentPath = `/${param.company}/digital-assets`;
  const accessToken = globalState?.accessToken;

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (files) {
      if (files?.length > 1) {
        if (fileContainer?.length > 0) {
          const combinedFiles = [...fileContainer, ...files];
          setFileContainer(combinedFiles);
          displayFilePreview(combinedFiles);
        } else {
          displayFilePreview(files);
          setFileContainer(files);
        }
      } else {
        displayFilePreview(files);
        setSingleImageUpload(files);
      }
    }
  };

  // const handleSaveButtonClick = async () => {
  //   const file = fileInputRef.current?.files[0];
  //   if (file) {
  //     // Upload file ke Dropbox ketika tombol "Save" diklik

  //     const data = await UploadFileToDropboxV2(
  //       file,
  //       parentPath,
  //       accessToken,
  //       setProgress
  //     );
  //     setShareLink(data.link);
  //     if (data) {
  //       const collectionName = 'files';
  //       const dataNewUpdate = {
  //         ...dataNew,
  //         path: data.path,
  //         link: data.link,
  //         type: 'digital_assets',
  //         category: data?.type,
  //         folderId: dataParam?.id || param?.id,
  //         size: Math.round(data?.size || 0),
  //         ratioWidth: data?.ratioWidth || 0,
  //         ratioHeight: data?.ratioHeight || 0,
  //         projectId: globalState?.currentProject,
  //         companyId: globalState?.currentCompany,
  //       };

  //       try {
  //         const docID = await addDocumentFirebase(
  //           collectionName,
  //           dataNewUpdate,
  //           globalState.currentCompany
  //         );
  //         toast({
  //           title: 'Deoapp',
  //           description: `Success add File ${docID}.`,
  //           status: 'success',
  //           isClosable: true,
  //           duration: 9000,
  //         });
  //         fetchData();
  //       } catch (error) {
  //         toast({
  //           title: 'oops!',
  //           description: error.message,
  //           isClosable: true,
  //           duration: 9000,
  //           status: 'error',
  //         });
  //       }
  //     }
  //   } else {
  //     toast({
  //       title: 'oops!',
  //       description: 'Please select a file to upload.',
  //       isClosable: true,
  //       duration: 9000,
  //       status: 'error',
  //     });
  //   }
  // };

  const handleSaveButtonClick = async () => {
    let files = singleImageUpload;

    if(singleImageUpload?.length === 0 || !singleImageUpload) {
      files = fileData
    }

    // console.log(singleImageUpload)
    // console.log(files)
    // console.log(fileData)
    // console.log(dataNew)

    if (files && files.length > 0) {
      const uploadPromises = [];
      const newFilesData = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i].file || files[i];
        
        // Upload each file to Dropbox
        const uploadPromise = UploadFileToDropboxV2(
          file,
          parentPath,
          accessToken,
          setProgress
        ).then((data) => {
          // Update the data for each file
          const dataNewUpdate = {
            ...dataNew,
            title: (singleImageUpload?.length === 0 || !singleImageUpload) ? files[i].title : dataNew?.title || 'New File',
            path: data.path,
            link: data.link,
            type: 'digital_assets',
            category: data?.type,
            folderId: dataParam?.id || param?.id,
            size: Math.round(data?.size || 0),
            ratioWidth: data?.ratioWidth || 0,
            ratioHeight: data?.ratioHeight || 0,
            projectId: globalState?.currentProject,
            companyId: globalState?.currentCompany,
          };

          newFilesData.push(dataNewUpdate);
          return data;
        });

        uploadPromises.push(uploadPromise);
      }

      // console.log(uploadPromises, '1234')

      try {
        const uploadedFiles = await Promise.all(uploadPromises);

        // Set the share link of the last uploaded file (optional)
        setShareLink(uploadedFiles[uploadedFiles.length - 1].link);

        const collectionName = 'files';

        for (const dataNewUpdate of newFilesData) {
          await addDocumentFirebase(
            collectionName,
            dataNewUpdate,
            globalState.currentCompany
          );
        }

        toast({
          title: 'Deoapp',
          description: 'Success add File.',
          status: 'success',
          isClosable: true,
          duration: 9000,
        });

        fetchData();
      } catch (error) {
        console.log(error)
        toast({
          title: 'oops!',
          description: error.message,
          isClosable: true,
          duration: 9000,
          status: 'error',
        });
      }
    } else {
      toast({
        title: 'oops!',
        description: 'Please select files to upload.',
        isClosable: true,
        duration: 9000,
        status: 'error',
      });
    }
  };


  // const displayFilePreview = (file) => {
  //   const fileUrl = URL.createObjectURL(file);
  //   const fileType = file.type.split('/')[0];

  //   switch (fileType) {
  //   case 'image':
  //     setFilePreview(
  //       <img src={fileUrl} alt="File Preview" style={{ width: '300px' }} />
  //     );
  //     break;
  //   case 'video':
  //     setFilePreview(
  //       <video src={fileUrl} controls style={{ width: '300px' }}>
  //           Your browser does not support the video tag.
  //       </video>
  //     );
  //     break;
  //   case 'audio':
  //     setFilePreview(
  //       <audio src={fileUrl} controls style={{ width: '300px' }}>
  //           Your browser does not support the audio element.
  //       </audio>
  //     );
  //     break;
  //   case 'application':
  //     // Jika tipe datanya adalah PDF, gunakan elemen iframe
  //     if (file.type === 'application/pdf') {
  //       setFilePreview(
  //         <iframe
  //           src={fileUrl}
  //           title="File Preview"
  //           width="300"
  //           height="200"
  //         ></iframe>
  //       );
  //     } else {
  //       setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
  //     }
  //     break;
  //   default:
  //     setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
  //     break;
  //   }
  // };

  const handleInputName = (e, index) => {
    const newFileData = [...fileData];
    newFileData[index]['title'] = e.target.value;
    setFileData(newFileData);
  };

  const displayFilePreview = (files) => {
    const previews = [];
    const newFileData = [];

    for (let i = 0; i < files?.length; ++i) {
      const file = files[i];
      const fileUrl = URL.createObjectURL(file);
      const fileType = file.type.split('/')[0];

      newFileData.push({ title: '', file });

      switch (fileType) {
      case 'image':
        previews.push(
          <img src={fileUrl} alt="File Preview" style={{ width: '250px', height: '250px', objectFit: 'cover' }} />
        );
        break;
      case 'video':
        previews.push(
          <video src={fileUrl} controls style={{ width: '250px', maxHeight: '250px' }}>
            Your browser does not support the video tag.
          </video>
        );
        break;
      case 'audio':
        previews.push(
          <audio src={fileUrl} controls style={{ width: '250px', height: '250px' }}>
            Your browser does not support the audio element.
          </audio>
        );
        break;
      case 'application':
        if (file.type === 'application/pdf') {
          previews.push(
            <iframe
              src={fileUrl}
              title="File Preview"
              width="300"
              height="250"
            ></iframe>
          );
        } else {
          previews.push(<div key={i}>Unsupported file type</div>);
        }
        break;
      default:
        previews.push(<div key={i}>Unsupported file type</div>);
        break;
      }
    }

    setFileData(newFileData);

    if (files?.length > 1) {
      setMultipleFilePreview(previews);
    } else {
      setFilePreview(previews);
    }
  };

  // console.log(multipleFilePreview)

  const handleDoneButton = () => {
    // Mengatur kembali state menjadi nilai awal
    setFilePreview(null);
    setMultipleFilePreview([])
    setProgress(0);
    setShareLink('');
    setPath('');
    setDataNew({});
    // Menghapus file yang dipilih dengan mengecek bahwa fileInputRef.current tidak null
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Deoapp',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  const fetchData = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      { field: 'type', operator: '==', value: 'digital_assets' },
      { field: 'category', operator: '==', value: typeActive },
      { field: 'folderId', operator: '==', value: dataParam?.id || param?.id },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue
      );
      setDataFile(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleFilter = (x) => {
    setTypeActive(x);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setDataFile(null);
      setDataSearchFile([]);
      setSelectedUserProjectIds([]);
    };
  }, [typeActive, startIndex, globalState?.currentCompany]);

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); // Tambahkan jumlah data per halaman saat tombol "Load More" diklik
  };

  const handleCloseModalUpload = () => {
    // Reset state saat modal ditutup
    setFilePreview(null);
    setMultipleFilePreview([])
    setProgress(0);
    setShareLink('');
    setPath('');
    setDataNew({});
    setModalUpload(false);
  };

  const handleOpenModal = (file) => {
    setFileCategory(file.category);
    setFileView(file.link);
    setModalView(true);
  };

  const handleCloseModal = () => {
    setModalView(false);
    setFileCategory('');

    setFileView('');
  };

  const handleSearch = (q) => {
    setSearchInput(q);
    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `type: digital_assets && companyId:${globalState.currentCompany}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('files')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setDataSearchFile(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);
    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });
    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };
  const handleAddTeamProject = async () => {
    const collectionName = `folders/${dataParam?.id || param?.id}/users`;
    let docName = '';
    let data = '';
    const mapIdUser = selectedUserProjectIds.map((x) => x?.id);
    const collectionNameArr = 'folders';
    const arrDocName = `${dataParam?.id || param?.id}`;
    let field = '';
    const values = mapIdUser;
    switch (access) {
    case 'visitor':
      selectedUserProjectIds.forEach(async (x) => {
        docName = x.id;
        data = x;
        try {
          await setDocumentFirebase(collectionName, docName, data);

          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });

      field = 'users';
      try {
        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          field,
          values
        );
        toast({
          title: 'Success',
          description: 'Success share this folder',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setModalProjectUser(false);
        setSelectedUserProjectIds([]);
        // setProjectActive("");
        setSearchResult([]);
        // getDataProjects();
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      break;
    default:
      toast({
        title: t('toast.error'),
        description: 'You should give users an access',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      break;
    }
  };
  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Spacer />
        <HStack>
          <Stack>
            <Input
              placeholder={t('search')}
              size={'sm'}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Stack>
          <Heading size={'md'} textTransform="capitalize">
            {t('file')} {dataParam?.name}
          </Heading>
          <Button onClick={() => setModalProjectUser(true)}>
            <FcShare />
          </Button>
        </HStack>
      </HStack>
      <Stack p={[1, 1, 5]}>
        {dataType.length > 0 && (
          <SimpleGrid columns={[1, 2, 4]} gap={5}>
            {dataType.map((x, index) => {
              const dataView =
                x === 'image' ? (
                  <FcImageFile size={50} />
                ) : x === 'audio' ? (
                  <FcAudioFile size={50} />
                ) : x === 'video' ? (
                  <FcVideoFile size={50} />
                ) : (
                  <FcFile size={50} />
                );
              return (
                <Stack
                  borderRadius={'md'}
                  borderWidth={2}
                  shadow={'md'}
                  borderColor={x === typeActive ? '#ffd600' : 'blackAlpha.300'}
                  key={index}
                  cursor="pointer"
                  onClick={() => handleFilter(x)}
                >
                  <Stack
                    alignItems={'center'}
                    h="150px"
                    justifyContent="center"
                  >
                    <Box>{dataView}</Box>
                    <Text textTransform={'capitalize'}>{x}</Text>
                  </Stack>
                </Stack>
              );
            })}
          </SimpleGrid>
        )}
      </Stack>
      <Divider py={2} borderWidth="2" borderColor={'blackAlpha.500'} />

      <Stack spacing={5} p={[1, 1, 5]}>
        <Stack>
          <Box textAlign={'end'}>
            <Button
              onClick={() => setModalUpload(true)}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('addFile')}
            </Button>
          </Box>
          {searchInput !== ''
            ? dataSearchFile.length > 0 && (
              <SimpleGrid columns={[1, 2, 4]} gap={4}>
                {dataSearchFile.map((y, index) => {
                  const dataView =
                      y.category === 'image' ? (
                        <FcImageFile size={50} />
                      ) : y.category === 'audio' ? (
                        <FcAudioFile size={50} />
                      ) : y.category === 'video' ? (
                        <FcVideoFile size={50} />
                      ) : (
                        <FcFile size={50} />
                      );

                  return (
                    <Stack
                      overflow={'hidden'}
                      key={index}
                      borderRadius="md"
                      p={3}
                      borderColor="blackAlpha.300"
                      shadow={'md'}
                      borderWidth={1}
                    >
                      <HStack alignItems={'flex-start'}>
                        <Stack>{dataView}</Stack>
                        <Stack w="100%">
                          <Text
                            fontSize={'sm'}
                            fontWeight={500}
                            textTransform="capitalize"
                          >
                            {y?.title}
                          </Text>
                          <Text
                            fontSize={'xx-small'}
                            noOfLines={1}
                            w="100px"
                          >
                                Size : {y?.size} mb
                          </Text>
                          <Text
                            fontSize={'xx-small'}
                            color="gray.600"
                            noOfLines={1}
                            w="100px"
                          >
                            {t('path')}: {y?.path}
                          </Text>
                          <Text fontSize={'xx-small'} color="gray.600">
                            {t('category')}: {y?.category}
                          </Text>
                          <Spacer />
                          <HStack
                            alignItems="center"
                            justifyContent={'center'}
                          >
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => handleCopy(y.link)}
                            >
                              <FiCopy />
                            </Button>
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => window.open(y.link, '_blank')}
                            >
                              <HiOutlineFolderOpen />
                            </Button>
                            <Button
                              w={'100%'}
                              size={'xs'}
                              onClick={() => handleOpenModal(y)}
                            >
                              <FiDownload />
                            </Button>
                          </HStack>
                        </Stack>
                      </HStack>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            )
            : dataFile?.length > 0 && (
              <SimpleGrid columns={[1, 2, 4]} gap={4}>
                {dataFile
                  ?.slice(0, startIndex + itemsPerPage)
                  .map((y, index) => {
                    const dataView =
                        y.category === 'image' ? (
                          <FcImageFile size={50} />
                        ) : y.category === 'audio' ? (
                          <FcAudioFile size={50} />
                        ) : y.category === 'video' ? (
                          <FcVideoFile size={50} />
                        ) : (
                          <FcFile size={50} />
                        );

                    return (
                      <Stack
                        overflow={'hidden'}
                        key={index}
                        borderRadius="md"
                        p={3}
                        borderColor="blackAlpha.300"
                        shadow={'md'}
                        borderWidth={1}
                      >
                        <HStack alignItems={'flex-start'}>
                          <Stack>{dataView}</Stack>
                          <Stack w="100%">
                            <Text
                              fontSize={'sm'}
                              fontWeight={500}
                              textTransform="capitalize"
                            >
                              {y?.title}
                            </Text>
                            <Text
                              fontSize={'xx-small'}
                              noOfLines={1}
                              w="100px"
                            >
                                Size : {y?.size} mb
                            </Text>
                            <Text
                              fontSize={'xx-small'}
                              color="gray.600"
                              noOfLines={1}
                              w="100px"
                            >
                              {t('path')}: {y?.path}
                            </Text>
                            <Text fontSize={'xx-small'} color="gray.600">
                              {t('category')}: {y?.category}
                            </Text>
                            <Spacer />
                            <HStack
                              alignItems="center"
                              justifyContent={'center'}
                            >
                              <Button
                                w={'100%'}
                                size={'xs'}
                                onClick={() => handleCopy(y.link)}
                              >
                                <FiCopy />
                              </Button>
                              <Button
                                w={'100%'}
                                size={'xs'}
                                onClick={() => window.open(y.link, '_blank')}
                              >
                                <HiOutlineFolderOpen />
                              </Button>
                              <Button
                                w={'100%'}
                                size={'xs'}
                                onClick={() => handleOpenModal(y)}
                              >
                                <FiDownload />
                              </Button>
                            </HStack>
                          </Stack>
                        </HStack>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
            )}
        </Stack>

        <Stack alignItems={'center'} justifyContent="center">
          <Box>
            {dataFile?.length > startIndex && (
              <Button colorScheme="blue" onClick={handleLoadMore} size={'sm'}>
                {t('loadMore')}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
      {/* Modal Upload */}
      <DropboxUploaderAsset
        modalOpen={modalUpload}
        onClose={handleCloseModalUpload}
        param={param}
        dataParam={dataParam}
        fileInputRef={fileInputRef}
        handleFileInputChange={handleFileInputChange}
        handleSaveButtonClick={handleSaveButtonClick}
        handleCopy={handleCopy}
        handleDoneButton={handleDoneButton}
        filePreview={filePreview}
        progress={progress}
        shareLink={shareLink}
        dataNew={dataNew}
        setDataNew={setDataNew}
        setFilePreview={setFilePreview}
        setProgress={setProgress}
        setShareLink={setShareLink}
        setPath={setPath}
        path={path}
        setFileContainer={setFileContainer}
        setSingleImageUpload={setSingleImageUpload}
        globalState={globalState}
        handleInputName={handleInputName}
        multipleFilePreview={multipleFilePreview}
        setMultipleFilePreview={setMultipleFilePreview}
      />

      <Modal isOpen={modalView} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {fileCategory === 'image' ? (
              <Stack>
                <Image src={fileView} width="300" alt={'image dropbox'} />
              </Stack>
            ) : (
              <AspectRatio maxW="560px" ratio={4 / 3}>
                <iframe
                  src={fileView}
                  title="File Preview"
                  width="300"
                  height="200"
                ></iframe>
              </AspectRatio>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              {t('close')}
            </Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalProjectUser}
        onClose={() => setModalProjectUser(false)}
        isCentered
        size={'md'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {dataParam?.name} {t('team')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5} py={3}>
              <HStack m="1">
                <Input
                  type="text"
                  placeholder={t('searchUser')}
                  onChange={(e) => handleSearchUsers(e.target.value)}
                />
              </HStack>

              {searchResult?.length > 0 ? (
                searchResult?.map((x, index) => {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x.document.name}
                        src={x.document.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x.document.name}</Text>
                        <Text>{x.document.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => handleUserProjectClick(x.document)}
                      >
                        +
                      </Button>
                    </HStack>
                  );
                })
              ) : (
                <></>
              )}
              {/* <SimpleGrid columns={2}> */}
              <Stack gap="2">
                <HStack
                  gap="2"
                  p="4"
                  rounded={5}
                  borderRightWidth={4}
                  shadow={'md'}
                  align={'left'}
                  justify={'left'}
                  cursor={'pointer'}
                  borderColor={access === 'visitor' ? 'blue.500' : 'none' } 
                  _hover={
                    access &&
                    access !== 'visitor' && {
                      bg: 'gray.100',
                      transform: 'scale(1.02)',
                      transition: '0.3s',
                      cursor: 'pointer',
                    }
                  }
                  onClick={() => setAccess('visitor')}
                >
                  <Box>
                    <FcBinoculars fontSize={'25'} />
                  </Box>
                  <Stack>
                    <Heading size={'sm'}> {t('visitor')}</Heading>
                    <Text fontSize={'sm'}>
                      {t('noAccess')}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>

              {/* </SimpleGrid> */}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <AvatarGroup size="sm" gap="1" max={4}>
                {selectedUserProjectIds.length > 0 &&
                  selectedUserProjectIds.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))}
              </AvatarGroup>
              <Spacer />
              <Button
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddTeamProject()}
              >
                {t('add')} {t('team')}
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalProjectUser(false);
                }}
              >
                {t('cancel')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ProductivityAssetsDetailPage;
