/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { Badge, Box, Button, Center, Fade, Heading, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { getCollectionFirebaseV4, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import {  onGetClientTypesenseFront } from '../../Api/Typesense';
import { db } from '../../Config/firebase';
import { useDrop } from 'react-dnd';
import TaskCardComponent from '../Card/TaskCardComponent';
import { useTranslation } from 'react-i18next';
import { formatNumbertoK } from '../../Utils/numberUtil';

const ColumnColorScheme = {
  BACKLOG: 'red',
  TODOS: 'blue',
  PROGRESS: 'green',
  REVIEW: 'yellow',
  DONE: 'gray',
};

function KanbanColumnsComponent({
  allowedDropEffect,
  column,
  kanbanData,
  filterData,
  handleModalOpen,
  stateRefresh,
}) {
  const {t} = useTranslation()
  const globalState = useUserStore();
  const [columnsData, setColumnsData] = useState([]);
  const [columnsData2, setColumnsData2] = useState([]);
  const [countDoc, setCountDoc] = useState(0);
  const param = useParams();
  useKanbanStore((state) => state.setFunctionAddData);

  const handleLoad = () => {
    const conditions = [
      { field: 'filesId', operator: '==', value: param?.id },
      { field: 'column', operator: '==', value: column },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = '';
    if (columnsData2?.length > 0) {
      startAfter = columnsData2[columnsData2?.length - 1].lastUpdated;
      if (filterData?.category)
        conditions.push({
          field: 'category',
          operator: '==',
          value: filterData?.category,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
      if (filterData?.createdBy)
        conditions.push({
          field: 'createdBy',
          operator: '==',
          value: filterData?.createdBy,
        });

      if (filterData?.asignee)
        conditions.push({
          field: 'asignee',
          operator: 'array-contains-any',
          value: filterData?.asignee,
        });
    } else {
      // console.log('pertama kali')
      startAfter = columnsData[columnsData.length - 1].lastUpdated;
      if (filterData?.category)
        conditions.push({
          field: 'category',
          operator: '==',
          value: filterData?.category,
        });

      if (filterData?.createdBy)
        conditions.push({
          field: 'createdBy',
          operator: '==',
          value: filterData?.createdBy,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });

      if (filterData?.asignee)
        conditions.push({
          field: 'asignee',
          operator: 'array-contains-any',
          value: filterData?.asignee,
        });
    }

    getCollectionFirebaseV4(
      'kanban',
      { conditions },
      { sortBy },
      { limitValue },
      { startAfterData: startAfter }
    ).then((x) => {
      const updateData = [...columnsData2, ...x];
      setColumnsData2(updateData);
    });
  };

  const handleTypesenseSearch = async (q) => {
    try {
      const query = q ? q : '*';
      let fieldName = 'title';
      const sort = '_text_match:desc';
      let filter = `filesId: ${param.id} && column:${column} `;

      if (filterData?.createdBy) {
        fieldName += ',createdBy' ;
        filter += ` && createdBy:="${filterData?.createdBy}"`;
      }
      if (filterData?.asignee) {
        fieldName += ',asignee' ;
        filter += ` && asignee:="${filterData?.asignee}"`;
      }
      const searchResults = await onGetClientTypesenseFront(
        query,
        'kanban',
        fieldName,
        filter,
        5,
        null,
        sort
      );

      const hits = searchResults.hits.map((hit) => hit.document);

      const newData = await Promise.all(
        hits?.map(async (x) => {
          return await getSingleDocumentFirebase('kanban', x?.id);
        })
      );

      setCountDoc(searchResults.found)
      setColumnsData(newData);
    } catch (error) {
      console.log(error.message)
    }
    
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (filterData?.search)
      setTimeout(function () {
        handleTypesenseSearch(filterData.search);
      }, 300);

    if (globalState.currentCompany && !filterData?.search) {
      let collectionRef = collection(db, 'kanban');
      collectionRef = query(collectionRef, where('filesId', '==', param.id));
      collectionRef = query(collectionRef, where('column', '==', column));
      collectionRef = query(
        collectionRef,
        where('companyId', '==', globalState?.currentCompany)
      );

      if (filterData?.category)
        collectionRef = query(
          collectionRef,
          where('category', '==', filterData?.category)
        );

      if (filterData?.label)
        collectionRef = query(
          collectionRef,
          where('label', '==', filterData.label)
        );

      if (filterData?.createdBy)
        collectionRef = query(
          collectionRef,
          where('createdBy', '==', filterData?.createdBy)
        );

      if (filterData?.asignee)
        collectionRef = query(
          collectionRef,
          where('asignee', 'array-contains-any', filterData.asignee)
        );

      const limitedQuery = query(collectionRef, orderBy('lastUpdated', 'desc'), limit(5));

      unsubscribe = onSnapshot(limitedQuery, (querySnapshot) => {
        const addTask = [];

        querySnapshot.forEach((doc) => {
          addTask.push({ id: doc.id, ...doc.data() });
        });


        getCountFromServer(collectionRef)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setCountDoc(assessmentLength)
            // console.log(assessmentLength)
          })
          .catch((error) => {
            throw error;
          })
        // console.log('addTask', addTask)
        setColumnsData(addTask);
      });
    }

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [globalState.currentCompany, filterData]);

  useEffect(() => {
    const updateColumnsData2 = () => {
      if (
        Array.isArray(columnsData2) &&
        stateRefresh &&
        stateRefresh.length > 0
      ) {
        const newData = columnsData2.filter(
          (item) => !stateRefresh.includes(item?.id)
        );
        setColumnsData2(newData);
      }
    };

    if (stateRefresh && stateRefresh.length > 0) {
      updateColumnsData2();
    }
  }, [stateRefresh]); 

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect]
  );

  const ColumnTasks = (datas, type, handleModalOpen) =>
    datas?.map((x, index) => {
      // console.log(x, 'ini datanya di columntask')
      if (x?.id)
        return (
          <Fade in={true} initialscale={0.9} key={index}>
            <TaskCardComponent
              key={index}
              index={index}
              task={x}
              setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
              columnsData={datas}
              kanbanData={kanbanData}
              handleModalOpen={handleModalOpen}
              // onDropHover={swapTasks}
              //   onUpdate={updateTask}
              //   onDelete={deleteTask}
            />
          </Fade>
        );
    });

  return (
    <Box maxW="full" m="1">
      <HStack w={'full'} position={'relative'}>
        <Heading w={'full'} fontSize="md" mb={4}  letterSpacing="wide" textAlign="center">
          <Badge
            w="full"
            fontSize="xl"
            px={2}
            py={1}
            rounded="lg"
            colorScheme={ColumnColorScheme[column.toUpperCase()]}
          >
            {column}
          </Badge>
        </Heading>
        <Center cursor={'pointer'} position={'absolute'} boxSize='1.5em' borderRadius={'full'} transform="translateY(-80%)" right={2}  top="50%" bg={`${ColumnColorScheme[column.toUpperCase()]}.400`}  whiteSpace="nowrap" overflow={'hidden'}> <Tooltip label={`Detail: ${countDoc}`} size={'sm'}><Text fontSize={'12px'} fontWeight={'medium'} textOverflow={'ellipsis'} isTruncated >{formatNumbertoK(countDoc)}</Text></Tooltip></Center>
      </HStack>

      <Stack
        ref={drop}
        direction="column"
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver && 'red'}
        rounded="lg"
        boxShadow="md"
        overflow="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            w: '2',
            h: '3',
          },
          '&::-webkit-scrollbar-track': {
            w: '6',
            h: '3',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10',
            bg: 'gray.300',
          },
        }}
        // scrollSnapStop={false}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? (
          ColumnTasks(columnsData2, 'manual', handleModalOpen)
        ) : (
          <></>
        )}
        {columnsData?.length > 3 && columnsData2?.length === 0 ? (
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {t('loadMore')}
          </Button>
        ) : columnsData2?.length > 3 ? (
          <Button
            onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={'white'}
            fontSize="sm"
          >
            {t('loadMore')}
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
}

export default KanbanColumnsComponent;
