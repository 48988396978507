/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';

import 'reactflow/dist/style.css';
import useCopyPaste from '../../Components/Flowchart/useCopyPaste';
import UseUndoRedo from '../../Components/Flowchart/useUndoRedo';
import { useNavigate, useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';
import { shallow } from 'zustand/shallow';
import {
  arrayUnionFirebase,
  getDocWithSnapshotFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import ProductivityShapeNode from './ProductivityShapeNodePage';
import {
  FcBinoculars,
  FcEditImage,
  FcRedo,
  FcShare,
  FcUndo,
} from 'react-icons/fc';
import { clientTypessense } from '../../Api/Typesense';
import { dataButton, dataColor } from '../../Components/Flowchart/dataButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import ReactFlow, {
  isEdge,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MarkerType,
  ConnectionMode,
  ConnectionLineType,
  ReactFlowProvider,
  Controls,
  MiniMap,
  Panel,
} from 'reactflow';
import DownloadButtonComponent from '../../Components/Buttons/DownloadButtonComponent';
import { useTranslation } from 'react-i18next';
import ModalAddUser from '../Workflow/Components/ModalAddUser';

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  updateNodeLabel: state.updateNodeLabel,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  setTitle: state.setTitle,
  setOwner: state.setOwner,
  owner: state.owner,
  title: state.title,
});

const nodeTypes = {
  shape: ProductivityShapeNode,
};

const MindmapPages = () => {
  const { t } = useTranslation();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = UseUndoRedo();
  const { copy } = useCopyPaste();

  const params = useParams();
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    setNodes,
    setEdges,
    setTitle,
    owner,
    setOwner,
    title,
  } = useFlowchartStore(selector, shallow);
  const [flowchart, setFlowchart] = useState([]);
  const [latestId, setlatestId] = useState('');
  const [latestPos, setLatestPos] = useState({ x: 0, y: 0 });
  const [edgeId, setEdgeId] = useState('');
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [searchResult, setSearchResult] = useState('');
  const [selectedNode, setSelectedNode] = useState();
  const [access, setAccess] = useState('');

  const [modalProjectUser, setModalProjectUser] = useState(false);
  const proOptions = { account: 'paid-pro', hideAttribution: true };

  const getFlowchart = async () => {
    try {
      const res = await getDocWithSnapshotFirebase('flowcharts', params?.id);
      if (res) {
        setTitle(res.title);
        setFlowchart(res);
        setNodes(res.nodes);
        setEdges(res.edges);
        setlatestId(res.nodes.length);
        setEdgeId(res.edges.length);
        setLatestPos({
          x: res.nodes[res.nodes.length - 1].position.x,
          y: res.nodes[res.nodes.length - 1].position.y,
        });

        if (res.owner.includes(globalState?.uid)) {
          setOwner(true);
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getFlowchart();
    return () => {
      setFlowchart();
      setEdges([]);
      setNodes([]);
      setAccess('');
      setSelectedUserProjectIds([]);
      setOwner('');
      setSearchResult([]);
      setModalProjectUser(false);
    };
  }, []);

  const [nodeName, setNodeName] = useState('Node 1');
  const [nodeActive, setNodeActive] = useState(null);

  const handleEdgeClick = (event, edge) => {
    const newElements = edges.map((el) => {
      if (el.id === edge.id && isEdge(el)) {
        const newValue = prompt('Masukkan nilai baru:');
        if (newValue !== null) {
          el = { ...el, label: newValue };
        }
      }
      return el;
    });
    setEdges(newElements);
  };

  const handleNodeClick = (e, node) => {
    let i;
    const newElements = nodes.map((el) => {
      if (el.id === node.id) {
        i = el.id;
      }
    });
    setNodeActive(newElements);
    setSelectedNode(node);
    copy();
  };

  const handleAdd = async (type, color) => {
    takeSnapshot();

    const newNode = {
      id: `${latestId + 1}`,
      type: 'shape',
      position: { x: 0, y: 0 },
    };
    const newEdges = {
      id: `reactflow__edge-${latestId + 1}-${edgeId + 1}`,
      source: `${latestId}`,
      target: `${latestId + 1}`,
      sourceHandle: 'bottom',
      targetHandle: 'top',
    };
    switch (type) {
    case 'diamond':
      newNode.data = {
        shape: type,
        color: '#fdc606',
        width: 120,
        height: 120,
      };
      newNode.position = {
        ...newNode.position,
        x: parseInt(latestPos.x + 25),
      };
      break;
    case 'parallelogram':
      newNode.data = {
        shape: type,
        width: 150,
        height: 70,
        color: '#ffb884',
      };
      break;
    case 'hexagon':
      newNode.data = {
        shape: type,
        width: 150,
        height: 70,
        color: '#d9ead3',
      };
      break;
    case 'Ellipse':
      newNode.data = {
        shape: 'circle',
        width: 150,
        height: 50,
        color: '#ff0072',
      };
      break;
    case 'circle':
      newNode.data = { shape: type, width: 70, height: 70, color: '#ffffff' };
      newNode.position = {
        ...newNode.position,
        x: parseInt(latestPos.x + 40),
      };
      break;
    case 'Arrow Rectangle':
      newNode.data = {
        shape: 'arrow-rect',
        width: 130,
        height: 50,
        color: '#784be8',
      };
      break;
    case 'arrow-rect':
      newNode.data = { shape: type, width: 150, height: 70, color: null };
      break;
    case 'database':
      newNode.data = { shape: type, width: 100, height: 80, color: null };
      // newNode.position = { ...newNode.position, x: parseInt(latestPos.x + 25) }
      break;
    case 'Triangle':
      newNode.data = {
        shape: 'triangle',
        width: 100,
        height: 70,
        color: '#ff6700',
      };
      break;
    case 'offpage-reference':
      newNode.data = { shape: type, width: 100, height: 60, color: null };
      break;
    case 'process':
      newNode.data = { shape: type, width: 90, height: 90, color: '#f17924' };
      break;
    case 'manual-operation':
      newNode.data = { shape: type, width: 120, height: 60, color: null };
      break;
    case 'document':
      newNode.data = {
        shape: type,
        width: 120,
        height: 60,
        color: '#7030a1',
      };
      break;
    case 'predefined-process':
      newNode.data = { shape: type, width: 120, height: 60, color: null };
      break;
    case 'display':
      newNode.data = { shape: type, width: 90, height: 60, color: null };
      break;
    default:
      newNode.data = {
        shape: 'round-rect',
        width: 150,
        height: 75,
        color: null,
      };
      break;
    }

    const res = await setDocumentFirebase(
      'flowcharts',
      params.id,
      {
        nodes: [...nodes, newNode],
        edges: [...edges, newEdges],
        companyId: globalState?.currentCompany,
        projetId: globalState?.currentProject,
      },
      globalState.currentCompany
    );

    if (res) {
      setLatestPos({ x: parseInt(latestPos.x), y: latestPos.y + 150 });
      setNodes([...nodes, newNode]);
      setEdges([...edges, newEdges]);
      setlatestId(latestId + 1);
      setEdgeId(edgeId + 1);
    }
  };

  const onNodeDrag = async (e, node) => {
    setLatestPos({ x: node.position.x, y: node.position.y });
  };

  const onNodeDragStart = useCallback(() => {
    // 👇 make dragging a node undoable
    takeSnapshot();
    // 👉 you can place your event handlers here
  }, [takeSnapshot]);

  const onSelectionDragStart = useCallback(() => {
    // 👇 make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);
  const defaultEdgeOptions = {
    type: 'smoothstep',
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { strokeWidth: 2 },
  };
  const onNodesDelete = useCallback(
    (deleted) => {
      // 👇 make deleting nodes undoable
      takeSnapshot();

      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `reactflow__edge-${source}-${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [takeSnapshot, nodes, edges]
  );

  const onEdgesDelete = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const handleSave = async () => {
    const newData = {
      ...flowchart,
      edges: edges,
      nodes: nodes,
      lastUpdated: new Date(),
      lastUpdatedBy: {
        uid: globalState.uid,
        email: globalState.email,
      },
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
    };

    try {
      const res = await setDocumentFirebase(
        'flowcharts',
        params.id,
        newData,
        globalState.currentCompany
      );
      if (res) {
        toast({
          title: 'Saved',
          description: res.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/flowchart');
      }
    } catch (error) {
      toast({
        title: t('toast.error'),
        description: error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onDropNodes = async () => {
    try {
      setTimeout(async () => {
        const res = await setDocumentFirebase(
          'flowcharts',
          params.id,
          { nodes: nodes, edges: edges },
          globalState.currentCompany
        );
      }, 15000);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };
  const handleAddTeamProject = async () => {
    const collectionName = `flowcharts/${params.id}/users`;
    let docName = '';
    let data = '';
    const mapIdUser = selectedUserProjectIds.map((x) => x.id);
    const collectionNameArr = 'flowcharts';
    const arrDocName = `${params?.id}`;
    let field = '';
    const values = mapIdUser;

    switch (access) {
    case 'visitor':
      selectedUserProjectIds.forEach(async (x) => {
        docName = x.id;
        data = x;
        try {
          await setDocumentFirebase(collectionName, docName, data);

          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });

      field = 'users';
      try {
        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          field,
          values
        );
        toast({
          title: 'Success',
          description: 'Success share this flowchart',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setModalProjectUser(false);
        setSelectedUserProjectIds([]);
        // setProjectActive("");
        setSearchResult([]);
        // getDataProjects();
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      break;
    case 'editor':
      selectedUserProjectIds.forEach(async (x) => {
        docName = x.id;
        data = x;
        try {
          await setDocumentFirebase(collectionName, docName, data);
          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });
      field = 'owner';
      try {
        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          'owner',
          values
        );

        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          'users',
          values
        );

        setModalProjectUser(false);
        setSelectedUserProjectIds([]);
        setSearchResult([]);
        toast({
          title: 'Success',
          description: 'Success share this flowchart',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      break;
    default:
      toast({
        title: t('toast.error'),
        description: 'You should give users an access',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      break;
    }
  };

  const ColorPalleteComponent = () => {
    const handleColorChange = (selectedColor) => {
      if (selectedNode) {
        const updatedNodes = nodes.map((node) => {
          if (node.id === selectedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                color: selectedColor,
              },
            };
          }
          return node;
        });

        setNodes(updatedNodes);
      }
    };

    return (
      <Stack gap={2}>
        <Text pt={5}>{t('colorPalette')}</Text>
        <Divider />
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          {dataColor?.map((color, i) => (
            <Box
              key={i}
              w={7}
              h={7}
              bgColor={color}
              onClick={() => handleColorChange(color)}
              cursor={'pointer'}
            ></Box>
          ))}
        </Grid>
      </Stack>
    );
  };

  const width = window.innerWidth;
  const heigth = window.innerHeight;

  return (
    <Stack>
      <HStack
        w={width}
        h={heigth}
        align={'left'}
        border={'1px solid lightgrey'}
      >
        <Box
          bgColor="gray.200"
          spacing={5}
          borderRadius="md"
          w={owner ? '80%' : '100%'}
          h={heigth}
        >
          <ReactFlow
            proOptions={proOptions}
            nodesDraggable={owner ? true : false}
            nodes={nodes}
            edges={edges}
            defaultEdgeOptions={defaultEdgeOptions}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeDrag={onNodeDrag}
            onNodeClick={handleNodeClick}
            onEdgeDoubleClick={handleEdgeClick}
            nodeTypes={nodeTypes}
            onNodeDragStop={onDropNodes}
            onNodeDragStart={onNodeDragStart}
            onSelectionDragStart={onSelectionDragStart}
            onNodesDelete={onNodesDelete}
            onEdgesDelete={onEdgesDelete}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            connectionMode={ConnectionMode.Loose}
          >
            <Panel position="top-left">
              <HStack
                bgColor={'white'}
                p={2}
                px={7}
                borderRadius={'3xl'}
                boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              >
                <BackButtons />
                <Center height="30px">
                  <Divider orientation="vertical" />
                </Center>
                <Text color={'darkgray'}>{title}</Text>
              </HStack>
            </Panel>
            <Controls />
            <MiniMap />
            <Panel position="top-right">
              <HStack
                bgColor={'white'}
                p={2}
                px={7}
                borderRadius={'3xl'}
                boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              >
                <Text color={'lightgrey'} size={'sm'}>
                  {t('panelButton')}
                </Text>
                <Center height="30px">
                  <Divider orientation="vertical" />
                </Center>
                <DownloadButtonComponent />
                {owner ? (
                  <>
                    <Button
                      bgColor={'transparent'}
                      size={'sm'}
                      disabled={canUndo}
                      onClick={undo}
                    >
                      <FcUndo />
                    </Button>
                    <Button
                      bgColor={'transparent'}
                      size={'sm'}
                      disabled={canRedo}
                      onClick={redo}
                    >
                      <FcRedo />
                    </Button>
                    <Button
                      bgColor={'transparent'}
                      onClick={() => setModalProjectUser(true)}
                      size={'sm'}
                    >
                      <FcShare />
                    </Button>
                    <Button
                      size={'sm'}
                      bgColor={'blue.600'}
                      onClick={() => handleSave()}
                    >
                      {t('save')}
                    </Button>
                  </>
                ) : (
                  <Button
                    bgColor={'transparent'}
                    onClick={() => setModalProjectUser(true)}
                    size={'sm'}
                  >
                    <FcShare />
                  </Button>
                )}
              </HStack>
            </Panel>
          </ReactFlow>
        </Box>

        <Stack
          flexDir={'column'}
          p={[1, 1, 5]}
          spacing={5}
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;"
          w="20%"
          h={heigth}
          display={!owner && 'none'}
          justify={'space-between'}
        >
          <Stack gap={2}>
            <Text>{t('basicFlowchartShape')}</Text>
            <Divider />
            <Grid templateColumns="repeat(4, 1fr)" gap={2}>
              {dataButton.map((item, id) => (
                <Box key={id}>
                  <Button
                    // boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    title={item.type}
                    h={10}
                    bgColor={'transparent'}
                    size={'md'}
                    display={!owner && 'none'}
                    onClick={() => handleAdd(`${item.shape}`)}
                    textTransform={'capitalize'}
                  >
                    <Image src={item.image} />{' '}
                  </Button>
                </Box>
              ))}
            </Grid>
            <Divider />
            <ColorPalleteComponent />
          </Stack>
        </Stack>

        <ModalAddUser
          title={'Flowchart'}
          modalProjectUser={modalProjectUser}
          setModalProjectUser={setModalProjectUser}
          handleSearchUsers={handleSearchUsers}
          searchResult={searchResult}
          handleUserProjectClick={handleUserProjectClick}
          owner={owner}
          access={access}
          setAccess={setAccess}
          selectedUserProjectIds={selectedUserProjectIds}
          handleAddTeamProject={handleAddTeamProject}
        />
      </HStack>
    </Stack>
  );
};

const ProductivityFlowchartCanvasPage = (props) => {
  return (
    <ReactFlowProvider>
      <MindmapPages {...props} />
    </ReactFlowProvider>
  );
};
export default ProductivityFlowchartCanvasPage;
