/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import CustomStepper from '../Stepper/CustomStepper';
import KanbanViewOtomation from './KanbanViewOtomation';
import moment from 'moment';
import { addDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import { useTranslation } from 'react-i18next';

function OtomationModalMom({ data, onClose }) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [successOrder] = useState(false);
  const [currentStep, setCurrentStep] = useState('');
  const [steps] = useState([]);

  const toast = useToast();

  const globalState = useUserStore();

  const [stateFromId, setStateFromId] = useState([]);
  const [stateAction, setStateAction] = useState('');
  const [stateToId, setStateToId] = useState([]);
  const [stateTo, setStateTo] = useState('');

  const [typeFile, setTypeFile] = useState('kanban')
  const [fileData, setFileData] = useState([])



  const handleCheckedData = (index, isChecked) => {
    const newData = [...stateFromId];
    if (isChecked) {
      newData.push(data.details[index]);
    } else {
      const dataIndex = newData.findIndex(
        (item) => item === data.details[index]
      );
      if (dataIndex !== -1) {
        newData.splice(dataIndex, 1);
      }
    }
    setStateFromId(newData);
  };

  const handleChecklist = (action) => {
    setStateAction(action)
  };

  const handleChecklistTo = (action) => {
    setStateTo(action);
    setTypeFile(action)
  };

  const handleSave = async () => {
    globalState.setIsLoading(true)

    const dataRes = {
      typeId: data?.id,
      fileFrom: stateFromId,
      type: 'mom',
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      action: stateAction,
      fileToId: stateToId,
      fileTo: stateTo,
      createdAt: new Date(),
      isActive: true,
      dataAutomation: data,
      timeActionSchedule: moment().unix(),
    };


    try {
      if (
        !dataRes.typeId ||
        !dataRes.companyId ||
        !dataRes.projectId ||
        !dataRes.action ||
        !dataRes.fileToId.length
      ) {
        return toast({
          title: t('warning'),
          description: 'Required data is missing.',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }

      switch (stateAction) {
      case 'create':
        const filePromises = stateFromId.map(async (x) => {
          const collectionName = 'kanban';
          const filesPromises = stateToId.map(async (y) => {
            const fileDataSend = {
              score: 6,
              title: `${data?.title}-${x?.key}` || '',
              column: x.value || '',
              companyId: dataRes.companyId || '',
              projectId: dataRes.projectId || '',
              dueOn: moment(x.timeTo).unix() || 0,
              timeFrom: moment(x.timeFrom).unix() || '',
              timeTo: moment(x.timeTo).unix() || '',
              asignee: data.users?.length > 0 ? data.users : [],
              pic:
                  data.pic?.length > 0
                    ? data?.pic?.map((value) => value.uid)
                    : [],
              createdAt: new Date(),
              filesId: y.id,
              filesName: y.title,
              lastUpdated: new Date(),
            };

            try {
              // Lakukan operasi penyimpanan data ke database di sini
              // Misalnya:
              await addDocumentFirebase(
                collectionName,
                fileDataSend,
                globalState.currentCompany
              );
              toast({
                title: t('success'),
                description: `success create new Kanban ${x.key}`,
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            } catch (error) {
              toast({
                title: t('error'),
                description: error.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          });

          await Promise.all(filesPromises); // Menunggu semua operasi penyimpanan selesai
        });

        await Promise.all(filePromises); // Menunggu semua operasi pengolahan file selesai
        break;
      case 'update':
        // console.log('Action is update');
        break;
      default:
        // console.log('Unknown action');
        break;
      }
      await addDocumentFirebase(
        'automation',
        dataRes,
        globalState.currentCompany
      );
      // console.log('ID Dokumen Baru:', docID);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setStateFromId([]);
      setStateAction('');
      setStateToId([]);
      onClose()
      setStateTo('');
      globalState.setIsLoading(false);
    }
  };

  const handleDone = () => {
    setStep((prev) => prev + 1);
    setCurrentStep('SUCCESS');
    handleSave();
  };


  const getDataKanban = async () => {
    const conditions = [
      { field: 'type', operator: '==', value: typeFile },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 999

    try {
      const res = await getCollectionFirebase('files', conditions, sortBy, limitValue)
      setFileData(res);


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      })
    }

  }

  useEffect(() => {
    if (globalState?.currentProject) {
      getDataKanban()
    }


    return () => {
    }
  }, [globalState?.currentProject, typeFile])

  

  return (
    <Stack padding={5} spacing={5}>
      <Stack p={5} borderRadius="md" shadow={'md'}>
        <CustomStepper steps={steps} currentStep={currentStep} />
      </Stack>
      1
      {step === 0 && (
        <Box>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            Klik Mulai untuk mengatur otomatisasi.
          </Text>

          <Button
            width="100%"
            colorScheme="green"
            mt={10}
            onClick={() => {
              {
                setStep((prev) => prev + 1);
                setCurrentStep('FROM');
              }
            }}
          >
            Mulai
          </Button>
        </Box>
      )}
      {step === 1 && (
        <Box>
          <Stack pt={4} spacing={5}>
            <Text fontWeight={600} fontSize={17} textAlign="center">
              Apakah Anda ingin mengatur data ini dan sudah memastikan bahwa
              data tersebut sudah diperbarui ?
            </Text>

            <Stack>
              {data?.details?.map((x, index) => {
                const isChecked = stateFromId.some((item) => item === x);

                return (
                  <Stack key={index}>
                    <HStack key={index}>
                      <Checkbox
                        onChange={(e) =>
                          handleCheckedData(index, e.target.checked)
                        }
                        isChecked={isChecked}
                      />
                      <Text>{x.key}</Text>
                      <Spacer />
                      <Text fontWeight={500} textTransform="capitalize">
                        {x.value}
                      </Text>
                    </HStack>
                    <Divider />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>

          <HStack>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  setStep((prev) => prev - 1);
                  setCurrentStep('');
                }
              }}
            >
              Kembali
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  stateFromId?.length === 0
                    ? toast({
                      title: t('warning'),
                      description: t('toast.fieldRequired'),
                      status: 'warning',
                    })
                    : setStep((prev) => prev + 1);
                  setCurrentStep('ACTION');
                }
              }}
            >
              Selanjutnya
            </Button>
          </HStack>
        </Box>
      )}
      {step === 2 && (
        <Box>
          <Stack pt={4} spacing={5}>
            <Text fontWeight={600} fontSize={17} textAlign="center">
              Pilih Tindakan yang Anda inginkan ?
            </Text>
          </Stack>
          <Stack>
            <HStack>
              <Checkbox
                isChecked={stateAction === 'create'}
                onChange={() => handleChecklist('create')}
              />
              <Text>Create</Text>
            </HStack>
            <Divider />
            {/* <HStack>
              <Checkbox
                isDisabled={true}
                isChecked={stateAction === 'update'}
                onChange={() => handleChecklist('update')}
              />
              <Text>Update</Text>
            </HStack> */}
          </Stack>

          <HStack>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  setStep((prev) => prev - 1);
                  setCurrentStep('Action');
                }
              }}
            >
              Kembali
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  typeFile === ''
                    ? toast({
                      title: t('warning'),
                      description: t('toast.fieldRequired'),
                      status: 'warning',
                    })
                    : setStep((prev) => prev + 1),
                  setCurrentStep('TO');
                }
              }}
            >
              Selanjutnya
            </Button>
          </HStack>
        </Box>
      )}
      {step === 3 && (
        <Box>
          <Stack pt={4} spacing={5}>
            <Text fontWeight={600} fontSize={17} textAlign="center">
              Pilih file yang ingin kamu kirimkan data-nya ?
            </Text>

            <HStack>
              <Checkbox
                isChecked={stateTo === 'kanban'}
                onChange={() => handleChecklistTo('kanban')}
              />
              <Text>Kanban</Text>
            </HStack>
            <Divider />

            <HStack>
              <Checkbox
                isChecked={stateTo === 'operations'}
                onChange={() => handleChecklistTo('operations')}
              />
              <Text>Operations</Text>
            </HStack>

            <Stack>
              <KanbanViewOtomation
                stateTo={stateToId}
                setStateTo={setStateToId}
                fileData={fileData}
              />
            </Stack>
          </Stack>

          <HStack>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  setStep((prev) => prev - 1);
                  setCurrentStep('ACTION');
                }
              }}
            >
              Kembali
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mt={10}
              onClick={() => {
                {
                  stateToId?.length === 0 || stateTo === ''
                    ? toast({
                      title: t('warning'),
                      description: t('toast.fieldRequired'),
                      status: 'warning',
                    })
                    : handleDone();
                }
              }}
            >
              Selesai
            </Button>
          </HStack>
        </Box>
      )}
      {successOrder && (
        <Stack>
          <Text>selesai</Text>
        </Stack>
      )}
    </Stack>
  );
}

export default OtomationModalMom;
