/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import {
  Avatar,
  AvatarGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase, arrayUnionFirebase } from '../../Api/firebaseApi';
import moment from 'moment';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { doc, onSnapshot } from '@firebase/firestore';
import BackButtons from '../../Components/Buttons/BackButtons';
import { clientTypessense } from '../../Api/Typesense';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import LiveTime from '../../Components/Timer/LiveTime';
import CountdownTimerV2 from '../../Components/Timer/CountdownTimerV2';
import { RiTimerLine } from 'react-icons/ri';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getDeviceType } from '../../Hooks/Middleware/sessionMiddleWare';
import { getUserByUid } from '../../Utils/getUser';
import KanbanLineColumnsComponent from '../../Components/Columns/KanbanLineColumnsComponent';
import { useTranslation } from 'react-i18next';
import ExportKanbanModal from '../../Components/Modals/ExportKanbanModal';
import { FaFileExport } from 'react-icons/fa';

function LineViewPage() {
  const { t } = useTranslation();
  const [kanbanData, setKanbanData] = useState();
  // const [search, setSearch] = useState({ status: false });
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const param = useParams();
  const navigate = useNavigate();
  const [toggleStopwatch, setToggleStopwatch] = useState(false);
  const toast = useToast();
  const exportKanbanView = useDisclosure()
  const updateUserDisplay = useKanbanStore((state) => state.setUserDisplay);
  const {
    userDisplay,
    setTaskData,
    setFilterData,
    filterData,
    resetFilterData,
  } = useKanbanStore();

  const globalState = useUserStore();

  const findProject = globalState.projects?.find(
    (x) => x.id === kanbanData?.projectId
  );

  const ColumnType = {
    BACKLOG: 'backlog',
    TODOS: 'todos',
    PROGRESS: 'progress',
    REVIEW: 'review',
    DONE: 'done',
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        new Error(error.message, 'Failed to send Slack login message');
      });
  };

  const handleNewCard = async () => {
    const durationInDays = kanbanData.duration || 0;

    const dueOnUnix = moment().add(durationInDays, 'days').unix();

    setTaskData(kanbanData);
    setLoading(true);
    addDocumentFirebase(
      'kanban',
      {
        title: 'New Card',
        asignee: [globalState.uid],
        column: 'todos',
        filesId: param.id,
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        createdAt: new Date(),
        lastUpdated: new Date(),
        dueOn: dueOnUnix,
        timeFrom: moment().unix(),
        timeTo: dueOnUnix,
        score: 6,
        pic: [],
        size: 0,
      },
      globalState.currentCompany
    )
      .then((docId) => {
        setTaskData({
          index: 0,
          columnsData: [],
          setData: function () {},
          task: {
            id: docId,
            title: 'New Card',
            asignee: [globalState.uid],
            column: 'todos',
            pic: [],
            filesId: param.id,
            companyId: globalState?.currentCompany,
            projectId: globalState?.currentProject,
            createdAt: new Date(),
            lastUpdated: new Date(),
            dueOn: dueOnUnix,
            timeFrom: moment().unix(),
            timeTo: dueOnUnix,
            score: 6,
            size: 0,
          },
        });
        navigate(docId, { state: { type: 'newCard' } });
      })
      .catch((error) => {
        new Error(error.message, 'Failed to send api  message');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddUser = (param, x) => {
    try {
      arrayUnionFirebase('files', param.id, 'users', [x.document.id]);
      arrayUnionFirebase('files', param.id, 'usersDisplay', [
        { ...x.document },
      ]);
      toast({
        title: 'Deoapp.com',
        description: 'success add users',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setSearchResult([]);
      handleLogActivity(param.id, x);
    }
  };

  const handleLogActivity = async (id, user) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL,
      createdBy: auth?.currentUser?.uid,
      type: 'operations',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'invite',
      collection_path: 'files',
      collection_id: id,
      from: auth?.currentUser?.displayName,
      from_id: auth?.currentUser?.uid,
      to: user.document.name,
      to_id: user.document.id,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      new Error(error.message, 'Failed to send Slack login message');
    }
  };

  const deviceType = getDeviceType();

  useEffect(() => {
    let unsub = () => {};

    unsub = onSnapshot(doc(db, 'files', param.id), (doc) => {
      setKanbanData(doc.data());
      updateUserDisplay(doc.data()?.usersDisplay);
    });

    return () => {
      unsub();
      setKanbanData();
    };
  }, []);

  return (
    <>
      <Box>
        <HStack flexDirection={['column', 'column', 'row']}>
          <BackButtons />

          <Box maxW={['auto', '500px', '700px']}>
            <Heading noOfLines={1} size={'lg'}>
              Kanban {param.title}
            </Heading>
            <Text fontSize="2xs" color={'gray.500'}>
              ID :{param.id}
            </Text>
            <Text fontSize="2xs" color={'gray.500'}>
              {t('project')} : {findProject?.name}
            </Text>
          </Box>
          <Spacer />
          {/* 
            {filterData?.asignee !== "" ||
            filterData?.label !== "" ||
            filterData?.category !== "" ? (
              <CountdownTimer seconds={60} />
            ) : (
              <CountdownTimerV2 />
            )} */}

          <HStack flexDirection={['column', 'row', 'row']} spacing={3}>
            <Stack
              alignItems={'flex-end'}
              justifyContent="flex-end"
              spacing={1}
            >
              <HStack>
                <AvatarGroup size="sm" gap="1" max={4}>
                  {userDisplay ? (
                    userDisplay?.map((x, i) => (
                      <Avatar key={i} name={x?.name} />
                    ))
                  ) : (
                    <></>
                  )}
                </AvatarGroup>
                <DynamicButton
                  size="sm"
                  action="create"
                  variant={'solid'}
                  onClick={onOpen}
                />
                <DynamicButton
                  size="sm"
                  action="custom"
                  variant={'solid'}
                  color={'orange'}
                  icon={RiTimerLine}
                  onClick={() => setToggleStopwatch((prev) => !prev)}
                />
                <DynamicButton
                  size="sm"
                  tooltip={'Export'}
                  action="custom"
                  variant={'solid'}
                  color={'blue'}
                  icon={FaFileExport}
                  onClick={() => exportKanbanView.onOpen()}
                />
              </HStack>

              <DynamicButton
                title={t('editKanban')}
                size="sm"
                variant="solid"
                action={'update'}
                onClick={() =>
                  navigate(`/line/edit/${kanbanData?.id || param.id}`, {
                    state: kanbanData,
                  })
                }
              />
            </Stack>

            {toggleStopwatch ? (
              <Stack
                bgColor={'blue.500'}
                shadow="md"
                py={1}
                px={2}
                borderRadius="md"
              >
                <CountdownTimerV2 seconds={60} />
              </Stack>
            ) : (
              <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
                <LiveTime color={'white'} />
              </Stack>
            )}
          </HStack>
        </HStack>

        <Box p={2} my={3} borderRadius={'md'} shadow={'base'}>
          <SimpleGrid
            borderRadius={'sm'}
            gap={1}
            columns={[1, 5, 5]}
            // bgColor="white"
            px="2"
            // py="3"
            // mx="1"
            // mt="3"
            // mb="2"
            // shadow="base"
          >
            <Select
              value={filterData?.asignee}
              placeholder={t('assignee')}
              textTransform={'capitalize'}
              onChange={(e) => {
                let updateData = [];
                if (filterData?.asignee?.length > 0)
                  updateData = filterData.asignee;
                updateData.push(e.target.value);
                setFilterData({ ...filterData, asignee: updateData });
                // console.log({ ...filterData, asignee: updateData }, "filterDataa")
              }}
            >
              {kanbanData?.usersDisplay?.length > 0 ? (
                kanbanData?.usersDisplay
                  .sort((a, b) => a?.name?.localeCompare(b?.name))
                  ?.map((x, index) => {
                    return (
                      <option key={index} value={x.id}>
                        {x.name ? x?.name : x?.email}
                      </option>
                    );
                  })
              ) : (
                <></>
              )}
            </Select>

            <Select
              value={filterData?.category}
              placeholder={t('category')}
              textTransform={'capitalize'}
              onChange={(e) => {
                // console.log({ ...filterData, category: e.target.value }, 'filter category')
                setFilterData({ ...filterData, category: e.target.value });
              }}
            >
              {kanbanData?.category ? (
                kanbanData?.category
                  ?.sort((a, b) => a?.localeCompare(b))
                  ?.map((x, i) => (
                    <option key={i} value={x}>
                      {x}
                    </option>
                  ))
              ) : (
                <></>
              )}
            </Select>

            <Select
              value={filterData?.label}
              placeholder="Label"
              onChange={(e) => {
                setFilterData({ ...filterData, label: e.target.value });
                // console.log({ ...filterData, label: e.target.value })
              }}
            >
              <option option="help">help</option>
              <option option="priority">priority</option>
              <option option="urgent">urgent</option>
              <option option="pending">pending</option>
              <option option="blocked">blocked</option>
              <option option="completed">completed</option>
            </Select>
            <Input
              type="text"
              placeholder={t('search')}
              value={filterData?.search}
              onChange={(e) => setFilterData({ search: e.target.value })}
            />

            <DynamicButton
              title={t('newCard')}
              isLoading={loading}
              variant="solid"
              action="create"
              onClick={() => {
                handleNewCard();
              }}
            />
          </SimpleGrid>

          {filterData?.asignee || filterData?.category || filterData?.label ? (
            <HStack spacing={0}>
              {filterData?.asignee ? (
                <HStack
                  p="2"
                  m="2"
                  // bgColor="white"
                  borderRadius="md"
                  shadow="base"
                >
                  <Text fontSize={12} textAlign="center">
                    {t('assignee')}:
                  </Text>
                  <HStack>
                    {filterData.asignee.map((uid, index) => {
                      const user = getUserByUid(globalState?.users, uid);
                      return (
                        <HStack spacing={3} key={index}>
                          <Avatar
                            key={index}
                            size="2xs"
                            marginRight="-2"
                            name={user?.name || 'Unknown'}
                            src={user?.image || <></>}
                          />
                          <Text fontSize={12}>{user?.name}</Text>
                        </HStack>
                      );
                    })}
                  </HStack>
                </HStack>
              ) : (
                <></>
              )}
              {filterData?.category ? (
                <HStack
                  fontSize={12}
                  p="2"
                  m="2"
                  // bgColor="white"
                  borderRadius="md"
                  shadow="base"
                >
                  {/* <Text position='fixed' top='0' right='2'>x</Text> */}
                  <Text textAlign="center">{t('category')}:</Text>
                  <Text textAlign="center" textTransform={'capitalize'}>
                    {filterData?.category}
                  </Text>
                </HStack>
              ) : (
                <></>
              )}
              {filterData?.label ? (
                <HStack
                  fontSize={12}
                  p="2"
                  m="2"
                  // bgColor="white"
                  borderRadius="md"
                  shadow="base"
                >
                  <Text textAlign="center">Label: </Text>
                  <Text textAlign="center" textTransform={'capitalize'}>
                    {filterData?.label}
                  </Text>
                </HStack>
              ) : (
                <></>
              )}
              {filterData?.search ? (
                <HStack
                  fontSize={12}
                  p="2"
                  m="2"
                  // bgColor="white"
                  borderRadius="md"
                  shadow="base"
                >
                  <Text textAlign="center">{t('search')}</Text>
                  <Text textAlign="center" textTransform={'capitalize'}>
                    {filterData?.search}
                  </Text>
                </HStack>
              ) : (
                <></>
              )}
              <Spacer />
              {/* <Button minW='min-content' colorScheme='green'>Search</Button> */}
              {/* <Button minW='min-content' colorScheme='green' onClick={() => console.log(filterData)}>check console</Button> */}
              <Button
                size={'xs'}
                minW="min-content"
                colorScheme="red"
                onClick={() => {
                  resetFilterData();
                }}
              >
                {t('clear')} Filter
              </Button>
              {/* <Text
                  as={"u"}
                  ml={1}
                  fontStyle={"italic"}
                  color={"blue.600"}
                  onClick={() => {
                    resetFilterData();
                  }}
                  cursor={"pointer"}
                  fontSize={12}
                >
                  Clear Filter
                </Text> */}
            </HStack>
          ) : (
            <></>
          )}
        </Box>

        <DndProvider
          backend={deviceType === 'desktop' ? HTML5Backend : TouchBackend}
        >
          <SimpleGrid columns={[1, 2, 5]}>
            <KanbanLineColumnsComponent
              kanbanData={kanbanData}
              allowedDropEffect="move"
              filterData={filterData}
              column={ColumnType.BACKLOG}
            />
            <KanbanLineColumnsComponent
              kanbanData={kanbanData}
              allowedDropEffect="move"
              filterData={filterData}
              column={ColumnType.TODOS}
            />
            <KanbanLineColumnsComponent
              kanbanData={kanbanData}
              allowedDropEffect="move"
              filterData={filterData}
              column={ColumnType.PROGRESS}
            />
            <KanbanLineColumnsComponent
              kanbanData={kanbanData}
              allowedDropEffect="move"
              filterData={filterData}
              column={ColumnType.REVIEW}
            />
            <KanbanLineColumnsComponent
              kanbanData={kanbanData}
              allowedDropEffect="move"
              filterData={filterData}
              column={ColumnType.DONE}
            />
          </SimpleGrid>
        </DndProvider>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack m="1">
              <Input
                type="text"
                placeholder="Search users"
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>
            {searchResult?.length > 0 ? (
              searchResult?.map((x, index) => {
                if (kanbanData?.users.find((z) => z !== x.document.id)) {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x?.document?.name}
                        src={x?.document.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x?.document?.name}</Text>
                        <Text>{x?.document?.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleAddUser(param, x);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  );
                }
              })
            ) : (
              <></>
            )}
          </ModalBody>

          {/* <ModalFooter>
                          <Button colorScheme='blue' mr={3} onClick={onClose}>
                              Close
                          </Button>
                      </ModalFooter> */}
        </ModalContent>
      </Modal>
      <ExportKanbanModal isOpen={exportKanbanView.isOpen} onClose={exportKanbanView.onClose} kanbanData={kanbanData}/>
    </>
  );
}

export default LineViewPage;
