/* eslint-disable react/prop-types */
import {
  Box,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import bellSound from '../../assets/sounds/mixkit-attention-bell-ding-586.wav';
import { MdPause, MdPlayArrow, MdRefresh } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const formatTime = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  if (minutes <= 10) minutes = '0' + minutes;
  if (seconds <= 10) seconds = '0' + seconds;

  return minutes + ' : ' + seconds;
};

const CountdownTimerV2 = ({ seconds }) => {
  const {t} = useTranslation()
  const [countDown, setCountDown] = useState(seconds);
  const [isStopping, setIsStopping] = useState(true);
  const timerId = useRef();
  const { resetFilterData } = useKanbanStore();
  const endSound = new Audio(bellSound);

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timerId.current);
      endSound.play();
      resetFilterData();
    }
  }, [countDown]);

  const stop = () => {
    clearInterval(timerId.current);
    setIsStopping(true);
  };

  const continuex = () => {
    timerId.current = setInterval(() => {
      setCountDown((prev) => {
        if (prev <= 0) {
          clearInterval(timerId.current);
          endSound.play();
          resetFilterData();
          return 0;
        }
        return prev - 1;
      });
      setIsStopping(false);
    }, 1000);
  };

  const reset = () => {
    clearInterval(timerId.current);
    setCountDown(seconds);
    setIsStopping(true);
  };

  const fillPercentage = isStopping
    ? 0
    : ((seconds - countDown) / seconds) * 100;
  const bgColor = `linear-gradient(90deg, rgb(217, 227, 250) 0%, rgb(217, 227, 250) ${fillPercentage}%, transparent ${fillPercentage}%, transparent 100%)`;

  return (
    <HStack spacing={1}>
      <HStack
        alignItems="center"
        justify={'center'}
        borderRadius="md"
        p={1}
        spacing={2}
        transition="background 1s"
        w={130}
        bg={bgColor}
      >
        <Stack
          align={'center'}
          color={'white'}
          justifyContent={'center'}
          spacing={0}
        >
          <Heading size="lg" pb={1} color={'white'}>
            {formatTime(countDown)}
          </Heading>
          <Text fontWeight={'bold'} fontSize={12}>
            {t('countdown')}
          </Text>
        </Stack>
      </HStack>

      <Divider size={'lg'} orientation="vertical" />

      <Stack color={'white'} align="center" justify="center" pr={1} spacing={1}>
        <Box>
          {isStopping ? (
            <MdPlayArrow cursor="pointer" size={18} onClick={continuex} />
          ) : (
            <MdPause size={15} cursor="pointer" onClick={stop} />
          )}
        </Box>
        <MdRefresh cursor="pointer" size={18} onClick={reset} />
        <Text
          fontWeight="500"
          cursor="pointer"
          fontSize={13}
          onClick={() => setCountDown((prev) => prev + 60)}
        >
          +1
        </Text>
      </Stack>
    </HStack>
  );
};

export default CountdownTimerV2;
