/* eslint-disable react/react-in-jsx-scope */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
  Center,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  deleteSubcollection,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import { collection, getDocs } from '@firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FcFolder } from 'react-icons/fc';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

function ProductivityAssetsPage() {
  const { t } = useTranslation();
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate();
  const alertDelete = useDisclosure();

  const [dataSearched, setDataSearched] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const [dataFolder, setDataFolder] = useState([]);
  const [dataNewFolder, setDataNewFolder] = useState({});
  const [modalFolder, setModalFolder] = useState(false);
  const [modal, setModal] = useState({ delete: false, user: false });
  const [selectedFolder, setSelectedFolder] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;
  const [data, setData] = useState({});
  const [editor, setEditor] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchCompanyName = globalState?.companies?.find(
    (x) => x.id === globalState?.currentCompany
  );

  const fetchDataV2 = async () => {
    if (globalState?.currentCompany) {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        { field: 'type', operator: '==', value: 'digital_assets' },
        { field: 'users', operator: 'array-contains', value: globalState?.uid },
      ];
      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      try {
        globalState?.setIsLoading(true);
        const resAssets = await getCollectionFirebase(
          'folders',
          conditions,
          sortBy,
          limitValue
        );
        const projectDataArray = await Promise.all(
          resAssets.map(async (projectData) => {
            const userSnapshot = await getDocs(
              collection(db, `folders/${projectData?.id}/users`)
            );
            const userData = userSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            projectData.usersProjectData = userData;
            return projectData;
          })
        );

        setDataFolder(projectDataArray.length > 0 ? projectDataArray : []);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        globalState?.setIsLoading(false);
      }
    }
  };

  const handleSearch = (text) => {
    if (text) {
      const newData = dataFolder.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setDataSearched(newData);
      setSearchInput(text);
    } else {
      setDataSearched([]);
      setSearchInput(text);
    }
  };

  const handleAddFolder = (e) => {
    const { value, name } = e.target;
    setDataNewFolder((prev) => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const handleAddNewFolder = async () => {
    setLoading(true);

    const companyId = globalState?.currentCompany;
    const dataUpdate = {
      ...dataNewFolder,
      company_name: searchCompanyName?.name,
      companyId,
      projectId: globalState?.currentProject,
      owner: [globalState?.uid],
      users: [globalState?.uid],
      category: 'folder',
      type: 'digital_assets',
    };

    const collectionName = 'folders';

    try {
      const docID = await addDocumentFirebase(
        collectionName,
        dataUpdate,
        companyId
      );

      if (docID) {
        const collectionNameFolder = `folders/${docID}/users`;
        const dataUser = {
          name: globalState?.name,
          uid: globalState?.uid,
          projectId: globalState?.currentProject,
        };

        try {
          await addDocumentFirebase(collectionNameFolder, dataUser, companyId);
          toast({
            title: 'Success',
            description: 'Succes to add new folder.',
            isClosable: true,
            duration: 9000,
            status: 'success',
          });
          fetchDataV2();
          setModalFolder(false);
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      }
      handleLogActivity(docID, dataUpdate);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogActivity = async (id, data) => {
    const dataActive = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Digital Assets',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Add New Folder',
      collection_path: 'folders',
      title: data.name,
      id: id,
    };

    try {
      await addActivity(dataActive); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteFolder = async () => {
    try {
      const res = await deleteSubcollection(
        `folders/${selectedFolder?.id}/users`
      );

      if (res) {
        const resDeleteFolder = await deleteDocumentFirebase(
          'folders',
          selectedFolder?.id
        );
        if (resDeleteFolder) {
          handleLogActivities(selectedFolder);
          setSelectedFolder('');
          setModal({ delete: false, user: false });
          toast({
            title: 'Success',
            description: resDeleteFolder,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          alertDelete?.onClose();
          fetchDataV2();
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLogActivities = async (x) => {
    const dataActive = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Digital Assets',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Delete Folder',
      collection_path: 'folders',
      title: x.name,
      id: x.id,
    };

    try {
      await addActivity(dataActive);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersProjectData?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      fetchDataV2();
    }

    return () => {
      // setDataFolder([])
      setDataSearched([]);
      setEditor([]);
    };
  }, [globalState?.currentCompany, startIndex]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack gap={5} flexDirection={['column', 'column', 'row']}>
        <BackButtons />
        <Heading size={'md'}>Folder</Heading>
        <Spacer />
        <HStack flexDirection={['column', 'row', 'row']}>
          <Stack>
            <Input
              shadow={'md'}
              // bgColor="white"
              placeholder={t('search')}
              size={'md'}
              borderRadius="md"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Stack>
          <DynamicButton
            action={'create'}
            title={t('newFolder')}
            onClick={() => setModalFolder(true)}
            variant={'solid'}
          />
        </HStack>
      </HStack>

      {dataFolder?.length > 0 && searchInput === '' ? (
        <SimpleGrid columns={[1, 2, 4]} gap={5}>
          {dataFolder.map((x, index) => (
            <Stack key={index}>
              <CardAvatar
                data={x && x}
                title={x?.name}
                globalState={globalState}
                onNavigate={() =>
                  navigate(`/assets/${x?.companyId}/${x?.id}`, { state: x })
                }
                userArray={x?.usersProjectData}
                timeCreated={x?.lastUpdated?.seconds}
                modalTeam={() => modalTeam(x)}
                owner={x?.owner}
                onDelete={() => {
                  handleOpenAlertDelete();
                  setSelectedFolder(x);
                }}
                Icon={FcFolder}
              />
            </Stack>
          ))}
        </SimpleGrid>
      ) : dataSearched.length > 0 ? (
        <SimpleGrid columns={[1, 2, 4]} gap={4}>
          {dataSearched.map((x, index) => {
            return (
              <Stack key={index}>
                <CardAvatar
                  data={x && x}
                  title={x?.name}
                  globalState={globalState}
                  onNavigate={() =>
                    navigate(`/assets/${x.company_name}/${x.name}`, {
                      state: x,
                    })
                  }
                  userArray={x?.usersProjectData}
                  timeCreated={x?.lastUpdated?.seconds}
                  modalTeam={() => modalTeam(x)}
                  owner={x?.owner}
                  onDelete={() => {
                    handleOpenAlertDelete();
                    setSelectedFolder(x);
                  }}
                  Icon={FcFolder}
                />
              </Stack>
            );
          })}
        </SimpleGrid>
      ) : (
        <AlertArrayNull titleData={t('assetsFolder')} action={t('newFolder')} />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {dataFolder?.length >= itemsPerPage &&
            dataFolder?.length > startIndex && (
            <Button
              onClick={handleLoadMore}
              colorScheme={'blue'}
              fontSize="sm"
            >
              {t('loadMore')}
            </Button>
          )}
        </Box>
      </Stack>

      <Modal
        isOpen={modalFolder}
        onClose={() => setModalFolder(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('newFolder')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={1}>
              <Stack>
                <Input
                  name="name"
                  type="text"
                  placeholder={t('nameFolder')}
                  onChange={handleAddFolder}
                />
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                isLoading={loading}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddNewFolder()}
              >
                {t('addFolder')}
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalFolder(false);
                }}
              >
                {t('cancel')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteFolder')}
        onAction={handleDeleteFolder}
      />

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'} textTransform="capitalize">
                    {data?.name}
                  </Heading>
                  <Text fontSize={'xs'}>{t('createdBy')} :</Text>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x.name === data?.usersProjectData[0].name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x.name} />
                          <Stack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x.name}
                            </Text>
                            <Text fontSize={'xs'}>{x.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              {t('creator')}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <Stack align={'flex-start'} spacing={0}>
                            <Text
                              fontWeight={500}
                              textTransform="capitalize"
                              fontSize={'sm'}
                            >
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          {data?.owner?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton action="delete" size={'sm'} />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ProductivityAssetsPage;
