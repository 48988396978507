/* eslint-disable */
import {
  AspectRatio,
  Box,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Skeleton,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { data } from '../Sidebar/DataMenu';
import BackButtons from '../Buttons/BackButtons';
import { useNavigate } from 'react-router-dom';
import {
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import { useTranslation } from 'react-i18next';

function MainMenuComponent(props) {
  if (!props) return;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [dataConfig, setDataConfig] = useState({});

  let menuName = '';
  if (props.menuName) {
    menuName = props.menuName;
  }

  const getDataMenu = async () => {
    const conditions = [
      {
        field: 'module',
        operator: '==',
        value: 'management',
      },
      {
        field: 'title',
        operator: '==',
        value: menuName,
      },
    ];

    try {
      const result = await getCollectionFirebase('config_menu', conditions);

      setDataConfig(result?.[0] || {});  // Atur default sebagai objek kosong
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  const handleNavigate = (value) => {
    if (!value.submenu || value?.submenu?.length === 0) {
      navigate(`${value.link}`);
    }
  };

  useEffect(() => {
    getDataMenu();

    return () => {
      setDataConfig({});
    };
  }, []);

  return (
    <Box p={[1, 1, 5]}>
      <BackButtons />
      <Stack align={'center'} >
        <SimpleGrid columns={[1, 1, 2]} gap={5} p={[1, 1, 5]}>
          <Stack>
            {dataConfig?.video ? (
              <Stack
                justify={'center'}
                textAlign={'center'}
                bgRepeat={'no-repeat'}
                bgSize={'cover'}
                opacity={videoLoaded ? 1 : 0} // Atur opacity untuk efek transisi
                transition={'opacity 0.3s ease-in-out'} // Transisi opasitas
              >
                <AspectRatio ratio={16 / 9}>
                  <Box
                    as="iframe"
                    title="YouTube video player"
                    src={dataConfig?.video}
                    allowFullScreen
                    frameBorder="0"
                    onLoad={() => setVideoLoaded(true)} // Mengatur video sebagai loaded saat selesai dimuat
                    visibility={videoLoaded ? 'visible' : 'hidden'} // Atur visibility untuk efek transisi
                    transition={'visibility 0.3s ease-in-out'} // Transisi visibility
                  ></Box>
                </AspectRatio>
              </Stack>
            ) : (
              <Skeleton width="450px" height="300px" />
            )}
          </Stack>
          <Stack  spacing={5}>
            <Heading textTransform={'capitalize'} >{t(menuName)}</Heading>
            <Text
              alignText={['center', 'start', 'start']}
              display={['flex', 'flex', 'flex']}
              color={'gray.500'}
              fontSize={['xs', 'sm', 'md']}
              // noOfLines={['4', '5', 'none']}
            >
              {dataConfig?.description || ''}
            </Text>
          </Stack>
        </SimpleGrid>
      </Stack>

      <Box  py={4} shadow={'md'}>
        <Text
          color={'gray.500'}
          fontWeight={500}
          mb={5}
          textTransform={'capitalize'}
        >
          {t(dataConfig?.title)} Menu
        </Text>
        <SimpleGrid columns={[1, 2, 4]} spacing={5}>
          {data
            .find((menu) => menu.name === props.menuName)
            ?.submenu?.map((x, i) => (
              <Stack key={i}>
                <Popover
                  placement="top"
                  isLazy
                  closeOnBlur={true}
                  arrowSize={0}
                >
                  <PopoverTrigger>
                    <Stack
                      p={3}
                      key={i}
                      border={'1px'}
                      shadow={'base'}
                      align={'center'}
                      cursor={'pointer'}
                      borderRadius={'md'}
                      borderColor={'gray.300'}
                      onClick={() => handleNavigate(x)}
                      position="relative"
                      _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
                    >
                      {x?.status && (
                        <Tag
                          textTransform={'capitalize'}
                          fontWeight={500}
                          position={'absolute'}
                          size="sm"
                          top={3}
                          right={3}
                          colorScheme={'red'}
                        >
                          {x?.status}
                        </Tag>
                      )}
                      <Icon as={x.icon} boxSize={12} />
                      <Text fontWeight={500}>{t(x.name)}</Text>
                    </Stack>
                  </PopoverTrigger>
                  {!x.submenu ? null : (
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          <SimpleGrid
                            columns={x.submenu?.length > 4 ? 2 : 1}
                            spacing={3}
                          >
                            {x.submenu?.map((subitem, i) => (
                              <Box
                                p={3}
                                shadow={'base'}
                                borderRadius={'md'}
                                cursor={'pointer'}
                                onClick={() => navigate(`${subitem.link}`)}
                                key={i}
                                _hover={{
                                  transform: 'scale(1.03)',
                                  transition: '0.3s',
                                }}
                              >
                                <Stack spacing="3" align={'center'}>
                                  <Icon as={subitem.icon} boxSize={6} />
                                  <Text
                                    pl={3}
                                    fontWeight={'500'}
                                    fontSize="sm"
                                    noOfLines={1}
                                  >
                                    {t(subitem.name)}
                                  </Text>
                                </Stack>
                              </Box>
                            ))}
                          </SimpleGrid>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  )}
                </Popover>
              </Stack>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default MainMenuComponent;
