/* eslint-disable react/react-in-jsx-scope */
import {
  ConnectionMode,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  MarkerType,
} from 'reactflow';
import { useToast } from '@chakra-ui/toast';
import { doc, onSnapshot } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import useUndoRedo from '../../Components/MindMap/useUndoRedo';
import { useFlowchartStoreMindmap } from '../../Hooks/Zustand/reactFlow';
import { db } from '../../Config/firebase';
import { arrayUnionFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import {
  Center,
  Divider,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/layout';
import BackButtons from '../../Components/Buttons/BackButtons';
import ModalAddUser from './Components/ModalAddUser';
import PanelControl from '../../Components/MindMap/PanelControl';
import useAutoLayout from './Components/UseAutoLayout';

import { useControls, Leva } from 'leva';
import edgeTypes from './Components/EdgeType';
import nodeTypes from './Components/NodeType';
import PerformanceComponent from './Components/PerformanceComponent';


function Mindmap() {
  const params = useParams();
  const selector = (state) => ({
    nodes: state.nodes,
    edges: state.edges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
    title: state.title,
    setTitle: state.setTitle,
    owner: state.owner,
    setOwner: state.setOwner,
  });
  const toast = useToast();
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const [access, setAccess] = useState('visitor');
  const [searchResult, setSearchResult] = useState([]);
  const [modalProjectUser, setModalProjectUser] = useState();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const {
    nodes,
    edges,
    onConnect,
    setNodes,
    setEdges,
    onEdgesChange,
    onNodesChange,
    title,
    setTitle,
    owner,
    setOwner,
  } = useFlowchartStoreMindmap(selector);

  const getMindmap = async () => {
    try {
      const docRef = doc(db, 'flowcharts', params.id);

      // Gunakan onSnapshot untuk memantau perubahan data secara real-time
      onSnapshot(docRef, (docDAta) => {
        if (docDAta.exists()) {
          const res = docDAta.data();
          setNodes(res.nodes);
          setEdges(res.edges);
          setTitle(res.title);
          setOwner(res.owner.includes(globalState.uid));
          setData(res);
        }
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getMindmap();

    return () => {
      setData([]);
      setEdges([]);
      setNodes([]);

      setAccess('');
      setSelectedUserProjectIds([]);
      setOwner('');
      setSearchResult([]);
      setModalProjectUser(false);
    };
  }, []);

  const proOptions = { account: 'paid-pro', hideAttribution: true };

  const onNodeDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const options = {
    about: {
      value:
        'Add child nodes by clicking a node in the graph. Add new root nodes by clicking the button below.',
      editable: true,
    },
    algorithm: {
      value: 'd3-hierarchy',
      options: [ 'd3-hierarchy'],
    },
    direction: {
      value: 'TB',
      options: {
        down: 'TB',
      },
    },
    spacing: [50, 5],
  }
  const [position]=useState(options)


  const layoutOptions = useControls(position);

  

  useAutoLayout(layoutOptions);



  const defaultEdgeOptions = {
    type: 'step',
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { strokeWidth: 3 },
  };



  const save = async () => {
    const newData = {
      edges: edges,
      nodes: nodes,
      lastUpdated: new Date(),
      lastUpdatedBy: {
        uid: globalState.uid,
        email: globalState.email,
      },
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
    };
    try {
      const res = await setDocumentFirebase('flowcharts', params.id, newData);
      if (res) {
        toast({
          title: 'Saved',
          description: res.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // navigate('/mindmap')
      }
    } catch (error) {
      toast({
        title: error.message,
        description: error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);
    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });
    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  const handleAddTeamProject = async () => {
    const collectionName = `flowcharts/${params.id}/users`;
    let docName = '';
    let data = '';
    const mapIdUser = selectedUserProjectIds.map((x) => x.id);
    const collectionNameArr = 'flowcharts';
    const arrDocName = `${params?.id}`;
    let field = '';
    const values = mapIdUser;

    switch (access) {
    case 'visitor':
      selectedUserProjectIds.forEach(async (x) => {
        docName = x.id;
        data = x;
        try {
          await setDocumentFirebase(collectionName, docName, data);

          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });

      field = 'users';
      try {
        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          field,
          values
        );
        toast({
          title: 'Success',
          description: 'Success share this flowchart',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setModalProjectUser(false);
        setSelectedUserProjectIds([]);
        setSearchResult([]);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      break;
    case 'editor':
      selectedUserProjectIds.forEach(async (x) => {
        docName = x.id;
        data = x;
        try {
          await setDocumentFirebase(collectionName, docName, data);
          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });
      field = 'owner';
      try {
        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          'owner',
          values
        );

        await arrayUnionFirebase(
          collectionNameArr,
          arrDocName,
          'users',
          values
        );

        setModalProjectUser(false);
        setSelectedUserProjectIds([]);
        setSearchResult([]);
        toast({
          title: 'Success',
          description: 'Success share this flowchart',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      break;
    default:
      toast({
        title: 'error code',
        description: 'You should give users an access',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      break;
    }
  };



  const width = window.innerWidth;
  const heigth = window.innerHeight;

  return (
    <div style={{ width: width, heigth: heigth }}>
      <HStack>
        <Stack
          w={'70%'}
          border={'1px'}
          borderColor={'gray.400'}
          bgColor="gray.200"
          borderRadius={'lg'}
          color="black"
          h={heigth}
        >
          <div
            className="simple-floatingedges"
            style={{ width: '100%', height: '800px', backgroundColor:'#171523' }}
          >
            <ReactFlow
              fitView
              nodes={nodes}
              edges={edges}
              onConnect={onConnect}
              defaultEdgeOptions={defaultEdgeOptions}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeDragStart={onNodeDragStart}
              onSelectionDragStart={onSelectionDragStart}
              proOptions={proOptions}
              connectionMode={ConnectionMode.Loose}
            >
              <Controls />
              <MiniMap />
              <Panel position="top-left">
                <HStack
                  bgColor={'white'}
                  p={2}
                  px={7}
                  borderRadius={'3xl'}
                  boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                >
                  <BackButtons />
                  <Center height="30px">
                    <Divider orientation="vertical" />
                  </Center>
                  <Text color={'darkgray'}>{title}</Text>
                </HStack>
              </Panel>
              <PanelControl
                data={data}
                globalState={globalState}
                canUndo={canUndo}
                undo={undo}
                canRedo={canRedo}
                redo={redo}
                setModalProjectUser={setModalProjectUser}
                save={save}
              />
            </ReactFlow>
          </div>

          <ModalAddUser
            title={'Workflow'}
            modalProjectUser={modalProjectUser}
            setModalProjectUser={setModalProjectUser}
            handleSearchUsers={handleSearchUsers}
            searchResult={searchResult}
            handleUserProjectClick={handleUserProjectClick}
            owner={owner}
            access={access}
            setAccess={setAccess}
            selectedUserProjectIds={selectedUserProjectIds}
            handleAddTeamProject={handleAddTeamProject}
          />
        </Stack>
        <Stack 
          borderRadius={'lg'}
          h={heigth}
          w={'30%'}
        >
          <PerformanceComponent nodes={nodes} edges={edges} />
        </Stack>
      </HStack>
    </div>
  );
}

function WorkflowDetailPage() {
  return (
    <ReactFlowProvider>
      <Leva hidden={true} />
      <Mindmap />
    </ReactFlowProvider>
  );
}

export default WorkflowDetailPage;
