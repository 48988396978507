/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import RichTextEditor from '../Quill/RichTextEditor';
import DropboxUploader from '../DropBox/DropboxUploader';
import parse from 'html-react-parser';
import DynamicButton from '../Buttons/DynamicButton';
import { useTranslation } from 'react-i18next';

function TaskCardAddComment(props) {
  const {t} = useTranslation()
  const globalState = useUserStore();
  const { userDisplay } = useKanbanStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');

  const titleComment = props.title;

  const [value, setValue] = useState('');
  const [data, setData] = useState();


  const handleContentChange = (value) => {
    setValue(value);
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setValue((prevContent) => prevContent + ` ${htmlContent}`);
    }
  };

  const handleSave = () => {
    if (!value) return;
    addDocumentFirebase(
      `kanban/${props.id}/comments`,
      {
        comments: value,
        lastUpdated: new Date(),
        projectId: globalState?.currentProject,
      },
      globalState?.currentCompany
    ).then((x) => {
      setValue();
      handleLogActivity(props.id, x);
    });
  };

  const handleLogActivity = async (props, id) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Task',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Add Comment Task',
      collection_path: `kanban/${props}/comments`,
      collection_id: id,
      title: titleComment,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      const q = query(
        collection(db, `kanban/${props?.id}/comments`),
        where('companyId', '==', globalState?.currentCompany),
        orderBy('lastUpdated', 'desc'),
        limit(10)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const qData = [];
        querySnapshot.forEach((doc) => {
          qData.push({ id: doc.id, ...doc.data() });
        });
        setData(qData);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [globalState?.currentCompany]);

  return (
    <Box borderBottom="1px" p={[1, 1, 2]} {...props}>
      {data ? (
        data.map((x, i) => (
          <Flex m="2" key={i} gap={3}>
            <Box m="2" p="2" borderRight="1px">
              <Tooltip
                label={
                  userDisplay?.find((z) => z.id === x.createdBy)?.name ||
                  userDisplay?.find((z) => z.id === x.createdBy)?.email
                }
                aria-label="Name"
              >
                <Avatar
                  name={
                    userDisplay?.find((z) => z.id === x.createdBy)?.name ||
                    userDisplay?.find((z) => z.id === x.createdBy)?.email
                  }
                  image={userDisplay?.find((z) => z.id === x.createdBy)?.image}
                />
              </Tooltip>
              <Text fontSize="2xs">
                {moment.unix(x.lastUpdated.seconds).fromNow()}
              </Text>
            </Box>
            <Box>
              {parse(x?.comments, {
                replace: (domNode) => {
                  if (domNode.type === 'text') {
                    const textWithLinksReplaced = domNode.data.replace(
                      /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                      (match) => {
                        const url = match.startsWith('http')
                          ? match
                          : `https://${match}`;
                        return `<a href="${url}" target="_blank">${match}</a>`;
                      }
                    );
                    return parse(textWithLinksReplaced);
                  }
                },
              })}
            </Box>
            <Spacer />
          </Flex>
        ))
      ) : (
        <></>
      )}

      <Stack>
        <RichTextEditor value={value} onChange={handleContentChange} />

        <HStack flexDirection={['column', 'row', 'row']}>
          {value !== '' && (
            <DynamicButton
              title={t('comment')}
              action="create"
              onClick={() => handleSave()}
              variant="solid"
              disabled={props?.type === 'mykanban' ? true : false}
            />
          )}

          <DynamicButton
            title={t('upload')}
            action="create"
            onClick={openModal}
            variant="solid"
            disabled={props?.type === 'mykanban' ? true : false}
          />
        </HStack>
      </Stack>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Box>
  );
}

export default TaskCardAddComment;
