/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  updateDocumentFirebase,
  addDocumentFirebase,
} from '../../Api/firebaseApi';
import DynamicButton from '../Buttons/DynamicButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function ModalShareCard({
  handleCloseModal,
  modalShare,
  fileData,
  kanbanData,
  cardData,
  globalState,
  typeFile, 
  setTypeFile
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedIds, setSelectedIds] = useState('');
  const param = useParams();
  const navigate = useNavigate();

  const handleChecklistTo = (action) => {
    setTypeFile(action)
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds(id);
  };

  const middlewarePic = () => {
    toast({
      status: 'warning',
      duration: 9000,
      title: 'You don\'t have access to do this card',
      isClosable: true,
    });
  };

  const handleUpdate = async () => {
    const picProject = kanbanData?.pic;
    const picTask = cardData?.pic;

    if (picTask && picTask.length > 0) {
      const filterPic = picTask.filter((x) => x === globalState?.uid);

      if (filterPic.length === 0) {
        return middlewarePic();
      }
    }

    if (picTask.length === 0) {
      if (picProject && picProject.length > 0) {
        const filterPic = picProject.filter((x) => x?.uid === globalState?.uid);

        if (filterPic.length === 0) {
          return middlewarePic();
        }
      }
    }

    const collectionName = 'kanban';
    const docName = param?.taskId;
    const dataUpdate = {
      filesId: selectedIds,
      column: 'todos',
    };

    try {
      await updateDocumentFirebase(collectionName, docName, dataUpdate);
      toast({
        status: 'success',
        duration: 4000,
        title: 'Success share this card',
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      toast({
        status: 'error',
        duration: 4000,
        title: error.message,
        isClosable: true,
      });
    }
  };

  const handleDuplicate = async () => {
    const picProject = kanbanData?.pic;
    const picTask = cardData?.pic;

    if (picTask && picTask.length > 0) {
      const filterPic = picTask.filter((x) => x === globalState?.uid);

      if (filterPic.length === 0) {
        return middlewarePic();
      }
    }

    if (picTask.length === 0) {
      if (picProject && picProject.length > 0) {
        const filterPic = picProject.filter((x) => x?.uid === globalState?.uid);

        if (filterPic.length === 0) {
          return middlewarePic();
        }
      }
    }

    const collectionName = 'kanban';
    const dataUpdateNew = cardData;

    try {
      (dataUpdateNew.filesId = selectedIds),
      (dataUpdateNew.column = 'todos'),
      (dataUpdateNew.createdAt = new Date()),
      (dataUpdateNew.lastUpdated = new Date()),
      (dataUpdateNew.dueOn = moment().unix()),
      await addDocumentFirebase(
        collectionName,
        dataUpdateNew,
        globalState.currentCompany
      );
      toast({
        status: 'success',
        duration: 4000,
        title: 'Success duplicate this card',
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      toast({
        status: 'error',
        duration: 4000,
        title: error.message,
        isClosable: true,
      });
    }
  };



  return (
    <Modal isOpen={modalShare} size={'xl'} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('share')} kanban folder</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack maxH={'400px'} overflowY={'scroll'}>
            <Text fontWeight={600} fontSize={17} textAlign="center">
                      Pilih file yang ingin kamu kirimkan data-nya ?
            </Text>
            <HStack>
              <Checkbox
                isChecked={typeFile === 'kanban'}
                onChange={() => handleChecklistTo('kanban')}
              />
              <Text>Kanban</Text>
            </HStack>
            <HStack>
              <Checkbox
                isChecked={typeFile === 'operations'}
                onChange={() => handleChecklistTo('operations')}
              />
              <Text>Operations</Text>
            </HStack>

            <Divider />
          
            {fileData?.length > 0 && (
              <Stack>
                {fileData?.map((x, index) => {
                  const isChecked = selectedIds === x.id;
                  return (
                    <Stack key={index}>
                      <HStack alignItems={'center'} justifyContent={'space-between'}>
                        <Stack spacing={1}>
                          <Text fontWeight={500}>{x.title}</Text>
                          <Text fontSize={'x-small'}>{x.type}</Text>
                        </Stack>
                        <Spacer />
                        <Checkbox
                          isChecked={isChecked}
                          onChange={() => handleCheckboxChange(x.id)}
                        />
                      </HStack>
                      <Divider />

                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button colorScheme="blue" onClick={handleCloseModal}>
              {t('close')}
            </Button>
            <DynamicButton
              variant={'solid'}
              action={'create'}
              title={t('move')}
              onClick={handleUpdate}
            />
            <DynamicButton
              variant={'solid'}
              action={'create'}
              title={t('duplicate')}
              onClick={handleDuplicate}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalShareCard;
