/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Heading,
  HStack,
  FormControl,
  FormLabel,
  Select,
  Avatar,
  Stack,
  Flex,
  Input,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react'
import useUserStore from '../../Hooks/Zustand/Store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserByUid } from '../../Utils/getUser';
import { countDocumentsFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import { PiExport } from 'react-icons/pi';
import DynamicButton from '../Buttons/DynamicButton';
import { exportToExcel, mapDataKanban } from '../../Utils/ExportUtils';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import { IoCloseCircle } from 'react-icons/io5';
function ExportKanbanModal({isOpen, onClose, kanbanData}) {
  const {t} = useTranslation()
  const param = useParams()
  const [filterData, setFilterData] = useState({})
  const [isLoading, setIsLoading] = useState({export: false})
  const globalState = useUserStore()
  const toast =useToast()

  // this for check form
  const checkForm = () => {
    // Check if any required input field is empty
    const requiredFields = [
      { field: 'column', value: filterData?.column },
      { field: 'startedAt', value: filterData?.startedAt  },
      { field: 'endAt', value: filterData?.endAt },
      //   { field: 'label', value: filterData?.label },
      //   { field: 'Category', value: filterData?.category },
      //   { field: 'CreatedBy', value: filterData?.createdBy },
      //   { field: 'Asignee', value: filterData?.asignee },
    
    ];
    const emptyField = requiredFields.find((field) => !field.value);
    
    if (emptyField) {
      // Display warning toast for empty required field
      toast({
        title: 'Info',
        description: `${emptyField.field} required`,
        position: 'top-right',
        duration: 3000,
        status: 'info',
        isClosable: true,
      });
  
      return false;
    }
  
    // All checks passed
    return true;
  };
  const handleExport = async() => {
    const checkFormulir = checkForm();
    if (checkFormulir === false) return;
    setIsLoading((prev) => ({...prev, export:true}))

    try {
      const collectionRef = [{field: 'filesId', operator: '==', value: param.id}, {field: 'column', operator: '==', value: filterData.column}, {field: 'companyId', operator: '==', value: globalState?.currentCompany}];
  
      // Accumulate filters based on conditions
      if (filterData?.category) {
        collectionRef.push({ field: 'category', operator: '==', value: filterData?.category });
      }
  
      // if (filterData?.label) {
      //   collectionRef.push({ field: 'label', operator: '==', value: filterData?.label });
      // }
  
      if (filterData?.createdBy) {
        collectionRef.push({ field: 'createdBy', operator: '==', value: filterData?.createdBy });
      }
  
      if (filterData?.asignee) {
        collectionRef.push({ field: 'asignee', operator: 'array-contains-any', value: filterData?.asignee });
      }
      if (filterData?.startedAt && filterData?.endAt) {
        collectionRef.push({ field: 'createdAt', operator: '>=', value: filterData?.startedAt });
        collectionRef.push({ field: 'createdAt', operator: '<=', value: filterData?.endAt });
      }

      const totalDoc = await countDocumentsFirebase('kanban', collectionRef)
      if(totalDoc > 5000) {
        setIsLoading((prev) => ({...prev, export:false}))
        toast({
          title: 'Warning',
          description: 'The data is too large to export.',
          position: 'top-right',
          duration: 3000,
          status: 'info',
          isClosable: true,
        });
        return;
      }
      
      const loopTimes = Math.ceil(parseInt(totalDoc) / 10)
      if(totalDoc == 0) {
        setIsLoading((prev) => ({...prev, export:false}))
        toast({
          title: 'Warning',
          description: 'No data',
          position: 'top-right',
          duration: 3000,
          status: 'info',
          isClosable: true,
        });
        return;
      }
      let results
      for(let i = 0; i < loopTimes; i++){
        const res = await getCollectionFirebase('kanban', collectionRef, null, 10, null)
        const promises = res?.map( async(x) => {const resComment = await getCollectionFirebase(`kanban/${x?.id}/comments`, [{field: 'companyId', operator: '==', value: globalState?.currentCompany}], null, null, null) ; return  {kanbanData: x, commentsUser: resComment }})
        const dataFetch = await Promise.all(promises);
        if(results?.length > 0) {
          results = [...results, ...dataFetch]
        } else {
          results = [...dataFetch]
        }
      }

      const tableData = mapDataKanban(results, globalState)
      exportToExcel(tableData.tableHeader,tableData.mappedData,'Data-Kanban')
      setIsLoading((prev) => ({...prev, export:false}))
      toast({
        title: 'Success',
        description: 'Complete to export',
        position: 'top-right',
        duration: 3000,
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      setIsLoading((prev) => ({...prev, export:false}))
      console.log(error.message)
      toast({
        title: 'Error',
        description: error.message,
        position: 'top-right',
        duration: 3000,
        status: 'info',
        isClosable: true,
      });
    }
  }


  const handleAddAsignee = (value) => {
    if(filterData?.asignee?.find((x) => x === value) !== undefined) return toast({
      title: 'Info',
      description: 'Data duplication is not allowed',
      position: 'top-right',
      duration: 3000,
      status: 'info',
      isClosable: true,
    });
    const existingData = Array.isArray(filterData?.asignee) ? filterData?.asignee : [];
    const updatedData = [...existingData, value];
    setFilterData({...filterData, asignee: updatedData})
  }

  const handleDeleteAsignee = (index) => {
    const existingData = Array.isArray(filterData?.asignee) ? filterData?.asignee : [];
    const updatedData = [...existingData];
    updatedData.splice(index, 1);
    setFilterData({...filterData, asignee: updatedData})
  }

  return (
    <Modal isOpen={isOpen} onClose={() => { onClose(); setFilterData('')}} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="700">
          <Heading size={'md'}>
          Export Kanban
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={10}>
          
          
          <Flex w={'full'} mb={3} gap={5} direction={['column', 'row', 'row']}>
            <Stack w={'sm'}>
              <FormControl>
                <FormLabel>Column</FormLabel>
                <Select
                  value={filterData?.column}
                  placeholder="Column"
                  onChange={(e) => {
                    setFilterData({ ...filterData, column: e.target.value });
                    // console.log({ ...filterData, label: e.target.value })
                  }}
                >
                  <option value="backlog">backlog</option>
                  <option value="todos">todos</option>
                  <option value="progress">progress</option>
                  <option value="review">review</option>
                  <option value="done">done</option>
                </Select>
             
              </FormControl>
              {/* <FormControl>
                <FormLabel>Label</FormLabel>
                <Select
                  value={filterData?.label}
                  placeholder="Label"
                  onChange={(e) => {
                    setFilterData({ ...filterData, label: e.target.value });
                    // console.log({ ...filterData, label: e.target.value })
                  }}
                >
                  <option value="help">help</option>
                  <option value="priority">priority</option>
                  <option value="urgent">urgent</option>
                  <option value="pending">pending</option>
                  <option value="blocked">blocked</option>
                  <option value="completed">completed</option>
                </Select>
             
              </FormControl> */}
              <FormControl>
                <FormLabel>{t('assignee')}</FormLabel>
                <Select
                  value={filterData?.asignee}
                  placeholder={t('assignee')}
                  textTransform={'capitalize'}
                  onChange={(e) => {
                    handleAddAsignee(e.target.value)
                  }}
                >
                  {kanbanData?.usersDisplay?.length > 0 ? (
                    kanbanData?.usersDisplay
                      .sort((a, b) => a?.name?.localeCompare(b?.name || ''))
                      ?.map((x, index) => {
                        return (
                          <option key={index} value={x.id}>
                            {x?.name ? x?.name : x?.email}
                          </option>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </Select>
                {filterData?.asignee?.length > 0 ? (
                  <HStack p="2" m="2" borderRadius="md" shadow="base" maxW={'180px'} overflow={'auto'}  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '2',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '2',
                      h: '2',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.300',
                    },
                  }}>
                    <HStack>
                      {filterData?.asignee.map((uid, index) => {
                        const user = getUserByUid(globalState?.users, uid);
                        return (
                          <HStack pos={'relative'} spacing={3} key={index}>
                            <Avatar
                              key={index}
                              size="2xs"
                              marginRight="-2"
                              name={user?.name || 'Unknown'}
                              src={user?.image || <></>}
                            />
                            <Text fontSize={12} noOfLines={1}>{user?.name}</Text>
                            <Box pos={'absolute'} top={0} right={-1} cursor={'pointer'} onClick={() => handleDeleteAsignee(index)}>
                              <IoCloseCircle color='red' size={13} />
                            </Box>
                          </HStack>
                        );
                      })}
                    </HStack>
                  </HStack>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>{t('createdBy')}</FormLabel>
                <Select
                  value={filterData?.createdBy}
                  placeholder={t('createdBy')}
                  textTransform={'capitalize'}
                  onChange={(e) => {
                    setFilterData({ ...filterData, createdBy: e.target.value });
                  }}
                >
                  {kanbanData?.usersDisplay?.length > 0 ? (
                    kanbanData?.usersDisplay
                      .sort((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((x, index) => {
                        return (
                          <option key={index} value={x.id}>
                            {x?.name ? x?.name : x?.email}
                          </option>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </Select>
             
              </FormControl>
            </Stack>
            <Stack w={'sm'}>
              
              <FormControl>
                <FormLabel>{t('category')}</FormLabel>
                <Select
                  value={filterData?.category}
                  placeholder={t('category')}
                  textTransform={'capitalize'}
                  onChange={(e) => {
                    // console.log({ ...filterData, category: e.target.value }, 'filter category')
                    setFilterData({ ...filterData, category: e.target.value });
                  }}
                >
                  {kanbanData?.category ? (
                    kanbanData?.category
                      ?.sort((a, b) => a?.localeCompare(b))
                      ?.map((x, i) => (
                        <option key={i} value={x}>
                          {x || ''}
                        </option>
                      ))
                  ) : (
                    <></>
                  )}
                </Select>
             
              </FormControl>
              <FormControl isRequired>
                <FormLabel>StartedAt</FormLabel>
                <Input
                  type='datetime-local'
                  value={moment
                    .unix(filterData?.startedAt?.seconds)
                    .format('YYYY-MM-DD HH:mm')}
                  onChange={(e) =>
                    setFilterData({ ...filterData, startedAt: Timestamp.fromDate(new Date(e.target.value)) })
                   
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>FinishAt</FormLabel>
                <Input
                  type='datetime-local'
                  value={moment
                    .unix(filterData?.endAt?.seconds)
                    .format('YYYY-MM-DD HH:mm')}
                  onChange={(e) =>
                    setFilterData({ ...filterData, endAt: Timestamp.fromDate(new Date(e.target.value)) })
                  }
                />
              </FormControl>
          
            </Stack>
          </Flex>
         
        
        </ModalBody>
        <ModalFooter gap={3} >
          <Button colorScheme={'red'} onClick={onClose}>
          cancel
          </Button>
          <DynamicButton isLoading={isLoading.export} action={'custom'} icon={PiExport}  title={'Export Kanban'} variant={'solid'} color={'green'} onClick={() => handleExport()}/>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ExportKanbanModal