/* eslint-disable react/prop-types */
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { Avatar, AvatarGroup, Box, Button, Flex, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { FcBinoculars, FcEditImage } from 'react-icons/fc';

function ModalAddUser({ title, modalProjectUser, setModalProjectUser, handleSearchUsers, searchResult, handleUserProjectClick, owner, access, setAccess, selectedUserProjectIds, handleAddTeamProject }) {

  const {t} = useTranslation()


  return (
    <Modal
      isOpen={modalProjectUser}
      onClose={() => setModalProjectUser(false)}
      isCentered
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title} Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} py={3}>
            <HStack m="1">
              <Input
                type="text"
                placeholder={'Search User'}
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>
            <Stack maxH={'500px'} overflowY={'scroll'}>
              {searchResult?.length > 0 ? (
                searchResult?.map((x, index) => {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x.document.name}
                        src={x.document.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x.document.name}</Text>
                        <Text>{x.document.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => handleUserProjectClick(x.document)}
                      >
                                                +
                      </Button>
                    </HStack>
                  );
                })
              ) : (
                <></>
              )}
            </Stack>
            <Stack gap="2">
              <Box display={!owner && 'none'}>
                <HStack
                  gap="2"
                  p="4"
                  rounded={5}
                  shadow={'md'}
                  borderRightWidth={4}
                  align={'left'}
                  justify={'left'}
                  cursor={'pointer'}
                  borderColor={access === 'editor' ? 'blue.500' : 'none' } 
                  onClick={() => setAccess('editor')}
                >
                  <Box>
                    <FcEditImage fontSize={'25'} />
                  </Box>
                  <Stack>
                    <Heading size={'sm'}>Editor</Heading>
                    <Text color={'gray.500'} fontSize={'sm'}>
                      {t('editorAccsess')}
                    </Text>
                  </Stack>
                </HStack>
              </Box>
              <Box display={!owner && 'none'}>
                <HStack
                  gap="2"
                  p="4"
                  rounded={5}
                  borderRightWidth={4}
                  shadow={'md'}
                  align={'left'}
                  justify={'left'}
                  cursor={'pointer'}
                  borderColor={access === 'visitor' ? 'blue.500' : 'none' } 
                  onClick={() => setAccess('visitor')}
                >
                  <Box>
                    <FcBinoculars fontSize={'25'} />
                  </Box>
                  <Stack>
                    <Heading size={'sm'}>Visitor</Heading>
                    <Text color={'gray.500'} fontSize={'sm'}>
                      {t('visitorAccsess')}
                    </Text>
                  </Stack>
                </HStack>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Flex gap={5}>
            <AvatarGroup size="sm" gap="1" max={4}>
              {selectedUserProjectIds?.length > 0 &&
                                selectedUserProjectIds?.map((x, i) => (
                                  <Avatar key={i} name={x?.name} />
                                ))}
            </AvatarGroup>
            <Spacer />
            <Button
              leftIcon={<AddIcon boxSize={3} />}
              colorScheme="green"
              onClick={() => handleAddTeamProject()}
            >
                            Add Team
            </Button>
            <Button
              leftIcon={<CloseIcon boxSize={3} />}
              colorScheme="red"
              onClick={() => {
                setModalProjectUser(false);
              }}
            >
                            Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalAddUser