/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import {
  Avatar,
  AvatarGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  Spacer,
  useToast,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase, arrayUnionFirebase } from '../../Api/firebaseApi';
import moment from 'moment';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { doc, onSnapshot } from '@firebase/firestore';
import BackButtons from '../../Components/Buttons/BackButtons';
import { clientTypessense } from '../../Api/Typesense';
import { DndProvider } from 'react-dnd';
import KanbanColumnsComponent from '../../Components/Columns/KanbanColumnsComponent';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import LiveTime from '../../Components/Timer/LiveTime';
import CountdownTimerV2 from '../../Components/Timer/CountdownTimerV2';
import { RiTimerLine } from 'react-icons/ri';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getDeviceType } from '../../Hooks/Middleware/sessionMiddleWare';
import { getUserByUid } from '../../Utils/getUser';
import ProductivityTaskViewModalPage from './ProductivityTaskViewModalPage';
import { FiEye } from 'react-icons/fi';
import KanbanTableComponent from '../../Components/Table/KanbanTableComponent';
import KanbanGanttComponent from '../../Components/Charts/ChartsGanttKanban/KanbanGanttComponent';
import { useTranslation } from 'react-i18next';
import { FaFileExport } from 'react-icons/fa';
import ExportKanbanModal from '../../Components/Modals/ExportKanbanModal';

function ProductivityKanbanViewPage() {
  const {t} = useTranslation()
  const [kanbanData, setKanbanData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  const [newCardModal, setNewCardModal] = useState(false);
  const [detailActive, setDetailActive] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stateRefresh, setStateRefresh] = useState(false);
  const exportKanbanView = useDisclosure()
  const param = useParams();
  const navigate = useNavigate();

  const [toggleStopwatch, setToggleStopwatch] = useState(false);

  const toast = useToast();

  const updateUserDisplay = useKanbanStore((state) => state.setUserDisplay);

  const {
    userDisplay,
    setFilterData,
    filterData,
    resetFilterData,
    setTaskData,
  } = useKanbanStore();

  const globalState = useUserStore();
  const [typeView, setTypeView] = useState(param.type);

  const handleButtonClick = () => {
    if (typeView === 'kanban') {
      setTypeView('table');
    } else if (typeView === 'table') {
      setTypeView('gantt');
    } else {
      setTypeView('kanban');
    }
  };

  const findProject = globalState.projects?.find(
    (x) => x.id === kanbanData?.projectId
  );

  const ColumnType = {
    BACKLOG: 'backlog',
    TODOS: 'todos',
    PROGRESS: 'progress',
    REVIEW: 'review',
    DONE: 'done',
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        new Error(error.message, 'Failed to send Slack login message');
      });
  };


  const handleModalOpen = (x) => {
    setModalDetail(true);
    setDetailActive(x);
  };

  const handleModalClose = () => {
    setModalDetail(false);
    setDetailActive('');
  };

  const handleModalOpenNewCard = () => {
    setNewCardModal(true);
  };

  const handleModalCloseNewCard = () => {
    setNewCardModal(false);
    setDetailActive('');
  };

  const handleNewCard = async () => {
    setTaskData(kanbanData);
    setLoading(true);
    try {
      const newCard = {
        title: 'New Card',
        asignee: [globalState.uid],
        column: 'backlog',
        filesId: param?.id,
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        createdAt: new Date(),
        lastUpdated: new Date(),
        dueOn: moment().unix(),
        score: 6,
        pic: [],
      };

      const docId = await addDocumentFirebase(
        'kanban',
        newCard,
        globalState.currentCompany
      );

      const updatedTaskData = {
        ...newCard,
        id: docId,
      };

      setTaskData({
        index: 0,
        columnsData: [],
        setData: function () {},
        task: updatedTaskData,
      });

      setDetailActive(updatedTaskData);

      handleModalOpenNewCard();
      // navigate(docId, { state: { type: 'newCard' } });
    } catch (error) {
      new Error(error.message, 'Failed to send api  message');
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateModal = () => {
    navigate(`${detailActive?.id}`, { state: { type: 'existing' } });
    setTaskData(kanbanData);
  };

  const handleAddUser = (param, x) => {

    
    try {
      arrayUnionFirebase('files', param.id, 'users', [x.document.id]);
      arrayUnionFirebase('files', param.id, 'usersDisplay', [
        { ...x.document },
      ]);
      toast({
        title: 'Deoapp.com',
        description: 'success add users',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setSearchResult([]);
      handleLogActivity(param.id, x);
    }
  };

  const handleLogActivity = async (id, user) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL,
      createdBy: auth?.currentUser?.uid,
      type: 'kanban',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'invite',
      collection_path: 'files',
      collection_id: id,
      from: auth?.currentUser?.displayName,
      from_id: auth?.currentUser?.uid,
      to: user?.document?.name,
      to_id: user?.document?.id,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      new Error(error.message, 'Failed to send Slack login message');
    }
  };

  const deviceType = getDeviceType();
  useEffect(() => {
    let unsub = () => {};

    try {
      unsub = onSnapshot(doc(db, 'files', param.id), (doc) => {
        setKanbanData(doc.data());
        updateUserDisplay(doc?.data()?.usersDisplay);
      });

    } catch (error) {
      new Error(error.message, 'Failed to send api  message');

    }

    return () => {
      unsub();
      setKanbanData();
    };
  }, []);

  const refreshData = (id) => {
    setStateRefresh(id);
  };

  return (
    <>
      <Box>
        <HStack flexDirection={['column', 'column', 'row']}>
          <BackButtons />

          <Box maxW={['auto', '500px', '700px']}>
            <Heading noOfLines={1} size={'lg'} textTransform={'capitalize'}>
              {param.type} {param.title}
            </Heading>
            <Text fontSize="2xs" color={'gray.500'}>
              ID :{param.id}
            </Text>
            <Text fontSize="2xs" color={'gray.500'}>
              {t('project')} : {findProject?.name}
            </Text>
          </Box>
          <Spacer />
          <HStack flexDirection={['column', 'row', 'row']} spacing={3}>
            <Stack
              alignItems={'flex-end'}
              justifyContent="flex-end"
              spacing={1}
            >
              <HStack>
                <AvatarGroup size="sm" gap="1" max={4}>
                  {userDisplay ? (
                    userDisplay?.map((x, i) => (
                      <Avatar key={i} name={x?.name || ''} />
                    ))
                  ) : (
                    <></>
                  )}
                </AvatarGroup>
                <DynamicButton
                  size="sm"
                  action="create"
                  variant={'solid'}
                  onClick={onOpen}
                />
                <DynamicButton
                  size="sm"
                  action="custom"
                  variant={'solid'}
                  color={'orange'}
                  icon={RiTimerLine}
                  onClick={() => setToggleStopwatch((prev) => !prev)}
                />
                <DynamicButton
                  size="sm"
                  tooltip={'Export'}
                  action="custom"
                  variant={'solid'}
                  color={'blue'}
                  icon={FaFileExport}
                  onClick={() => exportKanbanView.onOpen()}
                />
              </HStack>

              <HStack>
                <DynamicButton
                  title={t('editKanban')}
                  size="sm"
                  variant="solid"
                  action={'update'}
                  onClick={() =>
                    navigate(`/kanban/edit/${kanbanData?.id || param.id}`, {
                      state: kanbanData,
                    })
                  }
                />
                <DynamicButton
                  action={'custom'}
                  size="sm"
                  variant={'solid'}
                  color={'yellow'}
                  icon={FiEye}
                  title={
                    typeView === 'kanban'
                      ? 'Kanban'
                      : typeView === 'table'
                        ? t('table')
                        : 'Gantt'
                  }
                  onClick={handleButtonClick}
                />
              </HStack>
            </Stack>

            {toggleStopwatch ? (
              <Stack
                bgColor={'blue.500'}
                shadow="md"
                py={1}
                px={2}
                borderRadius="md"
              >
                <CountdownTimerV2 seconds={60} />
              </Stack>
            ) : (
              <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
                <LiveTime color={'white'} />
              </Stack>
            )}
          </HStack>
        </HStack>

        <Box p={2} my={3} borderRadius={'md'} shadow={'base'}>
          <SimpleGrid borderRadius={'sm'} gap={1} columns={[1, 6, 6]} px="2">
            <Select
              value={filterData?.asignee}
              placeholder={t('assignee')}
              textTransform={'capitalize'}
              onChange={(e) => {
                let updateData = [];
                if (filterData?.asignee?.length > 0)
                  updateData = filterData.asignee;
                updateData.push(e.target.value);
                setFilterData({ ...filterData, asignee: updateData });
              }}
            >
              {kanbanData?.usersDisplay?.length > 0 ? (
                kanbanData?.usersDisplay
                  .sort((a, b) => a?.name?.localeCompare(b?.name || ''))
                  ?.map((x, index) => {
                    return (
                      <option key={index} value={x.id}>
                        {x?.name ? x?.name : x?.email}
                      </option>
                    );
                  })
              ) : (
                <></>
              )}
            </Select>

            <Select
              value={filterData?.createdBy}
              placeholder={t('createdBy')}
              textTransform={'capitalize'}
              onChange={(e) => {
                setFilterData({ ...filterData, createdBy: e.target.value });
              }}
            >
              {kanbanData?.usersDisplay?.length > 0 ? (
                kanbanData?.usersDisplay
                  .sort((a, b) => a?.name?.localeCompare(b?.name))
                  ?.map((x, index) => {
                    return (
                      <option key={index} value={x.id}>
                        {x?.name ? x?.name : x?.email}
                      </option>
                    );
                  })
              ) : (
                <></>
              )}
            </Select>

            <Select
              value={filterData?.category}
              placeholder={t('category')}
              textTransform={'capitalize'}
              onChange={(e) => {
                // console.log({ ...filterData, category: e.target.value }, 'filter category')
                setFilterData({ ...filterData, category: e.target.value });
              }}
            >
              {kanbanData?.category ? (
                kanbanData?.category
                  ?.sort((a, b) => a?.localeCompare(b))
                  ?.map((x, i) => (
                    <option key={i} value={x}>
                      {x || ''}
                    </option>
                  ))
              ) : (
                <></>
              )}
            </Select>

            <Select
              value={filterData?.label}
              placeholder="Label"
              onChange={(e) => {
                setFilterData({ ...filterData, label: e.target.value });
                // console.log({ ...filterData, label: e.target.value })
              }}
            >
              <option option="help">help</option>
              <option option="priority">priority</option>
              <option option="urgent">urgent</option>
              <option option="pending">pending</option>
              <option option="blocked">blocked</option>
              <option option="completed">completed</option>
            </Select>
            <Input
              type="text"
              placeholder="search"
              value={filterData?.search}
              onChange={(e) => setFilterData({ search: e.target.value })}
            />

            <DynamicButton
              title={t('newCard')}
              isLoading={loading}
              variant="solid"
              action="create"
              onClick={() => {
                handleNewCard();
              }}
            />
          </SimpleGrid>

          {filterData?.asignee ||
          filterData?.category ||
          filterData?.label ||
          filterData?.createdBy ? (
              <HStack spacing={0}>
                {filterData?.createdBy ? (
                  <HStack p="2" m="2" borderRadius="md" shadow="base">
                    <Text fontSize={12} textAlign="center">
                      {t('createdBy')}:
                    </Text>

                    <HStack spacing={3}>
                      <Avatar
                        size="2xs"
                        marginRight="-2"
                        name={
                          getUserByUid(globalState?.users, filterData?.createdBy)
                            ?.name || 'Unknown'
                        }
                        src={
                          getUserByUid(globalState?.users, filterData?.createdBy)
                            ?.image || <></>
                        }
                      />
                      <Text fontSize={12}>
                        {
                          getUserByUid(globalState.users, filterData?.createdBy)
                            ?.email
                        }
                      </Text>
                    </HStack>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.asignee ? (
                  <HStack p="2" m="2" borderRadius="md" shadow="base">
                    <Text fontSize={12} textAlign="center">
                      {t('assignee')}:
                    </Text>
                    <HStack>
                      {filterData.asignee.map((uid, index) => {
                        const user = getUserByUid(globalState?.users, uid);
                        return (
                          <HStack spacing={3} key={index}>
                            <Avatar
                              key={index}
                              size="2xs"
                              marginRight="-2"
                              name={user?.name || 'Unknown'}
                              src={user?.image || <></>}
                            />
                            <Text fontSize={12}>{user?.name}</Text>
                          </HStack>
                        );
                      })}
                    </HStack>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.category ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    borderRadius="md"
                    shadow="base"
                  >
                    {/* <Text position='fixed' top='0' right='2'>x</Text> */}
                    <Text textAlign="center">{t('category')}:</Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {filterData?.category}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.label ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    borderRadius="md"
                    shadow="base"
                  >
                    <Text textAlign="center">Label: </Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {filterData?.label}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.search ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    borderRadius="md"
                    shadow="base"
                  >
                    <Text textAlign="center">{t('search')}</Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {filterData?.search}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                <Spacer />
                {/* <Button minW='min-content' colorScheme='green'>Search</Button> */}
                {/* <Button minW='min-content' colorScheme='green' onClick={() => console.log(filterData)}>check console</Button> */}
                <Button
                  size={'xs'}
                  minW="min-content"
                  colorScheme="red"
                  onClick={() => {
                    resetFilterData();
                  }}
                >
                  {t('clear')}
                </Button>
                {/* <Text
                as={"u"}
                ml={1}
                fontStyle={"italic"}
                color={"blue.600"}
                onClick={() => {
                  resetFilterData();
                }}
                cursor={"pointer"}
                fontSize={12}
              >
                Clear Filter
              </Text> */}
              </HStack>
            ) : (
              <></>
            )}
        </Box>

        <DndProvider
          backend={deviceType === 'desktop' ? HTML5Backend : TouchBackend}
        >
          {typeView === 'table' ? (
            <KanbanTableComponent
              kanbanData={kanbanData}
              filterData={filterData}
            />
          ) : typeView === 'kanban' ? (
            <SimpleGrid columns={[1, 2, 5]}>
              <KanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.BACKLOG}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <KanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.TODOS}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <KanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.PROGRESS}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <KanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.REVIEW}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <KanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.DONE}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
            </SimpleGrid>
          ) : (
            <KanbanGanttComponent
              kanbanData={kanbanData}
              filterData={filterData}
            />
          )}
        </DndProvider>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('add')} {t('user')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack m="1">
              <Input
                type="text"
                placeholder="Search users"
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>
            {searchResult?.length > 0 ? (
              searchResult?.map((x, index) => {
                if (kanbanData?.users.find((z) => z !== x.document.id)) {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x?.document?.name}
                        src={x?.document?.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x?.document?.name}</Text>
                        <Text>{x?.document?.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleAddUser(param, x);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  );
                }
              })
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Drawer
        size={'xl'}
        placement={'bottom'}
        onClose={() => {
          handleModalClose();
          handleModalCloseNewCard();
        }}
        isOpen={modalDetail || newCardModal}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack>
              {detailActive && (
                <ProductivityTaskViewModalPage
                  detailProps={detailActive}
                  handleNavigateModal={handleNavigateModal}
                  handleModalClose={() => {
                    handleModalClose();
                    handleModalCloseNewCard();
                  }}
                  handleRefresh={refreshData}
                />
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <ExportKanbanModal isOpen={exportKanbanView.isOpen} onClose={exportKanbanView.onClose} kanbanData={kanbanData}/>
    
    </>
  );
}

export default ProductivityKanbanViewPage;
