/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { collection, onSnapshot, orderBy, query } from '@firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../Config/firebase';
import { useTranslation } from 'react-i18next';

const TaskHistoryComponent = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, `kanban/${props.id}/history`),
      orderBy('lastUpdated', 'desc')
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const qData = [];
      querySnapshot.forEach((doc) => {
        qData.push({ id: doc.id, ...doc.data() });
      });
      setData(qData);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Box {...props}>
        {data?.map((x, i) => {
          return (
            <Flex borderBottomWidth={2} borderBottomColor="gray" key={i}>
              <Text fontSize={11}>{x?.comments}</Text>
            </Flex>
          );
        })}
        <Button
          size="xs"
          variant="ghost"
          onClick={() => props.setShowHistory(false)}
        >
          {t('hide')}
        </Button>
      </Box>
    </>
  );
};

export default TaskHistoryComponent;
