import { addDocumentFirebase } from '../Api/firebaseApi';
import { auth } from '../Config/firebase';

export const createHistoryCard = async (id, comments, globalState) => {
  if (id) {
    try {
      const res =  await addDocumentFirebase(
        `kanban/${id}/history`,
        {
          comments: `${auth?.currentUser?.email} ${comments} on ${new Date()}`,
          lastUpdated: new Date(),
        },
        globalState.currentCompany
      );
      return res;
    } catch (error) {
      return { error: error.message };
    }
  }
  return { error: 'id card not sustain' }; // Tambahkan ini jika window.gtag tidak terdefinisi
};