/* eslint-disable react/prop-types */
import { Avatar, Box, Divider, HStack, Input, Spacer, Stack, Text, Tooltip, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { clientTypessense } from '../../../Api/Typesense';
import { FcDisplay } from 'react-icons/fc';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import { getUserByUid } from '../../../Utils/getUser';
import moment from 'moment';
import { arrayUnionFirebase, setDocumentFirebase } from '../../../Api/firebaseApi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function KpiInputComponent({ globalStateProps, onClose }) {
  const {t} = useTranslation()
  const [dataSearch, setDataSearch] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState([]);
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const param = useParams()

  const handleSearchKpi = (value) => {

    const searchParameters = {
      q: value ? value : ' ',
      query_by: 'title',
      filter_by: `users: [${globalStateProps.uid}] && companyId: ${globalStateProps.currentCompany}`,
      per_page: 5,
    };

    clientTypessense
      .collections('kpi')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => ({ ...y.document }));
        setDataSearch(newData);
      });
  }

  useEffect(() => {
    handleSearchKpi()
  
    return () => {
    }
  }, [])
  

  const handleAddKpi = (selectedKpiItem) => {
    const isKpiExist = selectedKpi.some((kpi) => kpi.title === selectedKpiItem.title);

    if (isKpiExist) {
      toast({
        title: 'Deoapp.com',
        description: 'KPI sudah terdaftar',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    const newKpi = {
      ...selectedKpiItem,
      uid: globalStateProps?.uid,
      value: '',
    }
    setSelectedKpi([...selectedKpi, newKpi]);
  }

  const handleSubmitKpi = (index, value) => {
    const updatedKpiList = [...selectedKpi];
    updatedKpiList[index].value = value;
    setSelectedKpi(updatedKpiList);
  }

  const handleRemoveKpi = (index) => {
    const updatedKpiList = [...selectedKpi];
    updatedKpiList.splice(index, 1);
    setSelectedKpi(updatedKpiList);
  }

  const handleSubmitAllKpi = async () => {
    setLoading(true)
    try {
      for (const x of selectedKpi) {
        let currentPeriod = '';
        switch (x.period) {
        case 'daily':
          currentPeriod = 'd';
          break;
        case 'weekly':
          currentPeriod = 'w';
          break;
        case 'monthly':
          currentPeriod = 'M';
          break;
        case 'quarterly':
          currentPeriod = 'Q';
          break;
        case 'annually':
          currentPeriod = 'y';
          break;
        default:
          break;
        }
  
        const updatedPeriod = {
          ...x,
          status: currentPeriod,
          kpiTitle: x.title,
          data: [],
        };
  
        // Create unix time for each current period
        const arrCalculatio = [0];
        arrCalculatio.forEach((z) => {
          const formattedDate =
            currentPeriod === 'd'
              ? 'DD-MM-YY'
              : `${currentPeriod}-YY`;
  
          updatedPeriod.data.push({
            title: moment()
              .startOf(currentPeriod)
              .subtract(z, currentPeriod)
              .format(formattedDate),
            startDate: moment()
              .startOf(currentPeriod)
              .subtract(z, currentPeriod)
              .unix(),
            endDate: moment()
              .endOf(currentPeriod)
              .subtract(z, currentPeriod)
              .unix(),
            actual: parseInt(x.value),
            kpiId: x.id,
            uid: x.uid,
            title_kpi: x.title,
            target_kpi: x.target
          });
        });
  
        const collectionName = 'kanban';
        const docName = param.taskId;
        const field = 'kpi';
        const values = [updatedPeriod.data[0]];
  
        await setDocumentFirebase('kpi_data', `${x?.id}-${x?.uid}-${updatedPeriod?.status}-${updatedPeriod?.data[0]?.title}`, updatedPeriod?.data[0]);
        await arrayUnionFirebase(collectionName, docName, field, values);
        toast({
          title: 'Deoapp.com',
          description: 'success add Kpi',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
      // Reset selectedKpi after successful submission, if needed
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      // Error toast message
    }finally{
      setSelectedKpi([])
      onClose()
      setLoading(false)
    }
  }

  return (
    <Stack spacing={5}>
      <Text fontWeight={'bold'}>KPI Input: </Text>
      <Box>
        <Input
          type="text"
          placeholder="Search KPI"
          onChange={(e) => handleSearchKpi(e.target.value)}
        />
        <Stack maxH="500px" overflowY={'auto'}>
          {dataSearch.length > 0 &&
            dataSearch.map((x, i) => (
              <HStack key={i} m="1" p="1" shadow="base">
                <Box>
                  <FcDisplay size="35" />
                </Box>
                <Box>
                  <Text fontSize="sm" fontWeight={500}>
                    {x.title}
                  </Text>
                  <Text fontSize="2xs" noOfLines={2}>
                    {x.description}
                  </Text>
                </Box>
                <Spacer />
                <DynamicButton
                  action={'create'}
                  variant={'solid'}
                  size={'sm'}
                  onClick={() => handleAddKpi(x)}
                />
              </HStack>
            ))}
        </Stack>
      </Box>
      <Divider />
      <Spacer />
      <Text fontWeight={'bold'}>{t('KPI List')}: </Text>
      <Stack spacing={3}>
        {selectedKpi.map((x, i) => {
          return (
            <HStack key={i} spacing={3}>
              <Tooltip
                label={getUserByUid(globalStateProps.users, x.uid).name}
                textTransform={'capitalize'}
                aria-label="Name"
              >
                <Avatar
                  src={getUserByUid(globalStateProps.users, x.uid).image}
                  size={'sm'}
                  name={getUserByUid(globalStateProps.users, x.uid).name}
                />
              </Tooltip>
              <Text fontSize={'sm'} fontWeight={500} noOfLines={2}>
                {x?.title}
              </Text>
              <Spacer />
              <Box w={'80px'}>
                <Input
                  fontSize={'sm'}
                  size={'sm'}
                  placeholder={t('value')}
                  onChange={(e) => handleSubmitKpi(i, e.target.value)}
                />
              </Box>
              <Text>/</Text>
              <Text>{x?.target}</Text>
              <DynamicButton
                variant={'solid'}
                action={'delete'}
                size={'sm'}
                onClick={() => handleRemoveKpi(i)}
              />
            </HStack>
          );
        })}
        {selectedKpi.length > 0 && (
          <DynamicButton
            isLoading={loading}
            size={'sm'}
            title={t('submit')}
            variant={'solid'}
            action={'create'}
            onClick={handleSubmitAllKpi}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default KpiInputComponent;
