/* eslint-disable react/prop-types */
import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineAlert } from 'react-icons/ai'

function AlertArrayNull({ titleData, action }) {
  const {t} = useTranslation()
  return (
    <Stack minH="50vh" alignItems={'center'} justifyContent="center">
      <AiOutlineAlert color="gray" size={50} />
      <Text
        textAlign="center"
        color={'gray.500'}
        fontSize="2xl"
        fontWeight={'bold'}
      >
        {t('alertArray', { title: titleData || '' })}
      </Text>
      <Text
        fontWeight={500}
        fontSize="lg"
        textAlign="center"
        color={'gray.500'}
      >
        {t('alertArrayAction', { action: action || '' })}
      </Text>
    </Stack>
  );
}

export default AlertArrayNull