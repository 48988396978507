/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Badge,
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaCalendar } from 'react-icons/fa';
import { FaArrowRight} from 'react-icons/fa6';
import DatePickerNew from './DatePickerNew';

function ModalFilterDate({ handleDateRangeChange, getData }) {
  const modal = useDisclosure();
  const isTablet = useBreakpointValue({ base: false, lg: true });
  const [date, setDate] = useState({
    startDate: moment().subtract(1, 'months').format('DD MMMM'),
    endDate: moment().format('DD MMMM YYYY'),
  });

  const [range, setRange] = useState();

  const handleDateChange = (dateRange) => {
    const startDateMoment = moment(dateRange.startDate);
    const endDateMoment = moment(dateRange.endDate);
    const today = moment();

    if (endDateMoment.isSame(today, 'day')) {
      const type = moment(startDateMoment, 'YYYYMMDD').fromNow();
      setRange(type);
    } else {
      setRange('custom');
    }

    let startDate = moment(dateRange?.startDate).format('DD MMMM YYYY');
    let endDate = moment(dateRange?.endDate).format('DD MMMM YYYY');
    if (moment(dateRange.startDate).isSame(dateRange.endDate, 'year')) {
      startDate = moment(dateRange?.startDate).format('DD MMMM');
      endDate = moment(dateRange?.endDate).format('DD MMMM YYYY');
    }
    const startDateToDate = moment(dateRange?.startDate, 'DD-MM-YYYY').toDate();
    const endDateToDate = moment(dateRange?.endDate, 'DD-MM-YYYY').toDate();
    setDate({ startDate, endDate });
    handleDateRangeChange(startDateToDate, endDateToDate);
  };

  return (
    <>
      <Button onClick={modal.onOpen} colorScheme="blue" p={2} size={'sm'}>
       
        {isTablet ? (
          <>
            <Badge colorScheme="gray" variant="solid" mr={'2'}>
              {range}
            </Badge>
            <HStack>
              <FaCalendar />
              <Text> {`${date.startDate}`}</Text>

              <FaArrowRight />
              <Text> {`${date.endDate}`}</Text>

              <ChevronDownIcon />
            </HStack>
          </>
        ) : (
          <HStack>
            <FaCalendar />
            <Text> {`${date.endDate}`}</Text>

            <ChevronDownIcon />
          </HStack>
        )}
      </Button>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Tanggal Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center overflow={'auto'}>
              <DatePickerNew onDateChange={handleDateChange} />
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={modal.onClose}>
              Close
            </Button>
            <Button colorScheme="blue" mr={3} onClick={() => {if(getData){getData()} modal.onClose()}}>
              Search With Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalFilterDate;
