import React, { useCallback, useState } from 'react';
import {
  ConnectionMode,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  MarkerType,
} from 'reactflow';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  useToast,
  SimpleGrid,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Checkbox,
  Input,
  Spacer,
  Heading,
  AvatarGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Avatar,
  useDisclosure,
} from '@chakra-ui/react';
import edgeTypes from '../../Components/MindMap/EdgeType';
import useUndoRedo from '../../Components/MindMap/useUndoRedo';
import { FcDownload, FcRedo, FcUndo } from 'react-icons/fc';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import nodeTypes from '../../Components/MindMap/NodeType';
import { toPng } from 'html-to-image';
import { db } from '../../Config/firebase';
import './index.css';
import 'reactflow/dist/style.css';

import { doc, onSnapshot } from 'firebase/firestore';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import { useFlowchartStoreProduction } from '../../Hooks/Zustand/reactFlow';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import LiveTime from '../../Components/Timer/LiveTime';
import { clientTypessense } from '../../Api/Typesense';
import { TbReload } from 'react-icons/tb';
import { IoPeople } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

function LineOperation() {
  const { t } = useTranslation();
  const params = useParams();
  const selector = (state) => ({
    nodes: state.nodes,
    edges: state.edges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
    title: state.title,
    setTitle: state.setTitle,
  });
  const toast = useToast();
  const [data, setData] = useState({});
  const [dataKanban, setDataKanban] = useState([]);
  const globalState = useUserStore();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const {
    nodes,
    edges,
    onConnect,
    setNodes,
    setEdges,
    onEdgesChange,
    onNodesChange,
    title,
    setTitle,
  } = useFlowchartStoreProduction(selector);
  const [selectedNodesMap, setSelectedNodesMap] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const getMindmap = async () => {
    try {
      const docRef = doc(db, 'operations', params.id);

      // Gunakan onSnapshot untuk memantau perubahan data secara real-time
      onSnapshot(docRef, (docDAta) => {
        if (docDAta.exists()) {
          const res = docDAta.data();
          setNodes(res.nodes);
          setEdges(res.edges);
          setTitle(res.title);
          setData(res);
        } else {
          // Dokumen tidak ditemukan
          toast({
            title: 'Deoapp',
            description: 'data has not found',
            status: 'warning',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const sortResByNodesOrder = (nodesData, res) => {
    const nodeLabels = nodesData?.map((node) => node.data.label);

    const sortedRes = nodeLabels?.map((label) => {
      return res.find((item) => item.title === `${label}`);
    });

    return sortedRes?.filter((item) => item !== undefined); // Filter undefined items (jika ada)
  };

  const getKanbanOperation = async () => {
    if (nodes?.length > 0) {
      const conditions = [
        { field: 'operationId', operator: '==', value: params.id },
        { field: 'type', operator: '==', value: 'operations' },
      ];
      const sortBy = { field: 'createdAt', direction: 'asc' };
      const limitValue = 999;

      try {
        const res = await getCollectionFirebase(
          'files',
          conditions,
          sortBy,
          limitValue
        );
        const sortedRes = sortResByNodesOrder(nodes, res);
        setDataKanban(sortedRes);
      } catch (error) {
        throw new Error(error.message);
      }
    }
  };

  useEffect(() => {
    getMindmap();

    return () => {
      setData({});
    };
  }, [params?.id]);

  useEffect(() => {
    getKanbanOperation();
    return () => {
      setDataKanban([]);
    };
  }, [params?.id]);

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.projects.find(
      (x) => x.id === globalState.currentProject
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        new Error(error.message, 'Failed to send Slack login message');
      });
  };

  const handleAddUser = (param, x) => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
    try {
      arrayUnionFirebase('operations', param.id, 'users', [x.document.id]);
      arrayUnionFirebase('operations', param.id, 'usersDisplay', [
        { ...x.document },
      ]);
      toast({
        title: 'Deoapp.com',
        description: 'success add users',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setSearchResult([]);
    }
  };

  const handleRefresh = () => {
    try {
      getMindmap();
      getKanbanOperation();
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const proOptions = { account: 'paid-pro', hideAttribution: true };

  const onNodeDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const fitViewOptions = {
    padding: 0.95,
  };

  const downloadButton = async () => {
    const imageWidth = 1024;
    const imageHeight = 768;

    const downloadImage = (dataUrl) => {
      const a = document.createElement('a');
      a.setAttribute('download', `${title}.png`);
      a.setAttribute('href', dataUrl);
      a.click();
    };
    const dataUrl = await toPng(
      document.querySelector('.react-flow__renderer'),
      {
        style: {
          width: imageWidth,
          height: imageHeight,
        },
      }
    );
    downloadImage(dataUrl);
  };

  const handleCheckboxChange = (accordionItemId, nodeId) => {
    setSelectedNodesMap((prevMap) => ({
      ...prevMap,
      [accordionItemId]: {
        ...prevMap[accordionItemId],
        [nodeId]: !prevMap[accordionItemId]?.[nodeId],
      },
    }));
  };

  const handleData = async () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    globalState.setIsLoading(true);

    try {
      const updatedData = dataKanban.map((node) => {
        const accordionItemNodes = selectedNodesMap[node.id] || {};
        const sendToList = dataKanban
          .filter((n) => accordionItemNodes[n.id])
          .map((selectedNode) => selectedNode.id)
          .filter((label) => label !== node.id); // Exclude own label

        return {
          id: node.id,
          title: node.title,
          sendTo: sendToList,
          duration: node.duration || 0,
          users: data?.users || [],
          usersDisplay: data?.usersDisplay || [],
        };
      });

      if (updatedData.length > 0) {
        await Promise.all(
          updatedData.map(async (x) => {
            const collectionName = 'files';
            const docName = x.id;

            try {
              await updateDocumentFirebase(collectionName, docName, x);
              toast({
                title: 'Deoapp',
                description: 'success update kanban',
                status: 'success',
                duration: 3000,
                isClosable: true,
              }); // Success toast message
            } catch (error) {
              toast({
                title: 'Deoapp',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
              // Handle error for individual document update if needed
            }
          })
        );
      } else {
        handlePushKanban();
      }
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Handle overall error if needed
    } finally {
      getKanbanOperation();
      globalState.setIsLoading(false);
    }
  };

  const defaultEdgeOptions = {
    type: 'smoothstep',
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { strokeWidth: 3 },
  };


  const save = async () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
   


    globalState.setIsLoading(true);
    const newData = {
      edges: edges,
      nodes: nodes,
      lastUpdated: new Date(),
      lastUpdatedBy: {
        uid: globalState.uid,
        email: globalState.email,
      },
    };
    try {
      const res = await setDocumentFirebase(
        'operations',
        params.id,
        newData,
        globalState.currentCompany
      );
      if (res) {
        toast({
          title: 'Saved',
          description: res.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // navigate('/mindmap')
      }
    } catch (error) {
      toast({
        title: t('toast.error'),
        description: error,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handlePushKanban = async () => {
    globalState.setIsLoading(true);
    let promises = [];

    const createDocument = (x) => ({
      title: dataKanban.length > 0 ? `${x?.title}` : `${x?.data?.label}`,
      type: 'operations',
      default_score: 0,
      companyId: data?.companyId,
      projectId: data?.projectId,
      lastUpdated: new Date(),
      users: data?.users,
      sendTo: data?.sendTo || [],
      usersDisplay: data?.usersDisplay,
      operationId: params?.id,
      category: [dataKanban.length > 0 ? `${x?.title}` : `${x?.data?.label}`],
    });

    try {
      if (dataKanban.length > 0) {
        promises = dataKanban.map(async (x) => {
          try {
            const docID = await setDocumentFirebase(
              'files',
              x.id,
              createDocument(x),
              data.companyId
            );
            return docID;
          } catch (error) {
            throw new Error(error.message);
          }
        });
      } else {
        promises = nodes.map(async (x) => {
          try {
            const docID = await addDocumentFirebase(
              'files',
              createDocument(x),
              data.companyId
            );
            return docID;
          } catch (error) {
            throw new Error(error.message);
          }
        });
      }

      const results = await Promise.all(promises);
      // Lakukan tindakan setelah semua dokumen berhasil ditambahkan
      toast({
        title: 'Deoapp',
        description: `success add new document ${results}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      // Tangani kesalahan jika ada yang gagal ditambahkan
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      getKanbanOperation();
      globalState.setIsLoading(false);
    }
  };

  const findProject = globalState.projects?.find(
    (x) => x.id === data?.projectId
  );

  const handleAddCard = async (dataNodes) => {
    globalState.setIsLoading(true);
    const dataRes = {
      title: dataNodes?.data?.label,
      type: 'operations',
      default_score: 0,
      companyId: data?.companyId,
      projectId: data?.projectId,
      lastUpdated: new Date(),
      users: data?.users,
      sendTo: data?.sendTo || [],
      usersDisplay: data?.usersDisplay,
      operationId: params?.id,
      category: [dataNodes.data.label],
    };

    try {
      const docID = await addDocumentFirebase('files', dataRes, data.companyId);
      toast({
        title: 'Deoapp',
        description: `succes add new card ${docID}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      getKanbanOperation();
      getMindmap();
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const PanelControl = () => {
    return (
      <Panel position="top-right">
        <HStack
          bgColor={'white'}
          p={2}
          px={7}
          borderRadius={'3xl'}
          boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        >
          <Text color={'lightgrey'} size={'sm'}>
            {title}
          </Text>
          <Center height="30px">
            <Divider orientation="vertical" />
          </Center>
          <Button bgColor={'transparent'} size={'sm'} onClick={downloadButton}>
            <FcDownload />
          </Button>
          <Button
            bgColor={'transparent'}
            size={'sm'}
            disabled={canUndo}
            onClick={undo}
          >
            <FcUndo />
          </Button>
          <Button
            bgColor={'transparent'}
            size={'sm'}
            disabled={canRedo}
            onClick={redo}
          >
            <FcRedo />
          </Button>
          <Button
            bgColor={'transparent'}
            size={'sm'}
            onClick={save}
            textColor={'black'}
          >
            {t('save')}
          </Button>
        </HStack>
      </Panel>
    );
  };

  return (
    <Stack p={[1, 1, 5]}>
      <HStack flexDirection={['column', 'column', 'row']}>
        <BackButtons />

        <Box maxW={['auto', '500px', '700px']}>
          <Heading noOfLines={1} size={'lg'}>
            {t('Operation')} {params.title}
          </Heading>
          <Text fontSize="2xs" color={'gray.500'}>
            ID :{params.id}
          </Text>
          <Text fontSize="2xs" color={'gray.500'}>
            {t('project')} : {findProject?.name}
          </Text>
        </Box>
        <Spacer />
        <HStack flexDirection={['column', 'row', 'row']} spacing={3}>
          <Stack alignItems={'flex-end'} justifyContent="flex-end" spacing={1}>
            <HStack>
              <AvatarGroup size="sm" gap="1" max={4}>
                {data?.usersDisplay ? (
                  data?.usersDisplay?.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))
                ) : (
                  <></>
                )}
              </AvatarGroup>
              <DynamicButton
                size={'sm'}
                action="custom"
                icon={IoPeople}
                color={'blue'}
                variant={'solid'}
                onClick={onOpen}
              />
              {/* {dataKanban?.length > 0 && ( */}
              <DynamicButton
                action={'custom'}
                icon={TbReload}
                variant="solid"
                color="blue"
                onClick={() => handleRefresh()}
                size="sm"
              />
              {/* )} */}
            </HStack>
            <HStack>
              <DynamicButton
                title="Save"
                onClick={() => handleData()}
                action="create"
                variant="solid"
                size="sm"
              />
              <DynamicButton
                title="Edit"
                onClick={() =>
                  navigate(`/operation/edit/${params.id}`, {
                    state: data,
                  })
                }
                action="update"
                variant="solid"
                size="sm"
              />
            </HStack>
          </Stack>

          <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
            <LiveTime color={'white'} />
          </Stack>
        </HStack>
      </HStack>

      <SimpleGrid columns={[1, 1, 2]} gap={5}>
        <Stack
          border={0.5}
          shadow="md"
          borderColor={'gray.400'}
          borderRadius={'lg'}
          h={'85vh'}
          textColor="black"
        >
          <div className="simple-floatingedges" style={{ width: '100%', height: '800px', backgroundColor:'#171523' }}>
            <ReactFlow
              fitView
              nodes={nodes}
              edges={edges}
              onConnect={onConnect}
              defaultEdgeOptions={defaultEdgeOptions}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitViewOptions={fitViewOptions}
              onNodeDragStart={onNodeDragStart}
              onSelectionDragStart={onSelectionDragStart}
              proOptions={proOptions}
              connectionMode={ConnectionMode.Loose}
            >
              <Controls />
              <MiniMap />

              <PanelControl />
            </ReactFlow>
          </div>
        </Stack>
        <Stack
          border={0.5}
          shadow="md"
          borderColor={'gray.400'}
          borderRadius={'lg'}
          h={'85vh'}
          overflow="scroll"
        >
          <Stack p={[1, 1, 5]} spacing={5}>
            <HStack>
              <Text fontSize={'xl'} fontWeight="bold">
                {t('list')} {t('Line')}
              </Text>
              <Spacer />
            </HStack>
            <Stack>
              <Accordion defaultIndex={[0]} allowMultiple>
                {dataKanban?.length > 0 &&
                  dataKanban?.map((x, index) => {
                    return (
                      <AccordionItem key={index}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Text textTransform={'capitalize'}>
                                {x.title}
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel p={4}>
                          <Stack spacing={4}>
                            <SimpleGrid columns={[1, 2, 2]} gap={10}>
                              <Stack>
                                <Text fontWeight={500}>Set up:</Text>
                                <Stack>
                                  {dataKanban.map((node, nodeIndex) => (
                                    <HStack key={nodeIndex}>
                                      <Checkbox
                                        onChange={() =>
                                          handleCheckboxChange(x.id, node.id)
                                        }
                                        isChecked={
                                          selectedNodesMap[x?.id]?.[node?.id]
                                        }
                                        // defaultChecked={x?.sendTo?.includes(node.id)}
                                      >
                                        {node?.title}
                                      </Checkbox>
                                    </HStack>
                                  ))}
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack>
                                  <Text fontWeight={500}>Duration</Text>
                                  <HStack>
                                    <Input
                                      defaultValue={x.duration || 0}
                                      type={'number'}
                                      onChange={(e) => {
                                        const updatedDataKanban =
                                          dataKanban.map((item) => {
                                            if (item.id === x.id) {
                                              return {
                                                ...item,
                                                duration: parseInt(
                                                  e.target.value,
                                                  10
                                                ),
                                              };
                                            }
                                            return item;
                                          });
                                        setDataKanban(updatedDataKanban);
                                      }}
                                    />
                                    <Text>Days</Text>
                                  </HStack>
                                </Stack>
                                <Text fontWeight={500}>Send to:</Text>
                                {x?.sendTo?.length > 0 && (
                                  <Stack>
                                    {x?.sendTo.map((x, index) => {
                                      const dataTitle = dataKanban?.find(
                                        (y) => y?.id === x
                                      );
                                      return (
                                        <Text key={index}>
                                          {dataTitle?.title}
                                        </Text>
                                      );
                                    })}
                                  </Stack>
                                )}
                              </Stack>
                            </SimpleGrid>
                            <DynamicButton
                              variant={'solid'}
                              size="sm"
                              title="Open card"
                              action={'read'}
                              onClick={() =>
                                navigate(`/line/view/${x.id}/${x.title}`)
                              }
                            />
                          </Stack>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}

                {nodes
                  ?.filter(
                    (dataFilter) =>
                      !dataKanban.some(
                        (y) => y?.title === dataFilter?.data?.label
                      )
                  )
                  .map((y, index) => {
                    return (
                      <AccordionItem key={index}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              <Text textTransform={'capitalize'}>
                                {y?.data?.label}
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel p={4}>
                          {dataKanban?.length > 0 && (
                            <Stack spacing={4}>
                              <DynamicButton
                                variant={'solid'}
                                size="sm"
                                title="Add new card"
                                action={'create'}
                                onClick={() => handleAddCard(y)}
                              />
                            </Stack>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </Stack>
          </Stack>
        </Stack>
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack m="1">
              <Input
                type="text"
                placeholder={t('searchUser')}
                onChange={(e) => handleSearchUsers(e.target.value)}
              />
            </HStack>
            {searchResult?.length > 0 ? (
              searchResult?.map((x, index) => {
                if (data?.users?.find((z) => z !== x.document.id)) {
                  return (
                    <HStack key={index} p="2" borderBottom="1px">
                      <Avatar
                        name={x?.document?.name}
                        src={x?.document.image ? x.document.image : ''}
                      />
                      <Box>
                        <Text>{x?.document?.name}</Text>
                        <Text>{x?.document?.email}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleAddUser(params, x);
                        }}
                      >
                        +
                      </Button>
                    </HStack>
                  );
                }
              })
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

function LineIndexPage() {
  return (
    <ReactFlowProvider>
      <LineOperation />
    </ReactFlowProvider>
  );
}

export default LineIndexPage;
