/* eslint-disable no-console */
import axios from 'axios';
import moment from 'moment';
import { getFileSizeInMB, getVideoDimensions } from '../../Utils/media';
import imageCompression from 'browser-image-compression';

const MAX_CHUNK_SIZE = 150 * 1024 * 1024; // 150MB (maximum size per chunk)

const getFileType = (file) => {
  const fileType = file.type.split('/')[0];
  return ['image', 'video', 'audio'].includes(fileType) ? fileType : 'file';
};

const uploadToDropbox = async (url, file, headers, setProgress) => {
  return axios.post(url, file, {
    headers,
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(progress);
    },
  });
};

const uploadFileToDropboxV3 = async (file, parentPath, token, setProgress) => {
  const currentMillis = moment().valueOf();
  const fileType = getFileType(file);

  const accessToken = token;
  // for image upload
  const url = 'https://content.dropboxapi.com/2/files/upload';
  // other
  const uploadUrl =
  'https://content.dropboxapi.com/2/files/upload_session/start';
  const appendUrl = 'https://content.dropboxapi.com/2/files/upload_session/append_v2';
  const finishUrl = 'https://content.dropboxapi.com/2/files/upload_session/finish';

  let sessionId = null;
  let cursor = 0; // For tracking position in file

  try {
    // Compress image if file type is image
    let fileToUpload = file;
    if (fileType === 'image') {
      const options = {
        maxSizeMB: 0.8,
        // maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      fileToUpload = await imageCompression(file, options);
    }

    const headers = {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${accessToken}`,
      'Dropbox-API-Arg': JSON.stringify({
        path: `${parentPath}/${fileType}/${currentMillis}-${file.name}`,
        mode: 'add',
        autorename: true,
        mute: false,
      }),
    };

    if (fileType === 'image') {
      const responseImage = await uploadToDropbox(url, fileToUpload, headers, setProgress);
      const response = responseImage?.data
      return await handleFileUploadResponse(response, fileType, file, accessToken, currentMillis, parentPath);
    }

    // Start upload session
    const startResponse = await axios.post(uploadUrl, null, {
      headers: {
        ...headers,
        'Dropbox-API-Arg': JSON.stringify({ close: false }),
      },
    });
    sessionId = startResponse.data.session_id;

    // Upload file in chunks
    while (cursor < file.size) {
      const chunkSize = Math.min(MAX_CHUNK_SIZE, file.size - cursor);
      const chunk = file.slice(cursor, cursor + chunkSize);

      await axios.post(appendUrl, chunk, {
        headers: {
          ...headers,
          'Dropbox-API-Arg': JSON.stringify({
            cursor: { offset: cursor, session_id: sessionId },
            close: cursor + chunkSize >= file.size,
          }),
        },
      });

      cursor += chunkSize;
      setProgress(Math.round((cursor * 100) / file.size));
    }

    // Finish upload session
    const finishResponse = await axios.post(finishUrl, null, {
      headers: {
        ...headers,
        'Dropbox-API-Arg': JSON.stringify({
          cursor: { offset: cursor, session_id: sessionId },
          commit: {
            path: `${parentPath}/${fileType}/${currentMillis}-${file.name}`,
            mode: 'add',
            autorename: true,
            mute: false,
          },
        }),
      },
    });
    
    return await handleFileUploadResponse(finishResponse.data, fileType, file, accessToken, currentMillis, parentPath);
  } catch (error) {
    console.error(error);
    return error;
  }
};

const handleFileUploadResponse = async (response, fileType, file, accessToken, currentMillis) => {
  if (response?.path_lower) {
    const fileSize = getFileSizeInMB(file);
    let ratioWidth = 0;
    let ratioHeight = 0;

    if (fileType === 'image') {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      await new Promise((resolve) => (img.onload = resolve));
      ratioWidth = img.naturalWidth;
      ratioHeight = img.naturalHeight;
    } else if (fileType === 'video') {
      const dimensions = await getVideoDimensions(file);
      ratioWidth = dimensions.width;
      ratioHeight = dimensions.height;
    }

    return await createShareLink(
      response.path_lower,
      fileType,
      accessToken,
      fileSize,
      `${currentMillis}-${file.name}`,
      ratioWidth,
      ratioHeight
    );
  }
};

const createShareLink = async (filePath, typeFile, token, fileSize, name, ratioWidth, ratioHeight) => {
  const url = 'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const requestData = { path: filePath };

  try {
    const response = await axios.post(url, requestData, { headers });
    const urlData = response?.data?.url;
    if (urlData) {
      const dataFix = urlData.includes('.mov') || urlData.includes('.MOV');
      const urlRaw = urlData.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
      const finalUrl = dataFix ? urlRaw : `${urlRaw}&raw=1`;

      return {
        link: finalUrl,
        type: typeFile,
        size: Math.round(fileSize || 0),
        name,
        path: filePath,
        ratioWidth,
        ratioHeight,
      };
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default uploadFileToDropboxV3;
