/* eslint-disable react/prop-types */
import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FaDownload } from 'react-icons/fa';
import {
  HStack,
  Button,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { msToTime } from '../../Utils/timeUtil';
import moment from 'moment';
import _axios from '../../Api/AxiosBarrier';
import _ from 'lodash';
import { clientTypessense } from '../../Api/Typesense';

const DownloadButtonExcel = ({
  isLoading,
  size,
  title,
  dates,
  sorting,
  currentPage,
  currentCompany,
  searchId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleTypeSense = (q, uids) => {
    let query_by;
    let collections;
    if (title.toLowerCase().includes('task')) {
      query_by = 'title';
      collections = 'files';
    } else if (title.toLowerCase().includes('people')) {
      query_by = 'name,email';
      collections = 'users';
    }

    const searchParameters = {
      q: q,
      query_by: query_by,
      per_page: 100,
      filter_by: `id: [${uids.join(',')}]`,
      sort_by: '_text_match:desc',
    };

    return clientTypessense
      .collections(collections)
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => {
          return { ...y.document };
        });
        return newData;
      });
  };


  const downloadAllData = async () => {
    try {
      let params;
      if (title.toLowerCase().includes('task')) {
        params = `scoreboard-kanban?page=${currentPage}&limit=100&group=filesId&sort=${
          sorting.desc ? -1 : 1
        }&companyId=${currentCompany}&sortBy=${sorting.sort}&startDate=${
          dates?.startDate
        }&endDate=${dates?.endDate}&type=kanban`;
      } else if (title.toLowerCase().includes('people')) {
        params = `scoreboard-kanban?page=${currentPage}&limit=100&group=uid&sort=${
          sorting.desc ? -1 : 1
        }&companyId=${currentCompany}&sortBy=${sorting.sort}&startDate=${
          dates?.startDate
        }&endDate=${dates?.endDate}&type=kanban&search=${searchId}`;
      }
      const res = await _axios.get(params);

      const uids = _.map(res.data, 'id');
      const searchUid = await handleTypeSense('', uids);

      const data = res.data
        .map((item) => {
          const addData = searchUid.find((y) => y.id === item.id);
          return addData ? { ...addData, ...item } : null;
        })
        .filter(Boolean);

      const formatedData = data?.map(
        ({ email, name, id, title, tasks, avgTime, avgScore }) => ({
          Name: name || title,
          ...(email && { Email: email }),
          ...(id && { Id: id }),
          Tasks: tasks,
          Time: msToTime(avgTime),
          Score: avgScore,
        })
      );

      downloadExcel(formatedData);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  };

  const rangeDate = {
    startDate: moment.unix(dates?.startDate).format('DD-MM-YYYY'),
    endDate: moment.unix(dates?.endDate).format('DD-MM-YYYY'),
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = `${title} ${rangeDate?.startDate} - ${rangeDate?.endDate}${fileExtension}`;

  const downloadExcel = async (excelData) => {

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName);

    return toast({
      description: 'The data has been successfully Downloaded.',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
  };

  return (
    <Tooltip label={`${t('download')} ${title}`} aria-label="A tooltip">
      <Button
        colorScheme="blue"
        shadow="base"
        isLoading={isLoading}
        size={size && size}
        onClick={() => downloadAllData()}
      >
        <HStack>
          <FaDownload />
          <Text>{t('download')}</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
};

export default DownloadButtonExcel;
