import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';

function ProductivityDashboardPage() {
  const {t} = useTranslation()
  const [startIndex, setStartIndex] = useState(0);
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const itemsPerPage = 8;

  const limitValue = startIndex + itemsPerPage;

  useEffect(() => {
    if (globalState?.currentCompany) {
      globalState?.setIsLoading(true);
      const q = query(
        collection(db, 'activity'),
        where('companyId', '==', globalState?.currentCompany),
        limit(limitValue),
        orderBy('createdAt', 'desc')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const qData = [];
        querySnapshot.forEach((doc) => {
          qData.push({ id: doc.id, ...doc.data() });
        });
        setData(qData);
        globalState?.setIsLoading(false);
      });
      return () => {
        unsubscribe();
        // setData([]);
      };
    }
  }, [globalState?.currentCompany, startIndex]);

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  return (
    <Stack p={[1, 1, 5]} spacing={10} minH="100vh">
      <Stack>
        <BackButtons />
        <Text fontWeight={'bold'} fontSize="3xl">
          🚀 {t('welcomeProd')}
        </Text>
        <Text>{t('descNotif')}</Text>
        <Divider />
      </Stack>

      <Stack w="full">
        <Grid templateColumns={{ base: '1fr', md: '1fr' }} gap={3}>
          {/* <Stack
                        overflow={'auto'}
                        spacing={2}
                        borderTopWidth={3}
                        borderBottomWidth={3}
                        borderColor="green.500"
                        py={4}
                        px={2}
                        borderRadius="md"
                        shadow="md"
                    >
                        <ChartsPieOrder />
                    </Stack> */}

          <Stack
            overflow={'auto'}
            h="600px"
            spacing={2}
            borderTopWidth={3}
            borderBottomWidth={3}
            borderColor="green.500"
            py={4}
            px={2}
            borderRadius="md"
            shadow="md"
          >
            <Stack>
              <Text fontSize={'md'} fontWeight={500} textTransform="capitalize">
                {t('userActivity')}
              </Text>
              <Stack gap={1}>
                {data.length > 0 &&
                  data.map((x, index) => {
                    return (
                      <HStack
                        key={index}
                        shadow={'md'}
                        borderRadius={'md'}
                        p={4}
                        spacing={5}
                      >
                        <Stack>
                          <Avatar
                            cursor={'pointer'}
                            size="sm"
                            src={x?.image}
                            alt={x?.title}
                          >
                            <AvatarBadge boxSize="1.2em" bg="green.500" />
                          </Avatar>
                        </Stack>
                        <Stack spacing={0}>
                          <Text fontWeight={500} textTransform="capitalize">
                            {x.action}
                          </Text>
                          <Text fontSize={'sm'}>
                            <b>{x.createdName}</b> {x.action}{' '}
                            <b>{x?.to || ''}</b> {t('at')} <b>{x?.title || ''}</b>{' '}
                            {moment(x.createdAt.seconds * 1000).fromNow()}
                          </Text>
                        </Stack>
                        <Spacer />
                        <Stack>
                          <Text
                            fontSize={'xx-small'}
                            textTransform="capitalize"
                            color={'gray.600'}
                          >
                            {moment(x.createdAt.seconds * 1000).fromNow()}
                          </Text>
                        </Stack>
                      </HStack>
                    );
                  })}
              </Stack>
              <Stack alignItems={'center'} justifyContent="center" py={5}>
                {data?.length > 0 ? (
                  <Box>
                    {data?.length > startIndex && (
                      <Button
                        colorScheme={'blue'}
                        onClick={handleLoadMore}
                        size="sm"
                      >
                        {t('loadMore')}
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Box py={10}>
                    <Center py={10}>
                      <Spinner />
                    </Center>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ProductivityDashboardPage;
