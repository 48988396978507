import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  useToast,
  useColorMode
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import { useTranslation } from 'react-i18next';

function ProductivityDocsViewPage() {
  const {t} = useTranslation()
  const param = useParams();

  const [dataDocsActive, setDataDocsActive] = useState(null);
  const { colorMode } = useColorMode();
  const [alertFile, setAlertFile] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();

  const getData = async () => {
    try {
      const result = await getSingleDocumentFirebase('files', param.id);
      setDataDocsActive(result);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      }
  };

  const handleDelete = () => {
    setAlertFile(true);
  };

  const handleConfirmDelete = async () => {
    const collectionName = 'files';
    const docName = param.id;

    try {
      await deleteDocumentFirebase(collectionName, docName);
      toast({
        title: 'Success',
        description: 'Succes delete file.',
        isClosable: true,
        duration: 9000,
        status: 'success',
      });
      navigate(-1);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      }
  };

  useEffect(() => {
    getData();

    return () => {
      setDataDocsActive(null)
    };
  }, []);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <Stack>
        <HStack>
          <Box>
            <BackButtons />
          </Box>
          <Spacer />
          <HStack>
            <Box>
              <Button
                size={'sm'}
                colorScheme="blue"
                variant={'outline'}
                onClick={() =>
                  navigate(`/docs/edit?id=${param.id}`, {
                    state: dataDocsActive,
                  })
                }
              >
                {t('edit')}
              </Button>
            </Box>

            <Box>
              <Button
                size={'sm'}
                colorScheme="red"
                onClick={() => handleDelete(param.id)}
              >
                {t('delete')}
              </Button>
            </Box>
          </HStack>
        </HStack>
      </Stack>

      {dataDocsActive && (
        <Stack
          spacing={4}
          p={[1, 1, 5]}
          bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}
          // bgColor="white"
          borderRadius={'md'}
          shadow="md"
          minH={'700px'}
        >
          <Heading size="lg" textTransform={'capitalize'}>
            {dataDocsActive.heading}
          </Heading>
          <Text color={'gray.700'}>{dataDocsActive.description}</Text>

          <Divider />

          {/* Tampilkan konten dalam bentuk HTML */}
          <div
            dangerouslySetInnerHTML={{ __html: dataDocsActive.content }}
            style={{ fontSize: '13px', width: '100%', overflow: 'hidden' }}
          />
        </Stack>
      )}

      {/* Modal konfirmasi */}
      <AlertDialog isOpen={alertFile} onClose={() => setAlertFile(true)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('deletePermanent')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('deleteFile')}</AlertDialogBody>

            <AlertDialogFooter>
              <HStack spacing={3}>
                <Button onClick={() => setAlertFile(false)}>
                  {t('cancel')}
                </Button>
                <Button colorScheme="red" onClick={() => handleConfirmDelete()}>
                  {t('delete')}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
}

export default ProductivityDocsViewPage;
