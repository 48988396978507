/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Progress,
  Text,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Select,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function DropboxUploaderAsset({
  modalOpen,
  onClose,
  fileInputRef,
  handleFileInputChange,
  handleSaveButtonClick,
  handleCopy,
  filePreview,
  progress,
  shareLink,
  setDataNew,
  setFilePreview,
  setProgress,
  setShareLink,
  setPath,
  path,
  multipleFilePreview,
  setMultipleFilePreview,
  handleInputName,
  setFileContainer,
  setSingleImageUpload
}) {
  const { t } = useTranslation();
  // const [preview, setPreview] = useState([])
  // const [fileContainer, setFileContainer] = useState([])

  const handleAddNew = (e) => {
    const { value, name } = e.target;
    setDataNew((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleAddNewMultiple = (e, index) => {
  //   const { value, name } = e.target;
  //   setDataNew((prevData) => ({ ...prevData, [name]: value }));
  // }

  const handleModalClose = () => {
    // Mengatur kembali state menjadi nilai awal saat modal ditutup
    setFilePreview(null);
    setMultipleFilePreview([])
    setProgress(0);
    setShareLink('');
    setPath('');
    setFileContainer([])
    setDataNew({});
    setSingleImageUpload(null)
    onClose();
  };

  // const handleMultipleFiles = (files) => {
  //   if(fileContainer?.length > 0){
  //     setFileContainer([...fileContainer, ...files])
  //     displayFilePreview([...fileContainer, ...files])
  //   } else {
  //     displayFilePreview(files)
  //     setFileContainer(files)
  //   }
  // }

  return (
    <Modal isOpen={modalOpen} size={'2xl'} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent zIndex={10}>
        <ModalHeader>
          {t('upload')} {t('file')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant={'enclosed'}>
            <TabList>
              <Tab>Upload Single File</Tab>
              <Tab>Multi File Upload</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Stack spacing={5} p={4}>
                  {filePreview}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                    style={{
                      height: '300px',
                      width: '300px',
                      borderRadius: 'md',
                      border: '2px dashed #cbd5e0',
                      cursor: 'pointer',
                      padding: '8',
                      textAlign: 'center',
                      display: filePreview !== null ? 'none' : 'flex',
                    }}
                  />
                  <Stack>
                    <Input
                      name="title"
                      onChange={handleAddNew}
                      placeholder={t('nameFile')}
                    />
                    <Text>
                      {t('path')}: {path}
                    </Text>
                  </Stack>
                  <Progress
                    mt={2}
                    value={progress}
                    size="sm"
                    colorScheme="blue"
                  />
                  <Box mt={2}>{progress}%</Box>
                  {shareLink && (
                    <Stack mt={4} alignItems="center" justifyContent={'center'}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Text fontSize={11} color="gray.500">
                          {shareLink ? shareLink : 'none'}
                        </Text>
                      </Box>
                      <HStack>
                        <Button
                          onClick={() => handleCopy(shareLink)}
                          size="sm"
                          variant="outline"
                          colorScheme={'blue'}
                        >
                          {t('copy')}
                        </Button>
                        <a href={shareLink} target="_blank" rel="noopener noreferrer">
                          <Button size="sm" variant="outline" colorScheme={'blue'}>
                            {t('open')}
                          </Button>
                        </a>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack spacing={3} p={2}>
                  {/* <Stack spacing={1}>
                    <Text fontWeight={500}>Select File Type</Text>
                    <Select size={'sm'} placeholder='Select File Type'>
                      <option>Image</option>
                      <option>Video</option>
                      <option>Audio</option>
                      <option>File</option>
                    </Select>
                  </Stack> */}

                  <Stack w={'fit-content'}>
                    {multipleFilePreview?.length > 0 ? (
                      <Flex gap={3} wrap={'wrap'}>
                        {multipleFilePreview?.map((x, i) => (
                          <Stack key={i} w={'fit-content'}>
                            {x}
                            <Input placeholder='file name' onChange={(e) => handleInputName(e, i)} />
                          </Stack>
                        ))}
                        <input
                          type="file"
                          ref={fileInputRef}
                          multiple
                          // onChange={(e) => handleMultipleFiles(e.target.files)}
                          onChange={handleFileInputChange}
                          style={{
                            height: '250px',
                            width: '250px',
                            borderRadius: 'md',
                            border: '2px dashed #cbd5e0',
                            cursor: 'pointer',
                            padding: '8',
                            textAlign: 'center',
                            // display: filePreview !== null ? 'none' : 'flex',
                          }}
                        />
                      </Flex>
                    ):(
                      <input
                        type="file"
                        ref={fileInputRef}
                        multiple
                        // onChange={(e) => handleMultipleFiles(e.target.files)}
                        onChange={handleFileInputChange}
                        style={{
                          height: '250px',
                          width: '250px',
                          borderRadius: 'md',
                          border: '2px dashed #cbd5e0',
                          cursor: 'pointer',
                          padding: '8',
                          textAlign: 'center',
                          // display: filePreview !== null ? 'none' : 'flex',
                        }}
                      />
                    )}
                  </Stack>
                  <Stack>
                    {/* <Input
                      name="title"
                      onChange={handleAddNew}
                      placeholder={t('nameFile')}
                    /> */}
                    <Text>
                      {t('path')}: {path}
                    </Text>
                  </Stack>
                  <Progress
                    mt={2}
                    value={progress}
                    size="sm"
                    colorScheme="blue"
                  />
                  <Box mt={2}>{progress}%</Box>
                  {shareLink && (
                    <Stack mt={4} alignItems="center" justifyContent={'center'}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Text fontSize={11} color="gray.500">
                          {shareLink ? shareLink : 'none'}
                        </Text>
                      </Box>
                      <HStack>
                        <Button
                          onClick={() => handleCopy(shareLink)}
                          size="sm"
                          variant="outline"
                          colorScheme={'blue'}
                        >
                          {t('copy')}
                        </Button>
                        <a href={shareLink} target="_blank" rel="noopener noreferrer">
                          <Button size="sm" variant="outline" colorScheme={'blue'}>
                            {t('open')}
                          </Button>
                        </a>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {progress === 0 && (
              <Button onClick={handleSaveButtonClick}> {t('save')}</Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DropboxUploaderAsset;
