/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  HStack,
  Spacer,
  Text,
  Stack,
  Tooltip,
  useToast,
  Image,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Textarea,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import axios from 'axios';
import { FiTag } from 'react-icons/fi';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../DropBox/UploadFileToDropbox';

import { useDrag } from 'react-dnd';
import moment from 'moment';
import SingleCalendarComponent from './SingleCalendarComponent';
import CardFile from '../Accounting/CardFile';
import ProgressCircle from '../Progress/ProgressCircle';
import { FcFolder } from 'react-icons/fc';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import DynamicButton from '../Buttons/DynamicButton';
import { MdOutlineFlashOn } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { createHistoryCard } from '../../Utils/historyCardUtil';


function TaskLineCardComponent(props) {
  const {t} = useTranslation()
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { userDisplay } = useKanbanStore();
  const setSomeFunction = useKanbanStore((state) => state.setFunctionAddData);
  const setTaskData = useKanbanStore((state) => state.setTaskData);
  const globalState = useUserStore();
  const [modalNotes, setModalNotes] = useState(false)

  const [progress, setProgress] = useState(0)





  const toast = useToast();

  const middlewarePic = () => {
    toast({
      status: 'warning',
      duration: 9000,
      title: 'You don\'t have access to do this card',
      isClosable: true,
    });
  };

  const handleAddPoint = async (assignee, point, task) => {
    const seen = {};
    const result = [];

    if (!assignee || assignee.length === 0) {
      return toast({
        status: 'warning',
        duration: 9000,
        title: 'You don\'t have assignee for this point',
        isClosable: true,
      });
    }

    const uniqueAssignee = Array.from(new Set(assignee)); // Remove duplicate assignees

    const promises = uniqueAssignee?.map(async (uid) => {
      if (uid in seen) {
        return;
      }

      const userData = userDisplay.find((user) => user.id === uid);

      if (userData) {
        const newObj = {
          uid: uid,
          email: userData?.email,
          title: props?.kanbanData?.title,
          task: task,
          filesId: props?.task?.filesId,
          score: parseInt(point),
          type: 'operation',
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          time_backlog_at: props?.task?.timeFrom * 1000,
          time_completed_at: props?.task?.time_completed_at ? moment(new Date(props?.task?.time_completed_at?.seconds * 1000)).valueOf() : moment(new Date()).valueOf(),
          time_from: new Date(props?.task?.createdAt?.seconds * 1000),
          time_from_unix: moment(new Date(props?.task?.createdAt?.seconds * 1000)).valueOf(),
          time_to: props?.task?.time_completed_at ? new Date(props?.task?.time_completed_at?.seconds * 1000) : new Date(),
          time_to_unix: props?.task?.time_completed_at ? moment(new Date(props?.task?.time_completed_at?.seconds * 1000)).valueOf() : moment(new Date()).valueOf(),
          createdAt: new Date(),
          status: 'done'
        };




        result.push(newObj);

        const data = {
          collection_name: 'files',
          fields: newObj,
        };


        try {
          const res = await axios.post(
            'https://new-apiv2.importir.com/api/general-log/store-deoapp',
            data,
            {
              headers: {
                token:
                  '$2y$10$bN5cOHkcoUR2QaK05xmTrOUHY1vEVmS9ne6h99Jrp7CJJKryMylri',
              },
            }
          );

          if (res?.data?.status === true) {
            const collectionName = 'logs';

            try {
              await addDocumentFirebase(
                collectionName,
                data,
                globalState?.currentCompany
              );
              toast({
                status: 'success',
                duration: 2000,
                title: `${userData?.email} get ${point} score from ${task} card`,
                isClosable: true,
              });
            } catch (error) {
              toast({
                title: t('error'),
                description: error.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          } else {
            toast({
              status: 'error',
              duration: 9000,
              title: res?.data?.status?.message,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: 'Error!',
            description: error.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }

        seen[uid] = true;
      } else {
        toast({
          status: 'warning',
          duration: 9000,
          title: 'No matching user found for assignee with UID: ' + uid,
          isClosable: true,
        });
      }
    });

    await Promise.all(promises);
  };

  const handleMoveCard = (id, column) => {
    const picProject = props?.kanbanData?.pic;
    const picTask = props?.task?.pic;
    const assignee = props?.task?.asignee;
    const score = props?.task?.score;
    const title = props?.task?.title



    if (column !== 'done' && props?.task?.column === 'done') {
      return toast({
        status: 'warning',
        duration: 9000,
        title: 'Cannot put back to review if your task has done',
        isClosable: true,
      });
    }

    if (column !== 'backlog' && column !== 'todos') {
      if (props?.task?.timeFrom === undefined) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'Please make sure to insert your card through the todos/progress first.',
          isClosable: true,
        });
      }
    }

    if (picTask && picTask.length > 0) {
      const filterPic = picTask.filter((x) => x === globalState?.uid);


      if (column === 'done' && filterPic.length === 0) {
        return middlewarePic();
      }
    }


    if (picTask.length === 0) {
      if (picProject && picProject.length > 0) {
        const filterPic = picProject.filter((x) => x?.uid === globalState?.uid);



        if (column === 'done' && filterPic.length === 0) {

          return middlewarePic();
        }
      }
    }




    if (column === 'done' && props?.task?.column !== 'done') {
      if (!assignee || assignee?.length === 0) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have assignee for this card',
          isClosable: true,
        });
      }


      if (score === 0 || score === undefined) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have a score for this card',
          isClosable: true,
        });
      }

      if (props?.task?.label === undefined) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have label status for this card',
          isClosable: true,
        });
      }
    }

    props.setData([
      ...props.columnsData.slice(0, index),
      ...props.columnsData.slice(props.index + 1),
    ]);

    const durationInDays = props?.kanbanData?.duration || 0;

    const dueOnUnix = moment().add(durationInDays, 'days').unix();

    if (column === 'progress' && props?.task?.time_backlog_at === undefined) {

      updateDocumentFirebase('kanban', id, {
        column: column,
        lastUpdated: new Date(),
        time_backlog_at: new Date(),
        dueOn: dueOnUnix,
        timeFrom: moment().unix(),
        timeTo: dueOnUnix,
      });


    } else {
      updateDocumentFirebase('kanban', id, {
        column: column,
        lastUpdated: new Date(),
        dueOn: dueOnUnix,
        timeFrom: moment().unix(),
        timeTo: dueOnUnix,
      });
    }

    const comments = `has moved card to ${column}`

    createHistoryCard(id, comments, globalState)

    if (
      column === 'done' &&
      props?.task?.column !== 'done' &&
      props?.task?.label === 'completed'
    ) {
      handleAddPoint(assignee, score, title, 'done');
      handleSendCard(props?.task, props?.kanbanData)
    }



  };

  const handleSendCard = (task, kanban) => {
    if (kanban?.sendTo?.length > 0) {
      kanban?.sendTo.map(async (x) => {
        const data = {
          title: task.title,
          asignee: [globalState.uid],
          column: 'todos',
          filesId: x,
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          createdAt: new Date(),
          lastUpdated: new Date(),
          dueOn: moment().unix(),
          timeFrom: moment().unix(),
          timeTo: moment().unix(),
          score: 6,
          pic: [],
          size: 0,
          files: task?.files || [],
          notes: task?.notes || ''
        }
        try {
          await addDocumentFirebase('kanban', data, globalState?.currentCompany);
          toast({
            status: 'success',
            duration: 3000,
            title: 'Success add new card.',
            isClosable: true,
          });

        } catch (error) {
          toast({
            status: 'error',
            duration: 3000,
            title: error.message,
            isClosable: true,
          });
        }
      })
    }
  }

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'column',
      item: props?.task.id,
      end(item, monitor) {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          const isDropAllowed = dropResult.allowedDropEffect === 'move';
          if (isDropAllowed) {
            handleMoveCard(props.task.id, dropResult.name);
          }
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [props.task]
  );



  const onSelectFile = async (arg) => {
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );

      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = props.task.id;
        const field = 'files';
        const values = [dropboxLink];

        await arrayUnionFirebase(collectionName, docName, field, values);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });

      }



    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }

  }



  useEffect(() => {
    setIndex(props.index);
  }, []);


  const handleOpenModalNotes = () => {
    setModalNotes(true)
  }

  const handleCloseModalNotes = () => {
    setModalNotes(false)
  }

  return (
    <>
      <Box
        // height='24'
        width="full"
        shadow="base"
        p="2"
        ref={drag}
        style={{ opacity }}
        cursor="pointer"
      >
        {props?.task?.files?.length > 0 ? (
          <Stack shadow={'md'} borderRadius="md">
            {props?.task?.files[props?.task?.files?.length - 1].type ===
            'image' ? (
                <Image
                  src={props?.task?.files[props?.task?.files?.length - 1]?.link}
                  borderRadius="md"
                  objectFit="contain"
                  w="full"
                  alt="www.google.com"
                />
              ) : (
                <Stack alignItems={'center'} justify="center">
                  <FcFolder size={80} />
                </Stack>
              )}
          </Stack>
        ) : (
          <Stack zIndex={100} onChange={onSelectFile} display="none">
            <CardFile IsIcon={true} onChange={onSelectFile} />
          </Stack>
        )}

        <Stack spacing={1} my={2}>
          <Box
            onClick={() => {
              navigate(`${props?.task?.id}`, { state: { type: 'existing' } });
              setSomeFunction(props?.setData);
              setTaskData(props);
            }}
          >
            {props.task?.category && (
              <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
                {props?.task?.category}
              </Tag>
            )}
            {props?.task?.automationId && (
              <Tag colorScheme={'yellow'} size="sm" fontSize={'xs'}>
                <MdOutlineFlashOn />
              </Tag>
            )}
          </Box>
          <HStack alignItems={'flex-start'} justifyContent="flex-start">
            <Text
              onClick={() => {
                navigate(`${props?.task?.id}`, { state: { type: 'existing' } });
                setSomeFunction(props?.setData);
                setTaskData(props);
              }}
              fontWeight="extrabold"
            >
              {props.task.title}
            </Text>
            <Spacer />
            <Stack>
              <HiOutlinePencilAlt size={20} onClick={handleOpenModalNotes} />
            </Stack>
          </HStack>
        </Stack>
        <Stack
          spacing={1}
          onClick={() => {
            navigate(`${props?.task?.id}`, { state: { type: 'existing' } });
            setSomeFunction(props?.setData);
            setTaskData(props);
          }}
        >
          <Box>
            {props.task.label ? (
              <Badge
                colorScheme={
                  props.task.label === 'help'
                    ? 'yellow'
                    : props.task.label === 'pending'
                      ? 'gray'
                      : props.task.label === 'blocked'
                        ? 'red'
                        : props.task.label === 'completed'
                          ? 'green'
                          : props.task.label === 'priority'
                            ? 'blue'
                            : 'orange'
                }
              >
                <HStack>
                  <FiTag />
                  <Text>{props.task.label}</Text>
                </HStack>
              </Badge>
            ) : (
              <></>
            )}
          </Box>
          <HStack spacing={1}>
            {props.task.dueOn ? (
              <SingleCalendarComponent
                data={props?.task?.dueOn}
                date={moment.unix(props?.task?.dueOn).format('DD')}
                month={moment.unix(props?.task?.dueOn).format('MMM')}
              />
            ) : (
              <></>
            )}
            <Stack
              w="30px"
              borderRadius={'full'}
              h="30px"
              bgColor={props?.task?.score > 0 ? 'blue.400' : 'red.400'}
              alignItems={'center'}
              justifyContent="center"
            >
              <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
                {props?.task?.score || 0}
              </Text>
            </Stack>

            {props?.task?.createdBy ? (
              <Tooltip
                label={
                  userDisplay?.find((z) => z.id === props?.task?.createdBy)
                    ?.name || props?.task?.createdBy
                }
                aria-label="Name"
              >
                {props?.task?.createdBy === 'Deoapp Automation' ? (
                  <Stack
                    w="30px"
                    borderRadius={'full'}
                    h="30px"
                    bgColor={'yellow.400'}
                    alignItems={'center'}
                    justifyContent="center"
                  >
                    <MdOutlineFlashOn />
                  </Stack>
                ) : (
                  <Avatar
                    m="0"
                    size="sm"
                    name={
                      userDisplay?.find((z) => z.id === props?.task?.createdBy)
                        ?.name || props?.task?.createdBy
                    }
                    image={
                      userDisplay?.find((x) => x.id === props?.task?.createdBy)
                        ?.image
                    }
                  />
                )}
              </Tooltip>
            ) : (
              <></>
            )}

            <Spacer />

            <AvatarGroup spacing={-3} size="sm" max={1}>
              {props?.task?.asignee ? (
                props.task?.asignee?.sort()?.map((x, i) => {
                  const name = props.kanbanData?.usersDisplay?.find(
                    (y) => y.id === x
                  )?.name;
                  return (
                    <Tooltip key={i} label={name} aria-label="Name">
                      <Avatar size="xs" name={name} />
                    </Tooltip>
                  );
                })
              ) : (
                <></>
              )}
            </AvatarGroup>
          </HStack>
          {props?.task?.pic?.length > 0 ? (
            props?.task?.pic.map((x, index) => {
              return (
                <HStack key={index}>
                  <Text
                    textAlign="left"
                    fontSize="2xs"
                    fontWeight={500}
                    color="gray.600"
                  >
                    {t('pic')}
                  </Text>
                  <Spacer />
                  <Text
                    textAlign="right"
                    fontSize="2xs"
                    fontWeight={500}
                    textTransform="capitalize"
                  >
                    {userDisplay?.find((y) => y.id === x)?.name}
                  </Text>
                </HStack>
              );
            })
          ) : (
            <></>
          )}
          {props?.task?.lastUpdated?.seconds ? (
            <Text textAlign="left" fontSize="2xs">
              {t('updated')}
              {moment.unix(props?.task?.lastUpdated?.seconds).fromNow()}
            </Text>
          ) : (
            <></>
          )}
          <Text fontSize="3xs">ID: {props.task.id}</Text>
        </Stack>
      </Box>

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}

      <Modal size={'xl'} isOpen={modalNotes} onClose={handleCloseModalNotes}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('note')}</ModalHeader>
          <ModalBody>
            <Textarea
              height={'300px'}
              value={props?.task?.notes || 'dont have any notes for this card'}
            />
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <DynamicButton
                title={t('close')}
                size="sm"
                action="delete"
                variant="solid"
                onClick={handleCloseModalNotes}
              />
              <DynamicButton
                title={t('edit')}
                size="sm"
                action="read"
                variant="solid"
                onClick={() => {
                  navigate(`${props?.task?.id}`, {
                    state: { type: 'existing' },
                  });
                  setSomeFunction(props?.setData);
                  setTaskData(props);
                }}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TaskLineCardComponent;
