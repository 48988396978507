import { useState, useCallback, useEffect, useRef } from 'react';
import {
  useReactFlow,
  getConnectedEdges,
  useStore,
} from 'reactflow';
import { shallow } from 'zustand/shallow';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  setNodesZustand: state.setNodes,
  setEdgesZustand: state.setEdges,
});

function useCopyPaste() {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);

  const { project, getNodes, setNodes, getEdges, setEdges } = useReactFlow();
  const {  setNodesZustand, setEdgesZustand } = useFlowchartStore(
    selector,
    shallow
  );
  const [bufferedNodes, setBufferedNodes] = useState([]);
  const [bufferedEdges, setBufferedEdges] = useState([]);

  useEffect(() => {
    const events = ['cut', 'copy', 'paste'];

    if (rfDomNode) {
      const preventDefault = (e) => e.preventDefault();

      const onMouseMove = (event) => {
        const bounds = rfDomNode.getBoundingClientRect();
        mousePosRef.current = {
          x: event.clientX - (bounds?.left ?? 0),
          y: event.clientY - (bounds?.top ?? 0),
        };
      };

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }

      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  const copy = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      (edge) => {
        const isExternalSource = selectedNodes.every(
          (n) => n.id !== edge.source
        );
        const isExternalTarget = selectedNodes.every(
          (n) => n.id !== edge.target
        );

        return !(isExternalSource || isExternalTarget);
      }
    );

    setBufferedNodes(selectedNodes);
    setBufferedEdges(selectedEdges);
  }, [getNodes, getEdges]);

  const cut = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      (edge) => {
        const isExternalSource = selectedNodes.every(
          (n) => n.id !== edge.source
        );
        const isExternalTarget = selectedNodes.every(
          (n) => n.id !== edge.target
        );

        return !(isExternalSource || isExternalTarget);
      }
    );

    setBufferedNodes(selectedNodes);
    setBufferedEdges(selectedEdges);

    setNodes((nodes) => nodes.filter((node) => !node.selected));
    setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge)));
  }, [getNodes, setNodes, getEdges, setEdges]);

  const paste = useCallback(
    (
      { x: pasteX, y: pasteY } = project({
        x: mousePosRef.current.x,
        y: mousePosRef.current.y,
      })
    ) => {
      const minX = Math.min(...bufferedNodes.map((s) => s.position.x));
      const minY = Math.min(...bufferedNodes.map((s) => s.position.y));

      const now = Date.now();

      const newNodes = bufferedNodes.map((node) => {
        const id = `${node.id}-${now}`;
        const x = pasteX + (node.position.x - minX);
        const y = pasteY + (node.position.y - minY);

        return { ...node, id, position: { x, y } };
      });

      const newEdges = bufferedEdges.map((edge) => {
        const id = `${edge.id}-${now}`;
        const source = `${edge.source}-${now}`;
        const target = `${edge.target}-${now}`;

        return { ...edge, id, source, target };
      });

      setNodes((nodes) => [
        ...nodes.map((node) => ({ ...node, selected: false })),
        ...newNodes,
      ]);
      setEdges((edges) => [...edges, ...newEdges]);
      setNodesZustand(getNodes());
      setEdgesZustand(getEdges());
      // console.log(getNodes(), 'nodes paste')
    },
    [bufferedNodes, bufferedEdges, project, setNodes, setEdges]
  );

  // useShortcut(['Meta+x', 'Ctrl+x'], cut);
  // useShortcut(['Meta+c', 'Ctrl+c'], copy);
  // useShortcut(['Meta+v', 'Ctrl+v'], paste);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        copy();
      } else if (event.key === 'x' && (event.ctrlKey || event.metaKey)) {
        cut();
      } else if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        paste();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [cut, copy, paste]);

  return { cut, copy, paste, bufferedNodes, bufferedEdges };
}

// function useShortcut(keyCode, callback) {
//      const [didRun, setDidRun] = useState(false);
//      const shouldRun = useKeyPress(keyCode);

//      useEffect(() => {
//           if (shouldRun && !didRun) {
//                callback();
//                setDidRun(true);
//           } else {
//                setDidRun(shouldRun);
//           }
//      }, [shouldRun, didRun, callback]);
// }

export default useCopyPaste;
