/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex, Icon, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import moment from 'moment';
import { MdOutlineFlashOn, MdOutlineSubdirectoryArrowLeft, MdOutlineSubdirectoryArrowRight } from 'react-icons/md';


const stepsDummy = [
  { title: 'FROM', description: 'Customer has paid', icon: MdOutlineSubdirectoryArrowLeft },
  { title: 'ACTION', description: 'Supplier has paid', icon: MdOutlineFlashOn },
  { title: 'TO', description: 'In warehouse', icon: MdOutlineSubdirectoryArrowRight },
];

const Step = ({ title, description, isActive, isCompleted, icon }) => {
  return (
    <Stack>
      <Stack alignItems={['flex-start', 'flex-start', 'center']} justifyContent={['flex-start', 'flex-start', 'center']}>
      
        <Icon transition="background-color 0.3s"
          boxSize='10' bgColor={isCompleted ? 'blue.500' : 'gray.100'} fontSize='sm' borderWidth={0.5} shadow={'md'} borderColor='blue.500' borderRadius='full' as={icon} p='1.5'
        />

      </Stack>
      <Stack alignItems={['flex-start', 'flex-start', 'center']}>
        <Text fontWeight={isActive ? 'bold' : 'normal'} fontSize='sm' textTransform="uppercase">
          {title}
        </Text>
        <Text fontSize="xs">{description ? moment(description).format('LLL') : ''}</Text>
      </Stack>
    </Stack>
  );
};

const CustomStepper = ({ currentStep, steps }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getStepIndex = () => {
    return stepsDummy.findIndex((step) => step.title === currentStep.toUpperCase());
  };

  const updatedSteps = stepsDummy.map((step) => {
    const matchedStep = steps.find((s) => s.title.toUpperCase() === (step.title));
    return {
      ...step,
      description: matchedStep ? matchedStep.created_at : '',
    };
  });


  return (
    <Stack>
      <Flex direction={isMobile ? 'column' : 'row'}>
        {stepsDummy.length > 0 &&
          updatedSteps.map((step, index) => (
            <React.Fragment key={index}>
              {index !== 0 && !isMobile && (
                <Box
                  flex="1"
                  mx={3}
                  h="2px"
                  bg={index <= getStepIndex() ? 'blue.500' : 'gray.300'}
                  alignSelf="center"
                  transition="background-color 0.3s"
                />
              )}
              <Stack>
                <Step
                  title={step.title}
                  icon={step.icon}
                  description={step.description}
                  isActive={step.title === currentStep}
                  isCompleted={index <= getStepIndex()}
                  lastStep={currentStep}
                />
              </Stack>
            </React.Fragment>
          ))}
      </Flex>
    </Stack>
  );
};

export default CustomStepper;