/* eslint-disable react/no-children-prop */
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { errorSlack } from '../../Api/slackApi';
import { useTranslation } from 'react-i18next';

function LoginEmail() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const globalState = useUserStore();

  const path = window.location.href;

  if (globalState?.uid) {
    navigate('/');
  }

  const toast = useToast();

  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      localStorage.clear();

      setPersistence(auth, browserSessionPersistence)
        .then(async () => {
          setLoading(true);
          const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          
          const user = userCredential.user;

          if (user) {
            toast({
              title: 'Login Successful',
              description: `You have successfully logged in as ${userCredential?.user?.email} `,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }

        
        })
        .catch(async (error) => {
          toast({
            title: t('toast.error'),
            description:
              error.code === 'auth/wrong-password'
                ? 'Wrong email or password. Please try again.'
                : error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });

          await errorSlack(
            `${error.message}-${email}`,
            'business.deoapp.com',
            path
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const height = window.innerHeight;

  return (
    <>
      <Stack
        spacing={10}
        pt={19}
        minH={height}
        bg="url(https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/product/hk1BcSSZMoWI9GBLJBGA/vecteezy_abstract-white-and-gray-light-gradient-lines-background_6469230.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={'center'}
        justifyContent="center"
      >
        <Stack>
          <Stack alignItems={'center'} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              // bgColor="blackAlpha.500"
              bgColor="#FFFFFF"
              boxShadow="md" 
              border="1px solid #e0e0e0"
              p={10}
              borderRadius="xl"
            >
              <Box  p={2}>
                <Image
                  w="200px"
                  src={'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png'}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '95%',
                    md: '100%',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="email"
                    fontSize={'md'}
                    shadow={'md'}
                    bgColor={'white'}
                    color={'black'}
                    onChange={
                      (e) => setEmail(e.target.value)
                    //   setDataLogin({ ...dataLogin, email: e.target.value })
                    }
                  />
                </InputGroup>
              </Stack>
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '92%',
                    md: '92%',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdVpnKey size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="password"
                    fontSize={'md'}
                    shadow={'md'}
                    type={showPassword ? 'text' : 'password'}
                    color={'black'}
                    bgColor={'white'}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>

              </Stack>


              <Stack>
                {loading ? (
                  <Spinner size={'md'}  />
                ) : (
                  <HStack>
                    <Stack w={'full'}>
                      <Button m={2} variant="outline" size={'sm'} onClick={() => navigate('/reset-password')}>
                        <Text
                          color={'#4A5568'}
                          fontSize={['xs', null, 'sm']}
                          fontWeight={'bold'}
                        >
                          Forgot password
                        </Text>

                      </Button>
                    </Stack>
                    <Stack>
                      <Button
                        alignItems={'center'}
                        justifyContent="center"
                        size={'sm'}
                        cursor={'pointer'}
                        bgColor={'gray.200'}
                        m={2}
                        onClick={() => handleLogin()}
                      >
                        <Text color={'black'} fontSize="xs" fontWeight="bold">
                          CONTINUE
                        </Text>
                      </Button>
                    </Stack>
                  </HStack>
                )}
              </Stack>
              <Stack px={5} w={'100%'} alignItems="flex-start" color={'black'} align="flex-start" justifyContent={'flex-start'}  >
                {/* <Checkbox
                  size={"md"}
                  defaultChecked={false}
                  isChecked={checkForce}
                  onChange={(e) => setCheckForce(e.target.checked)}
                >
                  <Text fontSize="sm" color={"gray.400"} fontWeight={500}>Forced Login</Text>
                </Checkbox> */}

              </Stack>

              <Spacer />

              <HStack space={1}>
                <Text color={'black'} fontSize={['xs', null, 'sm']}>
                  Dont have any account ?
                </Text>
                <Text
                  color={'black'}
                  fontSize={['xs', null, 'sm']}
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() => navigate('/signup')}
                >
                  Join now !
                </Text>
              </HStack>
            </Stack>
          </Stack>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default LoginEmail;
