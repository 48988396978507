import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Heading,
  SimpleGrid,
  useDisclosure,
  useToast,
  Stack,
  Text,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
  VStack,
  Divider,
  Input,
  Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  deleteSubcollection,
  getCollectionFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  updateDoc,
} from '@firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { clientTypessense } from '../../Api/Typesense';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FcMindMap } from 'react-icons/fc';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { MarkerType } from 'reactflow';
import CardAvatar from '../../Components/Card/CardAvatar';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

const FlowchartPage = () => {
  const {t} = useTranslation()
  const [flowchart, setFlowchart] = useState([]);
  const [selectedFlowchart, setSelectedFlowchart] = useState(null);
  const addMindmap = useDisclosure();
  const navigate = useNavigate();
  const globalState = useUserStore();
  const alertDelete = useDisclosure();
  const toast = useToast();
  const [modal, setModal] = useState({ delete: false, user: false });
  const [editor, setEditor] = useState([]);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [dataMindmapSearch, setDataMindmapSearch] = useState([]);

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;
  const [input, setInput] = useState('');

  // const nodeDefaults = {
  //   sourcePosition: Position.Right,
  //   targetPosition: Position.Left,
  // };
  const type = 'mindmap';
  const handleClick = async () => {
    const initialNodes = [
      {
        id: '1',
        label: '1',
        position: { x: 0, y: 0 },
        data: { label: 'Add' },
        type: 'custom',
      },
      {
        id: '2',
        label: '2',
        position: { x: 0, y: 200 },
        data: { label: 'Add' },
        type: 'custom',
      },
    ];

    const initialEdges = [
      {
        id: '1-2',
        source: '1',
        target: '2',
        sourceHandle: 'c',
        targetHandle: 'a',
        type: 'floating',
        color: 'black',
        markerEnd: { type: MarkerType.ArrowClosed },
      },
    ];

    addDocumentFirebase(
      'flowcharts',
      {
        owner: [globalState?.uid],
        users: [globalState?.uid],
        nodes: initialNodes,
        edges: initialEdges,
        title: input,
        type: type,
        projectId: globalState?.currentProject,
      },
      globalState?.currentCompany
    )
      .then((res) => {
        handleLogActivityNew(input);
        setDocumentFirebase(`flowcharts/${res}/users`, globalState?.uid, {
          name: globalState?.name,
          uid: globalState?.uid,
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
        })
          .then(() => {
            navigate(`/mindmap/${res}`);
          })
          .catch((error) => {
            toast({
              title: 'Deoapp.com',
              duration: 3000,
              description: error.message,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  const handleLogActivityNew = async (input) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Task',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Add New Mindmap',
      collection_path: 'mindmap',
      title: input,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getMindmapV2 = async () => {
    if (globalState?.currentCompany) {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        { field: 'type', operator: '==', value: 'mindmap' },
        { field: 'users', operator: 'array-contains', value: globalState?.uid },
      ];

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      try {
        globalState.setIsLoading(true);
        const resAssets = await getCollectionFirebase(
          'flowcharts',
          conditions,
          sortBy,
          limitValue
        );
        const projectDataArray = await Promise.all(
          resAssets?.map(async (projectData) => {
            const userSnapshot = await getDocs(
              collection(db, `flowcharts/${projectData?.id}/users`)
            );
            const userData = userSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            projectData.usersProjectData = userData;
            return projectData;
          })
        );

        setFlowchart(projectDataArray.length > 0 ? projectDataArray : []);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        globalState.setIsLoading(false);
      }
    }
  };

  const handleLogActivity = async (x) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Mindmap',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Delete Mindmap',
      collection_path: 'flowchart',
      title: x.title,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteFlowchart = async () => {
    const resSubCollection = await deleteSubcollection(
      `flowcharts/${selectedFlowchart?.id}/users`
    );
    // console.log(resSubCollection, 'xxx')
    if (resSubCollection) {
      // console.log('cek')
      const res = await deleteDocumentFirebase(
        'flowcharts',
        selectedFlowchart?.id
      );
      if (res) {
        handleLogActivity(selectedFlowchart);
        setSelectedFlowchart(null);
        // setModal({ ...modal, delete: false })
        toast({
          title: 'Deoapp.com',
          description: res,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        alertDelete?.onClose();
        getMindmapV2();
      }
    }
  };

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersProjectData?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  const handleRemoveUser = async (x) => {
    try {
      await deleteDocumentFirebase(`flowcharts/${data.id}/users`, x.id);

      const dataRef = doc(db, 'flowcharts', data?.id);
      await updateDoc(dataRef, { owner: arrayRemove(x.id) });
      await updateDoc(dataRef, { users: arrayRemove(x.id) });

      setModal({ ...modal, user: false });
      toast({
        title: 'Deleted',
        description: 'User Deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      getMindmapV2();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `type: mindmap && companyId:${globalState?.currentCompany} && users:${globalState.uid}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('flowcharts')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x?.hits?.map((x) => x.document);
        setDataMindmapSearch(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getMindmapV2();
    }

    return () => {
      // setFlowchart([])
      setEditor([]);
      setDataMindmapSearch([]);
      setSelectedFlowchart(null);
    };
  }, [globalState?.currentCompany, startIndex]);

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} textTransform="capitalize">
          {t('Mind Map')}
        </Heading>
        <Spacer />
        <Stack>
          <Input
            placeholder={t('search')}
            // bgColor={'white'}
            shadow="md"
            borderRadius={'md'}
            size={'md'}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Stack>
        <DynamicButton
          action={'create'}
          onClick={() => addMindmap.onOpen()}
          title={t('createMindmap')}
          variant={'solid'}
        />
        {/* <AddButtonComponentMindmap type={"mindmap"} link={"/mindmap"} /> */}
      </HStack>
      {searchInput !== '' ? (
        <SimpleGrid columns={[1, 4, 4]} spacing={3} pt="5">
          {dataMindmapSearch.length > 0 &&
            dataMindmapSearch?.map((item, index) => (
              <Stack key={index}>
                <CardAvatar
                  data={item && item}
                  title={item?.title}
                  globalState={globalState}
                  onNavigate={() => navigate(`/mindmap/${item?.id}`)}
                  userArray={item?.usersProjectData}
                  timeCreated={item?.lastUpdated?.seconds}
                  modalTeam={() => modalTeam(item)}
                  owner={item?.owner}
                  onDelete={() => {
                    handleOpenAlertDelete();
                    setSelectedFlowchart(item);
                  }}
                  Icon={FcMindMap}
                />
              </Stack>
            ))}
        </SimpleGrid>
      ) : flowchart.length > 0 ? (
        <Stack>
          <SimpleGrid columns={[1, 4, 4]} spacing={3} pt="5">
            {flowchart?.map((item, index) => {
              return (
                <Stack key={index}>
                  <CardAvatar
                    data={item}
                    title={item.title}
                    globalState={globalState}
                    onNavigate={() => navigate(`/mindmap/${item?.id}`)}
                    userArray={item?.usersProjectData}
                    timeCreated={item?.createdAt?.seconds}
                    modalTeam={() => modalTeam(item)}
                    owner={item?.owner}
                    onDelete={() => {
                      handleOpenAlertDelete();
                      setSelectedFlowchart(item);
                    }}
                    Icon={FcMindMap}
                  />
                </Stack>
              );
            })}
          </SimpleGrid>
        </Stack>
      ) : (
        <AlertArrayNull titleData={t('Mind Map')} action={t('createMindmap')} />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {flowchart?.length >= itemsPerPage &&
            flowchart?.length > startIndex && (
            <Button
              onClick={handleLoadMore}
              colorScheme={'blue'}
              fontSize="sm"
            >
              {t('loadMore')}
            </Button>
          )}
        </Box>
      </Stack>

      <AlertDialogV2
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteMindmap', { title: selectedFlowchart?.title })}
        onAction={handleDeleteFlowchart}
      />

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t('team')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Text fontSize={'xs'}>
                    {t('createdBy')} : {data?.usersProjectData[0]?.name}
                  </Text>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x.name === data?.usersProjectData[0]?.name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x.name}
                            </Text>
                            <Text fontSize={'xs'}>{x.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              {t('creator')}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x.name}
                            </Text>
                            <Text fontSize={'xs'}>{x.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          {data?.owner?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action={'delete'}
                                size="sm"
                                variant={'solid'}
                                onClick={() => handleRemoveUser(x)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <DynamicButton
              action={'delete'}
              title={t('close')}
              variant={'solid'}
              onClick={() => setModal({ ...modal, user: false })}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={addMindmap.isOpen} onClose={addMindmap.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('add')} {t('Mind Map')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={t('title')}
              type="text"
              onChange={(e) => setInput(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              title={t('add')}
              action="create"
              variant={'solid'}
              onClick={() => handleClick()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default FlowchartPage;
