/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import ProgressCircle from '../Progress/ProgressCircle';
import { useTranslation } from 'react-i18next';
import uploadFileToDropboxV3 from './UploadFileToDropboxV3';

function DropboxUploader({
  isActive,
  onClose,
  parentPath,
  setShareLink,
  accessTokenDb,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef();
  const [filePreview, setFilePreview] = useState(null);
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [link, setLink] = useState('');

  const toast = useToast();

  const accessToken = globalState?.accessToken || accessTokenDb;

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Deoapp',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  useEffect(() => {
    // Cleanup effect to revoke object URLs
    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Tampilkan pratinjau file saat dipilih
      displayFilePreview(file);
    }
  };

  const handleSaveButtonClick = async () => {
    const file = fileInputRef.current?.files[0];
    setIsLoading(true);
    try {
      if (file) {
        const data = await uploadFileToDropboxV3(
          file,
          parentPath,
          accessToken,
          setProgress
        );
        setShareLink({ link: data.link, type: data.type });
        setLink({ link: data.link, type: data.type });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };


  const displayFilePreview = (file) => {
    const fileUrl = URL.createObjectURL(file);
    const fileType = file.type.split('/')[0];

    switch (fileType) {
      case 'image':
        setFilePreview(
          <img src={fileUrl} alt="File Preview" style={{ width: '300px' }} />
        );
        break;
      case 'video':
        setFilePreview(
          <video src={fileUrl} controls style={{ width: '300px' }}>
            Your browser does not support the video tag.
          </video>
        );
        break;
      case 'audio':
        setFilePreview(
          <audio src={fileUrl} controls style={{ width: '300px' }}>
            Your browser does not support the audio element.
          </audio>
        );
        break;
      case 'application':
        // Jika tipe datanya adalah PDF, gunakan elemen iframe
        if (file.type === 'application/pdf') {
          setFilePreview(
            <iframe
              src={fileUrl}
              title="File Preview"
              width="300"
              height="200"
            ></iframe>
          );
        } else {
          setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
        }
        break;
      default:
        setFilePreview(null); // Kosongkan pratinjau untuk tipe datanya yang tidak didukung
        break;
    }
  };

  const handleDoneButton = () => {
    // Mengatur kembali state menjadi nilai awal
    setFilePreview(null);
    setProgress(0);
    setShareLink('');
    setLink('');
    onClose();
    // Menghapus file yang dipilih dengan mengecek bahwa fileInputRef.current tidak null
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  return (
    <Modal isOpen={isActive} onClose={handleDoneButton}>
      <ModalOverlay />
      <ModalContent zIndex={10}>
        {' '}
        {/* Mengatur zIndex agar modal berada di paling depan */}
        <ModalHeader>
          {t('upload')} {t('file')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} p={4}>
            {/* Isi modal, gunakan komponen DropboxUploader yang sudah Anda buat */}
            {/* Tambahkan progress dan tombol-tombol yang diperlukan */}
            {filePreview}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{
                height: '300px',
                width: '300px',
                borderRadius: 'md',
                border: '2px dashed #cbd5e0',
                cursor: 'pointer',
                padding: '8',
                textAlign: 'center',
                display: filePreview !== null ? 'none' : 'flex',
              }}
            />
            {progress > 0 && progress < 100 ? (
              <Stack p={-10}>
                <ProgressCircle value={progress} />
              </Stack>
            ) : (
              <></>
            )}
            <Progress mt={2} value={progress} size="sm" colorScheme="blue" />
            <Box mt={2}>{progress}%</Box>

            {link && (
              <Stack mt={4} alignItems="center" justifyContent={'center'}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontSize={11} color="gray.500">
                    {link?.link ? link?.link : 'none'}
                  </Text>
                </Box>
                <HStack>
                  <Button
                    size={'sm'}
                    colorScheme="blue"
                    onClick={() => handleCopy(link?.link)}
                    variant={'outline'}
                  >
                    {t('copy')}
                  </Button>

                  <a
                    href={link?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size={'sm'} colorScheme="blue" variant={'outline'}>
                      {t('newTab')}
                    </Button>
                  </a>
                </HStack>
              </Stack>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          {progress === 0 && (
            <HStack>
              <Button
                isLoading={isLoading}
                onClick={handleSaveButtonClick}
                colorScheme="blue"
              >
                {t('save')}
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DropboxUploader;
