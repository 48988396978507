import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import LiveTime from '../../Components/Timer/LiveTime';
import ModalInviteUser from '../../Components/Modals/ModalInviteUser';
import { RiTimerLine } from 'react-icons/ri';
import CountdownTimerV2 from '../../Components/Timer/CountdownTimerV2';
import { MdOutlineFlashOn } from 'react-icons/md';
import OtomationModalMom from '../../Components/Otomation/OtomationModalMom';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';

function MomViewPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const param = useParams();
  const globalState = useUserStore();
  const [selectedProject, setSelectedProject] = useState('');

  const [data, setData] = useState(state || {});
  const [isLoading, setIsLoading] = useState(false);
  const [newCategory, setNewCategory] = useState();
  const [selectedPic, setSelectedPic] = useState('');
  const [pics, setPics] = useState(data.pic || []);
  const [dataMom, setDataMom] = useState({});
  const [usersDisplay, setUsersDisplay] = useState([]);
  const [users, setUsers] = useState([]);

  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [modalProjectUser, setModalProjectUser] = useState(false);
  const [details, setDetails] = useState([]);
  const toast = useToast();
  const alertDelete = useDisclosure();
  const [toggleStopwatch, setToggleStopwatch] = useState(false);

  const [otomationModal, setOtomationModal] = useState(false);

  const getDataMom = async () => {
    if (param?.id) {
      try {
        const result = await getSingleDocumentFirebase('files', param?.id);
        setDataMom(result);
        setUsersDisplay(result.usersDisplay || []);
        setUsers(result.users || []);
        if (result?.details?.length > 0) {
          setDetails(result.details);
        } else {
          setDetails([]);
        }
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    getDataMom();

    return () => {
      setDataMom({});
    };
  }, [param.id, isAddingTeam]);

  const navigate = useNavigate();

  const addPic = () => {
    if (selectedPic && !pics.find((pic) => pic.uid === selectedPic)) {
      const user = data.usersDisplay.find((user) => user.id === selectedPic);
      if (user) {
        setPics([
          ...pics,
          { name: user.name, uid: selectedPic, email: user.email },
        ]);
      }
    }
  };

  const removePic = (uid) => {
    const updatedPics = pics.filter((pic) => pic.uid !== uid);
    setPics(updatedPics);
  };

  const modalAddFeatures = useDisclosure();

  const handleNewCategory = (e) => {
    setNewCategory(e.target.value);
  };

  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '', timeFrom: '', timeTo: '' }]);
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data.ex_users) ? data.ex_users : [];

    const newFeatureList = [...existingFeatures, newCategory];
    setData({ ...data, ex_users: newFeatureList });
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...data.ex_users];
    updatedFeatures.splice(index, 1);
    setData({ ...data, ex_users: updatedFeatures });
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteKanban = async () => {
    setIsLoading(true);
    try {
      await deleteDocumentFirebase('files', param?.id);

      toast({
        status: 'success',
        title: 'Success',
        description: 'Minutes of meeting deleted',
        duration: 3000,
      });
    } catch (error) {
      toast({
        status: 'success',
        title: t('toast.failed'),
        description: 'Failed delete kanban',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      navigate('/mom');
    }
  };

  const handleRemoveUser = (index) => {
    if (index < 0 || index >= usersDisplay.length) {
      return; // Pastikan index valid
    }

    const updatedUsersDisplay = [...usersDisplay];
    updatedUsersDisplay.splice(index, 1);

    const userToRemove = usersDisplay[index];
    const updatedUsers = users.filter((uid) => uid !== userToRemove.id);
    setUsersDisplay(updatedUsersDisplay);
    setUsers(updatedUsers);

    // setData({
    //   ...data,
    //   usersDisplay: updatedUsersDisplay,
    //   users: updatedUsers,
    // });
  };

  const handleOpenModalProject = () => {
    setModalProjectUser(true);
  };

  const handleDetailChange = (index, key, value, timeFrom, timeTo) => {
    const updatedDetails = [...details];
    updatedDetails[index] = { key, value, timeFrom, timeTo };
    setDetails(updatedDetails);
  };

  const handleOpenOtomationModal = () => {
    setOtomationModal(true);
  };

  const handleCloseOtomationModal = () => {
    setOtomationModal(false);
  };

  const handleSubmit = async () => {
    const updateData = data;
    updateData.pic = pics || dataMom.pic;
    updateData.details = details || dataMom.pic;
    updateData.users = users;
    updateData.usersDisplay = usersDisplay;
    updateData.projectId = selectedProject || dataMom.projectId;

    const collectionName = 'files';
    const docName = param.id;

    try {
      await updateDocumentFirebase(collectionName, docName, updateData);
      navigate('/mom');

      toast({
        status: 'success',
        duration: 9000,
        title: 'Edit Success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={2}>
      <HStack  flexDirection={['column', 'row', 'row']}>
        <HStack>
          <BackButtons />
          <Text textTransform={'capitalize'} fontSize="xl" fontWeight={'bold'}>
            {state?.title}
          </Text>
        </HStack>
        <Spacer />
        <HStack alignItems={'flex-start'} justifyContent="flex-start">
          <Stack>
            <DynamicButton
              action={'delete'}
              variant="solid"
              onClick={handleOpenAlertDelete}
              size="sm"
            />
            <DynamicButton
              size="sm"
              action="custom"
              variant={'solid'}
              color={'orange'}
              icon={RiTimerLine}
              onClick={() => setToggleStopwatch((prev) => !prev)}
            />
          </Stack>
          {toggleStopwatch ? (
            <Stack
              bgColor={'blue.500'}
              shadow="md"
              py={1}
              px={2}
              borderRadius="md"
            >
              <CountdownTimerV2 seconds={60} />
            </Stack>
          ) : (
            <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
              <LiveTime color={'white'} />
            </Stack>
          )}
        </HStack>
      </HStack>

      <Stack spacing={3} shadow={'md'} p={[1, 1, 5]}>
        <Stack>
          <Text fontWeight={'bold'}>{t('projectId')}</Text>

          <Select
            defaultValue={state?.projectId}
            placeholder={t('project')}
            size="sm"
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            {globalState?.projects?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Select>

          <Stack>
            <Text fontWeight={'bold'}>{t('title')}</Text>
            <Input
              defaultValue={state?.title}
              size="sm"
              placeholder="ex: Gedung Parlemen"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </Stack>

          <SimpleGrid columns={[1, 1, 2]} gap={5}>
            <Stack>
              <Text fontWeight={'bold'}>{t('location')}</Text>
              <Input
                defaultValue={state?.location}
                size="sm"
                placeholder="ex: Gedung Parlemen"
                onChange={(e) => setData({ ...data, location: e.target.value })}
              />
            </Stack>

            <Stack>
              <Text fontWeight={'bold'}>{t('time')}</Text>
              <Input
                defaultValue={state?.time}
                size="sm"
                type="datetime-local"
                onChange={(e) => setData({ ...data, time: e.target.value })}
              />
            </Stack>
          </SimpleGrid>
        </Stack>

        <Stack>
          <Text fontWeight={'bold'}>{t('pic')}</Text>
          <HStack>
            <Select
              value={selectedPic}
              placeholder={t('user')}
              size={'sm'}
              onChange={(e) => setSelectedPic(e.target.value)}
            >
              {data?.usersDisplay?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Select>
            <DynamicButton
              size={'sm'}
              variant={'solid'}
              action={'create'}
              onClick={addPic}
            />
          </HStack>
        </Stack>
        {pics?.map((pic) => {
          return (
            <Box
              key={pic.uid}
              p={2}
              border={'1px'}
              borderRadius={'sm'}
              shadow={'base'}
              pos={'relative'}
              borderColor={'gray.300'}
            >
              <HStack textTransform={'capitalize'} spacing={3}>
                <Avatar
                  cursor={'pointer'}
                  size="sm"
                  src={pic.image || pic.name}
                  name={pic.name}
                  alt={pic}
                >
                  <AvatarBadge boxSize="1.2em" bg="green.500" />
                </Avatar>
                <Text fontWeight={500} textTransform={'capitalize'}>
                  {pic.name}
                </Text>
                <Icon
                  onClick={() => removePic(pic.uid)}
                  cursor="pointer"
                  pos={'absolute'}
                  color={'red.300'}
                  top={-1}
                  right={-1}
                  as={MdCancel}
                  boxSize={4}
                />
              </HStack>
            </Box>
          );
        })}

        <SimpleGrid columns={[1, 1, 2]} gap={5}>
          <Stack>
            <Text fontWeight="bold">{t('exTeam')}</Text>
            <Box
              shadow="base"
              // bg="white"
              padding={3}
              border={'0.5px'}
              borderColor={'gray.300'}
              borderRadius={'sm'}
            >
              <HStack
                spacing={3}
                p={2}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                w="100%"
              >
                <HStack>
                  <Stack
                    borderRadius={'sm'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <DynamicButton
                      action={'create'}
                      variant="solid"
                      size={'sm'}
                      onClick={modalAddFeatures.onOpen}
                    />
                  </Stack>
                  {data?.ex_users?.length > 0 ? (
                    <HStack w={'500px'} overflowX={'scroll'} p="2" gap={3}>
                      {data?.ex_users?.map((x, i) => (
                        <Stack
                          key={i}
                          p={2}
                          border={'1px'}
                          borderRadius={'sm'}
                          shadow={'base'}
                          pos={'relative'}
                          borderColor={'gray.300'}
                          alignItems="center"
                          minW={'100px'}
                        >
                          <Avatar
                            cursor={'pointer'}
                            size="md"
                            src={x}
                            name={x}
                            alt={x}
                          >
                            <AvatarBadge boxSize="1.2em" bg="green.500" />
                          </Avatar>
                          <HStack textTransform={'capitalize'}>
                            <Text
                              fontWeight={500}
                              noOfLines={1}
                              fontSize={'2xs'}
                              textTransform={'capitalize'}
                            >
                              {x}
                            </Text>
                            <Icon
                              onClick={() => handleRemoveFeature(i)}
                              cursor="pointer"
                              pos={'absolute'}
                              color={'red.300'}
                              top={-1}
                              right={-1}
                              as={MdCancel}
                              boxSize={4}
                            />
                          </HStack>
                        </Stack>
                      ))}
                    </HStack>
                  ) : (
                    <Text>{t('noExUser')}</Text>
                  )}
                </HStack>
              </HStack>
            </Box>
          </Stack>

          <Stack overflowX={'auto'}>
            <Text fontWeight="bold">{t('team')}</Text>
            <Box
              shadow="base"
              // bg="white"
              padding={3}
              border={'0.5px'}
              borderColor={'gray.300'}
              borderRadius={'sm'}
            >
              <HStack
                spacing={3}
                p={2}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
              >
                <HStack>
                  <Stack
                    borderRadius={'sm'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <DynamicButton
                      action={'create'}
                      variant="solid"
                      size={'sm'}
                      onClick={handleOpenModalProject}
                    />
                  </Stack>
                  {usersDisplay?.length > 0 ? (
                    <HStack w={'500px'} overflowX={'scroll'} py="2" gap={3}>
                      {usersDisplay?.map((x, i) => (
                        <Stack
                          key={i}
                          p={2}
                          border={'1px'}
                          borderRadius={'sm'}
                          shadow={'base'}
                          pos={'relative'}
                          borderColor={'gray.300'}
                          alignItems="center"
                          minW={'100px'}
                        >
                          <Avatar
                            cursor={'pointer'}
                            size="md"
                            src={x.image || x.name}
                            name={x.name}
                            alt={x}
                          >
                            <AvatarBadge boxSize="1.2em" bg="green.500" />
                          </Avatar>
                          <HStack
                            textTransform={'capitalize'}
                            overflow="hidden"
                          >
                            <Text
                              noOfLines={1}
                              fontSize={'2xs'}
                              fontWeight={500}
                              textTransform={'capitalize'}
                            >
                              {x.name}
                            </Text>
                            <Icon
                              onClick={() => handleRemoveUser(i)}
                              cursor="pointer"
                              pos={'absolute'}
                              color={'red.300'}
                              top={-1}
                              right={-1}
                              as={MdCancel}
                              boxSize={4}
                            />
                          </HStack>
                        </Stack>
                      ))}
                    </HStack>
                  ) : (
                    <Text>{t('noTeam')}</Text>
                  )}
                </HStack>
              </HStack>
            </Box>
          </Stack>
        </SimpleGrid>

        <Stack>
          <Text fontWeight={'bold'}>{t('note')}</Text>
          <Textarea
            type="text"
            defaultValue={data?.notes}
            onChange={(e) => setData({ ...data, notes: e.target.value })}
            minH="150px"
          />
        </Stack>

        <Grid overflowX={'auto'} gap={3} templateColumns={{ base: '1fr', md: '1fr 4fr' }}>
          <Stack w={'100%'}>
            <Text fontWeight={'bold'}>{t('pom')} :</Text>
          </Stack>
          <Stack>
            {details?.length > 0 &&
              details?.map((detail, index) => (
                <HStack key={index} align={'center'} justify={'center'}>
                  <FormControl id={`detail-key-${index}`}>
                    <FormLabel>{t('point')}:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Title detail, ex: location"
                      value={detail?.key}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          e.target.value,
                          detail?.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>{t('from')}:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeFrom}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          e.target.value,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>{t('to')}:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeTo}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          detail?.timeFrom,
                          e.target.value
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Status:</FormLabel>
                    <Select
                      size={'sm'}
                      placeholder="Status"
                      value={detail?.value}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          e.target.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    >
                      <option value={'backlog'}>Backlog</option>
                      <option value={'todos'}>To Dos</option>
                      <option value={'progress'}>Progress</option>
                      <option value={'review'}>Review</option>
                      <option value={'done'}>Done</option>
                    </Select>
                  </FormControl>
                  <Box pt="7">
                    <DynamicButton
                      variant={'solid'}
                      action="delete"
                      onClick={() => handleRemoveDetail(index)}
                      size="sm"
                    />
                  </Box>
                </HStack>
              ))}
          </Stack>
        </Grid>
        <Stack>
          <DynamicButton
            size={'sm'}
            variant={'solid'}
            title={t('point')}
            action="create"
            onClick={handleAddDetail}
          />
        </Stack>

        <HStack alignItems={'flex-end'} justifyContent="flex-end" pt={2}>
          <DynamicButton
            action={'custom'}
            color={'yellow'}
            icon={MdOutlineFlashOn}
            title={t('Automation')}
            variant={'solid'}
            onClick={() => handleOpenOtomationModal()}
          />
          <DynamicButton
            action={'create'}
            title={t('save')}
            variant={'solid'}
            onClick={() => handleSubmit()}
          />
        </HStack>
      </Stack>

      <Modal
        isOpen={modalAddFeatures.isOpen}
        onClose={modalAddFeatures.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('exTeam')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={t('newCategory')}
              onChange={handleNewCategory}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" onClick={handleAddFeature}>
              {t('add')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={isLoading}
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteMom', { title: state?.title })}
        onAction={handleDeleteKanban}
      />

      <ModalInviteUser
        setSearchResult={setSearchResult}
        setSelectedUserProjectIds={setSelectedUserProjectIds}
        setIsAddingTeam={setIsAddingTeam}
        searchResult={searchResult}
        isAddingTeam={isAddingTeam}
        selectedUserProjectIds={selectedUserProjectIds}
        dataProps={dataMom}
        setModalProjectUser={setModalProjectUser}
        modalProjectUser={modalProjectUser}
        collection={'files'}
      />

      <Modal
        size="2xl"
        transition={'all 0.3s ease-in-out'}
        isOpen={otomationModal}
        onClose={handleCloseOtomationModal}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('Automation')}{' '}
            {t('Minutes of Meeting')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalCloseButton />
          <ModalBody>
            <OtomationModalMom data={dataMom} onClose={handleCloseOtomationModal}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default MomViewPage;
