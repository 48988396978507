import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';

function LineEditPage() {
  const { t } = useTranslation();
  const { state } = useLocation();

  const globalState = useUserStore();

  const [data, setData] = useState(state || {});
  const [isLoading, setIsLoading] = useState(false);
  const [newCategory, setNewCategory] = useState();
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedPic, setSelectedPic] = useState('');
  const [pics, setPics] = useState(data.pic || []);
  const [score] = useState(data.default_score || 0);

  const toast = useToast();
  const alertDelete = useDisclosure();

  const navigate = useNavigate();

  const addPic = () => {
    if (selectedPic && !pics.find((pic) => pic.uid === selectedPic)) {
      const user = data.usersDisplay.find((user) => user.id === selectedPic);
      if (user) {
        setPics([
          ...pics,
          { name: user.name, uid: selectedPic, email: user.email },
        ]);
      }
    }
  };

  const removePic = (uid) => {
    const updatedPics = pics.filter((pic) => pic.uid !== uid);
    setPics(updatedPics);
  };

  const param = useParams();

  const modalAddFeatures = useDisclosure();

  const handleNewCategory = (e) => {
    setNewCategory(e.target.value);
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data.category) ? data.category : [];

    const newFeatureList = [...existingFeatures, newCategory];
    setData({ ...data, category: newFeatureList });
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...data.category];
    updatedFeatures.splice(index, 1);
    setData({ ...data, category: updatedFeatures });
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteKanban = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    setIsLoading(true);

    try {
      await deleteDocumentFirebase('files', param?.id);

      toast({
        status: 'success',
        title: 'Success',
        description: 'line deleted',
        duration: 3000,
      });
    } catch (error) {
      toast({
        status: 'success',
        title: t('toast.failed'),
        description: 'Failed delete line',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      navigate('/line');
    }
  };

  const handleRemoveUser = (index) => {
    if (index < 0 || index >= data.usersDisplay.length) {
      return; // Pastikan index valid
    }

    const updatedUsersDisplay = [...data.usersDisplay];
    updatedUsersDisplay.splice(index, 1);

    const userToRemove = data.usersDisplay[index];
    const updatedUsers = data.users.filter((uid) => uid !== userToRemove.id);

    setData({
      ...data,
      usersDisplay: updatedUsersDisplay,
      users: updatedUsers,
    });
  };

  const handleSubmit = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    if (globalState.roleProjects === 'user') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    const updateData = data;
    updateData.pic = pics || state.pic;
    updateData.projectId = selectedProject || state.projectId;
    updateData.default_score = parseInt(score || 0);

    const collectionName = 'files';
    const docName = param.id;

    try {
      await updateDocumentFirebase(collectionName, docName, updateData);
      navigate(-1);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Edit Success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={2}>
      <BackButtons />
      <HStack>
        <Text textTransform={'capitalize'} fontSize="xl" fontWeight={'bold'}>
          {t('edit')} {state?.title}
        </Text>
        <Spacer />
        <DynamicButton
          action={'delete'}
          variant="solid"
          title={t('deletePermanent')}
          onClick={handleOpenAlertDelete}
        />
      </HStack>

      <Stack spacing={2} shadow={'md'} p={[1, 1, 5]}>
        <Text>{t('title')}</Text>
        <Input
          defaultValue={state?.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />

        <Text>{t('taskDuration')}</Text>
        <HStack>
          <Input
            defaultValue={state?.duration}
            onChange={(e) => setData({ ...data, duration: e.target.value })}
          />
          <Text fontWeight={500}>{t('day')}</Text>
        </HStack>

        <Text>{t('type')}</Text>
        <Input defaultValue={state?.type} isDisabled />

        <Text>{t('projectId')}</Text>

        <Select
          defaultValue={state.projectId}
          placeholder={t('project')}
          onChange={(e) => setSelectedProject(e.target.value)}
        >
          {globalState.projects?.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </Select>

        <Text>{t('pic')}</Text>
        <HStack>
          <Select
            value={selectedPic}
            placeholder={t('user')}
            onChange={(e) => setSelectedPic(e.target.value)}
          >
            {data?.usersDisplay?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Select>
          <DynamicButton variant={'solid'} action={'create'} onClick={addPic} />
        </HStack>
        {pics?.map((pic) => {
          return (
            <Box key={pic.uid} shadow="md" padding={3} mb={3}>
              <Text fontWeight="bold">{pic.name}</Text>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => removePic(pic.uid)}
              >
                {t('clear')} {t('pic')}
              </Button>
            </Box>
          );
        })}

        <Box shadow="md" padding={3} mb={3}>
          <Text fontWeight="bold">{t('category')}</Text>
          <HStack
            spacing={3}
            my={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {data?.category?.length > 0 ? (
              <SimpleGrid columns={[2, 4, 6]} gap={3}>
                {data?.category?.map((x, i) => (
                  <Box
                    key={i}
                    py={1}
                    px={2}
                    border={'1px'}
                    borderRadius={'sm'}
                    shadow={'base'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <HStack textTransform={'capitalize'}>
                      <Text fontWeight={500} textTransform={'capitalize'}>
                        {x}
                      </Text>
                      <Icon
                        onClick={() => handleRemoveFeature(i)}
                        cursor="pointer"
                        pos={'absolute'}
                        color={'red.300'}
                        top={-1}
                        right={-1}
                        as={MdCancel}
                        boxSize={4}
                      />
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text>{t('noCategory')}</Text>
            )}
          </HStack>

          <Box align={'center'} py={3}>
            <Button
              size={'sm'}
              colorScheme="green"
              onClick={modalAddFeatures.onOpen}
            >
              {t('addCategory')}
            </Button>
          </Box>
        </Box>

        <Box shadow="md" padding={3} mb={3}>
          <Text fontWeight="bold">{t('team')}</Text>
          <HStack
            spacing={3}
            my={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {data?.usersDisplay?.length > 0 ? (
              <SimpleGrid columns={[2, 4, 6]} gap={3}>
                {data?.usersDisplay?.map((x, i) => (
                  <Box
                    key={i}
                    py={1}
                    px={2}
                    border={'1px'}
                    borderRadius={'sm'}
                    shadow={'base'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <HStack textTransform={'capitalize'} overflow="hidden">
                      <Text fontWeight={500} textTransform={'capitalize'}>
                        {x.name}
                      </Text>
                      <Icon
                        onClick={() => handleRemoveUser(i)}
                        cursor="pointer"
                        pos={'absolute'}
                        color={'red.300'}
                        top={-1}
                        right={-1}
                        as={MdCancel}
                        boxSize={4}
                      />
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text>{t('noTeam')}</Text>
            )}
          </HStack>
        </Box>

        {/* <Text>Default Score</Text>
  
          <Flex>
            <NumberInput maxW='100px' mr='2rem' min={0} max={10} value={score} onChange={handleChange}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Slider
              flex='1'
              focusThumbOnChange={false}
              value={score}
              min={0}
              max={10}
              onChange={handleChange}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb fontSize='sm' boxSize='32px' children={score} />
            </Slider>
          </Flex> */}

        <Stack alignItems={'flex-end'} justifyContent="flex-end">
          <DynamicButton
            action={'create'}
            title={t('save')}
            variant={'solid'}
            onClick={() => handleSubmit()}
          />
        </Stack>
      </Stack>

      <Modal
        isOpen={modalAddFeatures.isOpen}
        onClose={modalAddFeatures.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('addCategory')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={t('addCategory')}
              onChange={handleNewCategory}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" onClick={handleAddFeature}>
              {t('add')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={isLoading}
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteFile', { title: state?.title })}
        onAction={handleDeleteKanban}
      />
    </Stack>
  );
}

export default LineEditPage;
