import React, { useState, useEffect, useRef } from 'react';
import { HStack, Select, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { Chart } from 'chart.js/auto';
import { useParams } from 'react-router-dom';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import { useTranslation } from 'react-i18next';

function ChartsBarKanban() {
  const {t} = useTranslation()
  const chartContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const [time, setTime] = useState('30');
  const [column, setColumn] = useState('done');
  const globalState = useUserStore();
  const params = useParams();
  const toast = useToast()

  const fetchData = async () => {
    const dataApi = {
      dateRange: time,
      filesId: params.id,
      companyId: globalState.currentCompany,
      column: column,
    };
    
    try {
      const filesDoc = await getSingleDocumentFirebase('files', dataApi.filesId);
    
      const dateFilter = {
        '1': 1,
        '7': 7,
        '30': 30,
      }[dataApi.dateRange || '30'];
    
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
    
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() - dateFilter);
    
      const collectionRef = collection(db, 'kanban');
    
      const promises = filesDoc.usersDisplay.map((asignee) => {
        return getCountFromServer(
          query(
            collectionRef,
            where('companyId', '==', dataApi.companyId),
            where('asignee', 'array-contains', asignee.id),
            where('filesId', '==', dataApi.filesId),
            where('column', '==', column),
            where('createdAt', '>=', endDate),
            where('createdAt', '<', currentDate),
          )
        ).then((snapshot) => ({
          [column || 'Total']: snapshot.data().count,
          name: asignee.name || '',
          email: asignee.email || '',
          image: asignee.image || '',
          uid: asignee.id || '',
        }));
      });
    
      const result = await Promise.all(promises);
    
      const data = result.reverse();
    
      const names = data.map((entry) => entry.name);
      const columnValues = data.map((entry) => entry[column]);
    
      const ctx = chartContainer.current.getContext('2d');
    
      if (chart) {
        chart.destroy();
      }
    
      const backgroundColors = getBackgroundColors(column, data.length);
    
      const newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: names,
          datasets: [
            {
              label: column,
              data: columnValues,
              fill: false,
              backgroundColor: backgroundColors,
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 2,
              pointBackgroundColor: 'rgba(75,192,192,1)',
              pointRadius: 4,
              pointHoverRadius: 6,
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    
      setChart(newChart);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      }
  };

  const getBackgroundColors = (column, count) => {
    const backgroundColors = {
      todos: 'rgba(255, 99, 132, 0.6)',
      backlog: 'rgba(75, 192, 192, 0.6)',
      progress: 'rgba(255, 206, 86, 0.6)',
      review: 'rgba(54, 162, 235, 0.6)',
      done: 'rgba(153, 102, 255, 0.6)',
    };

    return Array(count).fill(backgroundColors[column]);
  };

  useEffect(() => {
    if (params.id) {
      fetchData();
    }

    return () => {
      setChart(null);
    };
  }, [time, column]);

  return (
    <Stack className="line-chart" spacing={2}>
      <HStack>
        <Text fontSize={'md'} fontWeight={500} textTransform="capitalize">
          Line order {time} day
        </Text>
        <Spacer />
        <Stack>
          <Select
            size={'sm'}
            borderRadius="md"
            placeholder="Select option"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          >
            <option value="1">1 {t('day')}</option>
            <option value="7">7 {t('day')}</option>
            <option value="30">30 {t('day')}</option>
          </Select>
          <Select
            size={'sm'}
            borderRadius="md"
            placeholder="Select Payment Method"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          >
            <option value="todos">To Dos</option>
            <option value="backlog">Backlog</option>
            <option value="progress">Progress</option>
            <option value="review">Review</option>
            <option value="done">Done</option>
          </Select>
        </Stack>
      </HStack>
      <canvas ref={chartContainer} width="auto" height="auto"></canvas>
    </Stack>
  );
}

export default ChartsBarKanban;
