/* eslint-disable react/no-children-prop */
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  MdEmail,
  MdAccountCircle,
  MdOutlinePhoneIphone,
  MdLock,
  MdVisibilityOff,
  MdVisibility,
  MdStoreMallDirectory,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import {
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { auth, db } from '../../Config/firebase';
import _axios from '../../Api/AxiosBarrier';
import { useTranslation } from 'react-i18next';


function SignUpPage() {
  const {t} = useTranslation()
  const [name, setName] = useState('');
  const [nohp, setNohp] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);




  const navigate = useNavigate();
  const toast = useToast();


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const userAddCompany = async (uid, companyId) => {
    const dataCreate = {
      uid,
      companyId
    }

    try {
      await _axios.post('company-ownerAdd', dataCreate)
    } catch (error) {
      toast({
        title: 'Something Wrong',
        description: 'Error Create Owner',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }


  }

  const handleSignup = () => {
    const displayName = name;

    if (
      (email === '' && password === '' && nohp === '' && name === '') ||
      password !== confirmPassword
    )
      return toast({
        title: 'Something Wrong',
        description: 'check your email, password, data',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-end',
      });

    if (email !== '' && password !== '' && nohp !== '' && name !== '') {
      try {
        setLoading(true);

        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            await updateProfile(auth.currentUser, {
              displayName,
            });
            // sendEmailVerification(auth.currentUser);

            // Signed in
            const user = userCredential.user;
            if (user) {
              await setDoc(doc(db, 'users', user.uid), {
                name: name,
                keyword_name: name.toLowerCase().split(' ').join(''),
                email: user.email,
                createdAt: new Date(),
                phone: nohp,
                phoneNumber: nohp
              });

              //create company with user
              const newUser = { companyName: companyName, module: 'management' };
      

              const res = await _axios.post('company-create', newUser)
              if (res) {
                userAddCompany(user?.uid, res?.companyId)
              }


              setLoading(false);
              navigate('/', { replace: true });

              toast({
                title: t('success'),
                description: `Success Create account ${user.displayName}`,
                status: 'success',
                duration: 10000,
                isClosable: true,
                position: 'top-right',
              });
            }
          })
          .catch((error) => {
            toast({
              title: 'Something Wrong',
              description: `It looks like you don't have account in your browser, please signup and reload this page / ${error.message}`,
              status: 'error',
              duration: 10000,
              isClosable: true,
              position: 'top-right',
            });
            setLoading(false);
          });
      } catch (error) {
        toast({
          title: 'Something Wrong',
          description: error,
          status: 'error',
          duration: 10000,
          isClosable: true,
          position: 'top-end',
        });
        setLoading(false);
      }
    } else {
      toast({
        title: 'Something Wrong',
        description: 'check your data',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-end',
      });
    }
  };



  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <>
   
      <Stack
        pt={18}
        spacing={10}
        minH={height}
        bg="url(https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/product/hk1BcSSZMoWI9GBLJBGA/vecteezy_abstract-white-and-gray-light-gradient-lines-background_6469230.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={'center'}
        justifyContent="center"
      >
        <Stack position={'absolute'} top={5} right={5}>
          {/* <DynamicButton
            action="custom"
            title="Go to Recruitment"
            color="blue"
            variant={'solid'}
            onClick={() => navigate('/signup/recruitment')}
          /> */}
        </Stack>

        {/* <Text>{activeStep}</Text> */}
        <SimpleGrid columns={[1, 1, 1]} gap="5">
          <Stack alignItems={'center'} justifySelf="start">
            <Stack
              w={['90%', null, width / 3]}
              spacing={3}
              p={10}
              bgColor="#FFFFFF"
              shadow={'md'}
              borderRadius={'xl'}
              // _hover={{ transform: "scale(1.1)", shadow: "xl" }}
              transition={'0.2s ease-in-out'}
              alignItems={'center'}
              justifyContent="center"
              boxShadow="md" 
              border="1px solid #e0e0e0"
            >
              <Box p={2}>
                <Image
                  w="200px"
                  src={'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png'}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={
                      <MdStoreMallDirectory
                        name="email"
                        size={24}
                        color="black"
                      />
                    }
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Company Name"
                    fontSize={'sm'}
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdAccountCircle size={24} color="black" />}
                  />
                  <Input
                    placeholder="Full name"
                    fontSize={'sm'}
                    type="text"
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdOutlinePhoneIphone size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Phone number"
                    fontSize={'sm'}
                    type="number"
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setNohp(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Email"
                    fontSize={'sm'}
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '90%',
                    md: '275',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdLock size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Password"
                    fontSize={'sm'}
                    type={showPassword ? 'text' : 'password'}
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '90%',
                    md: '275',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    bgColor="#E2E8F0"
                    children={<MdLock size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Confirm password"
                    fontSize={'sm'}
                    id="password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    shadow={'md'}
                    bgColor={'white'}
                    color={'blackAlpha.700'}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSignup();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showConfirmPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

      

              <Stack pt={2}>
                {loading ? (
                  <Spinner size={'sm'} />
                ) : (
                  <Button
                    alignItems={'center'}
                    justifyContent="center"
                    size={'sm'}
                    shadow={'md'}
                    variant="outline"
                    onClick={() => handleSignup()}
                    cursor={'pointer'}
                    bgColor={'gray.200'}
                  >
                    <Text color={'black'} fontSize="xs" fontWeight="bold">
                      CREATE ACCOUNT
                    </Text>
                  </Button>
                )}
              </Stack>

              <Spacer />

              <HStack space={1}>
                <Text color={'black'} fontSize="sm">
                  Back to
                </Text>
                <Text
                  color={'black'}
                  fontWeight="bold"
                  cursor={'pointer'}
                  fontSize="sm"
                  onClick={() => navigate('/login')}
                >
                  Login
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Stack>
    </>
  );
}

export default SignUpPage;
