import { Stack, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineAlert } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

function NoAccessPage() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  return (
    <Stack minH="50vh" alignItems={'center'} justifyContent="center">
      <AiOutlineAlert color="gray" size={100} />
      <Text textAlign="center" color={'gray.500'} fontSize="2xl" fontWeight={'bold'}>{t('warning')}!</Text>
      <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'}>{t('noAccess')}</Text>
      <Button onClick={() => navigate(-2)}>{t('back')}</Button>
    </Stack>
  )
}

export default NoAccessPage