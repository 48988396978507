import { useCallback, useEffect, useState } from 'react';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';
import { shallow } from 'zustand/shallow';

// const initialNodes = [
//      { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
//      {
//           id: "2",
//           type: "triangle",
//           data: { label: '2' },
//           position: { x: 25, y: 100 }
//      }, {
//           id: "3",
//           type: "parallelogram",
//           data: { label: '3' },
//           position: { x: 0, y: 300 }
//      }
// ];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }, { id: 'e2-3', source: '2', target: '3' }];
const defaultOptions = {
  maxHistorySize: 100,
  enableShortcuts: true,
};
const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
});
const UseUndoRedo = ({
  maxHistorySize = defaultOptions.maxHistorySize,
  enableShortcuts = defaultOptions.enableShortcuts,
} = defaultOptions) => {
  const [past, setPast] = useState([]);
  const [future, setFuture] = useState([]);
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
  } = useFlowchartStore(selector, shallow);

  const takeSnapshot = useCallback(() => {
    setPast((past) => [
      ...past.slice(past.length - maxHistorySize + 1, past.length),
      { nodes: nodes, edges: edges },
    ]);
    setFuture([]);
  }, [nodes, edges, maxHistorySize]);

  const undo = useCallback(() => {
    const pastState = past[past.length - 1];

    if (pastState) {
      setPast((past) => past.slice(0, past.length - 1));
      setFuture((future) => [...future, { nodes: nodes, edges: edges }]);
      setNodes(pastState.nodes);
      setEdges(pastState.edges);
    }
  }, [setNodes, setEdges, nodes, edges, past]);

  const redo = useCallback(() => {
    const futureState = future[future.length - 1];

    if (futureState) {
      setFuture((future) => future.slice(0, future.length - 1));
      setPast((past) => [...past, { nodes: nodes, edges: edges }]);
      setNodes(futureState.nodes);
      setEdges(futureState.edges);
    }
  }, [setNodes, setEdges, nodes, edges, future]);

  useEffect(() => {
    if (!enableShortcuts) {
      return;
    }

    const keyDownHandler = (event) => {
      if (event.key === 'y' && (event.ctrlKey || event.metaKey)) {
        redo();
      } else if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        undo();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [undo, redo, enableShortcuts]);

  return {
    undo,
    redo,
    takeSnapshot,
    canUndo: !past.length,
    canRedo: !future.length,
  };
};

export default UseUndoRedo;
