/* eslint-disable no-undef */
import axios from 'axios';


const apiHost = process.env.REACT_APP_SENDGRID_HOST

const sendEmailSendgrid = async (data) => {
  const url = apiHost
  const configtest = {headers : {
    'Content-Type': 'application/json',
  },
  }
  return axios.post(url, data, configtest,)
    .then((x,) => x.data,)
    .catch((err,) => (err),)
};


export {
  sendEmailSendgrid, // Tambahkan fungsi patchAccounting ke ekspor
};
