/* eslint-disable no-unsafe-optional-chaining */
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  useDisclosure,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Text,
  SimpleGrid,
  Stack,
  Flex,
  useToast,
  Divider,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDocumentFirebase,
  arrayRemoveFirebase,
  arrayUnionFirebase,
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../../Api/firebaseApi';
import BackButtonComponent from '../../../Components/Buttons/BackButtons';
import ImageComponent from '../../../Components/Image/ImageComponent';
import { uploadImage } from '../../../Api/firebaseFunction';
import useUserStore from '../../../Hooks/Zustand/Store';
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import { clientTypessense } from '../../../Api/Typesense';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

function ProjectsViewPage() {
  const { t } = useTranslation();
  const globalState = useUserStore();
  const [data, setData] = useState();
  const [users, setUsers] = useState([]);
  const [input, setInput] = useState({});
  const [modules, setModules] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);

  const [projectActive, setProjectActive] = useState('');
  const [companyActive, setCompanyActive] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [modalProjectUser, setModalProjectUser] = useState(false);
  const [modalProjectUserTeam, setModalProjectUserTeam] = useState(false);

  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const modalAlertDelete = useDisclosure();

  // const dataCheckBox = [
  //   {
  //     value: 'rms',
  //     name: 'RMS',
  //     description: 'End to end restaurant management system',
  //   },
  //   {
  //     value: 'lms',
  //     name: 'LMS',
  //     description: 'End to end Learning Management System',
  //   },
  //   {
  //     value: 'eCommerce',
  //     name: 'eCommerce',
  //     description: 'End to end restaurant management system',
  //   },
  //   {
  //     value: 'listing',
  //     name: 'Listing',
  //     description: 'End to end restaurant management system',
  //   },
  //   {
  //     value: 'omniChannel',
  //     name: 'Omni Channel',
  //     description: 'End to end restaurant management system',
  //   },
  //   {
  //     value: 'event',
  //     name: 'Events',
  //     description: 'Event management inside LMS',
  //   },
  //   {
  //     value: 'crm',
  //     name: 'CRM',
  //     description:
  //       'Customer Relationship Management from leads, web chat, marketplace, social media monitoring',
  //   },
  // ];

  const getData = async () => {
    try {
      const dataRes = await getSingleDocumentFirebase('projects', params.id);
      setData(dataRes);

      if (dataRes) {
        const userSnapshot = getDocs(
          collection(db, `projects/${dataRes.id}/users`)
        );

        userSnapshot
          .then((snapshot) => {
            const usersData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setUsers(usersData ? usersData : []);
            setData({ ...dataRes, usersProjectData: usersData });
          })
          .catch((err) => {
            toast({
              title: 'Deoapp.com',
              duration: 3000,
              description: err.message,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          });

        setModules(dataRes?.modules ? dataRes.modules : []);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleAddTeamProject = async () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    try {
      setIsAddingTeam(true);

      const promises = selectedUserProjectIds.map(async (x) => {
        const collectionName = `projects/${projectActive.id}/users`;
        const docName = x.id;
        const data = x;

        try {
          await arrayUnionFirebase('projects', `${projectActive.id}`, 'users', [
            `${data?.id}`,
          ]);

          await setDocumentFirebase(collectionName, docName, data);
          // Pesan toast yang berhasil
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      });

      await Promise.all(promises);

      const mapIdUser = selectedUserProjectIds.map((z) => z.id);
      const collectionNameProject = 'projects';
      const docNameProject = `${projectActive.id}`;
      const field = 'users';
      const values = mapIdUser;

      try {
        await arrayUnionFirebase(
          collectionNameProject,
          docNameProject,
          field,
          values
        );
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        setIsAddingTeam(false);
      }

      toast({
        status: 'success',
        title: 'Deoapp Business',
        description: 'Success adding team to the project',
        duration: 1000,
      });

      setModalProjectUser(false);
      setSelectedUserProjectIds([]);
      setProjectActive('');
      setSearchResult([]);
      getData();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsAddingTeam(false);
      // Tambahkan logika atau pesan error yang sesuai di sini
    }
  };

  const saveData = async () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    try {
      if (input.image === '') {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: 'Please fill the image',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        return;
      }

      if (modules) input.modules = modules;

      setIsLoading(true);

      if (params.id === 'new') {
        await addDocumentFirebase(
          'projects',
          input,
          globalState.currentCompany
        ).then(async (x) => {
          await setDocumentFirebase(`projects/${x}/users`, globalState?.uid, {
            name: globalState?.name,
            email: globalState?.email,
            createdAt: new Date(),
            id: globalState?.uid,
          });
        });
        toast({
          title: 'Berhasil',
          description: 'Success edit project',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } else {
        await setDocumentFirebase('projects', params.id, input, data.companyId);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      }); // Tangani error sesuai kebutuhan, seperti menampilkan pesan error kepada pengguna
    } finally {
      // setIsLoading(false);
      navigate(-1);
      //  window.location.reload()
    }
  };

  const submitImage = async (file) => {
    const res = (await uploadImage(file[0])).data;

    if (res.status) {
      setInput({ ...input, image: res.data });
      setData({ ...data, image: res.data });
      toast({
        title: 'Upload Image',
        description: 'Image saved',
        status: 'success',
        duration: 2000,
      });
    }
  };

  const handleDeleteImage = () => {
    setInput({ ...input, image: '' });
    setData({ ...data, image: '' });
  };

  const deleteProject = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    const collectionName = 'projects';
    const docName = params.id;

    try {
      await deleteDocumentFirebase(collectionName, docName);

      toast({
        title: 'Success!',
        description: 'Success delete project',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      toast({
        title: 'error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }

    modalAlertDelete.onClose();
  };

  const handleOpenAlert = () => {
    modalAlertDelete.onOpen();
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  const handleOpenModaProjectTeam = () => {
    setModalProjectUserTeam(true);
    setCompanyActive(data);
  };

  const handleOpenModalProject = () => {
    setModalProjectUser(true);
    setProjectActive(data);
  };

  useEffect(() => {
    getData();
    return () => {
      setData();
    };
  }, []);

  const handleRemoveUser = async (x) => {
    try {
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }

      await deleteDocumentFirebase(`projects/${data.id}/users`, x.id);

      const dataRef = doc(db, 'projects', data?.id);
      await updateDoc(dataRef, { managers: arrayRemove(x.id) });
      await updateDoc(dataRef, { users: arrayRemove(x.id) });
      setModalProjectUserTeam(false);
      toast({
        title: 'Deleted',
        description: 'User Deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      getData();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleRemoveManager = async (id) => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    const collectionName = 'projects';
    const docName = params.id;
    const field = 'managers';
    const values = [id];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);
      getData();
      toast({
        title: 'Success!',
        description: 'Success delete role manager user',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack spacing={3}>
        <BackButtonComponent />
        <Box>
          <Heading size={'lg'}>{t('project')}</Heading>
          <Text fontSize="3xs">ID: {params.id}</Text>
        </Box>
        <Spacer />
        <DynamicButton
          variant="solid"
          action={'delete'}
          onClick={() => handleOpenAlert()}
          title={t('deletePermanent')}
        />
      </HStack>

      <Flex
        w={'100%'}
        gap={5}
        direction={params.id === 'new' ? 'column' : ['column', 'row', 'row']}
      >
        <Box
          w={params.id === 'new' ? '100%' : ['100%', '50%', '50%']}
          p={5}
          borderRadius="md"
          shadow={'base'}
        >
          <Stack spacing={3} align={'center'}>
            <ImageComponent
              image={data?.image}
              name={data?.name}
              width="200px"
            />
            {data?.image ? (
              <Button
                size={'xs'}
                colorScheme="red"
                // onClick={() => deleteImage()}
                onClick={handleDeleteImage}
              >
                {t('clearImage')}
              </Button>
            ) : (
              <Box>
                <Input
                  type="file"
                  onChange={(e) => submitImage(e.target.files)}
                />
              </Box>
            )}
          </Stack>

          <>
            <FormControl mt="2">
              <FormLabel>{t('nameProject')}</FormLabel>
              <Input
                type="text"
                placeholder="Project name"
                defaultValue={data?.name}
                onChange={(e) => setInput({ ...input, name: e.target.value })}
              />
            </FormControl>

            <FormControl mt="2">
              <FormLabel>{t('descProject')}</FormLabel>
              <Input
                type="text"
                defaultValue={data?.description}
                placeholder="Description"
                onChange={(e) =>
                  setInput({ ...input, description: e.target.value })
                }
              />
            </FormControl>

            <FormControl mt="2">
              <FormLabel>{t('emailProject')}</FormLabel>
              <Input
                isDisabled={
                  params.id !== 'new' && globalState.roleCompany !== 'owner'
                    ? true
                    : false
                }
                type="text"
                defaultValue={data?.email}
                placeholder="Email"
                onChange={(e) => setInput({ ...input, email: e.target.value })}
              />
            </FormControl>

            <FormControl mt="2">
              <FormLabel>{t('phoneProject')}</FormLabel>
              <Input
                isDisabled={
                  params.id !== 'new' && globalState.roleCompany !== 'owner'
                    ? true
                    : false
                }
                type="number"
                defaultValue={data?.phone}
                placeholder="Phone Number"
                onChange={(e) => setInput({ ...input, phone: e.target.value })}
              />
            </FormControl>

            {/* <FormControl mt="2" borderRadius="md" shadow="base" p="5">
              <FormLabel>Project Modules</FormLabel>
              <SimpleGrid columns={[1, 2, 3]} gap={3}>
                {modules ? (
                  dataCheckBox?.map((x, i) => (
                    <Checkbox
                      key={i}
                      onChange={(e) => {
                        if (e.target.checked)
                          setModules([...modules, x.value]);
                        else
                          setModules([
                            ...modules?.filter((z) => z !== x.value),
                          ]);
                      }}
                      defaultChecked={
                        modules?.find((z) => z === x.value) ? true : false
                      }
                    >
                      <HStack>
                        <Text>{x.name}</Text>
                        <Tooltip label={x.description} aria-label="A tooltip">
                          <InfoIcon color="blue" />
                        </Tooltip>
                      </HStack>
                    </Checkbox>
                  ))
                ) : (
                  <></>
                )}
              </SimpleGrid>
            </FormControl> */}

            <Button
              isLoading={isLoading}
              mt="5"
              colorScheme="green"
              w="full"
              onClick={() => saveData()}
            >
              {t('save')}
            </Button>
          </>
        </Box>

        <Box p={5} w={['100%', '50%', '50%']}>
          {params.id !== 'new' ? (
            <Stack>
              <Heading size={'md'} align={'center'}>
                {data?.name}
              </Heading>

              <Text py={5} align={'center'}>
                {t('manager')}
              </Text>

              <SimpleGrid columns={[2, 3, 3]} spacing={3}>
                {data?.managers?.length > 0 &&
                  data?.managers?.map((y, i) => {
                    const user = data?.usersProjectData?.find(
                      (userData) => userData.id === y
                    );
                    return (
                      <Stack
                        key={i}
                        p={3}
                        borderRadius={'md'}
                        shadow={'base'}
                        align={'center'}
                      >
                        <HStack>
                          <Avatar
                            size={'sm'}
                            name={user?.name}
                            src={user?.image ? user?.image : user?.email}
                          />
                          <DynamicButton
                            action={'delete'}
                            size="xs"
                            variant={'solid'}
                            onClick={() => handleRemoveManager(user?.id)}
                          />
                        </HStack>
                        <Text
                          fontSize={[10, 12, 12]}
                          fontWeight={500}
                          noOfLines={1}
                        >
                          {user?.email}
                        </Text>
                      </Stack>
                    );
                  })}
              </SimpleGrid>

              <Box py={5}>
                <Divider />
              </Box>

              <Text align={'center'}>{t('usersProject')}</Text>

              <SimpleGrid columns={[2, 3, 3]} spacing={3}>
                {users?.slice(0, 9)?.map((x, i) => (
                  <Stack
                    key={i}
                    p={3}
                    borderRadius={'md'}
                    border={'1px'}
                    borderColor={'gray.50'}
                    shadow={'base'}
                    align={'center'}
                    overflow="hidden"
                  >
                    <Avatar
                      size={'sm'}
                      name={x.email}
                      src={x.image ? x.image : x.email}
                    />
                    <Text
                      w={'150px'}
                      fontSize={[10, 12, 12]}
                      noOfLines={1}
                      fontWeight={500}
                    >
                      {x.email}
                    </Text>
                  </Stack>
                ))}
              </SimpleGrid>
              <Text
                align={'center'}
                onClick={handleOpenModaProjectTeam}
                color={'blue.400'}
                fontSize={12}
                cursor={'pointer'}
              >
                {t('seeAll')} {t('Users')} {t('here')}
              </Text>

              <HStack justify={'center'} py={3}>
                <Button
                  onClick={handleOpenModalProject}
                  size={'sm'}
                  colorScheme="green"
                >
                  {t('add')} {t('Users')}
                </Button>
                <Button
                  onClick={handleOpenModaProjectTeam}
                  size={'sm'}
                  colorScheme="green"
                >
                  {t('edit')} {t('user')}
                </Button>
              </HStack>
            </Stack>
          ) : null}
        </Box>
      </Flex>

      <Modal
        size={'md'}
        isOpen={modalProjectUser}
        onClose={() => setModalProjectUser(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('companyTeam')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={1} py={3}>
              <HStack m="1">
                <Input
                  type="text"
                  placeholder={t('searchUser')}
                  onChange={(e) => handleSearchUsers(e.target.value)}
                />
              </HStack>

              <Stack>
                <Stack
                  maxH={300}
                  overflowY={'auto'}
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {searchResult.length > 0 ? (
                    searchResult.map((x, index) => {
                      return (
                        <HStack key={index} p="2" borderBottom="1px">
                          <Avatar
                            name={x.document.name}
                            src={x.document.image ? x.document.image : ''}
                          />
                          <Box>
                            <Text w={'200px'} noOfLines={1}>
                              {x.document.name}
                            </Text>
                            <Text w={'200px'} noOfLines={1}>
                              {x.document.email}
                            </Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme="green"
                            onClick={() => handleUserProjectClick(x.document)}
                          >
                            +
                          </Button>
                        </HStack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <AvatarGroup size="sm" gap="1" max={4}>
                {selectedUserProjectIds.length > 0 &&
                  selectedUserProjectIds.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))}
              </AvatarGroup>
              <Spacer />
              <Button
                isLoading={isAddingTeam}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddTeamProject()}
              >
                {t('add')} {t('team')}
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalProjectUser(false);
                }}
              >
                {t('cancel')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalProjectUserTeam}
        onClose={() => setModalProjectUserTeam(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('team')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              spacing={1}
              py={3}
              h={300}
              overflowY={'auto'}
              sx={{
                '&::-webkit-scrollbar': {
                  w: '2',
                  h: '3',
                },
                '&::-webkit-scrollbar-track': {
                  w: '6',
                  h: '5',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10',
                  bg: 'gray.200',
                },
              }}
            >
              {companyActive?.usersProjectData?.length > 0 &&
                companyActive?.usersProjectData?.map((x, index) => {
                  let roleUser = '';
                  if (companyActive?.owners?.includes(x.id)) {
                    roleUser = 'owner';
                  } else if (companyActive?.managers?.includes(x.id)) {
                    roleUser = 'manager';
                  } else if (companyActive?.users?.includes(x.id)) {
                    roleUser = 'user';
                  } else if (companyActive?.admin?.includes(x.id)) {
                    roleUser = 'admin';
                  }

                  const handleChangeRoleProject = async (event) => {
                    // Mengubah role pengguna
                    const selectedRole = event.target.value;

                    if (
                      globalState.roleCompany === 'owner' ||
                      globalState.roleProject === 'manager'
                    ) {
                      const collectionName = 'projects';
                      const docName = companyActive.id;
                      const field =
                        selectedRole === 'manager'
                          ? 'managers'
                          : selectedRole === 'user'
                            ? 'users'
                            : 'admin';
                      const values = [x.id];

                      try {
                        await arrayUnionFirebase(
                          collectionName,
                          docName,
                          field,
                          values
                        );

                        toast({
                          title: 'Berhasil',
                          description: 'Success update role team',
                          status: 'success',
                          duration: 2000,
                          isClosable: true,
                        });
                      } catch (error) {
                        toast({
                          title: 'Deoapp.com',
                          duration: 3000,
                          description: error.message,
                          status: 'error',
                          position: 'top-right',
                          isClosable: true,
                        });
                      } finally {
                        getData();
                      }
                    } else {
                      toast({
                        title: t('warning'),
                        description: 'You dont have any access to set role.',
                        status: 'warning',
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    // Implementasikan logika untuk mengubah role pengguna sesuai dengan kebutuhan Anda
                  };

                  return (
                    <HStack
                      cursor={'pointer'}
                      spacing={2}
                      key={index}
                      p={2}
                      borderRadius="lg"
                    >
                      <Stack>
                        <Avatar size={'sm'} name={x?.name} />
                      </Stack>
                      <Stack spacing={0}>
                        <Text
                          fontSize={'sm'}
                          fontWeight={500}
                          textTransform="capitalize"
                        >
                          {x?.name}
                        </Text>
                        <Text fontSize={'xs'}>{x?.email}</Text>
                      </Stack>
                      <Spacer />
                      <Stack>
                        <Select
                          size="xs"
                          defaultValue={roleUser}
                          onChange={handleChangeRoleProject}
                          variant="outline"
                          fontWeight="normal"
                        >
                          <option value="manager">{t('manager')}</option>
                          {/* <option value="admin">Admin</option> */}
                          <option value="user">{t('user')}</option>
                        </Select>
                      </Stack>
                      <Stack>
                        <DynamicButton
                          size={'xs'}
                          action="delete"
                          onClick={() => handleRemoveUser(x)}
                        />
                      </Stack>
                    </HStack>
                  );
                })}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalProjectUserTeam(false);
                }}
              >
                {t('cancel')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={isLoading}
        isOpen={modalAlertDelete?.isOpen}
        onClose={modalAlertDelete.onClose}
        title={t('deleteProject')}
        onAction={deleteProject}
      />
    </Stack>
  );
}

export default ProjectsViewPage;
