// Rules:
// 1. Masukan keyword sesuai alphabet, gunakan https://codeshack.io/json-sorter/ (copas bagian translation lalu sort by key name)
// 2. Pastikan keyword yang baru ada di 2 tempat (id dan en)
const resLang = {
  id: {
    translation: {
      company: 'Perusahaan',
      'score-board': 'Papan Skor',
      task: 'Tugas',
      taskID: 'ID Tugas',
      sop: 'SOP & KPI',
      tools: 'Alat',
      assets: 'Aset',
      configuration: 'Konfigurasi',
      logout: 'Keluar',
      attention: 'Perhatian',
      teamDeoappAlert:
        'Halo Team Deoapp, saat ini kami menyediakan fitur SOP, KPI, & OKR Beta Version',
      connection: 'Koneksi',
      connected: 'Koneksi terhubung',
      disconnect:
        'Koneksi internet tidak stabil. Mohon periksa kembali koneksi Anda.',
      welcome: 'Selamat datang di Deoapp Bisnis',
      welcome2:
        'Rasakan keunggulan Manajemen Bisnis dan Transformasi Bisnis Anda',
      warning: 'Peringatan',
      noAccess: 'Maaf, Anda tidak diizinkan untuk mengakses halaman ini.',
      noAccessUsers:
        'Pengguna tidak memiliki akses untuk ubah dan hapus peta pikran ini',
      back: 'Kembali',
      People: 'Orang',
      Tasks: 'Tugas',
      Activity: 'Aktivitas',
      'Task Management': 'Manajemen Tugas',
      Kanban: 'Kanban',
      Table: 'Tabel',
      Gantt: 'Gantt',
      'Minutes of Meeting': 'Notulen Rapat',
      addMom: 'Buat Notulen Rapat',
      deleteMom: 'Hapus Notulen Rapat {{title}}',
      Operation: 'Operasional',
      addOperation: 'Tambah Operasional',
      Line: 'Garis',
      'SOP Lists': 'Daftar SOP',
      'SOP Template': 'Format SOP',
      KPI: 'KPI',
      'KPI List': 'Daftar KPI',
      'KPI Template': 'Format KPI',
      'KPI Simulator': 'Simulator KPI',
      OKR: 'OKR',
      'OKR Lists': 'Daftar OKR',
      'Docs & Files': 'Dokumen & Berkas',
      'Digital Assets': 'Aset Digital',
      'Mind Map': 'Peta Pikiran',
      createMindmap: 'Buat Peta Pikiran',
      deleteMindmap: 'Hapus Peta Pikiran {{title}}',
      'Flow Chart': 'Diagram Alir',
      Automation: 'Otomatisasi',
      project: 'Proyek',
      projectTeam: 'Tim Proyek',
      Projects: 'Proyek',
      Users: 'Pengguna',
      settingRole:
        'untuk mengatur role, kamu bisa melakukannya di hr.deoapp.com',
      prev: 'Sebelumnya',
      next: 'Selanjutnya',
      employeeForm: 'Formulir Karyawan',
      deleteUser: 'Hapus Pengguna',
      deleteUser2:
        'Apakah Anda yakin ingin menghapus pengguna {{name}}? Data yang dihapus tidak dapat dikembalikan.',
      delete: 'Hapus',
      deleteFile: 'Apakah Anda yakin ingin menghapus berkas ini?',
      cancel: 'Batal',
      name: 'Nama',
      phoneNumber: 'Nomor Telepon',
      bankAccount: 'Nama Bank',
      bankAccountNumber: 'Nomor Rekening Bank',
      bankAccountName: 'Nama Rekening Bank',
      joinDate: 'Tanggal Bergabung',
      deletePermanent: 'Hapus Secara Permanen',
      updateUser: 'Perbarui Data Pengguna',
      submit: 'Kirim',
      action: 'Tindakan',
      noData: 'Tidak Ada Data',
      searchUser: 'Cari Pengguna',
      user: 'Pengguna',
      manager: 'Manajer',
      modules: 'Modul',
      create: 'Buat',
      created: 'Dibuat',
      createdBy: 'Dibuat oleh',
      noProject: 'Tidak Ada Data Proyek',
      newProject: 'Silahkan Buat Proyek Baru',
      addProject: 'Tambahkan Proyek',
      nameProject: 'Nama Proyek',
      descProject: 'Deskripsi Proyek',
      emailProject: 'Email Proyek',
      phoneProject: 'Nomor Telepon Proyek',
      usersProject: 'Pengguna di proyek ini',
      projectId: 'Id Proyek',
      loadMore: 'Lebih Banyak',
      save: 'Simpan',
      seeAll: 'Lihat Semua',
      see: 'Lihat',
      clearImage: 'Hapus Gambar',
      here: 'di sini',
      add: 'Tambah',
      edit: 'Ubah',
      editKanban: 'Ubah Kanban',
      companyTeam: 'Tim Perusahaan',
      team: 'Tim',
      deleteProject: 'Apakah anda yakin ingin menghapus proyek ini',
      confirm: 'Konfirmasi',
      toast: {
        success: 'Berhasil!',
        error: 'Eror!',
        warning: 'Peringatan!',
        alert: 'Perhatian!',
        failed: 'Gagal!',
        fieldRequired: 'Mohon lengkapi semua data yang diperlukan',
        selectUser: 'Mohon pilih pengguna',
        noContact: 'Tidak ada kontak',
        noAccess: 'Anda tidak memiliki akses',
        updated: 'Berhasil diperbarui!',
        delete: 'Berhasil menghapus {{title}}',
        automation: 'Berhasil membuat otomatisasi baru',
      },
      password: 'Kata Sandi',
      passwordChanged: 'Kata sandi telah diperbarui!',
      passwordNotMatch: 'Kata sandi tidak cocok',
      changePassword: 'Ubah Kata Sandi Anda',
      enterPassword: 'Masukkan Kata Sandi',
      confirmPassword: 'Konfirmasi Kata Sandi',
      welcomeNotif: 'Selamat Datang di Deoapp Notifikasi',
      newFeatureNotif: 'Notifikasi Fitur Terbaru ',
      notifFeature: 'Notifikasi Fitur',
      descNotif:
        'Jadikan bisnis anda lebih efektif dengan Deoapp Produktivitas.',
      notification: 'Notifikasi',
      newFeature: 'Fitur Terbaru',
      profile: 'Profil',
      billing: 'Tagihan',
      adminKey: 'Kunci Admin',
      key: 'Kunci',
      account: 'Akun',
      date: 'Tanggal',
      startDate: 'Tanggal Mulai',
      endDate: 'Tanggal Akhir',
      from: 'Dari',
      to: 'Hingga',
      to2: 'Kepada',
      clear: 'Hapus',
      filterDate: 'Filter Tanggal',
      management: 'Manajemen',
      today: 'Hari Ini',
      dateRange: 'Periode Tanggal',
      score: 'Skor',
      time: 'Waktu',
      userId: 'ID Pengguna',
      select: 'Pilih',
      close: 'Tutup',
      kanbanHistory: 'Riwayat Kanban',
      calendarView: 'Tampilan Kalender',
      listView: 'Tampilan Daftar',
      updated: 'Diperbarui',
      leader: 'Pemimpin',
      day: 'Hari',
      all: 'Semua',
      creator: 'Pembuat',
      noCreator: 'Tidak ada pembuat',
      lastActivity: 'Aktivitas terakhir',
      lastUpdate: 'Terakhir diperbarui',
      active: 'Aktif',
      nonActive: 'Tidak Aktif',
      type: 'Tipe',
      expired: 'Kadaluwarsa',
      dataFrom: 'Sumber data',
      title: 'Judul',
      column: 'Kolom',
      dueOn: 'Tenggat Waktu',
      file: 'Berkas',
      welcomeProd: 'Selamat Datang di Deoapp Produktivitas',
      userActivity: 'Aktivitas Pengguna',
      at: 'pada',
      typeView: 'Tampilan {{type}}',
      addKanban: 'Tambah Kanban',
      myKanban: 'Kanban Saya',
      myKanbanNote: 'Kanban hanya bisa di akses untuk memindahkan tugas anda',
      search: 'Cari',
      searchSop: 'Cari SOP',
      sopView: 'Tampilan SOP',
      templateSopCompany: 'Template SOP Perusahaan',
      addSop: 'Tambah SOP',
      seeTemplate: 'Lihat Template',
      assignee: 'Penerima',
      pic: 'PJ',
      category: 'Kategori',
      noCategory: 'Tidak ada kategori',
      addCategory: 'Tambah kategori baru',
      assigneeTo: 'Ditugaskan untuk',
      noKpi: 'Kartu ini tidak memiliki KPI',
      thumbnail: 'Thumbnail',
      noThumbnail: 'Tidak ada thumbnail',
      comment: 'Komentar',
      upload: 'Unggah',
      copy: 'Salin',
      newTab: 'Buka jendela baru',
      countdown: 'Hitung mundur',
      newCard: 'Kartu Baru',
      hide: 'Sembunyikan',
      hideHistory: 'Sembunyikan riwayat',
      showHistory: 'Tampilkan riwayat',
      share: 'Bagikan',
      move: 'Pindah',
      duplicate: 'Duplikat',
      sendEmail: 'Apakah anda ingin kirim data kanban ini melalui email',
      deleteKpi: 'Apakah anda ingin menghapus data KPI {{title}}',
      deleteOkr: 'Apakah anda ingin menghapus data OKR {{title}}',
      value: 'Nilai',
      descDoc: 'Anda dapat drag dan drop untuk menggabungkan file ke folder',
      newFolder: 'Folder baru',
      addFolder: 'Tambah folder',
      deleteFolder: 'Hapus Folder',
      assetsFolder: 'Folder aset',
      nameFolder: 'Nama Folder',
      description: 'Deskripsi',
      addFile: 'Tambah Berkas',
      newFile: 'Berkas Baru',
      nameFile: 'Judul File',
      alertArray: 'Anda tidak memiliki data {{title}}',
      alertArrayAction: 'Klik tombol {{action}} untuk menambahkan data.',
      folderNote:
        'Jika Anda ingin mengekstrak file ini, Anda dapat melakukannya dengan menekan lama pada salah satu file di atas.',
      open: 'Buka',
      confirmMove: 'Konfirmasi Perpindahan',
      moveAlert: 'Apakah Anda yakin ingin memindahkan tugas ini keluar?',
      documentation: 'Dokumentasi',
      link: 'Tautan',
      inputTitleDoc: 'Masukkan Judul Dokumen',
      inputDescDoc: 'Masukkan Deskripsi Dokumen',
      createFlowchart: 'Buat Diagram Alir',
      titleFlowchart: 'Judul Diagram Alir',
      addFlowchart: 'Tambah Diagram Alir',
      deleteFlowchart: 'Hapus Flowchart {{title}}',
      path: 'Alur',
      visitor: 'Pengunjung',
      location: 'Lokasi',
      exTeam: 'Tim Eksternal',
      noExUser: 'Tidak ada pengguna eksternal',
      noTeam: 'Tidak ada data tim',
      note: 'Catatan',
      pom: 'Catatan penting',
      point: 'Poin',
      newCategory: 'Kategori Baru',
      addTeam: 'Tambah Tim',
      addTag: 'Tambah Tag',
      noTagData: 'Tidak ada data tag',
      addNewTag: 'Tambah Tag Baru',
      deleteKanban: 'Apakah anda ingin menghapus kanban {{title}}',
      list: 'Daftar',
      taskDuration: 'Durasi Tugas',
      cardSize: 'Ukuran Kartu',
      publish: 'Terbitkan',
      draft: 'Draf',
      deleteSop: 'Apakah anda ingin menghapus SOP {{title}}',
      useThisTemplate: 'Gunakan Template',
      duplicateFile: 'Apakah anda yakin ingin duplikat file ini?',
      duplicateFileCompany:
        'Apakah anda ingin duplikat file ini ke perusahaan anda',
      searchText: 'Cari Teks',
      addKpi: 'Tambah KPI',
      addKpiForProject: 'Tambah KPI untuk Proyek',
      searchByProgress: 'Cari Berdasarkan Progres',
      status: 'Status',
      progress: 'Progres',
      period: 'Periode',
      noOkr: 'Tidak ada data OKR',
      nameKpi: 'Judul KPI',
      periodKpi: 'Periode KPI',
      targetKpi: 'Target KPI',
      average: 'Rata-rata',
      selectOption: 'Pilih Opsi',
      achievementKpi: 'Pencapaian KPI',
      sopForThisKpi: 'SOP untuk KPI ini',
      actual: 'Aktual',
      editKpi: 'Edit KPI',
      allTemplateKpi: 'Semua Template KPI  ',
      searchKpi: 'Cari KPI',
      viewTemplateKpi: 'Lihat Template KPI',
      simulatorKpi: 'Simulator KPI',
      income: 'Pendapatan',
      times: 'Kali',
      profit: 'Laba',
      nettProfitTotal: 'Pendapatan Bersih Total',
      expenseProduct: 'Biaya Produk',
      quantityProduct: 'Kuantitas Produk',
      expenseAds: 'Biaya Iklan',
      budgetAds: 'Biaya Iklan',
      budgetMarketing: 'Biaya Marketing',
      expenseTeam: 'Biaya Tim',
      totalSalary: 'Gaji Total',
      quantityTeamNeeded: 'Kuantitas Tim Diperlukan',
      head: 'Kepala',
      month: 'Bulan',
      salary: 'Gaji',
      product: 'Produk',
      productPrice: 'Harga Produk',
      cacValue: 'Nilai CAC',
      nettProfit: 'Pendapatan Bersih',
      quarter: 'Triwulan',
      annual: 'Tahunan',
      addOkr: 'Tambah OKR',
      objective: 'Objektif',
      assignor: 'Pemberi',
      start: 'Mulai',
      end: 'Akhir',
      nameOkr: 'Judul OKR',
      periodOkr: 'Periode OKR',
      controlPanel: 'Panel Kontrol',
      mindmapTeam: 'Tim Peta Pikiran',
      panelButton: 'Panel Tombol',
      basicFlowchartShape: 'Diagram Alir Sederhana',
      flowchartTeam: 'Tim Diagram Alir',
      visitorAccsess: 'Pengunjung hanya bisa melihat diagram alir ini',
      editorAccsess:
        'Pengguna memiliki akses untuk mengedit dan menghapus diagram alir ini',
      colorPalette: 'Palet Warna',
      startConversation: 'Mulai Percakapan',
      help: 'Bantuan',
      typeYourMessage: 'Tulis pesan anda di sini',
      canIHelpYou: 'Apa yang bisa kami bantu hari ini?',
      descSopAi:
        'Platform AI untuk Konsultasi dan Pembuatan Template SOP secara Otomatis!',
      waChatAi:
        'Hi, Silahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊',
      usage: 'Penggunaan',
      download: 'Unduh',
      bestOf10: '10 Teratas',
      performance: 'Kinerja',
    },
  },
  en: {
    translation: {
      company: 'Company',
      'score-board': 'Score Board',
      task: 'Task',
      taskID: 'Task ID',
      sop: 'SOP & KPI',
      tools: 'Tools',
      assets: 'Assets',
      configuration: 'Configuration',
      logout: 'Logout',
      attention: 'Attention',
      teamDeoappAlert:
        'Hello Team Deoapp, we currently provide SOP, KPI, & OKR Beta Version features',
      connection: 'Connection',
      connected: 'Connected',
      disconnect: 'Connection lost. Please check your internet connection.',
      welcome: 'Welcome to Deoapp Business',
      welcome2:
        'Unlock the Power of Business Management and Transform Your Business',
      warning: 'Warning',
      noAccess: 'Im sorry, but based on your role,  You dont have access.',
      noAccessUsers: ' Users have access to edit and delete this flowchart',
      back: 'Back',
      People: 'People',
      Tasks: 'Tasks',
      Activity: 'Activity',
      'Task Management': 'Task Management',
      Kanban: 'Kanban',
      Table: 'Table',
      Gantt: 'Gantt',
      'Minutes of Meeting': 'Minutes of Meeting',
      addMom: 'Add Minutes of Meeting',
      deleteMom: 'Delete {{title}} Minutes of Meeting',
      Operation: 'Operation',
      addOperation: 'Add Operation',
      Line: 'Line',
      'SOP Lists': 'SOP Lists',
      'SOP Template': 'SOP Template',
      KPI: 'KPI',
      'KPI List': 'KPI List',
      'KPI Template': 'KPI Template',
      'KPI Simulator': 'KPI Simulator',
      OKR: 'OKR',
      'OKR Lists': 'OKR List',
      'Docs & Files': 'Docs & Files',
      'Digital Assets': 'Digital Assets',
      'Mind Map': 'Mind Map',
      createMindmap: 'Create Mindmap',
      deleteMindmap: 'Delete {{title}} Mindmap',
      'Flow Chart': 'Flow Chart',
      Automation: 'Automation',
      project: 'Project',
      projectTeam: 'Project Team',
      Projects: 'Projects',
      Users: 'Users',
      settingRole: 'for setting role, you can do it on hr.deoapp.com',
      prev: 'Prev',
      next: 'Next',
      employeeForm: 'Employee Form',
      deleteUser: 'Delete User',
      deleteUser2:
        ' Are you sure want to delete user {{name}}? You cant undo this action afterwards.',
      delete: 'Delete',
      deleteFile: 'Are you sure want to delete this file?',
      cancel: 'Cancel',
      name: 'Name',
      phoneNumber: 'Phone Number',
      bankAccount: 'Bank Account',
      bankAccountNumber: 'Bank Account Number',
      bankAccountName: 'Bank Account Name',
      joinDate: 'Join Date',
      deletePermanent: 'Delete Permanently',
      updateUser: 'Update Data User',
      submit: 'Submit',
      action: 'Action',
      noData: 'No Data Found',
      searchUser: 'Search User',
      user: 'User',
      manager: 'Manager',
      modules: 'Modules',
      create: 'Create',
      created: 'Created',
      createdBy: 'Created by',
      noProject: 'No Data Project',
      newProject: 'Please Create a New Project',
      addProject: 'Add New Project',
      nameProject: 'Project Name',
      descProject: 'Project Description',
      emailProject: 'Project Email',
      phoneProject: 'Project Phone Number',
      usersProject: 'Users in this project',
      projectId: 'Project Id',
      loadMore: 'Load More',
      save: 'Save',
      seeAll: 'See All',
      see: 'See',
      clearImage: 'Clear Image',
      here: 'Here',
      add: 'Add',
      edit: 'Edit',
      editKanban: 'Edit Kanban',
      companyTeam: 'Company Team',
      team: 'Team',
      deleteProject: 'Are you sure want to delete this project',
      confirm: 'Confirm',
      toast: {
        success: 'Success!',
        error: 'Error!',
        warning: 'Warning!',
        alert: 'Alert!',
        failed: 'Failed!',
        fieldRequired: 'Please complete all required fields',
        selectUser: 'Please select user',
        noContact: 'You don\'t have any contact',
        updated: 'Success updating',
        noAccess: 'You dont have access to do this action',
        delete: 'Success delete {{title}}',
        automation: 'Success create new automation',
      },
      password: 'Password',
      passwordChanged: 'Password has been edited!',
      passwordNotMatch: 'Password not match',
      changePassword: 'Change Your Password',
      enterPassword: 'Enter Your Password',
      confirmPassword: 'Confirm Your Password',
      welcomeNotif: 'Welcome to Deoapp Notification',
      newFeatureNotif: 'New Feature Notification',
      notifFeature: 'Feature Notification',
      descNotif:
        ' Make your business more efficient with the help from Deoapp Productivity.',
      notification: 'Notification',
      newFeature: 'New Feature',
      profile: 'Profile',
      billing: 'Billing',
      adminKey: 'Admin Key',
      key: 'Key',
      account: 'Account',
      date: 'Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      from: 'From',
      to: 'To',
      to2: 'To',
      clear: 'Clear',
      filterDate: 'Filter Date',
      management: 'Management',
      today: 'Today',
      dateRange: 'Date Range',
      score: 'Score',
      select: 'Select',
      close: 'Close',
      kanbanHistory: 'Kanban History',
      calendarView: 'Calender View',
      listView: 'List View',
      updated: 'Updated',
      leader: 'Leader',
      day: 'Day',
      all: 'All',
      creator: 'Creator',
      noCreator: 'No creator',
      lastActivity: 'Last activity',
      lastUpdate: 'Last Updated',
      active: 'Active',
      nonActive: 'Non Active',
      type: 'Type',
      expired: 'Expired',
      dataFrom: 'Data From',
      title: 'Title',
      column: 'Column',
      dueOn: 'Due on',
      file: 'File',
      welcomeProd: 'Welcome to Deoapp Productivity',
      userActivity: 'User Activity',
      at: 'at',
      typeView: '{{type}} View',
      addKanban: 'Add Kanban',
      myKanban: 'My Kanban',
      myKanbanNote: 'My Kanban only can accsess to drag your task',
      search: 'Search',
      searchSop: 'Search SOP',
      sopView: 'SOP View',
      templateSopCompany: 'Template SOP Company',
      addSop: 'Add SOP',
      seeTemplate: 'See Template',
      assignee: 'Assignee',
      assigneeTo: 'Assigned to',
      pic: 'PIC',
      category: 'Category',
      noCategory: 'No category',
      addCategory: 'Add new category',
      noKpi: 'This card does not have a KPI',
      thumbnail: 'Thumbnail',
      noThumbnail: 'No thumbnail data',
      comment: 'Comment',
      upload: 'Upload',
      copy: 'Copy',
      newTab: 'Open in new tab',
      countdown: 'Countdown',
      newCard: 'New Card',
      hide: 'Hide',
      hideHistory: 'Hide History',
      showHistory: 'Show History',
      share: 'Share',
      move: 'Move',
      duplicate: 'Duplicate',
      sendEmail: 'Would you like to send this Kanban data via email',
      deleteKpi: 'Would you like to delete {{title}} KPI',
      deleteOkr: 'Would you like to delete {{title}} OKR',
      value: 'Value',
      descDoc:
        'You can perform a drag-and-drop action to merge one of the files into a folder',
      newFolder: 'New folder',
      description: 'Description',
      addFolder: 'Add folder',
      deleteFolder: 'Delete Folder',

      assetsFolder: 'Folder Assets',
      nameFolder: 'Folder Name',
      addFile: 'Add File',
      newFile: 'New File',
      nameFile: 'File Name',
      alertArray: 'You dont have any {{title}} data yet.',
      alertArrayAction: 'To create one, click the {{action}} button.',
      folderNote:
        'If you wish to extract this file, you can do so by long-pressingon one of the files above.',
      open: 'Open',
      confirmMove: 'Confirm Move',
      moveAlert: 'Are you sure you want to move this file?',
      documentation: 'Documentation',
      link: 'Link',
      inputTitleDoc: 'Input documentation title',
      inputDescDoc: 'Input documentation description',
      createFlowchart: 'Create Flowchart',
      titleFlowchart: 'Title Flowchart',
      addFlowchart: 'Add Flowchart',
      deleteFlowchart: 'Delete {{title}} Flowchart ',
      path: 'Path',
      visitor: 'Visitor',
      location: 'Location',
      exTeam: 'External Team',
      noExUser: 'No External User',
      noTeam: 'No Team Data',
      note: 'Note',
      pom: 'Point of Meeting',
      point: 'Point',
      newCategory: 'New Category',
      addTeam: 'Add Team',
      addTag: 'Add Tag',
      noTagData: 'No Tag Data',
      addNewTag: 'Add New Tag',
      deleteKanban: 'Would you like to delete {{title}} kanban?',
      list: 'List',
      taskDuration: 'Task Duration',
      cardSize: 'Card Size',
      publish: 'Publish',
      draft: 'Draft',
      deleteSop: 'Would you like to delete {{title}} SOP?',
      useThisTemplate: 'Use This Template',
      duplicateFile: 'Are you sure want to duplicate this file?',
      duplicateFileCompany:
        'Do you want to duplicate this file to your company',
      searchText: 'Search Text',
      addKpi: 'Add KPI',
      addKpiForProject: 'Add KPI for Project',
      searchByProgress: 'Search By Progress',
      status: 'Status',
      progress: 'Progress',
      period: 'Period',
      noOkr: 'No OKR',
      nameKpi: 'KPI Name',
      periodKpi: 'KPI Period',
      targetKpi: 'KPI Target ',
      average: 'Average',
      selectOption: 'Select Option',
      achievementKpi: 'KPI Achievement',
      editKpi: 'Edit KPI',
      sopForThisKpi: 'SOP for this KPI',
      actual: 'Actual',
      allTemplateKpi: 'All KPI Template ',
      searchKpi: 'Search KPI',
      viewTemplateKpi: 'View Template KPI',
      simulatorKpi: 'KPI Simulator',
      income: 'Income',
      times: 'Times',
      time: 'Time',
      profit: 'Profit',
      nettProfitTotal: 'Nett Profit Total',
      expenseProduct: 'Expense Product',
      quantityProduct: 'Quantity Product',
      expenseAds: 'Expense Ads',
      budgetAds: 'Budget Ads',
      budgetMarketing: 'Budget Marketing',
      expenseTeam: 'Expense Team',
      totalSalary: 'Total Salary',
      quantityTeamNeeded: 'Quantity Team Needed',
      head: 'Head',
      month: 'Month',
      salary: 'Salary',
      product: 'Product',
      productPrice: 'Product Price',
      cacValue: 'CAC Value',
      nettProfit: 'Nett Profit',
      quarter: 'Quarterly',
      annual: 'Annually',
      addOkr: 'Add OKR',
      objective: 'Objective',
      assignor: 'Assignor',
      start: 'Start',
      end: 'End',
      nameOkr: 'OKR Name',
      periodOkr: 'OKR Period',
      controlPanel: 'Control Panel',
      mindmapTeam: 'Mindmap Team',
      pnelButton: 'Panel Button',
      basicFlowchartShape: 'Basic Flowchart Shape',
      flowchartTeam: 'Flowchart Team',
      visitorAccsess: 'Users only can view this flowchart',
      editorAccsess: 'Users have access to edit and delete this flowchart',
      colorPalette: 'Color Palette',
      startConversation: 'Start Conversation',
      help: 'Help',
      typeYourMessage: 'Type your message here',
      canIHelpYou: 'How can I help you today?',
      descSopAi:
        'An AI Platform for Consultation and Automatic SOP Template Generator! ',
      waChatAi:
        'Hi, Please leave your message, and our Support Team will reply soon 😊',
      usage: 'Usage',
      download: 'Download',
      bestOf10: 'Best of 10',
      performance: 'Performance',
    },
  },
};
export default resLang;
