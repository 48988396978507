/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import {
  Box,
  Input,
  Select,
  InputLeftAddon,
  InputGroup,
  Avatar,
  Tooltip,
  HStack,
  Text,
  Flex,
  Heading,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spacer,
  useToast,
  Image,
  Grid,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useUserStore from '../../Hooks/Zustand/Store';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
  arrayUnionFirebase,
  arrayRemoveFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import { addActivity, addPostTopic, addSubscribeTopic } from '../../Api/logApi';
import TaskCardAddComment from '../../Components/Card/TaskCardAddComment';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ProgressCircle from '../../Components/Progress/ProgressCircle';
import CardFile from '../../Components/Accounting/CardFile';
import { decryptToken } from '../../Utils/encrypToken';
import { FcAudioFile, FcFolder, FcVideoFile } from 'react-icons/fc';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { IoShareSocialSharp } from 'react-icons/io5';
import { doc, onSnapshot } from 'firebase/firestore';
import { getUserByUid } from '../../Utils/getUser';
import { formatFrice } from '../../Utils/Helper';
import { useTranslation } from 'react-i18next';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import DeleteCardComponent from './Components/DeleteCardComponent';
import { createHistoryCard } from '../../Utils/historyCardUtil';
import UploadFileToAwsAndDropbox from '../../Components/DropBox/UploadFileToAwsAndDropbox';

const ProductivityTaskViewModalPage = ({
  detailProps,
  handleModalClose,
  handleNavigateModal,
  handleRefresh,
}) => {
  const { t } = useTranslation()
  const param = useParams();
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate()

  const [mainData, setMainData] = useState();
  const [data, setData] = useState(detailProps);
  const [progress, setProgress] = useState(0);
  const [score, setScore] = useState(detailProps?.score || 0);
  const [asignee, setAsignee] = useState(detailProps?.asignee || []);
  const [pic, setPic] = useState(detailProps?.pic || []);
  const [category, setCategory] = useState({ open: false });
  const [addCategory, setAddCategory] = useState('');

  const titleRef = useRef(data?.title || '');
  const dueOnRef = useRef(data?.dueOn || '');
  const categoryRef = useRef(data?.category || '');
  const labelRef = useRef(data?.label || '');


  useEffect(() => {
    //onsnapshot here...
    const unsubCategory = onSnapshot(doc(db, 'files', param.id), (doc) => {
      if (doc.data()) {
        setCategory({ name: doc.data().category, open: false });
        setMainData(doc.data());
      } else {
        setCategory({ open: false });
        setMainData();
      }
    });

    const unsubTask = onSnapshot(doc(db, 'kanban', detailProps.id), (doc) => {
      const dataRes = doc?.data();
      const idRes = doc?.id;
      setData({ ...dataRes, id: idRes });
      setScore(doc?.data()?.score || 0);
      setAsignee(doc?.data()?.asignee ? doc.data()?.asignee : []);
      setPic(doc?.data()?.pic ? doc.data()?.pic : []);
    });

    return () => {
      unsubTask();
      unsubCategory();
      setCategory();
      setAsignee([]);
      setMainData('');
    };
  }, []);

  const handleChange = (value) => setScore(value);

  const handleEditData = (type, value) => {
    if (type === 'dueOn') {
      value = moment(value).unix();

      const comments = `has change due on to ${moment(value * 1000).format('LLL')}`
      createHistoryCard(detailProps?.id, comments, globalState)
    }
    switch (type) {
    case 'title':
      titleRef.current = value;
      break;
    case 'dueOn':
      dueOnRef.current = value;
      break;
    case 'category':
      categoryRef.current = value;
      break;
    case 'label':
      labelRef.current = value;
      break;
    default:
      break;
    }
  };

  const saveCategory = () => {
    let catData = [];
    if (category?.name) catData = category.name;

    handleEditData('category', addCategory);
    catData = [...catData, addCategory];

    updateDocumentFirebase('files', param.id, { category: catData })
      .then((x) => {
        toast({
          title: 'Deoapp.com',
          description: `succes add file ${x}`,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });

    setCategory({ name: catData, open: false });
  };

  const handleSave = () => {
    const uniqueDataAssignee = [...new Set(asignee)];
    const updateData = {
      title: titleRef?.current || '',
      dueOn: dueOnRef?.current || '',
      label: labelRef?.current || '',
      category: categoryRef?.current || '',
      asignee: uniqueDataAssignee,
      pic,
      score:
        parseInt(score) !== 0
          ? parseInt(score)
          : parseInt(data?.score)
            ? parseInt(data?.score)
            : parseInt(mainData?.default_score || 0),
    };

    updateDocumentFirebase('kanban', detailProps?.id, updateData)
      .then(() => {
        handleLogActivity(updateData);
        handleNotifActivity(updateData);
        const comments = 'has update data card'

        createHistoryCard(detailProps?.id, comments, globalState)
        handleModalClose();
        handleRefresh(detailProps?.id);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleLogActivity = async () => {
    const uniqueDataAssignee = [...new Set(asignee)];

    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Task',
      companyId: globalState?.currentCompany,
      projectId:
        param?.title === 'MyKanban'
          ? data?.projectId
          : globalState?.currentProject,
      action: 'Add New Task',
      collection_path: 'kanban',
      asignee: uniqueDataAssignee,
      title: titleRef.current,
      score: parseInt(score || 0),
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNotifActivity = async () => {
    try {
      const uniqueDataAssignee = [...new Set(asignee)];

      const dataNotif = {
        topic: detailProps.id,
        collectionId: detailProps.id,
        createdName:
          auth?.currentUser?.displayName ||
          auth?.currentUser?.email ||
          'anonymous',
        image: auth?.currentUser?.photoURL || '',
        createdBy: auth?.currentUser?.uid,
        type: 'Task',
        companyId: globalState?.currentCompany,
        projectId:
          param?.title === 'MyKanban'
            ? data?.projectId
            : globalState?.currentProject,
        action: 'New Task',
        collectionField: 'kanban',
        userId: uniqueDataAssignee,
        title: titleRef.current || data?.title,
        parentName: param?.title || '',
        parentId: param?.id || '',
        body: `You have a new card from ${auth?.currentUser?.displayName ||
          auth?.currentUser?.email ||
          'anonymous'
        }`,
        score: parseInt(score || 0),
        onSee: false,
      };

      const dataPost = {
        topic: detailProps.id,
        title: titleRef.current || data?.title,
        body: `New card from ${auth?.currentUser?.displayName ||
          auth?.currentUser?.email ||
          'anonymous'
        }`,
      };

      const dataSubscribe = {
        topic: detailProps.id,
        collectionField: 'kanban',
        uids: uniqueDataAssignee,
        status: 'true',
      };

      if (uniqueDataAssignee.length > 1) {
        await addSubscribeTopic(dataSubscribe);
        await addPostTopic(dataPost);

        const collectionName = 'notification';
        const docName = detailProps.id;

        try {
          await setDocumentFirebase(
            collectionName,
            docName,
            dataNotif,
            globalState?.currentCompany
          );
        } catch (error) {
          handleNotificationError(error);
        }
      }
    } catch (error) {
      handleNotificationError(error);
    }
  };

  const handleNotificationError = (errorMessage) => {
    toast({
      title: 'Deoapp.com',
      description: errorMessage,
      status: 'error',
      position: 'top-right',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSelectLabel = async (value) => {
    if (value === 'completed') {
      await updateDocumentFirebase('kanban', detailProps.id, {
        time_completed_at: new Date(),
      });
      handleEditData('label', value);
      toast({
        status: 'success',
        duration: 9000,
        title: 'Good job, Team !!',
        isClosable: true,
      });
    } else {
      handleEditData('label', value);
    }
    const comments = `has edit label to ${value}`
    createHistoryCard(detailProps?.id, comments, globalState)
  };

  const onSelectFile = async (arg) => {
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState?.currentCompany}/${param?.title === 'MyKanban'
        ? detailProps?.projectId
        : globalState?.currentProject
      }/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToAwsAndDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );

      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = detailProps.id;
        const field = 'files';
        const values = [dropboxLink];

        await arrayUnionFirebase(collectionName, docName, field, values);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (arg) => {
    const collectionName = 'kanban';
    const docName = detailProps.id;
    const field = 'files';
    const values = [arg];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Success delete your files card.',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  return (
    <Stack maxW={['auto', 'auto', 'auto']}>
      {data ? (
        <Stack
          p={5}
          w={['auto', 'auto', 'auto']}
          overflowY="scroll"
          maxH={'80vh'}
          fontSize={['sm', 'auto', 'auto']}
        >
          <HStack
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems={'center'}
            py={3}
          >
            {/* <Button colorScheme={'red'} size="md" onClick={handleModalClose}>
              <IoMdClose />
            </Button> */}
            <Stack spacing={1}>
              <Heading size="sm">{data?.title}</Heading>
              <Text fontSize="3xs"> ID: {data?.id}</Text>
            </Stack>
            <Spacer />
            <HStack spacing={2}>
              <Stack>
                <DynamicButton
                  title={t('save')}
                  action="create"
                  variant={'solid'}
                  onClick={() => handleSave()}
                />
              </Stack>

              <Stack>
                <DynamicButton
                  color={'blue'}
                  icon={IoShareSocialSharp}
                  action="read"
                  title="Detail"
                  variant={'solid'}
                  onClick={() => handleNavigateModal()}
                />
              </Stack>
              {data?.column !== 'done' && (
                <Stack>
                  <DeleteCardComponent cardId={data?.id} cardData={data} />
                </Stack>
              )}
            </HStack>
          </HStack>

          <Box>
            <Grid templateColumns={{ base: '1fr', md: '2fr 1.5fr' }} gap={3}>
              <Stack spacing={3}>
                <Input
                  placeholder={data?.title === 'New Card' ? '' : data?.title}
                  onChange={(e) => handleEditData('title', e.target.value)}
                />

                <InputGroup>
                  <InputLeftAddon children={t('lastUpdate')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      data?.lastUpdated?.seconds
                        ? moment
                          .unix(data?.lastUpdated?.seconds)
                          .format('DD/MM/YYYY')
                        : '-'
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children={t('createdBy')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      mainData?.usersDisplay?.find(
                        (x) => x.id === data.createdBy
                      )?.name
                    }
                  />
                </InputGroup>

                <HStack spacing={2}>
                  <InputGroup>
                    <InputLeftAddon children={t('category')} fontWeight={500} />

                    <Select
                      placeholder={t('select')}
                      defaultValue={
                        detailProps?.category ? detailProps.category : <></>
                      }
                      onChange={(e) =>
                        handleEditData('category', e.target.value)
                      }
                    >
                      {category?.name?.sort()?.map((x, i) => (
                        <option key={i} value={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  {category?.open ? (
                    <></>
                  ) : (
                    <DynamicButton
                      action={'create'}
                      variant="solid"
                      onClick={() => {
                        setCategory({ ...category, open: true });
                      }}
                    />
                  )}
                </HStack>

                {category?.open ? (
                  <HStack spacing={2}>
                    <InputGroup>
                      <InputLeftAddon
                        children={t('category')}
                        fontWeight={500}
                        placeholder="category name"
                      />
                      <Input
                        type="text"
                        onChange={(e) => setAddCategory(e.target.value)}
                      />
                    </InputGroup>
                    <DynamicButton
                      title="save"
                      action={'create'}
                      onClick={() => saveCategory()}
                      variant="solid"
                    />
                    <DynamicButton
                      action={'delete'}
                      onClick={() => setCategory({ ...category, open: false })}
                      variant="solid"
                    />
                  </HStack>
                ) : (
                  <></>
                )}

                {asignee?.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon
                      children={t('assigneeTo')}
                      fontWeight={500}
                    />
                    <HStack ml="2" gap="0">
                      {[...new Set(asignee)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;
                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = asignee.filter(
                                    (z) => z !== x
                                  );
                                  setAsignee(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('assigneeTo')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder={t('assigneeTo')}
                    onChange={(e) => {
                      setAsignee([...asignee, e.target.value]);
                    }}
                    textTransform={'capitalize'}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                {pic.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon children={t('pic')} fontWeight={500} />
                    <HStack ml="1" gap="0">
                      {[...new Set(pic)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;

                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = pic.filter((z) => z !== x);
                                  setPic(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('pic')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder="users"
                    onChange={(e) => {
                      setPic([...pic, e.target.value]);
                    }}
                    textTransform={'capitalize'}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children="Label" fontWeight={500} />
                  <Select
                    defaultValue={data?.label}
                    placeholder="Label"
                    onChange={(e) => handleSelectLabel(e.target.value)}
                  >
                    <option value="help">help</option>
                    <option value="priority">priority</option>
                    <option value="urgent">urgent</option>
                    <option value="pending">pending</option>
                    <option value="blocked">blocked</option>
                    {data?.time_backlog_at !== undefined && (
                      <option value="completed">completed</option>
                    )}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children={t('dueOn')} fontWeight={500} />
                  <Input
                    type="date"
                    defaultValue={
                      data?.dueOn
                        ? moment.unix(data.dueOn).format('YYYY-MM-DD')
                        : moment(new Date()).format('yyyy-MM-DD')
                    }
                    onChange={(e) => handleEditData('dueOn', e.target.value)}
                  />
                </InputGroup>
              </Stack>
              <Stack spacing={3}>
                <Stack alignItems="end" justifyContent="end" pt={3}>
                  <Text textAlign={'end'} fontWeight={800} fontSize="lg">
                    {t('score')} : {data?.score || 0}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontWeight={'bold'}>{t('score')}: </Text>
                  <Flex flexDirection={['column', 'row', 'row']}>
                    <NumberInput
                      maxW="100px"
                      mr="2rem"
                      min={0}
                      max={10}
                      value={score}
                      onChange={handleChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Slider
                      flex="1"
                      focusThumbOnChange={false}
                      value={score}
                      min={0}
                      max={10}
                      onChange={handleChange}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb
                        fontSize="sm"
                        boxSize="32px"
                        children={score}
                      />
                    </Slider>
                  </Flex>
                </Stack>
                <Divider />
                <Stack>
                  <HStack>
                    <MdOutlineAccessTimeFilled />
                    <Text fontWeight={'bold'}>Time Duration: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.time_backlog_at?.seconds && (
                      <Text>Start : {moment(data?.time_backlog_at.seconds * 1000).format('LLL')}</Text>

                    )}

                    {data?.time_completed_at?.seconds && (
                      <Text>End : {moment(data?.time_completed_at.seconds * 1000).format('LLL')}</Text>

                    )}
                  </Stack>
                </Stack>

                <Divider />

                <Stack>
                  <HStack>
                    <Text fontWeight={'bold'}>KPI: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.kpi?.length > 0 ? (
                      <Stack>
                        {data?.kpi?.map((x, i) => {
                          return (
                            <Stack key={i}>
                              <HStack
                                spacing={3}
                                cursor="pointer"
                                onClick={() => navigate('/kpi/list')}
                              >
                                <Tooltip
                                  label={
                                    getUserByUid(globalState.users, x.uid).name
                                  }
                                  textTransform={'capitalize'}
                                  aria-label="Name"
                                >
                                  <Avatar
                                    src={
                                      getUserByUid(globalState.users, x.uid)
                                        .image
                                    }
                                    size={'sm'}
                                    name={
                                      getUserByUid(globalState.users, x.uid)
                                        .name
                                    }
                                  />
                                </Tooltip>
                                <Stack spacing={1}>
                                  <Text
                                    fontSize={'xs'}
                                    fontWeight={500}
                                    noOfLines={1}
                                  >
                                    {x?.title_kpi}
                                  </Text>
                                  <Text fontSize={'2xs'} noOfLines={1}>
                                    {t('time')}: {x?.title}
                                  </Text>
                                </Stack>

                                <Spacer />
                                <HStack>
                                  <Text fontWeight={500} letterSpacing={4}>
                                    ({formatFrice(x?.actual)}/
                                    {formatFrice(parseInt(x?.target_kpi))})
                                  </Text>
                                </HStack>
                              </HStack>
                              <Divider />
                            </Stack>
                          );
                        })}
                      </Stack>
                    ) : (
                      <Stack alignItems={'center'} justifyContent={'center'}>
                        <Text fontWeight={500} fontSize={'xs'}>
                          {t('noKpi')}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Divider />
                <Stack>
                  <Text fontWeight={'bold'}>Thumbnail: </Text>
                  <CardFile onChange={onSelectFile} />
                  {data?.files?.length > 0 && (
                    <SimpleGrid
                      h={'250px'}
                      overflow={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          w: '2',
                          h: '2',
                        },
                        '&::-webkit-scrollbar-track': {
                          w: '6',
                          h: '5',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '10',
                          bg: 'gray.200',
                        },
                      }}
                      py={3}
                      spacing={4}
                      columns={[2]}
                    >
                      {data?.files?.map((x, index) => {
                        const dataView =
                          x.type === 'image' ? (
                            <Image
                              src={x.link}
                              objectFit="contain"
                              w={'200px'}
                              borderRadius={'md'}
                              shadow="md"
                            />
                          ) : x.type === 'audio' ? (
                            <FcAudioFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : x.type === 'video' ? (
                            <FcVideoFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : (
                            <FcFolder
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          );
                        return (
                          <Stack
                            key={index}
                            borderWidth={1}
                            position="relative"
                            alignItems="center"
                            justifyContent={'center'}
                            borderRadius="md"
                            minH={'120px'}
                          >
                            <HStack
                              flexDirection={['column', 'row', 'row']}
                              position={'absolute'}
                              top={-2}
                              right={-2}
                              spacing={2}
                            >
                              <DynamicButton
                                action={'read'}
                                size="sm"
                                variant={'solid'}
                                onClick={() => window.open(x.link, '_blank')}
                              />
                              <DynamicButton
                                action={'delete'}
                                onClick={() => handleDelete(x)}
                                size="sm"
                                variant={'solid'}
                              />
                            </HStack>
                            <Stack overflow={'hidden'}>
                              {dataView && dataView}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </SimpleGrid>
                  )}
                  {data?.files?.length > 2 && (
                    <Stack alignItems={'center'} justifyContent="center">
                      <BsFillArrowDownCircleFill />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Box>

          <Flex flexDirection={['column', 'row', 'row']}>
            <Box flex="1">
              {data?.id && (
                <TaskCardAddComment id={data?.id} title={data?.id} />
              )}
            </Box>
          </Flex>
        </Stack>
      ) : (
        <></>
      )}

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ProductivityTaskViewModalPage;
