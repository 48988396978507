import React, { useCallback, useLayoutEffect } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import UseUndoRedo from '../../Components/Flowchart/useUndoRedo';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import { HStack, Stack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { FcRedo, FcUndo } from 'react-icons/fc';
import {
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
} from 'reactflow';
import nodeTypes from './Mindmap/NodeType';
import edgeTypes from './Mindmap/EdgeType';

function Mindmap() {
  const globalState = useUserStore();
  const selector = (state) => ({
    nodes: state.nodes,
    edges: state.edges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
  });
  const { undo, redo, canUndo, canRedo, takeSnapshot } = UseUndoRedo();
  const {
    nodes,
    edges,
    onConnect,
    setEdges,
    onEdgesChange,
    onNodesChange,
  } = useFlowchartStore(selector);

  const nodeClick = () => {
    // console.log(params, 'ni id');
  };

  const proOptions = { account: 'paid-pro', hideAttribution: true };

  const onNodeDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const fitViewOptions = {
    padding: 0.95,
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);
          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );
          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
      takeSnapshot();
    },
    [nodes, edges, takeSnapshot]
  );

  const save = async () => {
    nodes.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    edges.reduce((x, i) => {
      x[i.id] = i;
      return x;
    }, {});
    const type = 'mindmap';
    const dataNode = { nodes, edges, type };
    await addDocumentFirebase(
      'flowcharts',
      dataNode,
      globalState?.currentCompany
    );
  };
  useLayoutEffect();
  return (
    <>
      <Stack
        w={'full'}
        border={'1px'}
        borderColor={'gray.400'}
        borderRadius={'lg'}
        h={'100vh'}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitViewOptions={fitViewOptions}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodesDelete={onNodesDelete}
          onNodeClick={nodeClick}
          onNodeDragStart={onNodeDragStart}
          onSelectionDragStart={onSelectionDragStart}
          proOptions={proOptions}
        >
          <Controls />
          <MiniMap />
          <Panel position="top-right">
            <HStack>
              <Button disabled={canUndo} onClick={undo}>
                <FcUndo />
              </Button>
              <Button disabled={canRedo} onClick={redo}>
                <FcRedo />
              </Button>
              <Button onClick={save}> save </Button>
            </HStack>
          </Panel>
        </ReactFlow>
      </Stack>
    </>
  );
}

function MindMapPage() {
  return (
    <ReactFlowProvider>
      <Mindmap />
    </ReactFlowProvider>
  );
}

export default MindMapPage;
