/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Input,
  Select,
  InputLeftAddon,
  InputGroup,
  Avatar,
  Tooltip,
  HStack,
  Text,
  Flex,
  Heading,
  Stack,
  Spacer,
  useToast,
  Image,
  Grid,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { IoMdClose } from 'react-icons/io';
import {
  updateDocumentFirebase,
  arrayRemoveFirebase,
} from '../../Api/firebaseApi';
import TaskCardAddComment from '../../Components/Card/TaskCardAddComment';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { FcAudioFile, FcFolder, FcVideoFile } from 'react-icons/fc';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { IoShareSocialSharp } from 'react-icons/io5';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { formatFrice } from '../../Utils/Helper';
import { getUserByUid } from '../../Utils/getUser';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import DeleteCardComponent from './Components/DeleteCardComponent';
import { createHistoryCard } from '../../Utils/historyCardUtil';

const ProductivityMyTaskViewModalPage = ({
  props,
  handleModalClose,
  handleRefresh,
}) => {
  const { t } = useTranslation()
  const toast = useToast();
  const navigate = useNavigate();
  const { userDisplay } = useKanbanStore();
  const detailProps = props?.task;
  const globalState = useUserStore();

  const data = detailProps;
  const asignee = detailProps?.asignee || [];
  const pic = detailProps?.pic || [];


  const setSomeFunction = useKanbanStore((state) => state.setFunctionAddData);
  const setTaskData = useKanbanStore((state) => state.setTaskData);

  const handleNavigate = () => {
    navigate(
      `/kanban/view/${detailProps?.filesId}/MyKanban/${detailProps?.id}`,
      { state: { type: 'existing' } }
    );
    setSomeFunction(props?.setData);
    setTaskData(detailProps);
  };

  const handleSelectLabel = async (id, value) => {
    let updateData = {};

    if (value === 'completed') {
      updateData = {
        label: value,
        time_completed_at: new Date(),
      };
      toast({
        status: 'success',
        duration: 9000,
        title: 'Good job, Team !!',
        isClosable: true,
      });
    } else {
      updateData = {
        label: value,
      };
      toast({
        status: 'success',
        duration: 3000,
        title: `status changed to ${value}`,
        isClosable: true,
      });
    }
    const comments = `has edit label to ${value}`
    createHistoryCard(data?.id, comments, globalState)
    
    await updateDocumentFirebase('kanban', data?.id, updateData);
    handleRefresh(data?.id);
  };

  const handleDelete = async (arg) => {
    const collectionName = 'kanban';
    const docName = detailProps.id;
    const field = 'files';
    const values = [arg];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Success delete your files card.',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack maxW={['auto', 'auto', 'auto']}>
      {data ? (
        <Stack
          p={5}
          w={['auto', 'auto', 'auto']}
          overflowY="scroll"
          maxH={'80vh'}
          fontSize={['sm', 'auto', 'auto']}
        >
          <HStack
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems={'center'}
            py={3}
          >
            <Button colorScheme={'red'} size="md" onClick={handleModalClose}>
              <IoMdClose />
            </Button>
            <Stack spacing={1}>
              <Heading size="sm">{data?.title}</Heading>
              <Text fontSize="3xs"> ID: {data?.id}</Text>
            </Stack>
            <Spacer />
            <DynamicButton
              color={'blue'}
              icon={IoShareSocialSharp}
              action="read"
              title={'Detail'}
              variant={'solid'}
              onClick={() => handleNavigate()}
            />
            {data?.column !== 'done' && (
              <Stack>
                <DeleteCardComponent cardId={data?.id} cardData={data}/>
              </Stack>
            )}
          </HStack>

          <Box>
            <Grid templateColumns={{ base: '1fr', md: '2fr 1.5fr' }} gap={3}>
              <Stack spacing={3}>
                <Input isReadOnly placeholder={data?.title === 'New Card' ? '' : data?.title} />

                <InputGroup>
                  <InputLeftAddon children={t('lastUpdate')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      data?.lastUpdated?.seconds
                        ? moment
                          .unix(data?.lastUpdated?.seconds)
                          .format('DD/MM/YYYY')
                        : '-'
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children={t('cretor')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      userDisplay?.find((x) => x.id === data.createdBy)?.name
                    }
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children={t('category')} fontWeight={500} />

                  <Input
                    placeholder={t('noCategory')}
                    isReadOnly
                    defaultValue={detailProps?.category}
                  />
                </InputGroup>

                {asignee?.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon
                      children={t('assigneeTo')}
                      fontWeight={500}
                    />
                    <HStack ml="2" gap="0">
                      {[...new Set(asignee)]?.sort()?.map((x, i) => {
                        const name = userDisplay?.find((y) => y.id === x)?.name;
                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar size="sm" name={name} src={name} />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                {pic.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon children={t('pic')} fontWeight={500} />
                    <HStack ml="1" gap="0">
                      {[...new Set(pic)]?.sort()?.map((x, i) => {
                        const name = userDisplay?.find((y) => y.id === x)?.name;

                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar size="sm" name={name} src={name} />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                <InputGroup>
                  <InputLeftAddon children="Label" fontWeight={500} />
                  <Select
                    defaultValue={data?.label}
                    placeholder="Label"
                    textTransform={'capitalize'}
                    onChange={(e) =>
                      handleSelectLabel(data?.id, e.target.value)
                    }
                  >
                    <option value="help">help</option>
                    <option value="priority">priority</option>
                    <option value="urgent">urgent</option>
                    <option value="pending">pending</option>
                    <option value="blocked">blocked</option>
                    {data?.time_backlog_at !== undefined && (
                      <option value="completed">completed</option>
                    )}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children={t('dueOn')} fontWeight={500} />
                  <Input
                    type="date"
                    isReadOnly
                    defaultValue={
                      data?.dueOn
                        ? moment.unix(data.dueOn).format('YYYY-MM-DD')
                        : moment(new Date()).format('yyyy-MM-DD')
                    }
                  />
                </InputGroup>
              </Stack>
              <Stack spacing={3}>
                <Stack alignItems="end" justifyContent="end" pt={3}>
                  <Text textAlign={'end'} fontWeight={800} fontSize="lg">
                    {t('score')} : {data?.score || 0}
                  </Text>
                </Stack>
                <Divider />

                <Stack>
                  <HStack>
                    <MdOutlineAccessTimeFilled />
                    <Text fontWeight={'bold'}>Time Duration: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.time_backlog_at?.seconds && (
                      <Text>Start : {moment(data?.time_backlog_at.seconds * 1000).format('LLL')}</Text>

                    )}

                    {data?.time_completed_at?.seconds && (
                      <Text>End : {moment(data?.time_completed_at.seconds * 1000).format('LLL')}</Text>

                    )}
                  </Stack>
                </Stack>

                <Divider />

                <Stack>
                  <HStack>
                    <Text fontWeight={'bold'}>KPI: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.kpi?.length > 0 ? (
                      <Stack>
                        {data?.kpi?.map((x, i) => {
                          return (
                            <Stack key={i}>
                              <HStack
                                spacing={3}
                                cursor="pointer"
                                onClick={() => navigate('/kpi/list')}
                              >
                                <Tooltip
                                  label={
                                    getUserByUid(globalState.users, x.uid).name
                                  }
                                  textTransform={'capitalize'}
                                  aria-label="Name"
                                >
                                  <Avatar
                                    src={
                                      getUserByUid(globalState.users, x.uid)
                                        .image
                                    }
                                    size={'sm'}
                                    name={
                                      getUserByUid(globalState.users, x.uid)
                                        .name
                                    }
                                  />
                                </Tooltip>
                                <Stack spacing={1}>
                                  <Text
                                    fontSize={'xs'}
                                    fontWeight={500}
                                    noOfLines={1}
                                  >
                                    {x?.title_kpi}
                                  </Text>
                                  <Text fontSize={'2xs'} noOfLines={1}>
                                    {t('time')}: {x?.title}
                                  </Text>
                                </Stack>

                                <Spacer />
                                <HStack>
                                  <Text fontWeight={500} letterSpacing={4}>
                                    ({formatFrice(x?.actual)}/
                                    {formatFrice(parseInt(x?.target_kpi))})
                                  </Text>
                                </HStack>
                              </HStack>
                              <Divider />
                            </Stack>
                          );
                        })}
                      </Stack>
                    ) : (
                      <Stack alignItems={'center'} justifyContent={'center'}>
                        <Text fontWeight={500} fontSize={'xs'}>
                          {' '}
                          {t('noKpi')}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Divider />
                <Stack>
                  <Text fontWeight={'bold'}>{t('thumbnail')}: </Text>

                  {data?.files?.length > 0 ? (
                    <SimpleGrid
                      h={'250px'}
                      overflow={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          w: '2',
                          h: '2',
                        },
                        '&::-webkit-scrollbar-track': {
                          w: '6',
                          h: '5',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '10',
                          bg: 'gray.200',
                        },
                      }}
                      py={3}
                      spacing={4}
                      columns={[2]}
                    >
                      {data?.files?.map((x, index) => {
                        const dataView =
                          x.type === 'image' ? (
                            <Image
                              src={x.link}
                              objectFit="contain"
                              w={'200px'}
                              borderRadius={'md'}
                              shadow="md"
                            />
                          ) : x.type === 'audio' ? (
                            <FcAudioFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : x.type === 'video' ? (
                            <FcVideoFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : (
                            <FcFolder
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          );
                        return (
                          <Stack
                            key={index}
                            borderWidth={1}
                            position="relative"
                            alignItems="center"
                            justifyContent={'center'}
                            borderRadius="md"
                            minH={'120px'}
                          >
                            <HStack
                              flexDirection={['column', 'row', 'row']}
                              position={'absolute'}
                              top={-2}
                              right={-2}
                              spacing={2}
                            >
                              <DynamicButton
                                action={'read'}
                                size="sm"
                                variant={'solid'}
                                onClick={() => window.open(x.link, '_blank')}
                              />
                              <DynamicButton
                                action={'delete'}
                                onClick={() => handleDelete(x)}
                                size="sm"
                                variant={'solid'}
                              />
                            </HStack>
                            <Stack overflow={'hidden'}>
                              {dataView && dataView}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </SimpleGrid>
                  ) : (
                    <Text>{t('noThumbnail')}</Text>
                  )}
                  {data?.files?.length > 2 && (
                    <Stack alignItems={'center'} justifyContent="center">
                      <BsFillArrowDownCircleFill />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Box>

          <Flex flexDirection={['column', 'row', 'row']}>
            <Box flex="1">
              {data?.id && (
                <TaskCardAddComment
                  id={data?.id}
                  title={data?.id}
                  type="mykanban"
                />
              )}
            </Box>
          </Flex>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ProductivityMyTaskViewModalPage;
