/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { FiUploadCloud } from 'react-icons/fi'
import { useState } from 'react'
import { Text } from '@chakra-ui/react';
export default function CardFile({ onChange, IsIcon }) {
  const [fileName, setFileName] = useState('Select file to import')

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    onChange(file)
  }
  return (
    <div className="card-file">
      <input type="file" className="file-input" onChange={onSelectFile} />
      {!IsIcon && (
        <FiUploadCloud size="50" />
      )}
      <Text fontSize={'sm'}>{fileName}</Text>
    </div>
  )
}

CardFile.defaultProps = {
  onChange: () => 'oke'
}