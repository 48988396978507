import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  Input,
  Stack,
  Center,
  VStack,
  Divider,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcDatabase, FcIdea } from 'react-icons/fc';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import CardMyKanban from '../../Components/Card/CardMyKanban';
import { useTranslation } from 'react-i18next';

function ProductivityKanbanPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { resetTaskData, resetFilterData } = useKanbanStore((state) => state);
  const [dataKanban, setDataKanban] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const globalState = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editor, setEditor] = useState([]);
  const [filterOwner, setFilterOwner] = useState(false);
  const param = useParams();

  const [modal, setModal] = useState({ delete: false, user: false });

  const itemsPerPage = 11;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    let conditions = [
      { field: 'type', operator: '==', value: 'kanban' },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        { field: 'type', operator: '==', value: 'kanban' },
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
      ];
    }

    globalState.setIsLoading(true);

    try {
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue
      );
      setDataKanban(res);

      const collectionRef = collection(db, 'files');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(
        collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
        where('type', '==', 'kanban'),
        where('users', 'array-contains', globalState.uid)
      );
      if (filterOwner === true) {
        countQuery = query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          where('type', '==', 'kanban')
        );
      }

      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    getData();
  }, [globalState?.currentProject, filterOwner, currentPage]);

  const createNewKanban = () => {

    if (
      globalState?.currentCompany === null || globalState?.currentCompany === undefined ||
      globalState?.currentProject === null || globalState?.currentProject === undefined
    ) {
      toast({
        title: t('toast.alert'),
        description: 'You must setup your company and project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const data = {
      title: title,
      type: 'kanban',
      default_score: 0,
      companyId: globalState?.currentCompany || globalState?.companies[0].id || '',
      projectId: globalState?.currentProject || globalState?.projects[0].id || '',
      lastUpdated: new Date(),
      users: [globalState?.uid],
      usersDisplay: [
        {
          id: auth?.currentUser?.uid,
          image: auth.currentUser?.photoURL,
          name: auth?.currentUser?.displayName,
          email: auth?.currentUser?.email,
        },
      ],
    };
    addDocumentFirebase('files', data, globalState.currentCompany).then((x) => {
      navigate(`view/${x}/${title}`);
      handleLogActivity(x, title);
    });
  };

  const handleLogActivity = async (id, title) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL,
      createdBy: auth?.currentUser?.uid,
      type: 'kanban',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Create',
      title: title,
      collection_path: 'files',
      collection_id: id,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNavigate = (x) => {
    navigate(`view/${x.id}/${x.title}`);
    resetTaskData();
    resetFilterData();
  };

  const handleNavigateMy = () => {
    navigate(`/${param.type}/view`);
    resetTaskData();
    resetFilterData();
  };

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersDisplay?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };
  return (
    <>
      <Stack spacing={8}>
        <HStack m="2" flexDirection={['column', 'row', 'row']}>
          <BackButtons />

          <Heading size="md" textTransform="capitalize">
            {t('typeView', { type: param?.type })}
          </Heading>

          <Spacer />

          <DynamicButton
            title={t('seeAll')}
            action={'read'}
            onClick={handleOpenFilter}
            variant={'solid'}
          />

          <DynamicButton
            title={t('addKanban')}
            action={'create'}
            variant={'solid'}
            onClick={onOpen}
          />
        </HStack>
        {/* {dataKanban?.length > 0 ? ( */}
        <SimpleGrid columns={[1, 2, 4]} gap="3">
          <CardMyKanban
            Icon={FcIdea}
            title={t('myKanban')}
            userArray={[{ name: globalState?.name, uid: globalState?.uid }]}
            globalState={globalState}
            onNavigate={() => handleNavigateMy()}
          />
          {dataKanban?.map((x, i) => (
            <Stack key={i}>
              <CardAvatar
                data={x}
                title={x.title}
                globalState={globalState}
                onNavigate={() => handleNavigate(x)}
                userArray={x?.usersDisplay}
                timeCreated={x?.lastUpdated?.seconds}
                modalTeam={() => modalTeam(x)}
                owner={x?.users}
                // onDelete={() => console.log(x)}
                Icon={FcDatabase}
              />
            </Stack>
          ))}
        </SimpleGrid>
        {/* ) : (
          <AlertArrayNull titleData={'kanban'} action="Add Kanban" />
        )} */}

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button
                colorScheme={'blue'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                {t('loadMore')}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('addKanban')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" onChange={(e) => setTitle(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewKanban()}
              title={t('save')}
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('team')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x?.name === data?.usersDisplay[0]?.name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              {t('creator')}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <Stack align={'flex-start'} spacing={0}>
                            <Text
                              fontWeight={500}
                              textTransform="capitalize"
                              fontSize={'sm'}
                            >
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          {data?.users?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action="delete"
                                size={'sm'}
                                // onClick={() => handleRemoveUser(x, data)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProductivityKanbanPage;
