import React from 'react';
import AutomationDetailPage from '../Pages/Automation/AutomationDetailPage';
import AutomationPage from '../Pages/Automation/AutomationPage';
import ManagementAssetsPage from '../Pages/Management/ManagementAssetsPage';
import ManagementPage from '../Pages/Management/ManagementPage';
import ManagementScorePage from '../Pages/Management/ManagementScorePage';
import ManagementScorePeople from '../Pages/Management/ManagementScorePeople';
import ManagementScoreTask from '../Pages/Management/ManagementScoreTask';
import ManagementScoreTaskDetail from '../Pages/Management/ManagementScoreTaskDetail';
import ManagementTaskPage from '../Pages/Management/ManagementTaskPage';
import ManagementToolsPage from '../Pages/Management/ManagementToolsPage';
import ManagementSop from '../Pages/Management/ManagementSop';

const ManagementRouter = [
  {
    path: '/management',
    element: <ManagementPage />,
  },
  {
    path: '/management/score',
    element: <ManagementScorePage />,
  },
  {
    path: '/management/task',
    element: <ManagementTaskPage />,
  },
  {
    path: '/management/sop',
    element: <ManagementSop />,
  },
  {
    path: '/management/tools',
    element: <ManagementToolsPage />,
  },
  {
    path: '/management/assets',
    element: <ManagementAssetsPage />,
  },
  {
    path: '/management/score/people',
    element: <ManagementScorePeople />,
  },
  {
    path: '/management/score/tasks',
    element: <ManagementScoreTask />,
  },

  {
    path: '/management/score/tasks/:id',
    element: <ManagementScoreTaskDetail />,
  },

  {
    path: '/management/automation',
    element: <ManagementScoreTaskDetail />,
  },

  {
    path: '/automation',
    element: <AutomationPage />,
  },

  {
    path: '/automation/:id',
    element: <AutomationDetailPage />,
  },
  
];

export default ManagementRouter;
