/* eslint-disable react/prop-types */
import React from 'react';
import {
  Text,
  AvatarGroup,
  Avatar,
  Tooltip,
  Stack,
  HStack,
  Spacer,
  Badge,
  Tag,
  useColorMode,
} from '@chakra-ui/react';
import { MdEmail, MdOutlineFlashOn } from 'react-icons/md';
import { FiTag } from 'react-icons/fi';
import SingleCalendarComponent from '../../Card/SingleCalendarComponent';
import moment from 'moment';
import useKanbanStore from '../../../Hooks/Zustand/kanbanStore';

const TooltipComponent = ({ task }) => {
  const { colorMode } = useColorMode();
  const { userDisplay } = useKanbanStore();

  return (
    <Stack
      spacing={1}
      bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}
      p={2}
      w={'200px'}
      rounded={'md'}
      border={'1px'}
      borderColor={'gray.300'}
    >
      <HStack>
        {task?.data.category && (
          <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
            {task?.data.category}
          </Tag>
        )}

        {task?.data.automationId && (
          <Tag colorScheme={'yellow'} size="sm" fontSize={'xs'}>
            <MdOutlineFlashOn />
          </Tag>
        )}
        {task?.data.email_status && (
          <Tag colorScheme={'red'} size="sm" fontSize={'xs'}>
            <MdEmail />
          </Tag>
        )}
      </HStack>
      <Text fontWeight="semibold" style={{ wordWrap: 'break-word' }}>
        {task.data.title}
      </Text>

      {task.data.label ? (
        <Badge
          w={'fit-content'}
          colorScheme={
            task.data.label === 'help'
              ? 'yellow'
              : task.data.label === 'pending'
                ? 'gray'
                : task.data.label === 'blocked'
                  ? 'red'
                  : task.data.label === 'completed'
                    ? 'green'
                    : task.data.label === 'priority'
                      ? 'blue'
                      : 'orange'
          }
        >
          <HStack>
            <FiTag />
            <Text>{task.data.label}</Text>
          </HStack>
        </Badge>
      ) : (
        <></>
      )}
      <HStack>
        {task.data.dueOn ? (
          <SingleCalendarComponent
            data={task?.data.dueOn}
            date={moment.unix(task?.data.dueOn).format('DD')}
            month={moment.unix(task?.data.dueOn).format('MMM')}
          />
        ) : (
          <></>
        )}
        <Stack
          w="30px"
          borderRadius={'full'}
          h="30px"
          bgColor={task?.data.score > 0 ? 'blue.400' : 'red.400'}
          alignItems={'center'}
          justifyContent="center"
        >
          <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
            {task?.data.score || 0}
          </Text>
        </Stack>
        {task?.data.createdBy ? (
          <Tooltip
            label={
              userDisplay?.find((z) => z.id === task?.data.createdBy)?.name ||
              task?.data.createdBy
            }
            aria-label="Name"
          >
            {task?.data?.createdBy === 'Deoapp Automation' ? (
              <Stack
                w="30px"
                borderRadius={'full'}
                h="30px"
                bgColor={'yellow.400'}
                alignItems={'center'}
                justifyContent="center"
              >
                <MdOutlineFlashOn />
              </Stack>
            ) : (
              <Avatar
                m="0"
                size="sm"
                name={
                  userDisplay?.find((z) => z.id === task?.data?.createdBy)
                    ?.name || task?.data?.createdBy
                }
                image={
                  userDisplay?.find((x) => x.id === task?.data?.createdBy)
                    ?.image
                }
              />
            )}
          </Tooltip>
        ) : (
          <></>
        )}
        <Spacer />
        <AvatarGroup size="sm" max={1}>
          {task?.data.asignee ? (
            task?.data.asignee?.sort()?.map((x, i) => {
              const name = userDisplay?.find(
                (y) => y.id === x
              )?.name;
              return (
                <Tooltip key={i} label={name} aria-label="Name">
                  <Avatar size="sm" name={name} />
                </Tooltip>
              );
            })
          ) : (
            <></>
          )}
        </AvatarGroup>
      </HStack>
      <Stack>
        {task?.data.lastUpdated?.seconds ? (
          <Text textAlign="left" fontSize="2xs">
            Updated: {moment.unix(task?.data.lastUpdated?.seconds).fromNow()}
          </Text>
        ) : (
          <></>
        )}
        <Text fontSize="3xs">ID: {task.data.id}</Text>
      </Stack>
    </Stack>
  );
};

export default TooltipComponent;
