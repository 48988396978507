/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import { Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  useDisclosure,
  Avatar,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropdownCircle,
  IoMdSettings,
} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../Config/firebase';

import { data } from './DataMenu';
import useUserStore from '../../Hooks/Zustand/Store';
import { signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../Api/firebaseApi';
import { logoutUserWithIp } from '../../Hooks/Middleware/sessionMiddleWare';
import { encryptToken } from '../../Utils/encrypToken';
import { AiFillAppstore, AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai';
import { HiBell } from 'react-icons/hi';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import SelectCompanyProject from '../Select/SelectCompanyProject';
import { MdLightMode, MdDarkMode } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// import { anggaranApi } from '../../Api/Anggaran';
// import { ApiAccountingToken } from '../../Api/ApiAccountingToken';

// ** Theme Configuration

function SidebarComponentV3({ layout }) {
  const [menu, setMenu] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
  const [desktopShow, setDesktopShow] = useState(true);
  const [hidden, setHidden] = useState(!isOpen);
  const [countNotif, setCountNotif] = useState({notifUser:1, notifFeature:1});
  const { colorMode, toggleColorMode } = useColorMode();

  const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

  const [currentLanguage, setCurrentLanguage] = useState('en');
  const { i18n, t } = useTranslation();

  const globalState = useUserStore();

  const uid = globalState?.uid;

  const encryptUid = encryptToken(uid);
  const encryptFix = encodeURIComponent(encryptUid);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevIsVisible) => !prevIsVisible);
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const user = auth.currentUser;

  const navigate = useNavigate();

  const toast = useToast();

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'id' : 'en';
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleSelectMenu = (value) => {
    setMenu(value);

    if (value.status) {
      null;
    } else {
      if (value?.link?.includes('https')) {
        window.open(value?.link, '_blank');
      } else {
        navigate(value.link);
      }
    }
  };

  const logout = async () => {
    const pathLink = 'business';

    await logoutUserWithIp(
      window.location.hostname,
      globalState?.email,
      pathLink
    );

    signOut(auth)
      .then(() => {
        // Sign-out successful.

        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/');
        localStorage.clear();
        globalState.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const getRole = async () => {
    const conditions = [
      { field: 'users', operator: 'array-contains', value: globalState?.uid },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 10;
    const startAfterData = null;

    try {
      const res = await getCollectionFirebase(
        `companies/${globalState?.currentCompany}/roles`,
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );

      if (res[0]?.id) {
        const result = await getSingleDocumentFirebase('files', res[0]?.id);

        globalState.setRole(result);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      globalState.setRole({});
    }
  };

  const getCountNotification = async () => {
    const collectionRef = collection(db, 'notification');
    const snapshot = await getCountFromServer(
      query(
        collectionRef,
        where('userId', 'array-contains', globalState?.uid),
        where('companyId', '==', globalState?.currentCompany),
        where('onSee', '==', false)
      )
    );
    const userNotifLength = snapshot.data().count;
    setCountNotif((prev) => ({...prev, notifUser: userNotifLength}));

    if (globalState?.currentProject === '8NCG4Qw0xVbNR6JCcJw1'){
      const collectionRefFeature = collection(db, 'kanban');
      const resFeature = await getCountFromServer(
        query(
          collectionRefFeature,
          where('projectId', '==', '8NCG4Qw0xVbNR6JCcJw1'),
          where('column', '==', 'done'),
          where('label', '==', 'completed'),
          where('category', '==', 'Feature'),
          where('createdAt', '>=', moment().startOf('week').toDate()),
          where('createdAt', '<=', moment().endOf('week').toDate()),
        )
      );
      const featureNotifLength = resFeature.data().count;
      setCountNotif((prev) => ({...prev, notifFeature: featureNotifLength}));
    }
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getRole();
      getCountNotification();
    }

    return () => { };
  }, [globalState?.currentCompany]);

  const handleNavigate = (value) => {
    if (value?.link?.includes('https')) {
      window.open(`${value.link}?id=${encryptFix}`, '_blank');
    } else {
      navigate(value.link);
    }
  };

  const handleClick = () => {
    setDesktopShow(!desktopShow);
  };

  const height = window?.innerHeight;

  if (layout.type === 'vertical' || layout.type === 'vertical-horizontal')
    return (
      <HStack spacing={'-1'}>
        <Box
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}
          width={desktopShow ? '100px' : '15px'}
          transition={'0.2s ease-in-out'}
          shadow={'xl'}
          roundedBottomRight={'lg'}
          roundedTopRight={'lg'}
          h={height}
          overflowY={'auto'}
        // backgroundColor={themeConfig.color.colorFirst}
        >
          <Box position="sticky">
            <Stack
              position={'absolute'}
              right={-2}
              top={5}
              cursor={'pointer'}
              onClick={handleClick}
              alignItems="flex-end"
              justifyContent={'flex-end'}
              p={2}
              borderRadius="full"
              bgColor={colorMode === 'light' ? 'blue.200' : 'blue.600'}
              zIndex={500}
            >
              {desktopShow ? (
                <AiOutlineMobile size={17} />
              ) : (
                <AiOutlineDesktop size={17} />
              )}
            </Stack>

            <Stack
              {...getButtonProps()}
              position={'absolute'}
              right={-1}
              top={300}
              py={[5, null, null]}
              bg={colorMode === 'light' ? 'blue.300' : 'blue.600'}
              minH={50}
              // overflowY="scroll"
              borderRadius={'md'}
              zIndex={500}
              cursor={'pointer'}
              p={1}
              alignItems={'center'}
              justify={'center'}
            >
              {isOpen ? (
                <IoIosArrowBack size={18} color="white" />
              ) : (
                <IoIosArrowForward size={18} color="white" />
              )}
            </Stack>

            <Flex as="section" >
              <Stack display={desktopShow ? 'flex' : 'none'} spacing={2}>
                <Box onClick={() => navigate('/')} cursor={'pointer'} bgColor={'white'} p={2} >
                  <Image src={'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png'} />
                </Box>

                <Box px={2}>
                  <Divider />
                </Box>

                <HStack
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={1}
                >
                  {colorMode === 'light' ? (
                    <MdDarkMode color="gray" />
                  ) : (
                    <MdLightMode color="yellow" />
                  )}
                  <Switch
                    size="sm"
                    colorScheme={colorMode === 'light' ? 'gray' : 'teal'} // Sesuaikan dengan warna yang sesuai untuk dark mode
                    onChange={toggleColorMode}
                  />
                  <Box fontSize={'sm'} fontWeight={500} cursor={'pointer'} onClick={toggleLanguage}>
                    {currentLanguage === 'en' ? 'ID' : 'EN'}
                  </Box>
                </HStack>

                <SelectCompanyProject
                  globalStateProps={globalState}
                  moduleProps={'management'}
                />

                <Stack
                  height={['40vh', '50vh', '50vh']}
                  overflowY="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {data.map((menu, i) => (
                    <Stack
                      key={i}
                      pt={2}
                      spacing={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() => handleSelectMenu(menu)}
                      cursor={'pointer'}
                      position="relative"
                    >
                      <Icon as={menu.icon} boxSize={6} />
                      <Text fontSize={10} textTransform={'capitalize'}>
                        {t(menu.name)}
                      </Text>
                      {menu.status ? (
                        <Stack
                          size={'xs'}
                          position="absolute"
                          bgColor={'red'}
                          borderRadius="md"
                          visibility={isVisible ? 'visible' : 'hidden'}
                          top={2}
                        >
                          <Text
                            color={'white'}
                            fontSize={'xx-small'}
                            fontWeight={500}
                            py={'0.5'}
                            px={1}
                          >
                            Coming Soon
                          </Text>
                        </Stack>
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
                {data.lenght > 7 && (
                  <Stack alignItems={'center'} justifyContent="center">
                    <IoIosArrowDropdownCircle />
                  </Stack>
                )}

                <Spacer />

                <Stack spacing={3}>
                  {globalState?.currentProject === '8NCG4Qw0xVbNR6JCcJw1' && <Stack
                    align={'center'}
                    onClick={() => navigate('/newfeature')}
                    cursor={'pointer'}
                    position="relative"
                    mb={2}
                  >
                    <Icon as={AiFillAppstore} boxSize={6} color="subtle" />
                    <Stack
                      bottom={5}
                      right={5}
                      p={1}
                      position="absolute"
                      alignItems={'center'}
                      justifyContent={'center'}
                      w={['20px', '22px', '25px']}
                      h={['20px', '22px', '25px']}
                      borderRadius="full"
                      bgColor={'red'}
                      color={'white'}
                    >
                      <Text fontSize={['2xs', '2xs', 'xs']} fontWeight={'bold'}>
                        {countNotif?.notifFeature}
                      </Text>
                    </Stack>
                  </Stack>}
                  
                  <Stack
                    align={'center'}
                    onClick={() => navigate('/notification')}
                    cursor={'pointer'}
                    position="relative"
                  >
                    <Icon as={HiBell} boxSize={6} color="subtle" />
                    <Stack
                      bottom={5}
                      right={5}
                      p={1}
                      position="absolute"
                      alignItems={'center'}
                      justifyContent={'center'}
                      w={['20px', '22px', '25px']}
                      h={['20px', '22px', '25px']}
                      borderRadius="full"
                      bgColor={'red'}
                      color={'white'}
                    >
                      <Text fontSize={['2xs', '2xs', 'xs']} fontWeight={'bold'}>
                        {countNotif?.notifUser}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack
                    align={'center'}
                    onClick={() => navigate('/settings')}
                    cursor={'pointer'}
                  >
                    <Icon as={IoMdSettings} boxSize={6} color="subtle" />
                  </Stack>


                  {/* </Button> */}
                </Stack>

                <Box display="flex" flexDir="column" >
                  <Center py={2}>
                    <Avatar
                      boxSize={10}
                      name={
                        user?.displayName ||
                        `https://api.dicebear.com/7.x/adventurer/svg?seed=${user.email}`
                      }
                      src={
                        user?.photoURL === null ||
                          user?.photoURL?.includes('example.com')
                          ? `https://api.dicebear.com/7.x/adventurer/svg?seed=${user.email}`
                          : user?.photoURL
                      }
                    />
                  </Center>

                  <Box align={'center'}>
                    <Button size={'xs'} colorScheme="red" onClick={logout}>
                      {t('logout')}
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Flex>
          </Box>
        </Box>

        <motion.div
          {...getDisclosureProps()}
          hidden={hidden}
          initial={false}
          onAnimationStart={menu?.submenu ? () => setHidden(false) : null}
          onAnimationComplete={menu?.submenu ? () => setHidden(!isOpen) : null}
          animate={menu?.submenu ? { width: isOpen ? 200 : 0 } : 0}
          style={{
            borderStartEndRadius: 20,
            borderEndEndRadius: 20,
            // background: '#f5f5f5',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // position: "absolute",
            // left: "0",
            height: '100vh',
            // top: "0",
          }}
        >
          <Stack
            p={3}
            h={'100vh'}
            bgColor={colorMode === 'light' ? '#f5f5f5' : 'gray.700'}
          >
            <Text
              color={'gray.600'}
              my={3}
              fontWeight={'semibold'}
              align={'center'}
              textTransform="uppercase"
            >
              Business Deoapp
            </Text>
            <Box>
              <Divider />
            </Box>
            <Stack spacing={3} pt={3}>
              <Accordion allowToggle>
                {menu.submenu?.map((sub, i) => (
                  <AccordionItem
                    key={i}
                  // isDisabled={x.name === "Social Media" ? true : false}
                  >
                    <h2>
                      <AccordionButton w={'100%'}>
                        <HStack spacing={2} w={'100%'}>
                          <Icon as={sub.icon} boxSize={isDesktop ? 5 : 7} />
                          <Text
                            fontWeight={500}
                            fontSize={isDesktop ? 'sm' : 'xs'}
                            noOfLines={1}
                          >
                            {sub.name}
                          </Text>
                          <Spacer />
                          <AccordionIcon />
                        </HStack>
                      </AccordionButton>
                    </h2>
                    {sub.submenu ? (
                      <>
                        <AccordionPanel>
                          <Stack>
                            {sub.submenu?.map((subitem, i) => {
                              return (
                                <Box
                                  cursor={'pointer'}
                                  onClick={() => handleNavigate(subitem)}
                                  key={i}
                                >
                                  <HStack spacing="3">
                                    <Icon as={subitem.icon} boxSize={5} />
                                    <Text
                                      pl={3}
                                      fontWeight={300}
                                      fontSize={isDesktop ? 'sm' : 'xs'}
                                      noOfLines={1}
                                    >
                                      {subitem.name}
                                    </Text>
                                  </HStack>
                                  <Divider py={1} />
                                </Box>
                              );
                            })}
                          </Stack>
                        </AccordionPanel>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
            <Spacer />
            <Stack spacing={0} align={'center'} color={'gray.500'}>
              <Text fontSize={'xs'}>{globalState?.name}</Text>
              <Text fontSize={'xs'}>{globalState?.email}</Text>
            </Stack>
            <Divider />
            <Text
              color={'gray.500'}
              align={'center'}
              fontSize={12}
              fontWeight={'semibold'}
            >
              Business Deoapp
            </Text>
          </Stack>
        </motion.div>
      </HStack>
    );

  return <></>;
}

export default SidebarComponentV3;
