import React, { useEffect, useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Input,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  deleteSubcollection,
  getCollectionFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  updateDoc,
} from '@firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { clientTypessense } from '../../Api/Typesense';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FcWorkflow } from 'react-icons/fc';
import moment from 'moment';
import { DeleteIcon } from '@chakra-ui/icons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

const ProductivityFlowchartPage = () => {
  const {t} = useTranslation()
  const type = 'flowchart';
  const link = '/flowchart';
  const [flowchart, setFlowchart] = useState([]);
  const addFlowchart = useDisclosure();
  const alertDelete = useDisclosure();
  const [selectedFlowchart, setSelectedFlowchart] = useState(null);
  const navigate = useNavigate();
  const globalState = useUserStore();
  const [modal, setModal] = useState({ delete: false, user: false });
  const [editor, setEditor] = useState([]);
  const [data, setData] = useState();
  const toast = useToast();

  const [searchInput, setSearchInput] = useState('');
  const [dataFlowChartSearch, setDataFlowChartSearch] = useState([]);

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;

  const getFlowChartV2 = async () => {
    if (globalState?.currentCompany) {
      const conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState?.currentCompany,
        },
        { field: 'type', operator: '==', value: 'flowchart' },
        { field: 'users', operator: 'array-contains', value: globalState?.uid },
      ];

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      try {
        globalState?.setIsLoading(true);
        const resAssets = await getCollectionFirebase(
          'flowcharts',
          conditions,
          sortBy,
          limitValue
        );
        const projectDataArray = await Promise.all(
          resAssets?.map(async (projectData) => {
            const userSnapshot = await getDocs(
              collection(db, `flowcharts/${projectData?.id}/users`)
            );
            const userData = userSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            projectData.usersProjectData = userData;
            return projectData;
          })
        );

        setFlowchart(projectDataArray.length > 0 ? projectDataArray : []);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        globalState?.setIsLoading(false);
      }
    }
  };

  const handleLogActivity = async (x) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Flowcharts',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Delete Flowchart',
      collection_path: 'flowchart',
      title: x.title,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteFlowchart = async () => {
    const resSubCollection = await deleteSubcollection(
      `flowcharts/${selectedFlowchart?.id}/users`
    );
    if (resSubCollection) {
      const res = await deleteDocumentFirebase(
        'flowcharts',
        selectedFlowchart?.id
      );
      if (res) {
        handleLogActivity(selectedFlowchart);
        setSelectedFlowchart(null);
        setModal({ ...modal, delete: false });
        toast({
          title: 'Deoapp.com',
          description: res,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        alertDelete?.onClose();
        getFlowChartV2();
      }
    }
  };

  const modalTeam = (team) => {
    setData(team);

    const detail = team?.usersProjectData?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  const handleRemoveUser = async (x) => {
    try {
      await deleteDocumentFirebase(`flowcharts/${data.id}/users`, x.id);

      const dataRef = doc(db, 'flowcharts', data?.id);
      await updateDoc(dataRef, { owner: arrayRemove(x.id) });
      await updateDoc(dataRef, { users: arrayRemove(x.id) });
      setModal({ ...modal, user: false });
      toast({
        title: 'Deleted',
        description: 'User Deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      getFlowChartV2();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `type: flowchart && companyId:${globalState.currentCompany} && users:${globalState.uid}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('flowcharts')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setDataFlowChartSearch(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getFlowChartV2();
    }
    return () => {
      setDataFlowChartSearch([]);
      // setFlowchart([])
      setEditor([]);
      setSelectedFlowchart(null);
    };
  }, [globalState?.currentCompany]);

  const [input, setInput] = useState('');

  const handleClick = async () => {
    // setLoading(true)
    const initialNodes = [
      {
        id: '1',
        type: 'shape',
        position: { x: 0, y: 0 },
        data: { shape: 'round-rect', width: 150, height: 75, color: null },
      },
      {
        id: '2',
        type: 'shape',
        data: { shape: 'circle', width: 70, height: 70, color: null },
        position: { x: 0, y: 200 },
      },
    ];
    const initialEdges = [
      {
        id: 'reactflow__edge-1-2',
        source: '1',
        target: '2',
        sourceHandle: 'bottom',
        targetHandle: 'top',
      },
      {
        id: 'reactflow__edge-2-3',
        source: '2',
        target: '3',
        sourceHandle: 'bottom',
        targetHandle: 'top',
      },
    ];
    addDocumentFirebase(
      'flowcharts',
      {
        owner: [globalState?.uid],
        users: [globalState?.uid],
        nodes: initialNodes,
        edges: initialEdges,
        title: input,
        type: type,
        projectId: globalState?.currentProject,
      },
      globalState?.currentCompany
    )
      .then((res) => {
        handleLogActivityNew(input);
        setDocumentFirebase(`flowcharts/${res}/users`, globalState?.uid, {
          name: globalState.name,
          uid: globalState.uid,
        })
          .then(() => {
            navigate(`${link}/${res}`);
          })
          .catch((error) => {
            toast({
              title: 'Deoapp.com',
              duration: 3000,
              description: error.message,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  const handleLogActivityNew = async (input) => {
    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Task',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Add New Flowchart',
      collection_path: 'flowchart',
      title: input,
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} textTransform="capitalize">
          {t('Flow Chart')}
        </Heading>
        <Spacer />
        <Stack>
          <Input
            placeholder={t('search')}
            shadow="md"
            size={'md'}
            borderRadius="md"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Stack>
        <DynamicButton
          action={'create'}
          onClick={() => addFlowchart.onOpen()}
          title={t('createFlowchart')}
          variant={'solid'}
        />
        {/* <AddButtonComponent type={"flowchart"} link={"/flowchart"} /> */}
      </HStack>
      {searchInput !== '' ? (
        <SimpleGrid columns={[1, 2, 4]} gap={5} mt="5">
          {dataFlowChartSearch?.length > 0 &&
            dataFlowChartSearch?.map((item, index) => (
              <Flex
                key={index}
                justify={'space-between'}
                borderRadius={'md'}
                alignItems={'flex-start'}
                borderWidth={1}
                bgColor="white"
                shadow={'md'}
                borderColor="blackAlpha.300"
                cursor="pointer"
                w="100%"
              >
                <VStack
                  align={'flex-start'}
                  w={'full'}
                  h={'full'}
                  justify={'space-between'}
                >
                  <HStack spacing={3} px={4} pt={4} w="100%">
                    <Stack
                      onClick={() => navigate(`/flowchart/${item?.id}`)}
                      cursor="pointer"
                    >
                      <FcWorkflow size={50} />
                    </Stack>
                    <Stack w="100%">
                      <Text
                        zIndex={100}
                        onClick={() => navigate(`/flowchart/${item?.id}`)}
                        cursor="pointer"
                        textTransform={'capitalize'}
                        color="blackAlpha.800"
                        noOfLines={2}
                        fontSize={'sm'}
                        fontWeight={'medium'}
                      >
                        {item.title}
                      </Text>
                      <AvatarGroup
                        size="sm"
                        mx={10}
                        pb={4}
                        pr={4}
                        cursor="pointer"
                        onClick={() => modalTeam(item)}
                      >
                        {item?.usersProjectData?.map((z, i) => (
                          <Avatar name={z.name} src={z.name} key={i} />
                        ))}
                      </AvatarGroup>
                    </Stack>
                  </HStack>
                  <Text spacing={3} px={4} pb={4} w="85%" fontSize={'2xs'}>
                    {`${moment(item?.createdAt * 1000).format('llll')}`}{' '}
                  </Text>
                </VStack>
                {item?.owner?.includes(globalState?.uid) && (
                  <Box pt={4} w="15%">
                    <DeleteIcon
                      cursor={'pointer'}
                      onClick={() => {
                        handleOpenAlertDelete();
                        setSelectedFlowchart(item);
                      }}
                    />
                  </Box>
                )}
              </Flex>
            ))}
        </SimpleGrid>
      ) : (
        <Stack>
          {flowchart?.length > 0 ? (
            <SimpleGrid columns={[1, 2, 4]} gap={5} mt="5">
              {flowchart?.length > 0 &&
                flowchart?.map((item, index) => (
                  <Stack key={index}>
                    <CardAvatar
                      data={item}
                      title={item.title}
                      globalState={globalState}
                      onNavigate={() => navigate(`/flowchart/${item?.id}`)}
                      userArray={item?.usersProjectData}
                      timeCreated={item?.lastUpdated?.seconds}
                      modalTeam={() => modalTeam(item)}
                      owner={item?.owner}
                      onDelete={() => {
                        handleOpenAlertDelete();
                        setSelectedFlowchart(item);
                      }}
                      Icon={FcWorkflow}
                    />
                  </Stack>
                ))}
            </SimpleGrid>
          ) : (
            <AlertArrayNull titleData={'Flowchart'} action="Add Flowchart" />
          )}
        </Stack>
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {flowchart?.length >= itemsPerPage &&
            flowchart?.length > startIndex && (
            <Button
              onClick={handleLoadMore}
              colorScheme={'blue'}
              fontSize="sm"
            >
              {t('loadMore')}
            </Button>
          )}
        </Box>
      </Stack>

      <AlertDialogV2
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteFlowchart', { title: selectedFlowchart?.title })}
        onAction={handleDeleteFlowchart}
      />

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('team')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Text fontSize={'xs'}>
                    {t('createdBy')} : {data?.usersProjectData[0].name}
                  </Text>
                  <Divider />
                </VStack>
              </Center>

              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x.name === data?.usersProjectData[0].name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x.name}
                            </Text>
                            <Text fontSize={'xs'}>{x.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              {t('creator')}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x.name}
                            </Text>
                            <Text fontSize={'xs'}>{x.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          {data?.owner?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action={'delete'}
                                size="sm"
                                title={t('deleteUser')}
                                onClick={() => handleRemoveUser(x)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={addFlowchart.isOpen} onClose={addFlowchart.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('addFlowchart')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder={t('titleFlowchart')}
              onChange={(e) => setInput(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              variant={'solid'}
              title={t('addFlowchart')}
              action={'create'}
              onClick={() => handleClick()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ProductivityFlowchartPage;
