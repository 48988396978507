/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { MdOutlineFlashOn } from 'react-icons/md';
import DynamicButton from '../Buttons/DynamicButton';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import CustomStepper from '../Stepper/CustomStepper';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  addDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import KanbanViewOtomation from '../Otomation/KanbanViewOtomation';
import { useTranslation } from 'react-i18next';

function AutomationSet({ setTypeFile, fileData, dataSet }) {
  const { t } = useTranslation();
  const [modalOtomation, setModalOtomation] = useState(false);
  const globalState = useUserStore();
  const [successOrder, setSuccessOrder] = useState(false);

  const [step, setStep] = useState(0);
  const [currentStep, setCurrentStep] = useState('');
  const [steps] = useState([]);
  const toast = useToast();
  const params = useParams();

  const [stateAction, setStateAction] = useState('');
  const [stateDay, setStateDay] = useState([]);
  const [stateTime, setStateTime] = useState('');
  const [stateUntil, setStateUntil] = useState('');
  const [stateToId, setStateToId] = useState([]);
  const [stateTo, setStateTo] = useState('');

  const dayString = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const handleOpenOtomation = () => {
    setModalOtomation(true);
  };

  const handleCloseOtomation = () => {
    setModalOtomation(false);
  };

  const handleChecklist = (action) => {
    setStateAction(action);
  };

  const handleCheckedData = (index, isChecked) => {
    const newData = [...stateDay];
    if (isChecked) {
      newData.push(dayString[index]);
    } else {
      const dataIndex = newData.findIndex((item) => item === dayString[index]);
      if (dataIndex !== -1) {
        newData.splice(dataIndex, 1);
      }
    }
    setStateDay(newData);
  };

  const handleChecklistTo = (action) => {
    setStateTo(action);
    setTypeFile(action)
  };

  const handleSave = async () => {
    const dataOtomation = {
      dataAutomation: dataSet,
      action: stateAction,
      dayAction: stateDay,
      timeAction: stateTime,
      untilAction: moment(stateUntil).unix(),
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      fileToId: stateToId,
      fileTo: stateTo,
      type: 'kanban',
      typeId: params.taskId,
      isActive: true,
    };

    const collectionName = 'automation';
    const collectionNameUpdate = 'kanban';
    const docName = params.taskId;
    const data = { automationId: null };
    globalState.setIsLoading(true);
    try {
      const docID = await addDocumentFirebase(
        collectionName,
        dataOtomation,
        globalState.currentCompany
      );

      if (docID) {
        data.automationId = docID;
        await updateDocumentFirebase(collectionNameUpdate, docName, data);

        toast({
          title: t('toast.success'),
          description: t('toast.automation'),
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      const errorMessage = error.message || 'Terjadi kesalahan';
      toast({
        title: 'Error!',
        description: errorMessage,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setStateDay([]);
      setStateAction('');
      setStateUntil('');
      setStateTime('');
      setStateToId([]);
      setStateTo('');
      globalState.setIsLoading(false);
    }
  };

  const handleDone = () => {
    setStep((prev) => prev + 1);
    setCurrentStep('SUCCESS');
    handleSave();
    setSuccessOrder(true);
  };

  return (
    <Stack>
      <DynamicButton
        title={'Automations'}
        action="custom"
        icon={MdOutlineFlashOn}
        color="yellow"
        variant={'solid'}
        onClick={() => handleOpenOtomation()}
      />

      <Modal
        size="2xl"
        transition={'all 0.3s ease-in-out'}
        isOpen={modalOtomation}
        onClose={handleCloseOtomation}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Automation Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack padding={5} spacing={5}>
              <Stack p={5} borderRadius="md" shadow={'md'}>
                <CustomStepper steps={steps} currentStep={currentStep} />
              </Stack>

              {step === 0 && (
                <Box>
                  <Text fontWeight={600} fontSize={17} textAlign="center">
                    Klik Mulai untuk mengatur otomatisasi.
                  </Text>

                  <Button
                    width="100%"
                    colorScheme="green"
                    mt={10}
                    onClick={() => {
                      {
                        setStep((prev) => prev + 1);
                        setCurrentStep('FROM');
                      }
                    }}
                  >
                    Mulai
                  </Button>
                </Box>
              )}

              {step === 1 && (
                <Box>
                  <Stack pt={4} spacing={5}>
                    <Text fontWeight={600} fontSize={17} textAlign="center">
                      Apakah Anda ingin mengatur data ini dan sudah memastikan
                      bahwa data tersebut sudah diperbarui ?
                    </Text>

                    <Stack>
                      <Text fontWeight={500}>
                        Card Title : {dataSet?.title}
                      </Text>
                      <Text fontWeight={500}>Columns : {dataSet?.column} </Text>
                      <Text fontWeight={500}>Score : {dataSet?.score}</Text>
                    </Stack>
                  </Stack>

                  <HStack>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          setStep((prev) => prev - 1);
                          setCurrentStep('');
                        }
                      }}
                    >
                      Kembali
                    </Button>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          dataSet.title === '' || dataSet === ''
                            ? toast({
                              title: t('warning'),
                              description: t('toast.fieldRequired'),
                              status: 'warning',
                            })
                            : setStep((prev) => prev + 1);
                          setCurrentStep('ACTION');
                        }
                      }}
                    >
                      Selanjutnya
                    </Button>
                  </HStack>
                </Box>
              )}

              {step === 2 && (
                <Box>
                  <Stack pt={4} spacing={5}>
                    <Text fontWeight={600} fontSize={17} textAlign="center">
                      Pilih Tindakan yang Anda inginkan ?
                    </Text>
                  </Stack>
                  <Stack>
                    <HStack>
                      <Checkbox
                        isChecked={stateAction === 'repeat'}
                        onChange={() => handleChecklist('repeat')}
                      />
                      <Text>Repeat</Text>
                    </HStack>
                    <Divider />
                    <Stack pt={4} spacing={5}>
                      <Text fontWeight={600} fontSize={17} textAlign="center">
                        Pilih waktu yang ingin Anda atur ?
                      </Text>
                      <Input
                        type={'time'}
                        onChange={(e) => setStateTime(e.target.value)}
                      />
                      <Stack>
                        {dayString?.map((x, index) => {
                          const isChecked = stateDay.some((item) => item === x);

                          return (
                            <Stack key={index} spacing={1}>
                              <HStack key={index}>
                                <Checkbox
                                  onChange={(e) =>
                                    handleCheckedData(index, e.target.checked)
                                  }
                                  isChecked={isChecked}
                                />
                                <Text textTransform={'capitalize'}>{x}</Text>
                              </HStack>
                              <Divider />
                            </Stack>
                          );
                        })}
                      </Stack>
                      <Stack>
                        <Text fontWeight={600} fontSize={17} textAlign="center">
                          Tanggal berakhir ?
                        </Text>
                        <Input
                          type={'date'}
                          onChange={(e) => setStateUntil(e.target.value)}
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  <HStack>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          setStep((prev) => prev - 1);
                          setCurrentStep('ACTION');
                        }
                      }}
                    >
                      Kembali
                    </Button>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          stateAction === '' ||
                          stateDay?.length === 0 ||
                          stateUntil === ''
                            ? toast({
                              title: t('warning'),
                              description: t('toast.fieldRequired'),
                              status: 'warning',
                            })
                            : setStep((prev) => prev + 1),
                          setCurrentStep('TO');
                        }
                      }}
                    >
                      Selanjutnya
                    </Button>
                  </HStack>
                </Box>
              )}

              {step === 3 && (
                <Box>
                  <Stack pt={4} spacing={5}>
                    <Text fontWeight={600} fontSize={17} textAlign="center">
                      Pilih file yang ingin kamu kirimkan data-nya ?
                    </Text>

                    <HStack>
                      <Checkbox
                        isChecked={stateTo === 'kanban'}
                        onChange={() => handleChecklistTo('kanban')}
                      />
                      <Text>Kanban</Text>
                    </HStack>
                    <HStack>
                      <Checkbox
                        isChecked={stateTo === 'operations'}
                        onChange={() => handleChecklistTo('operations')}
                      />
                      <Text>Operations</Text>
                    </HStack>
                    <Divider />

                    <Stack>
                      <KanbanViewOtomation
                        stateTo={stateToId}
                        setStateTo={setStateToId}
                        fileData={fileData}
                      />
                    </Stack>
                  </Stack>

                  <HStack>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          setStep((prev) => prev - 1);
                          setCurrentStep('ACTION');
                        }
                      }}
                    >
                      Kembali
                    </Button>
                    <Button
                      width="100%"
                      colorScheme="green"
                      mt={10}
                      onClick={() => {
                        {
                          stateToId?.length === 0 || stateTo === ''
                            ? toast({
                              title: t('warning'),
                              description: t('toast.fieldRequired'),
                              status: 'warning',
                            })
                            : handleDone();
                        }
                      }}
                    >
                      Selesai
                    </Button>
                  </HStack>
                </Box>
              )}

              {successOrder && (
                <Stack>
                  <Text>selesai</Text>
                </Stack>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default AutomationSet;
