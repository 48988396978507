/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  AspectRatio,
  Divider,
  Text,
  Stack,
  Image,
  InputGroup,
  InputLeftAddon,
  HStack,
  Tooltip,
  Avatar,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { FcFolder, FcVideoFile } from 'react-icons/fc';
import parse from 'html-react-parser';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';
function NewFeatureDetailsModal({ isOpen, onClose, data }) {
  const { t } = useTranslation();
  const globalState = useUserStore();
  const [dataFeature, setDataFeature] = useState({
    comment: { comments: '' },
    files: { usersDisplay: [] },
  });
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    setIsLoading(true)
    try {
      const resComment = await getCollectionFirebase(
        `kanban/${data?.id}/comments`,
        [
          {
            field: 'companyId',
            operator: '==',
            value: globalState?.currentCompany,
          },
        ],
        { field: 'lastUpdated', direction: 'desc' },
        1,
        null
      );
      const resFile = await getSingleDocumentFirebase('files', data?.filesId);
      setDataFeature((prev) => ({
        ...prev,
        comment: resComment[0],
        files: resFile || [],
      }));
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    if (data?.id) getData();
  }, [data?.id, globalState?.currentCompany]);

  const typeFile =
    data?.files?.length > 0 ? data?.files[data?.files?.length - 1]?.type : '';
  const link =
    data?.files?.length > 0 ? data?.files[data?.files?.length - 1]?.link : '';
  const mov =
    data?.files?.length > 0 && typeFile === 'video'
      ? data?.files[data?.files?.length - 1]?.link
        ?.toLowerCase()
        ?.includes('.mov')
      : false;

  return (
    <Modal isOpen={isOpen} onClose={() => {setDataFeature({}); onClose()}} size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="700">
          <Heading fontSize="lg" mb={2}>
            {data?.title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="full">
            {data?.files?.length > 0 ? (
              <Stack shadow={'md'} borderRadius="md">
                {typeFile === 'image' ? (
                  <Image
                    src={link}
                    borderRadius="md"
                    objectFit="contain"
                    w="full"
                    alt="www.google.com"
                  />
                ) : (typeFile === 'video' && mov) ? (
                  <Stack alignItems={'center'} justify="center">
                    <FcVideoFile
                      size={80}
                    />
                  </Stack>
                ) : (typeFile === 'video' || typeFile === 'audio') ? (
                  <AspectRatio maxW="full" ratio={16 / 9}>
                    <iframe
                      title="newFeature"
                      allow="autoplay"
                      src={link}
                      allowFullScreen
                    />
                  </AspectRatio>
                ) : (
                  <Stack alignItems={'center'} justify="center">
                    <FcFolder size={80} />
                  </Stack>
                )}
              </Stack>
            ) : (
              <></>
            )}

            <Divider my={2} />
            <Stack gap={2} my={3}>
              {data?.asignee?.length > 0 && (
                <InputGroup>
                  <InputLeftAddon fontWeight={500}>
                    {t('createdBy')}
                  </InputLeftAddon>
                  <HStack ml="2" gap="0">
                    {[...new Set(data?.asignee)]?.sort().map((x, i) => {
                      const name = dataFeature?.files?.usersDisplay?.find(
                        (y) => y?.id === x
                      )?.name;
                      return (
                        <Box key={i}>
                          <Tooltip label={name || ''} aria-label="Name">
                            <Avatar
                              size="sm"
                              name={name || ''}
                              src={name || ''}
                            />
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </HStack>
                </InputGroup>
              )}
              {data?.pic?.length > 0 && (
                <InputGroup>
                  <InputLeftAddon fontWeight={500}>{t('pic')}</InputLeftAddon>
                  <HStack ml="2" gap="0">
                    {[...new Set(data?.pic)]?.sort().map((x, i) => {
                      const name = dataFeature?.files?.usersDisplay?.find(
                        (y) => y?.id === x
                      )?.name;
                      return (
                        <Box key={i}>
                          <Tooltip label={name || ''} aria-label="Name">
                            <Avatar
                              size="sm"
                              name={name || ''}
                              src={name || ''}
                            />
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </HStack>
                </InputGroup>
              )}
            </Stack>
            <Divider my={2} />
            <Heading fontSize="lg">{t('description')}</Heading></Box>
          {isLoading ? (<><Center><Spinner colorScheme='blue'/></Center></>) : (<> 
            <Box
              h="lg"
              overflow={'auto'}
              sx={{
                '&::-webkit-scrollbar': {
                  w: '2',
                  h: '2',
                },
                '&::-webkit-scrollbar-track': {
                  w: '6',
                  h: '5',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10',
                  bg: 'gray.200',
                },
              }}
            >
              <Box p={5}>
                {dataFeature?.comment && parse(dataFeature?.comment?.comments, {
                  replace: (domNode) => {
                    if (domNode?.type === 'text') {
                      const textWithLinksReplaced = domNode?.data?.replace(
                        /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                        (match) => {
                          const url = match.startsWith('http')
                            ? match
                            : `https://${match}`;
                          return `<a href="${url}" target="_blank">${match}</a>`;
                        }
                      );
                      return parse(textWithLinksReplaced);
                    }
                  },
                })}
              </Box>

              {data?.description?.split('\n').map((line, i) => (
                <Text fontSize={'14px'} key={i}>
                  {line}
                </Text>
              ))}
            </Box>
          </>)}
        
        </ModalBody>
        <ModalFooter gap={3}>
          <Button colorScheme={'green'} onClick={() => {setDataFeature({}); onClose()}}>
            Selesai
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewFeatureDetailsModal;
