/* eslint-disable react/prop-types */
import {
  AvatarGroup,
  Badge,
  Box,
  HStack,
  Image,
  Spacer,
  Stack,
  Tag,
  Text,
  Avatar,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FcFolder } from 'react-icons/fc';
import { FiTag } from 'react-icons/fi';
import { msToTime } from '../../Utils/timeUtil';
import SingleCalendarComponent from './SingleCalendarComponent';
import { getUserByUid } from '../../Utils/getUser';
import useUserStore from '../../Hooks/Zustand/Store';
import { useNavigate } from 'react-router-dom';

function HistoryTaskCard({ data }) {
  //   console.log(data, "ini data");
  const globalState = useUserStore();
  const [dataPIC, setDataPIC] = useState([]);
  const [dataAssignee, setDataAssignee] = useState([]);

  const navigate = useNavigate()

  const toast = useToast()

  const getDataPIC = async () => {
    if (data?.pic) {
      try {
        const promises = data?.pic.map(async (x) => {
          const y = await getUserByUid(globalState?.users, x);
          return y;
        });

        const results = await Promise.all(promises);

        setDataPIC(results);
      } catch (error) {
        toast({
          title: 'Error!',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        }); 
      }
    }
  };

  const getDataAssignee = async () => {
    if (data?.asignee) {
      try {
        const promises = data?.asignee?.map((x) => {
          const y = getUserByUid(globalState?.users, x);

          return y;
        });

        const results = await Promise.all(promises);

        setDataAssignee(results);
      } catch (error) {
        toast({
          title: 'Error!',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });         // Handle the error, e.g., return a default value or rethrow the error
      }
    }
  };


  useEffect(() => {
    getDataPIC();
    getDataAssignee();

    return () => {
      setDataPIC([]);
      setDataAssignee([]);
    };
  }, []);

  const handleNavigate = (data) => {
    navigate(`/kanban/view/${data?.filesId}/history/${data?.id}`)
  }

  return (
    <Stack>
      <Box width="full" shadow="base" p="2" cursor="pointer"  onClick={() => handleNavigate(data)}>
        {data?.files?.length > 0 && (
          <Stack shadow={'md'} borderRadius="md">
            {data?.files[data?.files?.length - 1].type === 'image' ? (
              <Image
                src={data?.files[data?.files?.length - 1]?.link}
                borderRadius="md"
                objectFit="contain"
                w="full"
                alt="www.google.com"
              />
            ) : (
              <Stack alignItems={'center'} justify="center">
                <FcFolder size={80} />
              </Stack>
            )}
          </Stack>
        )}

        <Stack spacing={1} my={2}>
          <Box>
            {data?.category && (
              <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
                {data?.category}
              </Tag>
            )}
          </Box>
          <Text fontWeight="extrabold">{data?.title}</Text>
        </Stack>

        {data?.label ? (
          <Badge
            colorScheme={
              data?.label === 'help'
                ? 'yellow'
                : data?.label === 'pending'
                  ? 'green'
                  : data?.label === 'blocked'
                    ? 'red'
                    : data?.label === 'completed'
                      ? 'green'
                      : data?.label === 'priority'
                        ? 'blue'
                        : 'orange'
            }
          >
            <HStack>
              <FiTag />
              <Text>{data?.label}</Text>
            </HStack>
          </Badge>
        ) : (
          <></>
        )}
        <HStack spacing={1}>
          {data?.dueOn ? (
            <SingleCalendarComponent
              data={data?.dueOn}
              date={moment.unix(data?.dueOn).format('DD')}
              month={moment.unix(data?.dueOn).format('MMM')}
            />
          ) : (
            <></>
          )}
          <Stack
            w="30px"
            borderRadius={'full'}
            h="30px"
            bgColor={data?.score > 0 ? 'blue.400' : 'red.400'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
              {data?.score || 0}
            </Text>
          </Stack>

          <Spacer />

          <AvatarGroup spacing={-3} size="sm" max={1}>
            {dataAssignee?.map((x, i) => (
              <Avatar key={i} name={x?.name} />
            ))}
          </AvatarGroup>
        </HStack>
        <HStack alignItems={'flex-start'} justifyContent="flex-start">
          <Stack>
            <HStack>
              <Text
                textAlign="left"
                fontSize="2xs"
                fontWeight={500}
                color="gray.600"
              >
                PIC
              </Text>
              <Spacer />
              {data?.pic?.length > 0 ? (
                dataPIC?.map((x, index) => {
                  //   const picName = dataPIC.find((y) => y.id === x.id);

                  //   console.log(picName, "ini picname");

                  return (
                    <Text
                      key={index}
                      textAlign="right"
                      fontSize="2xs"
                      fontWeight={500}
                      textTransform="capitalize"
                    >
                      {x?.name ? x?.name : ''}
                    </Text>
                  );
                })
              ) : (
                <></>
              )}
            </HStack>

            {data?.lastUpdated?.seconds ? (
              <Text textAlign="left" fontSize="2xs">
                Updated: {moment.unix(data?.lastUpdated?.seconds).fromNow()}
              </Text>
            ) : (
              <></>
            )}
          </Stack>
          <Spacer />
          <Stack>
            <Text fontSize={'xx-small'} color="gray.700">
              Duration
            </Text>
            <Text fontWeight={'bold'} fontSize="sm" color={'green.600'}>
              {msToTime(
                (data?.time_completed_at?.seconds * 1000 ||
                  data?.lastUpdated?.seconds * 1000) -
                  (data?.time_backlog_at?.seconds * 1000 ||
                    data?.createdAt?.seconds * 1000)
              )}
            </Text>
          </Stack>
        </HStack>
        <Text fontSize="3xs">ID: {data?.id}</Text>
      </Box>
    </Stack>
  );
}

export default HistoryTaskCard;
