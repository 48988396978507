/* eslint-disable react/no-children-prop */
import {
  Box,
  Button,
  Input,
  Select,
  InputLeftAddon,
  InputGroup,
  Avatar,
  Tooltip,
  HStack,
  Text,
  Flex,
  Heading,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spacer,
  useToast,
  Image,
  Grid,
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  Divider,
  DrawerCloseButton
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import { IoMdClose } from 'react-icons/io';
import useUserStore from '../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase, updateDocumentFirebase, arrayUnionFirebase, arrayRemoveFirebase, setDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import { addActivity, addPostTopic, addSubscribeTopic } from '../../Api/logApi';
import { doc, onSnapshot } from '@firebase/firestore';
import TaskCardAddComment from '../../Components/Card/TaskCardAddComment';
import TaskHistoryComponent from '../../Components/Card/TaskHistoryComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ProgressCircle from '../../Components/Progress/ProgressCircle';
import CardFile from '../../Components/Accounting/CardFile'
import { decryptToken } from '../../Utils/encrypToken';
import { FcAudioFile, FcFolder, FcVideoFile } from 'react-icons/fc';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import AutomationSet from '../../Components/Automations/AutomationSet';
import { MdEmail, MdOutlineAccessTimeFilled, MdOutlineFlashOn } from 'react-icons/md';
import { IoShareSocialSharp } from 'react-icons/io5';
import ModalShareCard from '../../Components/Modals/ModalShareCard';
import { getUserByUid } from '../../Utils/getUser';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { sendEmailSendgrid } from '../../Api/ApiSendgrid';
import KpiInputComponent from './Kpi/KpiInputComponent';
import { formatFrice } from '../../Utils/Helper';
import { useTranslation } from 'react-i18next';
import DeleteCardComponent from './Components/DeleteCardComponent';
import { createHistoryCard } from '../../Utils/historyCardUtil';
import UploadFileToAwsAndDropbox from '../../Components/DropBox/UploadFileToAwsAndDropbox';


const ProductivityTaskViewPage = () => {
  const { t } = useTranslation()
  const param = useParams();
  const id = param.taskId;
  const globalState = useUserStore();
  const [data, setData] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({});
  const [asignee, setAsignee] = useState([]);
  const [pic, setPic] = useState([]);
  const [category, setCategory] = useState({ open: false });
  const [addCategory, setAddCategory] = useState('');
  const [mainData, setMainData] = useState('');

  const [activeEmail, setActiveEmail] = useState(false)

  const [modalShare, setModalShare] = useState(false)
  const [fileData, setFileData] = useState([])
  const [loadingEmail, setLoadingEmail] = useState(false)

  const [drawerKpi, setDrawerKpi] = useState(false)

  const [typeFile, setTypeFile] = useState('kanban')

  const navigate = useNavigate();
  const toast = useToast()

  const [progress, setProgress] = useState(0)

  const [alertKpiDelete, setAlertKpiDelete] = useState(false)
  const [kpiActive, setKpiActive] = useState('')


  const [score, setScore] = useState(data?.score || 0);

  const handleChange = (value) => setScore(value)

  const middlewarePic = () => {
    toast({
      status: 'warning',
      duration: 9000,
      title: 'You don\'t have access to do this card',
      isClosable: true,
    });
  };



  const handleEditData = (type, value) => {
    const newData = data;
    if (type === 'dueOn') {
      value = moment(value).unix();


      const comments = `has change due on to ${moment(value * 1000).format('LLL')}`
      createHistoryCard(id, comments, globalState)
    }
    newData[type] = value;
    setDataSubmit({ ...newData });
  };

  const saveCategory = () => {
    let catData = [];
    if (category?.name) catData = category.name;

    handleEditData('category', addCategory);
    catData.push(addCategory);

    updateDocumentFirebase('files', param.id, { category: catData })
      .then((x) => {
        toast({
          title: 'Deoapp.com',
          description: `succes add file ${x}`,
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });

    setCategory({ name: catData, open: false });
  };

  useEffect(() => {
    if (globalState?.currentProject) {
      getDataKanban()
    }


    return () => {
    }
  }, [globalState?.currentProject, typeFile])

  const getDataKanban = async () => {
    const conditions = [
      { field: 'type', operator: '==', value: typeFile },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 999

    try {
      const res = await getCollectionFirebase('files', conditions, sortBy, limitValue)
      setFileData(res);


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }

  }

  const handleSave = () => {

    const uniqueDataAssignee = [...new Set(asignee)];
    globalState.setIsLoading(true)

    updateDocumentFirebase('kanban', id, {
      ...dataSubmit,
      asignee: uniqueDataAssignee,
      pic,
      score: parseInt(score) !== 0 ? parseInt(score) : parseInt(data?.score) ? parseInt(data?.score) : parseInt(mainData?.default_score || 0),
    })
      .then(() => {
        handleLogActivity(dataSubmit);
        handleNotifActivity(dataSubmit)
        const comments = 'has update data card'

        createHistoryCard(id, comments, globalState)
        globalState.setIsLoading(false)
        navigate(-1);
      
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleNotifActivity = async () => {
    try {
      const uniqueDataAssignee = [...new Set(asignee)];


      const dataNotif = {
        topic: id,
        collectionId: id,
        createdName: auth?.currentUser?.displayName || auth?.currentUser?.email || 'anonymous',
        image: auth?.currentUser?.photoURL || '',
        createdBy: auth?.currentUser?.uid,
        type: 'Task',
        companyId: globalState?.currentCompany,
        projectId: param?.title === 'MyKanban' ? data?.projectId : globalState?.currentProject,
        action: 'New Task',
        collectionField: 'kanban',
        userId: uniqueDataAssignee,
        title: dataSubmit?.title || data.title,
        parentName: param?.title || '',
        parentId: param?.id || '',
        body: `You have a new card from ${auth?.currentUser?.displayName || auth?.currentUser?.email || 'anonymous'}`,
        score: parseInt(score || 0),
        onSee: false
      };

      const dataPost = {
        topic: id,
        title: dataSubmit.title,
        body: `New card from ${auth?.currentUser?.displayName || auth?.currentUser?.email || 'anonymous'}`,
      }

      const dataSubscribe = {
        topic: id,
        collectionField: 'kanban',
        uids: uniqueDataAssignee,
        status: 'true'
      };

      if (uniqueDataAssignee.length > 1) {
        await addSubscribeTopic(dataSubscribe);
        await addPostTopic(dataPost);


        const collectionName = 'notification';
        const docName = id;

        try {
          await setDocumentFirebase(collectionName, docName, dataNotif, globalState?.currentCompany);
        } catch (error) {
          handleNotificationError(error);
        }

      }



    } catch (error) {
      handleNotificationError(error);
    }
  };

  const handleNotificationError = (errorMessage) => {
    toast({
      title: 'Deoapp.com',
      description: errorMessage,
      status: 'error',
      position: 'top-right',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleLogActivity = async (dataSubmit) => {
    const uniqueDataAssignee = [...new Set(asignee)];

    const data = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Task',
      companyId: globalState?.currentCompany,
      projectId: param?.title === 'MyKanban' ? dataSubmit?.projectId : globalState?.currentProject,
      action: 'Add New Task',
      collection_path: 'kanban',
      asignee: uniqueDataAssignee,
      title: dataSubmit.title,
      score: parseInt(score || 0),
    };

    try {
      await addActivity(data); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectLabel = async (value) => {
    if (value === 'completed') {
      await updateDocumentFirebase('kanban', id, {
        time_completed_at: new Date()
      })
      toast({
        status: 'success',
        duration: 9000,
        title: 'Good job, Team !!',
        isClosable: true,
      });
    }
    handleEditData('label', value)

    const comments = `has edit label to ${value}`
    createHistoryCard(id, comments, globalState)
  }

  const onSelectFile = async (arg) => {
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${param?.title === 'MyKanban' ? data?.projectId : globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToAwsAndDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );


      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = id;
        const field = 'files';
        const values = [dropboxLink];

        await arrayUnionFirebase(collectionName, docName, field, values);


        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });


      }



    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }

  }

  const handleDelete = async (arg) => {

    const collectionName = 'kanban';
    const docName = id;
    const field = 'files';
    const values = [arg];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);

      toast({
        status: 'success',
        duration: 9000,
        title: 'Success delete your files card.',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const handleSendEmail = async () => {
    if (asignee?.length === 0) return;

    const emailUserTo = [...new Set(asignee)].map((x) => getUserByUid(globalState?.users, x));
    const emailUserFrom = getUserByUid(globalState?.users, data?.createdBy).email;
    const emailUserPIC = [...new Set(pic)].map((x) => getUserByUid(globalState?.users, x)?.email);
    const ccEmail = [...emailUserPIC, emailUserFrom];

    setLoadingEmail(true);

    try {
      await Promise.all(emailUserTo.map(async (x) => {
        const dataSend = {
          platform_name: 'Deoapp',
          sender_email: 'info@deoapp.com',
          recipient_email: x?.email,
          recipient_name: x?.name || '',
          cc: ccEmail,
          subject: 'Notification Deoapp',
          title: 'Notification Deoapp Kanban',
          message: `<h1> Hello Team !<h1> <p> You have received a new notification! A Kanban card titled '${data?.title}' has been added to your project in Deoapp business management. This task has been assigned to ${x?.email}. To learn more, visit: ${window.location.href}</p>`
        };
        await sendEmailSendgrid(dataSend);
      }));

      await updateDocumentFirebase('kanban', param?.taskId, { email_status: true });

      toast({
        title: 'Deoapp.com',
        description: `Successfully sent email Kanban to ${emailUserTo.map((user) => user.email).join(', ')}`,
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingEmail(false);
      setActiveEmail(false)
    }
  }




  useEffect(() => {
    //onsnapshot here...
    const unsubCategory = onSnapshot(doc(db, 'files', param.id), (doc) => {
      if (doc.data()) {
        setCategory({ name: doc.data().category, open: false });
        setMainData(doc.data());
      } else {
        setCategory({ open: false });
        setMainData();
      }
    });

    const unsubTask = onSnapshot(doc(db, 'kanban', param.taskId), (doc) => {
      setData(doc?.data());
      setScore(doc?.data()?.score || 0)
      setAsignee(doc?.data()?.asignee ? doc.data()?.asignee : []);
      setPic(doc?.data()?.pic ? doc.data()?.pic : []);
    });

    return () => {
      unsubTask();
      unsubCategory();
      setCategory();
      setAsignee([])
      setMainData('')
    };
  }, []);

  const handleOpenAutomation = (id) => {
    navigate(`/automation/${id}`)
  }


  const handleOpenModalShare = () => {
    setModalShare(true)

  }

  const handleCloseModalShare = () => {
    setModalShare(false)

  }

  const handleEmailActive = () => {
    if (!asignee || asignee.length === 0) {
      toast({
        title: 'Deoapp.com',
        description: 'You have not assigned this kanban to anyone yet.',
        status: 'warning',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (data?.email_status === true) {
      toast({
        title: 'Deoapp.com',
        description: 'You have already shared this kanban via email.',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setActiveEmail(true);
  }

  const handleEmailClose = () => {
    setActiveEmail(false)
  }

  const handleDrawerOpen = () => {
    setDrawerKpi(true)
  }

  const handleDrawerClose = () => {
    setDrawerKpi(false)
  }

  const handleDeleteKpi = async () => {

    const picTask = data?.pic || []

    if (picTask && picTask?.length > 0) {
      const filterPic = picTask?.filter((x) => x === globalState?.uid);

      if (!filterPic) {
        return middlewarePic();
      }
    } else {
      if (kpiActive?.uid !== globalState?.uid) {
        return middlewarePic();
      }
    }


    const collectionName = 'kanban';
    const docName = param.taskId;
    const field = 'kpi';
    const values = [kpiActive];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);
      toast({
        title: 'Deoapp.com',
        description: 'succes delete file',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      handleKpiClose()
    }
  }

  const handleKpiOpen = (data) => {
    setKpiActive(data)
    setAlertKpiDelete(true)
  }

  const handleKpiClose = () => {
    setKpiActive('')
    setAlertKpiDelete(false)
  }


  return (
    <Stack w={['auto', 'auto', 'auto']} overflowX="scroll">
      {data ? (
        <Stack
          p={[3, 1, 10]}
          w={['auto', 'auto', 'auto']}
          overflowX="scroll"
          fontSize={['sm', 'auto', 'auto']}
        >
          <HStack
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems={'center'}
          >
            <HStack>
              <DynamicButton
                icon={IoMdClose}
                action={'custom'}
                color={'red'}
                variant={'solid'}
                onClick={() => navigate(-1)}
              />
              <Stack spacing={1}>
                <Heading size="md">{data?.title}</Heading>
                <Text fontSize="3xs"> ID: {param.taskId}</Text>
              </Stack>
            </HStack>
            <Spacer />
            <HStack spacing={2}>
              <Stack>
                <DynamicButton
                  title={'Save Card'}
                  action="create"
                  variant={'solid'}
                  onClick={() => handleSave()}
                />
              </Stack>
              <Stack>
                {data?.automationId ? (
                  <DynamicButton
                    title={`${t('see')}  ${t('action')}`}
                    action="custom"
                    icon={MdOutlineFlashOn}
                    color="yellow"
                    variant={'solid'}
                    onClick={() => handleOpenAutomation(data?.automationId)}
                  />
                ) : (
                  <AutomationSet
                    setTypeFile={setTypeFile}
                    fileData={fileData}
                    dataSet={{
                      ...data,
                      asignee: [...new Set(asignee)],
                      pic,
                      score:
                        parseInt(score) !== 0
                          ? parseInt(score)
                          : parseInt(data?.score)
                            ? parseInt(data?.score)
                            : parseInt(mainData?.default_score || 0),
                    }}
                  />
                )}
              </Stack>
              {data?.column !== 'done' && (
                <Stack>
                  <DeleteCardComponent cardId={param?.taskId} cardData={data}/>
                </Stack>
              )}

              <Stack>
                <DynamicButton
                  color={'blue'}
                  icon={IoShareSocialSharp}
                  action="custom"
                  variant={'solid'}
                  onClick={() => handleOpenModalShare()}
                />
              </Stack>
            </HStack>
          </HStack>

          <Box>
            <Grid templateColumns={{ base: '1fr', md: '2fr 1.5fr' }} gap={3}>
              <Stack spacing={3}>
                <HStack>
                  <Input
                    type="text"
                    placeholder={data?.title === 'New Card' ? '' : data?.title}
                    onChange={(e) => handleEditData('title', e.target.value)}
                  />
                  <Stack>
                    <DynamicButton
                      onClick={handleEmailActive}
                      icon={MdEmail}
                      variant={data?.email_status ? 'solid' : 'outline'}
                      color={'green'}
                      action={'custom'}
                    />
                  </Stack>
                </HStack>

                <InputGroup>
                  <InputLeftAddon children={t('lastUpdate')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      moment
                        .unix(data?.lastUpdated?.seconds)
                        .format('DD/MM/YYYY') ?? moment().format('yyyy-MM-DD')
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children={t('createdBy')} fontWeight={500} />
                  <Input
                    disabled
                    type="text"
                    value={
                      mainData?.usersDisplay?.find(
                        (x) => x.id === data.createdBy
                      )?.name
                    }
                  />
                </InputGroup>

                <HStack spacing={2}>
                  <InputGroup>
                    <InputLeftAddon children={t('category')} fontWeight={500} />

                    <Select
                      placeholder={t('category')}
                      defaultValue={data?.category ? data.category : <></>}
                      onChange={(e) =>
                        handleEditData('category', e.target.value)
                      }
                    >
                      {category?.name?.sort()?.map((x, i) => (
                        <option key={i} value={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                  </InputGroup>
                  {category?.open ? (
                    <></>
                  ) : (
                    <DynamicButton
                      action={'create'}
                      variant="solid"
                      onClick={() => {
                        setCategory({ ...category, open: true });
                      }}
                    />
                  )}
                </HStack>

                {category?.open ? (
                  <HStack spacing={2}>
                    <InputGroup>
                      <InputLeftAddon
                        children={t('category')}
                        fontWeight={500}
                        placeholder={t('category')}
                      />
                      <Input
                        type="text"
                        onChange={(e) => setAddCategory(e.target.value)}
                      />
                    </InputGroup>
                    <DynamicButton
                      title="save"
                      action={'create'}
                      onClick={() => saveCategory()}
                      variant="solid"
                    />
                    <DynamicButton
                      action={'delete'}
                      onClick={() => setCategory({ ...category, open: false })}
                      variant="solid"
                    />
                  </HStack>
                ) : (
                  <></>
                )}

                {asignee?.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon
                      children={t('assigneeTo')}
                      fontWeight={500}
                    />
                    <HStack ml="2" gap="0">
                      {[...new Set(asignee)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;
                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = asignee.filter(
                                    (z) => z !== x
                                  );
                                  setAsignee(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('assigneeTo')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder={t('assigneeTo')}
                    onChange={(e) => {
                      setAsignee([...asignee, e.target.value]);
                    }}
                    textTransform={'capitalize'}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                {pic?.length > 0 && (
                  <InputGroup>
                    <InputLeftAddon children={t('pic')} fontWeight={500} />
                    <HStack ml="1" gap="0">
                      {[...new Set(pic)]?.sort()?.map((x, i) => {
                        const name = mainData?.usersDisplay?.find(
                          (y) => y.id === x
                        )?.name;

                        return (
                          <Box key={i}>
                            <Tooltip label={name} aria-label="Name">
                              <Avatar
                                onClick={() => {
                                  const newAsingee = pic.filter((z) => z !== x);
                                  setPic(newAsingee);
                                }}
                                size="sm"
                                name={name}
                                src={name}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </HStack>
                  </InputGroup>
                )}

                <InputGroup>
                  <InputLeftAddon children={t('pic')} fontWeight={500} />
                  <Select
                    width="full"
                    placeholder={t('user')}
                    onChange={(e) => {
                      setPic([...pic, e.target.value]);
                    }}
                    textTransform={'capitalize'}
                  >
                    {mainData?.usersDisplay
                      ? mainData?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))
                      : data?.usersDisplay?.usersDisplay
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name ? x.name : x.email}
                          </option>
                        ))}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children="Label" fontWeight={500} />
                  <Select
                    onChange={(e) => handleSelectLabel(e.target.value)}
                    defaultValue={data?.label}
                    placeholder="Label"
                  >
                    <option value="help">help</option>
                    <option value="priority">priority</option>
                    <option value="urgent">urgent</option>
                    <option value="pending">pending</option>
                    <option value="blocked">blocked</option>
                    {data?.time_backlog_at !== undefined && (
                      <option value="completed">completed</option>
                    )}
                  </Select>
                </InputGroup>

                <InputGroup>
                  <InputLeftAddon children={t('dueOn')} fontWeight={500} />
                  <Input
                    type="date"
                    onChange={(e) => handleEditData('dueOn', e.target.value)}
                    defaultValue={
                      data?.dueOn
                        ? moment.unix(data?.dueOn).format('yyyy-MM-DD')
                        : moment(new Date()).format('yyyy-MM-DD')
                    }
                  />
                </InputGroup>
              </Stack>
              <Stack spacing={3}>
                <Stack alignItems="end" justifyContent="end" pt={3}>
                  <Text textAlign={'end'} fontWeight={800} fontSize="lg">
                    {t('score')} : {data?.score || 0}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontWeight={'bold'}>{t('assigneeTo')}: </Text>
                  <Flex flexDirection={['column', 'row', 'row']}>
                    <NumberInput
                      maxW="100px"
                      mr="2rem"
                      min={0}
                      max={10}
                      value={score}
                      onChange={handleChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Slider
                      flex="1"
                      focusThumbOnChange={false}
                      value={score}
                      min={0}
                      max={10}
                      onChange={handleChange}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb
                        fontSize="sm"
                        boxSize="32px"
                        children={score}
                      />
                    </Slider>
                  </Flex>
                </Stack>
                <Divider />
                <Stack>
                  <HStack>
                    <MdOutlineAccessTimeFilled />
                    <Text fontWeight={'bold'}>Time Duration: </Text>
                    <Spacer />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.time_backlog_at?.seconds && (
                      <Text>Start : {moment(data?.time_backlog_at.seconds * 1000).format('LLL')}</Text>

                    )}

                    {data?.time_completed_at?.seconds && (
                      <Text>End : {moment(data?.time_completed_at.seconds * 1000).format('LLL')}</Text>

                    )}
                  </Stack>
                </Stack>

                <Divider />

                <Stack>
                  <HStack>
                    <Text fontWeight={'bold'}>KPI: </Text>
                    <Spacer />
                    <DynamicButton
                      onClick={handleDrawerOpen}
                      variant={'solid'}
                      action={'create'}
                      size={'xs'}
                    />
                  </HStack>
                  <Stack maxH={'100px'} overflowY={'auto'}>
                    {data?.kpi?.length > 0 ? (
                      <Stack>
                        {data?.kpi?.map((x, i) => {
                          return (
                            <Stack key={i}>
                              <HStack spacing={3}>
                                <Tooltip
                                  label={
                                    getUserByUid(globalState.users, x.uid).name
                                  }
                                  textTransform={'capitalize'}
                                  aria-label="Name"
                                >
                                  <Avatar
                                    src={
                                      getUserByUid(globalState.users, x.uid)
                                        .image
                                    }
                                    size={'sm'}
                                    name={
                                      getUserByUid(globalState.users, x.uid)
                                        .name
                                    }
                                  />
                                </Tooltip>
                                <Stack
                                  spacing={1}
                                  onClick={() => navigate('/kpi/list')}
                                  cursor="pointer"
                                >
                                  <Text
                                    fontSize={'xs'}
                                    fontWeight={500}
                                    noOfLines={1}
                                  >
                                    {x?.title_kpi}
                                  </Text>
                                  <Text fontSize={'2xs'} noOfLines={1}>
                                    {t('time')}: {x?.title}
                                  </Text>
                                </Stack>

                                <Spacer />
                                <HStack>
                                  <Text fontWeight={500} letterSpacing={4}>
                                    ({formatFrice(x?.actual)}/
                                    {formatFrice(parseInt(x?.target_kpi))})
                                  </Text>
                                </HStack>
                                <DynamicButton
                                  size={'xs'}
                                  variant={'solid'}
                                  action={'delete'}
                                  onClick={() => handleKpiOpen(x)}
                                />
                              </HStack>
                              <Divider />
                            </Stack>
                          );
                        })}
                      </Stack>
                    ) : (
                      <Stack alignItems={'center'} justifyContent={'center'}>
                        <Text fontWeight={500} fontSize={'xs'}>
                          {t('noKpi')}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Divider />

                <Stack>
                  <Text fontWeight={'bold'}>Thumbnail: </Text>

                  <CardFile onChange={onSelectFile} />
                  {data?.files?.length > 0 && (
                    <SimpleGrid
                      h={'250px'}
                      overflow={'auto'}
                      sx={{
                        '&::-webkit-scrollbar': {
                          w: '2',
                          h: '2',
                        },
                        '&::-webkit-scrollbar-track': {
                          w: '6',
                          h: '5',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '10',
                          bg: 'gray.200',
                        },
                      }}
                      py={3}
                      spacing={4}
                      columns={[2]}
                    >
                      {data?.files?.map((x, index) => {
                        const dataView =
                          x.type === 'image' ? (
                            <Image
                              src={x.link}
                              objectFit="contain"
                              w={'200px'}
                              borderRadius={'md'}
                              shadow="md"
                            />
                          ) : x.type === 'audio' ? (
                            <FcAudioFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : x.type === 'video' ? (
                            <FcVideoFile
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          ) : (
                            <FcFolder
                              size={80}
                              onClick={() => window.open(x.link, '_blank')}
                            />
                          );
                        return (
                          <Stack
                            key={index}
                            borderWidth={1}
                            position="relative"
                            alignItems="center"
                            justifyContent={'center'}
                            borderRadius="md"
                            minH={'120px'}
                          >
                            <HStack
                              flexDirection={['column', 'row', 'row']}
                              position={'absolute'}
                              top={-2}
                              right={-2}
                              spacing={2}
                            >
                              <DynamicButton
                                action={'read'}
                                size="sm"
                                variant={'solid'}
                                onClick={() => window.open(x.link, '_blank')}
                              />
                              <DynamicButton
                                action={'delete'}
                                onClick={() => handleDelete(x)}
                                size="sm"
                                variant={'solid'}
                              />
                            </HStack>
                            <Stack overflow={'hidden'}>
                              {dataView && dataView}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </SimpleGrid>
                  )}
                  {data?.files?.length > 2 && (
                    <Stack alignItems={'center'} justifyContent="center">
                      <BsFillArrowDownCircleFill />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Box>

          <Flex flexDirection={['column', 'row', 'row']}>
            <Box flex="1">
              <TaskCardAddComment id={id} title={data?.title} />
            </Box>

            {showHistory ? (
              <Box maxW="xs" p={2}>
                <TaskHistoryComponent
                  setShowHistory={setShowHistory}
                  id={id}
                />
              </Box>
            ) : (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="green"
                onClick={() => setShowHistory(!showHistory)}
              >
                {showHistory ? 'Hide History' : 'Show History'}
              </Button>
            )}
          </Flex>
        </Stack>
      ) : (
        <></>
      )}

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}

      {modalShare === true && (
        <ModalShareCard
          handleCloseModal={handleCloseModalShare}
          modalShare={modalShare}
          fileData={fileData}
          kanbanData={mainData}
          cardData={data}
          globalState={globalState}
          typeFile={typeFile}
          setTypeFile={setTypeFile}
        />
      )}

      <AlertDialogV2
        isLoading={loadingEmail}
        isOpen={activeEmail}
        onClose={handleEmailClose}
        onAction={handleSendEmail}
        title={t('sendEmail')}
      />

      <AlertDialogV2
        isOpen={alertKpiDelete}
        onClose={handleKpiClose}
        onAction={handleDeleteKpi}
        title={'Would you like to delete this kpi data view in kanban ?'}
      />

      <Drawer
        size={'sm'}
        placement={'right'}
        onClose={handleDrawerClose}
        isOpen={drawerKpi}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack>
              <KpiInputComponent
                globalStateProps={globalState}
                onClose={handleDrawerClose}
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );

};

export default ProductivityTaskViewPage;
