/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Heading,
  HStack,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Spacer,
  Input,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import BackButtons from '../../Components/Buttons/BackButtons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import LiveTime from '../../Components/Timer/LiveTime';
import CountdownTimerV2 from '../../Components/Timer/CountdownTimerV2';
import { RiTimerLine } from 'react-icons/ri';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getDeviceType } from '../../Hooks/Middleware/sessionMiddleWare';
import MyKanbanColumnsComponent from '../../Components/Columns/MyKanbanColumnsComponent';
import useUserStore from '../../Hooks/Zustand/Store';
import ProductivityMyTaskViewModalPage from './ProductivityMyTaskViewModalPage';
import { FiEye } from 'react-icons/fi';
import MyKanbanTableComponent from '../../Components/Table/MyKanbanTableComponent';
import { useParams } from 'react-router-dom';
import MyKanbanGanttComponent from '../../Components/Charts/ChartsGanttKanban/MyKanbanGanttComponent';
import { useTranslation } from 'react-i18next';

function ProductivityMyKanbanViewPage() {
  const {t} = useTranslation()
  const [kanbanData] = useState();
  const [toggleStopwatch, setToggleStopwatch] = useState(false);
  const globalState = useUserStore();
  const param = useParams();

  const [modalDetail, setModalDetail] = useState(false);
  const [detailActive, setDetailActive] = useState('');
  const [stateRefresh, setStateRefresh] = useState(false);
  const [typeView, setTypeView] = useState(param.type);

  const handleButtonClick = () => {
    if (typeView === 'kanban') {
      setTypeView('table');
    } else if (typeView === 'table') {
      setTypeView('gantt');
    } else {
      setTypeView('kanban');
    }
  };

  const { setFilterData, filterData, resetFilterData } = useKanbanStore();

  const ColumnType = {
    BACKLOG: 'backlog',
    TODOS: 'todos',
    PROGRESS: 'progress',
    REVIEW: 'review',
    DONE: 'done',
  };

  const deviceType = getDeviceType();

  const handleProject = (id) => {
    const findProject = globalState?.projects?.find((x) => x?.id === id);

    return findProject || '';
  };

  const refreshData = (id) => {
    setStateRefresh(id);
  };

  const handleModalOpen = (x) => {
    setModalDetail(true);
    setDetailActive(x);
  };

  const handleModalClose = () => {
    setModalDetail(false);
    setDetailActive('');
  };

  return (
    <>
      <Box>
        <HStack flexDirection={['column', 'column', 'row']}>
          <BackButtons />

          <Box maxW={['auto', '500px', '700px']}>
            <Heading noOfLines={1} size={'lg'}>
              {t('myKanban')}
            </Heading>

            <Text fontSize="2xs" color={'gray.500'}>
              {t('project')} : {t('all')} {t('project')}
            </Text>
          </Box>
          <Spacer />
          <HStack flexDirection={['column', 'row', 'row']} spacing={3}>
            <Stack
              alignItems={'flex-end'}
              justifyContent="flex-end"
              spacing={1}
            >
              <HStack>
                <DynamicButton
                  size="sm"
                  action="custom"
                  variant={'solid'}
                  color={'orange'}
                  icon={RiTimerLine}
                  onClick={() => setToggleStopwatch((prev) => !prev)}
                />
                <DynamicButton
                  action={'custom'}
                  size="sm"
                  variant={'solid'}
                  color={'yellow'}
                  icon={FiEye}
                  title={
                    typeView === 'kanban'
                      ? 'Kanban'
                      : typeView === 'table'
                        ? t('table')
                        : 'Gantt'
                  }
                  onClick={handleButtonClick}
                />
              </HStack>
              <Stack alignItems="flex-end" justifyContent="flex-end">
                <Text fontSize={'xs'} color="red.600">
                  * {t('myKanbanNote')}
                </Text>
              </Stack>
            </Stack>

            {toggleStopwatch ? (
              <Stack
                bgColor={'blue.500'}
                shadow="md"
                py={1}
                px={2}
                borderRadius="md"
              >
                <CountdownTimerV2 seconds={60} />
              </Stack>
            ) : (
              <Stack bgColor={'blue.500'} shadow="md" p={2} borderRadius="md">
                <LiveTime color={'white'} />
              </Stack>
            )}
          </HStack>
        </HStack>

        <Box p={2} my={3} borderRadius={'md'} shadow={'base'}>
          <SimpleGrid borderRadius={'sm'} gap={1} columns={[1, 5, 5]} px="2">
            <Select
              value={filterData.projectId}
              placeholder="Projects"
              textTransform={'capitalize'}
              onChange={(e) => {
                setFilterData({ ...filterData, projectId: e.target.value });
                // console.log({ ...filterData, label: e.target.value })
              }}
            >
              {globalState?.projects?.map((x) => {
                return (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                );
              })}
            </Select>

            <Select
              value={filterData?.label}
              placeholder="Label"
              textTransform={'capitalize'}
              onChange={(e) => {
                setFilterData({ ...filterData, label: e.target.value });
              }}
            >
              <option option="help">help</option>
              <option option="priority">priority</option>
              <option option="urgent">urgent</option>
              <option option="pending">pending</option>
              <option option="blocked">blocked</option>
              <option option="completed">completed</option>
            </Select>

            <Input
              type="text"
              placeholder="search"
              value={filterData?.search}
              onChange={(e) => setFilterData({ search: e.target.value })}
            />
          </SimpleGrid>

          {filterData?.projectId ||
          filterData?.category ||
          filterData?.label ? (
              <HStack spacing={0}>
                {filterData?.projectId ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    // bgColor="white"
                    borderRadius="md"
                    shadow="base"
                  >
                    <Text textAlign="center">{t('projectId')}: </Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {handleProject(filterData?.projectId)?.name || ''}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.label ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    // bgColor="white"
                    borderRadius="md"
                    shadow="base"
                  >
                    <Text textAlign="center">Label: </Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {filterData?.label}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                {filterData?.search ? (
                  <HStack
                    fontSize={12}
                    p="2"
                    m="2"
                    // bgColor="white"
                    borderRadius="md"
                    shadow="base"
                  >
                    <Text textAlign="center">{t('search')}</Text>
                    <Text textAlign="center" textTransform={'capitalize'}>
                      {filterData?.search}
                    </Text>
                  </HStack>
                ) : (
                  <></>
                )}
                <Spacer />
                {/* <Button minW='min-content' colorScheme='green'>Search</Button> */}
                {/* <Button minW='min-content' colorScheme='green' onClick={() => console.log(filterData)}>check console</Button> */}
                <Button
                  size={'xs'}
                  minW="min-content"
                  colorScheme="red"
                  onClick={() => {
                    resetFilterData();
                  }}
                >
                  {t('clear')}
                </Button>
              </HStack>
            ) : (
              <></>
            )}
        </Box>

        <DndProvider
          backend={deviceType === 'desktop' ? HTML5Backend : TouchBackend}
        >
          {typeView === 'table' ? (
            <MyKanbanTableComponent
              props={detailActive}
              filterData={filterData}
            />
          ) : typeView === 'kanban' ? (
            <SimpleGrid columns={[1 , 2, 5]}>
              <MyKanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.BACKLOG}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <MyKanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.TODOS}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <MyKanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.PROGRESS}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <MyKanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.REVIEW}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
              <MyKanbanColumnsComponent
                kanbanData={kanbanData}
                allowedDropEffect="move"
                filterData={filterData}
                column={ColumnType.DONE}
                handleModalOpen={handleModalOpen}
                stateRefresh={stateRefresh}
              />
            </SimpleGrid>
          ) : (
            <MyKanbanGanttComponent kanbanData={kanbanData} filterData={filterData} />
          )}
        </DndProvider>
      </Box>

      <Drawer
        size={'xl'}
        placement={'bottom'}
        onClose={handleModalClose}
        isOpen={modalDetail}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack>
              {detailActive && (
                <ProductivityMyTaskViewModalPage
                  props={detailActive}
                  handleModalClose={handleModalClose}
                  handleRefresh={refreshData}
                />
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ProductivityMyKanbanViewPage;
