/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
  useColorMode,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { auth } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { FcFile, FcFolder } from 'react-icons/fc';
import { clientTypessense } from '../../Api/Typesense';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import { useTranslation } from 'react-i18next';

function ProductivityDocsPage() {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [dataDocsSearch, setDataDocsSearch] = useState([]);
  const [dataDocs, setDataDocs] = useState([]);
  const [dataFolder, setDataFolder] = useState({
    heading: '',
    description: '',
    data: [],
  });

  const [searchInput, setSearchInput] = useState('');

  const toast = useToast();

  const [modalFolder, setModalFolder] = useState(false);
  const [loading, setLoading] = useState(false);

  const globalState = useUserStore();
  const { colorMode } = useColorMode();
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;

  const getData = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      { field: 'type', operator: '==', value: 'docs&files' },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      globalState?.setIsLoading(true);
      const resFile = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue
      );
      const resFolder = await getCollectionFirebase(
        'folders',
        conditions,
        sortBy,
        limitValue
      );

      // Cari folder yang memiliki data category "file" dengan folderId yang sesuai, dan gabungkan dengan file tersebut
      const folderWithFiles = resFolder?.map((folder) => {
        const filesInFolder = resFile?.filter(
          (file) => file.folderId === folder.id
        );
        return {
          ...folder,
          data: filesInFolder,
        };
      });

      // Cari file yang tidak memiliki folderId
      const filesWithoutFolder = resFile?.filter((file) => !file.folderId);

      const combinedData = [...filesWithoutFolder, ...folderWithFiles];

      setDataDocs(combinedData);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();

    return () => {
      // setDataDocs([])
      setDataDocsSearch([]);
    };
  }, [globalState?.currentCompany, startIndex]);

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); // Tambahkan jumlah data per halaman saat tombol "Load More" diklik
  };

  const handleNewDocs = () => {
    navigate('/docs/create');
  };

  const handleNewFolder = () => {
    setModalFolder(true);
  };

  const handleAddFolder = (e) => {
    const { name, value } = e.target;
    setDataFolder((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogActivity = async (x) => {
    const dataActive = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Docs & File',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Add New Docs',
      collection_path: 'docs',
      title: x,
      createdAt: new Date(),
      //  id: id
    };

    try {
      await addActivity(dataActive); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleAddNewFolder = async () => {
    const companyId = globalState?.currentCompany;

    setLoading(true);

    const updateDataFolder = dataFolder;
    updateDataFolder.type = 'docs&files';
    updateDataFolder.category = 'folder';
    updateDataFolder.createdAt = new Date();
    updateDataFolder.owner = [globalState?.uid];
    updateDataFolder.title = dataFolder.heading;
    updateDataFolder.projectId = globalState?.currentProject;
    updateDataFolder.companyId = globalState?.currentProject;

    const collectionName = 'folders';

    try {
      const docID = await addDocumentFirebase(
        collectionName,
        updateDataFolder,
        companyId
      );
      if (docID) {
        handleLogActivity(updateDataFolder.title);
        const collectionNameFolder = `folders/${docID}/users`;
        const dataUser = globalState?.user;

        try {
          await addDocumentFirebase(collectionNameFolder, dataUser, companyId);
          toast({
            title: 'Success',
            description: 'Succes to add new file.',
            isClosable: true,
            duration: 9000,
            status: 'success',
          });
          setModalFolder(false);
        } catch (error) {
          toast({
            title: 'Deoapp.com',
            duration: 3000,
            description: error.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      getData();
      setLoading(false);
      setModalFolder(false);
    }
  };

  const DraggableItem = ({ item }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'ITEM',
      item: { id: item.id, category: item.category },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: 'ITEM',
      drop: (droppedItem) => handleDrop(droppedItem, item), // Fungsi untuk menangani event saat di-drop
    });

    const limitedData = item?.data?.slice(0, 6);

    const dataView =
      item.category === 'file' ? <FcFile size={50} /> : <FcFolder size={50} />;

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <Stack
          shadow={'md'}
          bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}
          h={'150px'}
          overflow={'hidden'}
          overflowY="scroll"
          p={5}
          borderRadius={'md'}
          borderWidth={0.5}
          borderColor="blackAlpha.300"
          onClick={() =>
            navigate(`/docs/${item.category}/${item.id}`, { state: item })
          }
          cursor="pointer"
        >
          <Stack w={'100%'} alignItems="center">
            <Text fontWeight={500} noOfLines={1} textTransform={'capitalize'}>
              {item.heading}
            </Text>
          </Stack>
          <Divider />
          {item.category === 'folder' ? (
            item?.data?.length > 0 && (
              <SimpleGrid columns={[2]} gap={3}>
                {limitedData.map((y, index) => {
                  const dataView =
                    y.category === 'file' ? (
                      <FcFile size={50} />
                    ) : (
                      <FcFolder size={50} />
                    );

                  return (
                    <Stack
                      key={index}
                      bgColor="yellow.100"
                      h={'100px'}
                      borderRadius={'md'}
                      p={1}
                      color="black"
                      borderWidth={0.5}
                      borderColor="blackAlpha.300"
                    >
                      <Stack>
                        <Text
                          fontSize={'xs'}
                          fontWeight={500}
                          textAlign="center"
                          noOfLines={1}
                        >
                          {y.heading}
                        </Text>
                      </Stack>
                      <Divider />
                      <Stack
                        alignItems={'center'}
                        justifyContent="center"
                        h="full"
                      >
                        {dataView}
                      </Stack>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            )
          ) : (
            <Stack alignItems={'center'} h="full" justifyContent="center">
              {dataView}
            </Stack>
          )}
        </Stack>
      </div>
    );
  };

  const handleDrop = async (droppedItem, folderData) => {
    if (droppedItem.category === 'folder' && folderData.category === 'file') {
      const collectionName = 'files';
      const docName = folderData.id;
      const data = {
        folderId: droppedItem.id,
      };

      try {
        await updateDocumentFirebase(collectionName, docName, data);

        getData(); // Pesan toast yang berhasil
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }

    if (droppedItem.category === 'file' && folderData.category === 'folder') {
      const collectionName = 'files';
      const docName = droppedItem.id;
      const data = {
        folderId: folderData.id,
      };

      try {
        await updateDocumentFirebase(collectionName, docName, data);

        getData(); // Pesan toast yang berhasil
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }

    // if (droppedItem.category === 'file' && folderData.category === 'file') {
    //   console.log('gabung');
    // }

    // if (droppedItem.id === folderData.id) {
    //   console.log('jangan terjadi apa apa');
    // }
  };

  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `type: docs&files && companyId:${globalState.currentCompany}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('files')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setDataDocsSearch(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack p={[1, 1, 5]} spacing={5}>
        <HStack flexDirection={['column', 'row', 'row']}>
          <BackButtons />
          <Heading size={'md'}>{t('Docs & Files')}</Heading>
          <Spacer />

          <HStack flexDirection={['column', 'row', 'row']}>
            <Stack>
              <Input
                shadow={'md'}
                // bgColor="white"
                placeholder={t('search')}
                size={'md'}
                borderRadius="md"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Stack>
            <DynamicButton
              action={'create'}
              onClick={handleNewDocs}
              title={t('addFile')}
              variant={'solid'}
            />
            <DynamicButton
              action={'create'}
              onClick={handleNewFolder}
              title={t('addFolder')}
              variant={'solid'}
            />
          </HStack>
        </HStack>

        <Stack spacing={4} p={[1, 1, 5]}>
          <Stack>
            <Text fontSize={'2xs'} color="red.400">
              *{t('descDoc')}
            </Text>
          </Stack>
          {searchInput !== '' ? (
            dataDocsSearch.length > 0 && (
              <SimpleGrid columns={[1, 2, 4]} gap={5}>
                {dataDocsSearch.map((x, index) => {
                  return (
                    <Stack
                      key={index}
                      shadow={'md'}
                      bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}
                      // bgColor="white"
                      h={'150px'}
                      overflow={'hidden'}
                      overflowY="scroll"
                      p={5}
                      borderRadius={'md'}
                      borderWidth={0.5}
                      borderColor="blackAlpha.300"
                      onClick={() =>
                        navigate(`/docs/file/${x.id}`, { state: x })
                      }
                      cursor="pointer"
                    >
                      <Stack w={'100%'} alignItems="center">
                        <Text
                          fontWeight={500}
                          noOfLines={1}
                          textTransform={'capitalize'}
                        >
                          {x.title}
                        </Text>
                      </Stack>
                      <Divider />

                      <Stack
                        alignItems={'center'}
                        h="full"
                        justifyContent="center"
                      >
                        <FcFile size={50} />
                      </Stack>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            )
          ) : dataDocs?.length > 0 ? (
            <SimpleGrid columns={[1, 2, 4]} gap={5}>
              {dataDocs?.slice(0, startIndex + itemsPerPage).map((x, index) => {
                return (
                  <DraggableItem key={index} item={x}>
                    <Stack
                      shadow={'md'}
                      // bgColor="white"
                      h={'200px'}
                      overflow={'hidden'}
                      overflowY="scroll"
                      key={index}
                      p={5}
                      borderRadius={'md'}
                      borderWidth={0.5}
                      borderColor="blackAlpha.300"
                      onClick={() =>
                        navigate(`/docs/${x.category}/${x.id}`, {
                          state: x,
                        })
                      }
                      cursor="pointer"
                    >
                      <Stack w={'100%'} alignItems="center">
                        <Text
                          fontWeight={500}
                          noOfLines={1}
                          textTransform={'capitalize'}
                        >
                          {x.heading}
                        </Text>
                      </Stack>
                      <Divider />
                    </Stack>
                  </DraggableItem>
                );
              })}
            </SimpleGrid>
          ) : (
            <AlertArrayNull
              titleData={'File & Folder'}
              action="Add File & Folder"
            />
          )}
        </Stack>

        <Stack alignItems={'center'} justifyContent="center">
          <Box>
            {dataDocs?.length >= itemsPerPage &&
              dataDocs?.length > startIndex && (
              <Button
                onClick={handleLoadMore}
                colorScheme={'blue'}
                fontSize="sm"
              >
                {t('loadMore')}
              </Button>
            )}
          </Box>
        </Stack>

        <Modal
          isOpen={modalFolder}
          onClose={() => setModalFolder(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('newFolder')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={1} py={3}>
                <Stack m="1">
                  <Input
                    name="heading"
                    type="text"
                    placeholder={t('title')}
                    onChange={handleAddFolder}
                  />
                  <Input
                    name="description"
                    type="text"
                    placeholder={t('description')}
                    onChange={handleAddFolder}
                  />
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Flex gap={5}>
                <Button
                  leftIcon={<AddIcon boxSize={3} />}
                  colorScheme="green"
                  isLoading={loading}
                  onClick={() => handleAddNewFolder()}
                >
                  {t('addFolder')}
                </Button>
                <Button
                  leftIcon={<CloseIcon boxSize={3} />}
                  colorScheme="red"
                  onClick={() => {
                    setModalFolder(false);
                  }}
                >
                  {t('cancel')}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </DndProvider>
  );
}

export default ProductivityDocsPage;
