/* eslint-disable no-unsafe-optional-chaining */
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  // Input,
  Avatar,
  Text,
  Heading,
  Stack,
  Spacer,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Badge,
  Flex,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _axios from '../../Api/AxiosBarrier';
import { clientTypessense } from '../../Api/Typesense';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import { msToTime } from '../../Utils/timeUtil';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DatePicker from '../../Components/DatePicker/DatePicker';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { FaRegCalendar } from 'react-icons/fa';
import { TbReload } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import DownloadButtonExcel from '../../Components/Buttons/DownloadButtonExcel';

function ManagementScoreTask() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [searchUid, setSearchUid] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParam, setSearchParam] = useState([]);
  const [selectedUid, setSelectedUid] = useState([]);
  const [mongoData, setMongoData] = useState({});
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });
  const [page, setPage] = useState(1);

  const dates = useRef({
    startDate: moment().subtract(30, 'days').unix(),
    endDate: moment().unix(),
  });

  const globalState = useUserStore();

  const toast = useToast();

  const getData = async (currentPage = page, sorting = sort) => {
    setPage(currentPage);
    globalState.setIsLoading(true);

    const { startDate, endDate } = dates.current;

    onClose();

    try {
      if (!globalState.currentCompany) return null;

      const params = `scoreboard-kanban?page=${currentPage}&limit=10&group=filesId&sort=${
        sorting.desc ? -1 : 1
      }&companyId=${globalState.currentCompany}&sortBy=${
        sorting.sort
      }&startDate=${startDate}&endDate=${endDate}&type=kanban`;
      const res = await _axios.get(params);

      const uids = _.map(res.data, 'id');
      const searchUid = await handleTypeSense('', uids);

      const filteredData = res.data
        .map((item) => {
          const addData = searchUid.find((y) => y.id === item.id);
          return addData && addData.title ? { ...addData, ...item } : null;
        })
        .filter(Boolean); // Filter out null values (items without title)

      setMongoData(res.totalPages);
      setData(filteredData);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };
  const navigate = useNavigate();

  const handleRemoveUid = (x) => {
    const updatedSelectedUid = selectedUid.filter(
      (uid) => uid !== x.document.id
    );
    setSelectedUid(updatedSelectedUid);

    const updatedSearchParam = searchParam.filter(
      (param) => param.document.id !== x.document.id
    );
    setSearchParam(updatedSearchParam);

    getData(1, true, updatedSelectedUid.join(','));
  };

  const handleTypeSense = (q, uids) => {
    const searchParameters = {
      q: q,
      query_by: 'title',
      per_page: 10,
      filter_by: `id: [${uids?.join(',')}]`,
      sort_by: '_text_match:desc',
    };

    return clientTypessense
      .collections('files')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => {
          return { ...y.document };
        });

        return newData;
      });
  };

  const handleReset = () => {
    setSearchUid();
    setSearchParam([]);
    setSelectedUid([]);
    getData();
  };

  const handleSeeHistory = async (x) => {
    navigate(`/management/score/tasks/${x.id}`);
  };

  const handleSort = (sort, desc) => {
    setSort({ sort: sort, desc: desc });
    getData(page, { sort: sort, desc: desc });
  };

  const onDateChange = (data) => {
    dates.current = {
      startDate: moment(data.startDate).unix(),
      endDate: moment(data.endDate).unix(),
    };
  };

  const handleSearchGroup = (x) => {
    setSearchParam([...searchParam, x]);
    setSelectedUid([...selectedUid, x.document.id]);
  };

  useEffect(() => {
    getData();
    if (globalState.currentCompany) {
      getData();
    }

    return () => {
      setData();
      setPage(1);
    };
  }, [globalState.currentCompany]);

  const handleReloadFilter = () => {
    setData();
    dates.current = {
      startDate: moment().subtract(30, 'days').unix(),
      endDate: moment().unix(),
    };
    getData();
  };

  return (
    <Stack p={[0, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>{t('score-board')}</Heading>
        <Spacer />
        <DownloadButtonExcel
          size={'sm'}
          title={'Score Board Task'}
          download={data}
          dates={dates.current}
          currentPage={page}
          sorting={sort}
          searchId={selectedUid.join(',')}
          currentCompany={globalState.currentCompany}
        />
      </HStack>

      <Box p="5" borderRadius="md" shadow="base">
        {/* <HStack>
          <Select placeholder="All">
            <option option="Backbone">Backbone</option>
            <option option="Importir">Importir</option>
          </Select>
          <Input type="date" />
          <Input type="date" />
        </HStack> */}

        <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
          {/* <Input
            w={'30%'}
            type="text"
            placeholder="Search User"
            onChange={(e) => {
              if (e.target.value) handleTypeSense(e.target.value);
              else {
                setSearchUid();
              }
            }}
          /> */}

          <Button
            onClick={onOpen}
            bgColor={'transparent'}
            // shadow="md"
            variant="outline"
            borderColor="blue.600"
            color="blue.600"
          >
            <HStack>
              <FaRegCalendar />
              <Text>{t('filterDate')}</Text>
            </HStack>
          </Button>

          <DynamicButton
            color={'blue'}
            action={'custom'}
            icon={TbReload}
            onClick={handleReloadFilter}
          />

          <Spacer />

          <Stack spacing={0}>
            <Text color={'gray.500'} align={'right'}>
              {t('today')},
            </Text>
            <Text color={'gray.500'}>{moment().format('Do MMMM YYYY')}</Text>
          </Stack>
        </Flex>

        <Flex m="2" gap={3} direction={['column-reverse', 'row', 'row']}>
          {/* <Spacer /> */}
          <HStack>
            {searchUid?.map((x, i) => {
              if (i < 3)
                return (
                  <Box
                    key={i}
                    w={['30%', '', '']}
                    bgColor="gray.50"
                    borderRadius="md"
                    p="1"
                    cursor="pointer"
                    onClick={() => {
                      handleSearchGroup(x);
                    }}
                  >
                    <HStack>
                      <Avatar
                        size="xs"
                        name={x.document.name}
                        src={x.document.image}
                      />
                      <Text noOfLines={1}>{x.document.name}</Text>
                    </HStack>
                  </Box>
                );
              else return <></>;
            })}
          </HStack>
        </Flex>

        {searchParam?.length > 0 && (
          <HStack m={2}>
            {searchParam?.map((x, index) => {
              return (
                <Box
                  key={index}
                  bgColor="gray.50"
                  borderRadius="md"
                  p="1"
                  cursor="pointer"
                  onClick={() => handleRemoveUid(x)}
                >
                  <HStack>
                    <Avatar
                      size="xs"
                      name={x.document.name}
                      src={x.document.image}
                    />
                    <Text>{x.document.name}</Text>
                  </HStack>
                </Box>
              );
            })}

            <Spacer />
            <Button onClick={handleReset}>Reset</Button>
          </HStack>
        )}

        <HStack pb={1} pt={5}>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {t('dateRange')}:
          </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {moment.unix(dates.current.startDate).format('DD-MM-YYYY')}
          </Text>
          <Text color={'gray.500'} textTransform={'lowercase'}>
            {' '}
            {t('to')}{' '}
          </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {moment.unix(dates.current.endDate).format('DD-MM-YYYY')}
          </Text>
        </HStack>

        <TableContainer borderRadius={'sm'} boxShadow={'base'}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>{t('name')}</Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('avgScore', !sort.desc);
                  }}
                >
                  {t('score')}{' '}
                  {sort.sort === 'avgScore' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('tasks', !sort.desc);
                  }}
                >
                  {t('task')}{' '}
                  {sort.sort === 'tasks' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('avgTime', !sort.desc);
                  }}
                >
                  {t('time')}
                  {sort.sort === 'avgTime' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th cursor="pointer">{t('action')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((x, i) => (
                <Tr key={i}>
                  <Td>
                    <HStack>
                      <Avatar
                        name={x?.title}
                        src={x?.image ? x.image : 'https://bit.ly/broken-link'}
                      />
                      <Stack spacing={0}>
                        <Text
                          textTransform={'capitalize'}
                          fontWeight={'bold'}
                          fontSize="sm"
                        >
                          {x?.title}
                        </Text>
                        <Text fontSize="3xs">
                          {t('taskID')}: {x?.id}
                        </Text>
                      </Stack>
                    </HStack>
                  </Td>
                  <Td>
                    <Badge
                      fontWeight={'bold'}
                      fontSize={'sm'}
                      p={1}
                      colorScheme={
                        x?.avgScore > 7
                          ? 'green'
                          : x?.avgScore > 5
                            ? 'yellow'
                            : 'red'
                      }
                    >
                      {' '}
                      {x?.avgScore?.toFixed(2)}
                    </Badge>
                  </Td>
                  <Td>{x?.tasks}</Td>
                  <Td>
                    <Badge
                      p={1}
                      fontSize={'sm'}
                      fontWeight={'bold'}
                      colorScheme={'green'}
                    >
                      {msToTime(x?.avgTime)}
                    </Badge>
                  </Td>
                  <Td>
                    <Box>
                      <DynamicButton
                        size={'sm'}
                        action="read"
                        onClick={() => handleSeeHistory(x)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <HStack justifyContent={'center'} spacing={5} pt={4}>
          {page > 1 ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => getData(page - 1)}
              isDisabled={page > 1 ? false : true}
            >
              <HStack color="white" spacing={1}>
                <MdNavigateBefore size={20} />
                <Text>{t('prev')}</Text>
              </HStack>
            </Button>
          ) : null}

          {page < mongoData ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => getData(page + 1)}
              isDisabled={page < mongoData ? false : true}
            >
              <HStack color="white" spacing={1}>
                <Text>{t('next')}</Text>
                <Box>
                  <MdNavigateNext size={20} />
                </Box>
              </HStack>
            </Button>
          ) : null}
        </HStack>
      </Box>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('select')} {t('date')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <DatePicker onDateChange={onDateChange} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                {t('close')}
              </Button>
              <DynamicButton
                title={'Filter Kanban'}
                action="read"
                variant={'solid'}
                onClick={() => getData()}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ManagementScoreTask;
