/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable react/react-in-jsx-scope */
import { Box, Textarea } from '@chakra-ui/react';
import { Handle,  Position } from 'reactflow';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';
import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';

const selector = (state) => ({
  updateNodeLabel: state.updateNodeLabel,
  owner: state.owner,
});

function useShape({ type, width, height, color = '#9ca8b3', selected }) {
  const styles = { fill: color };

  switch (type) {
  case 'circle':
    return (
      <ellipse
        cx={width / 2}
        cy={height / 2}
        rx={width / 1.5}
        ry={height / 1.5}
        {...styles}
      />
    );
  case 'round-rect':
    return (
      <rect x={0} y={0} rx={20} width={width} height={height} {...styles} />
    );
  case 'hexagon':
    const cornersRadius = 25; // Panjang sisi runcing
    return (
      <path
        d={`M${cornersRadius},0 L${width - cornersRadius},0 L${width},${
          height / 2
        } L${
          width - cornersRadius
        },${height} L${cornersRadius},${height} L0,${height / 2} z`}
        {...styles}
      />
    );
  case 'diamond':
    return (
      <path d={`M0,${height / 2} L${width / 2},0 L${width},${height / 2} L${width / 2},${height} z`} {...styles} />
    );

  case 'arrow-rect':
    return (
      <path
        d={`M0,0 L${width - 10},0  L${width},${height / 2} L${
          width - 10
        },${height} L0,${height} z`}
        {...styles}
      />
    );
  case 'database':
    return (
      <path
        d={`M0,${height * 0.125}  L 0,${height - height * 0.125} A ${
          width / 2
        } ${height * 0.125} 0 1 0 ${width} ${
          height - height * 0.125
        } L ${width},${height * 0.125} A ${width / 2} ${
          height * 0.125
        } 0 1 1 0 ${height * 0.125} A ${width / 2} ${
          height * 0.125
        } 0 1 1 ${width} ${height * 0.125} A ${width / 2} ${
          height * 0.125
        } 0 1 1 0 ${height * 0.125} z`}
        {...styles}
        strokeWidth={selected ? styles.strokeWidth : 1}
      />
    );
  case 'triangle':
    return (
      <path
        d={`M0,${height} L${width / 2},0 L${width},${height} z`}
        {...styles}
      />
    );
  case 'parallelogram':
    return (
      <path
        d={`M0,${height} L${width * 0.25},0 L${width},0 L${
          width - width * 0.25
        },${height} z`}
        {...styles}
      />
    );
    //-- ini nambah shape yang baru yes --//
  case 'process':
    return <rect x={0} y={0} width={width} height={height} {...styles} />;

  case 'document':
    const waveHeight = 20; // Ketinggian gelombang
    const waveOffset = 0;
    return (
      <g>
        <rect x={0} y={0} width={width} height={height} {...styles} />
        <path
          d={`M${waveOffset},${height} Q${width / 4},${height + waveHeight} ${
            width / 2
          },${height}`}
          {...styles}
        />
        <path
          d={`M${width / 2},${height} Q${(width + width - waveOffset) / 3},${
            height - waveHeight
          } ${width - waveOffset},${height}`}
          fill="white"
        />
      </g>
    );
  case 'display':
    const bulletWidth = width; // Lebar peluru
    const bulletHeight = height; // Tinggi peluru
    const curveWidth = 23; // Lebar kurva
    const centerX = width / 1.3; // Posisi horizontal tengah lingkaran
    const radius = Math.min(bulletWidth, bulletHeight) / 2; // Radius lingkaran
    return (
      <g>
        {/* Bentuk peluru dengan sisi runcing lebih panjang */}
        <path
          d={`M${curveWidth},0 L${
            bulletWidth - curveWidth
          },0 L${bulletWidth},${bulletHeight / 2} L${
            bulletWidth - curveWidth
          },${bulletHeight} L${curveWidth},${bulletHeight} L0,${
            bulletHeight / 2
          } z`}
          {...styles}
        />
        {/* Lingkaran di tengah */}
        <circle cx={centerX} cy={bulletHeight / 2} r={radius} {...styles} />
      </g>
    );
  case 'manual-operation':
    const topWidth = width; // Lebar bagian atas trapesium
    const bottomWidth = width * 0.7; // Lebar bagian bawah trapesium
    const middleX = width / 2; // Posisi horizontal tengah
    return (
      <g>
        <polygon
          points={`${middleX - topWidth / 2},0 ${middleX + topWidth / 2},0 ${
            middleX + bottomWidth / 2
          },${height} ${middleX - bottomWidth / 2},${height}`}
          {...styles}
        />
      </g>
    );
  case 'predefined-process':
    const cornerRadius = 10; // Radius sudut tumpul
    return (
      <g>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          rx={cornerRadius}
          ry={cornerRadius}
          {...styles}
        />
        <text
          x={width / 2}
          y={height / 2}
          textAnchor="middle"
          alignmentBaseline="middle"
          {...styles}
        >
          Predefined
        </text>
      </g>
    );
  case 'offpage-reference':
    const connectorLength = 30; // Panjang penghubung ke halaman lain
    const shapeWidth = width; // Lebar persegi panjang dan segitiga
    const shapeHeight = height; // Tinggi persegi panjang dan segitiga
    return (
      <>
        {/* Bentuk persegi panjang */}
        <rect
          x={0}
          y={0}
          width={shapeWidth}
          height={shapeHeight}
          {...styles}
        />
        {/* Segitiga terbalik */}
        <polygon
          points={`0,${shapeHeight} ${shapeWidth / 2},${
            shapeHeight + connectorLength
          } ${shapeWidth},${shapeHeight}`}
          fill={styles.stroke}
          {...styles}
        />
        {/* Garis penghubung */}
        {/* <line x1={shapeWidth / 2} y1={shapeHeight + connectorLength} x2={shapeWidth / 2} y2={shapeHeight} {...styles} /> */}
      </>
    );
  default:
    return null;
  }
}

function ProductivityShapeNode({ data, selected, id }) {
  const width = data?.width || 500;
  const height = data?.height || 500;
  const shape = useShape({
    type: data?.shape,
    width,
    height,
    color: data?.color,
    selected,
  });
  const { updateNodeLabel, owner } = useFlowchartStore(selector, shallow);
  const onChange = useCallback(
    (evt) => {
      updateNodeLabel(id, evt.target.value);
    },
    [id]
  );

  return (
    <div style={{ position: 'relative' }}>
      <Box>
        <Handle
          id="top"
          style={{ width: '9px', height: '9px', backgroundColor: 'black' }}
          position={Position.Top}
          type="source"
        />
        <Handle
          id="right"
          style={{ width: '9px', height: '9px', backgroundColor: 'black' }}
          position={Position.Right}
          type="source"
        />
        <Handle
          id="bottom"
          style={{ width: '9px', height: '9px', backgroundColor: 'black' }}
          position={Position.Bottom}
          type="source"
        />
        <Handle
          id="left"
          style={{ width: '9px', height: '9px', backgroundColor: 'black' }}
          position={Position.Left}
          type="source"
        />
      </Box>
      <svg
        style={{ display: 'block', overflow: 'visible' }}
        width={width}
        height={height}
      >
        {shape}
      </svg>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        {owner ? (
          <Textarea
            type="text"
            onChange={onChange}
            color={'white'}
            textAlign={'center'}
            defaultValue={data?.label}
            placeholder="type here..."
            fontSize={'13px'}
            className="nodrag"
            fontWeight={500}
            style={{ resize: 'none', borderColor: 'black' }}
            textShadow="0.5px 0.5px #000000"
            variant={'unstyled'}
            width={width / 1.2}
            minH={0}
            height={height / 1.3}
            justifyContent={'center'}
            alignItems={'center'}
            m={0}
            mt={2}
            p={0}
          />
        ) : (
          <div
            style={{
              fontFamily: 'monospace',
              fontWeight: 'bold',
              color: 'white',
              borderColor: 'black',
              fontSize: 12,
            }}
          >
            {data?.label}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductivityShapeNode;
