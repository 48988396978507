/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import { useSearchParams } from 'react-router-dom';
import { setDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import RichTextEditor from '../../Components/Quill/RichTextEditor';
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import { useTranslation } from 'react-i18next';

function ProductivityDocsEditPage() {
  const {t} = useTranslation()
  const dataParam = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');

  const [modalSlides, setModalSlides] = useState(false);
  const [slideLink, setSlideLink] = useState('');

  const [heading, setHeading] = useState(
    dataParam?.state?.heading || 'Untitled'
  );
  const [description, setDescription] = useState(
    dataParam?.state?.description || 'Page Description (Optional)'
  );
  const [content, setContent] = useState(dataParam?.state?.content);

  const globalState = useUserStore();

  const searchCompanyName = globalState?.companies?.find(
    (x) => x.id === globalState?.currentCompany
  );

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setContent((prevContent) => prevContent + ` ${htmlContent}`);
    }
  };

  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const handleSave = async () => {
    const dataToSave = {
      heading,
      description,
      content,
      title: heading,
      lastUpdated: new Date(),
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    };

    try {
      const collectionName = 'files';
      const docName = `${id}`;

      try {
        await setDocumentFirebase(collectionName, docName, dataToSave);

        toast({
          title: 'Success',
          description: 'Succes edit data.',
          isClosable: true,
          duration: 9000,
          status: 'success',
        });

        navigate(-1);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description:error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });            }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });          }
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openModalSlides() {
    setModalSlides(true);
  }

  function closeModalSlides() {
    setModalSlides(false);
    setSlideLink('');
  }

  const handleAddLink = () => {
    if (slideLink !== '') {
      setContent((prevContent) => prevContent + ` ${slideLink}`);
      closeModalSlides();
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <Stack>
          <BackButtons />
        </Stack>
        <Spacer />
        <Stack>
          <Heading size={'md'}>
            {' '}
            {t('edit')} {t('documentation')}
          </Heading>
        </Stack>
      </HStack>

      <Stack
        spacing={3}
        borderRadius={'md'}
        p={[1, 1, 5]}
        minH="700px"
        shadow="md"
      >
        <Stack alignItems={'flex-end'} justifyContent="flex-end">
          <HStack>
            <Button
              onClick={openModalSlides}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('add')} Slides
            </Button>

            <Button
              onClick={openModal}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('add')} {t('file')}
            </Button>
            <Button
              onClick={handleSave}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('save')}
            </Button>
          </HStack>
        </Stack>

        <Heading
          as="h1"
          size="xl"
          p={1}
          contentEditable
          placeholder={t('inputTitleDoc')}
          onBlur={(e) => setHeading(e.target.textContent)}
        >
          {heading ? heading : 'Untitled'}
        </Heading>
        {/* Tampilkan dan edit deskripsi */}
        <Text
          p={1}
          contentEditable
          placeholder={t('inputDescDoc')}
          onBlur={(e) => setDescription(e.target.textContent)}
        >
          {description ? description : 'Page Description (Optional)'}
        </Text>

        <RichTextEditor value={content} onChange={handleContentChange} />
      </Stack>

      <Modal isOpen={modalSlides} onClose={closeModalSlides}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {' '}
            {t('add')} {t('link')} Slides
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input
              type="text"
              placeholder="link google slide"
              onChange={(e) => setSlideLink(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleAddLink()}>
              {t('save')}
            </Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${searchCompanyName?.name}/docs-file`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
}

export default ProductivityDocsEditPage;
