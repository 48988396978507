import React from 'react';
import ProductivityDashboardPage from '../Pages/Productivity/ProductivityDashboardPage';
import ProductivityKanbanPage from '../Pages/Productivity/ProductivityKanbanPage';
import ProductivityKanbanViewPage from '../Pages/Productivity/ProductivityKanbanViewPage';
import ProductivityKanbanEditPage from '../Pages/Productivity/ProductivityKanbanEditPage';
import ProductivityTaskViewPage from '../Pages/Productivity/ProductivityTaskViewPage';
import ProductivityDocsPage from '../Pages/Productivity/ProductivityDocsPage';
import ProductivityDocsViewPage from '../Pages/Productivity/ProductivityDocsViewPage';
import ProductivityDocsViewFolderPage from '../Pages/Productivity/ProductivityDocsViewFolderPage';
import ProductivityDocsEditPage from '../Pages/Productivity/ProductivityDocsEditPage';
import ProductivityDocsCreatePage from '../Pages/Productivity/ProductivityDocsCreatePage';
import ProductivityFlowchartPage from '../Pages/Productivity/ProductivityFlowchartPage';
import ProductivityAssetsPage from '../Pages/Productivity/ProductivityAssetsPage';
import ProductivityAssetsDetailPage from '../Pages/Productivity/ProductivityAssetsDetailPage';
import ProductivityListMindmap from '../Pages/Productivity/ProductivityListMindmap';
import ProductivityMindmapPage from '../Pages/Productivity/ProductivityMindmapPage';
import ProductivityMyKanbanViewPage from '../Pages/Productivity/ProductivityMyKanbanViewPage';

const ProductivityRouter = [
  //dashboard
  {
    path: '/dashboard',
    element: <ProductivityDashboardPage />,
  },
  {
    path: '/productivity',
    element: <ProductivityDashboardPage />,
  },



  //kanban
  {
    path: '/:type',
    element: <ProductivityKanbanPage />,
  },

  {
    path: '/:type/view',
    element: <ProductivityMyKanbanViewPage />,
  },

  {
    path: '/:type/view/:id/:title',
    element: <ProductivityKanbanViewPage />,
  },

  {
    path: '/:type/edit/:id',
    element: <ProductivityKanbanEditPage />,
  },
  {
    path: '/:type/view/:id/:title/:taskId',
    element: <ProductivityTaskViewPage />,
  },


  //docs n files
  {
    path: '/docs',
    element: <ProductivityDocsPage />,
  },
  {
    path: '/docs/file/:id',
    element: <ProductivityDocsViewPage />,
  },
  {
    path: '/docs/folder/:id',
    element: <ProductivityDocsViewFolderPage />,
  },
  {
    path: '/docs/edit',
    element: <ProductivityDocsEditPage />,
  },
  {
    path: '/docs/create',
    element: <ProductivityDocsCreatePage />,
  },

  //flowchart
  {
    path: '/flowchart',
    element: <ProductivityFlowchartPage />,
  },

  //asset
  {
    path: '/assets',
    element: <ProductivityAssetsPage />,
  },
  {
    path: '/assets/:company/:id',
    element: <ProductivityAssetsDetailPage />,
  },

  //mindmap
  {
    path: '/mindmap',
    element: <ProductivityListMindmap />,
  },
  {
    path: '/create-mindmap',
    element: <ProductivityMindmapPage />,
  },

];

export default ProductivityRouter;
