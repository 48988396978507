/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox, VStack } from '@chakra-ui/react';

function BillingsListPage({ productList, selectedProducts, setSelectedProducts }) {

  const handleCheckboxChange = (product) => {
    setSelectedProducts(product);
  };


  return (
    <VStack align="start" spacing={4}>
      {productList.map((product, i) => (
        <Checkbox
          key={i}
          isChecked={product?.id === selectedProducts?.id}
          onChange={() => handleCheckboxChange(product)}
        >
          {product.name}
        </Checkbox>
      ))}
    </VStack>
  );
}

export default BillingsListPage;
