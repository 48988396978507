import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import useUserStore from '../../Hooks/Zustand/Store';
import { FaTag } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const KanbanOperationEditPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const globalState = useUserStore();
  const toast = useToast();
  const alertDelete = useDisclosure();
  const navigate = useNavigate();

  const [data, setData] = useState(state || {});
  const [newTags, setNewTags] = useState();
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedPic, setSelectedPic] = useState('');
  const [pics, setPics] = useState(data.pic || []);
  const [score] = useState(data.default_score || 0);

  const addPic = () => {
    if (selectedPic && !pics.find((pic) => pic.uid === selectedPic)) {
      const user = data.usersDisplay.find((user) => user.id === selectedPic);
      if (user) {
        setPics([
          ...pics,
          { name: user.name, uid: selectedPic, email: user.email },
        ]);
      }
    }
  };

  const removePic = (uid) => {
    const updatedPics = pics.filter((pic) => pic.uid !== uid);
    setPics(updatedPics);
  };

  const param = useParams();

  const modalAddFeatures = useDisclosure();

  const handleNewTags = (e) => {
    setNewTags(e.target.value);
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data.tags) ? data.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setData({ ...data, tags: newFeatureList });
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...data.tags];
    updatedFeatures.splice(index, 1);
    setData({ ...data, tags: updatedFeatures });
  };

  const handleOpenAlertDelete = async () => {
    alertDelete?.onOpen();
  };

  const handleDeleteKanban = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: t('toast.noAccess'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }

    globalState.setIsLoading(true);

    try {
      await deleteDocumentFirebase('operations', param?.id);

      toast({
        status: 'success',
        title: 'Success',
        description: 'Operation deleted',
        duration: 3000,
      });
    } catch (error) {
      toast({
        status: 'success',
        title: t('toast.failed'),
        description: 'Failed delete operation',
        duration: 3000,
      });
    } finally {
      globalState.setIsLoading(false);
      navigate('/operation');
    }
  };

  const handleRemoveUser = (index) => {
    if (index < 0 || index >= data.usersDisplay.length) {
      return; // Pastikan index valid
    }

    const updatedUsersDisplay = [...data.usersDisplay];
    updatedUsersDisplay.splice(index, 1);

    const userToRemove = data.usersDisplay[index];
    const updatedUsers = data.users.filter((uid) => uid !== userToRemove.id);

    setData({
      ...data,
      usersDisplay: updatedUsersDisplay,
      users: updatedUsers,
    });
  };

  const handleSubmit = async () => {
    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: t('toast.alert'),
          description: t('toast.noAccess'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    globalState.setIsLoading(true);

    const updateData = data;
    updateData.pic = pics || state.pic;
    updateData.projectId = selectedProject || state.projectId;
    updateData.default_score = parseInt(score || 0);

    const collectionName = 'operations';
    const docName = param.id;

    try {
      await updateDocumentFirebase(collectionName, docName, updateData);
      navigate('/operation');

      toast({
        status: 'success',
        duration: 9000,
        title: 'Edit Success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={2}>
      <HStack>
        <HStack>
          <BackButtons />
          <Text textTransform={'capitalize'} fontSize="xl" fontWeight={'bold'}>
            {t('edit')} {state?.title}
          </Text>
        </HStack>
        <Spacer />
        <DynamicButton
          action={'delete'}
          variant="solid"
          title={t('deletePermanent')}
          onClick={handleOpenAlertDelete}
        />
      </HStack>

      <Stack spacing={2} shadow={'md'} p={[1, 1, 5]}>
        <Text>{t('title')}</Text>
        <Input
          defaultValue={state?.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />

        <Text>{t('Operatypetion')}</Text>
        <Input defaultValue={state?.type} isDisabled />

        <Text>{t('projectId')}</Text>

        <Select
          defaultValue={state.projectId}
          placeholder={t('project')}
          onChange={(e) => setSelectedProject(e.target.value)}
        >
          {globalState.projects?.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </Select>

        <Text>{t('pic')}</Text>
        <HStack>
          <Select
            value={selectedPic}
            placeholder={t('user')}
            onChange={(e) => setSelectedPic(e.target.value)}
          >
            {data?.usersDisplay?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Select>
          <DynamicButton variant={'solid'} action={'create'} onClick={addPic} />
        </HStack>
        {pics?.map((pic) => {
          return (
            <Box key={pic.uid} shadow="md" padding={3} mb={3}>
              <Text fontWeight="bold">{pic.name}</Text>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => removePic(pic.uid)}
              >
                {t('clear')} {t('pic')}
              </Button>
            </Box>
          );
        })}

        <Box shadow="md" padding={3} mb={3}>
          <Text fontWeight="bold">Tags</Text>
          <HStack
            spacing={3}
            my={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {data?.tags?.length > 0 ? (
              <SimpleGrid columns={[2, 4, 6]} gap={3}>
                {data?.tags?.map((x, i) => (
                  <Box
                    key={i}
                    py={1}
                    px={2}
                    border={'1px'}
                    borderRadius={'sm'}
                    shadow={'base'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <HStack textTransform={'capitalize'}>
                      <Text fontWeight={500} textTransform={'capitalize'}>
                        {x}
                      </Text>
                      <Icon
                        onClick={() => handleRemoveFeature(i)}
                        cursor="pointer"
                        pos={'absolute'}
                        color={'red.300'}
                        top={-1}
                        right={-1}
                        as={MdCancel}
                        boxSize={4}
                      />
                      <Box
                        cursor={'pointer'}
                        size="xs"
                        borderRadius={'full'}
                        color={'blue.600'}
                        pos={'absolute'}
                        bottom={-2}
                        right={-2}
                      >
                        <FaTag />
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text>{t('noTagData')}</Text>
            )}
          </HStack>

          <Box align={'center'} py={3}>
            <Button
              size={'sm'}
              colorScheme="green"
              onClick={modalAddFeatures.onOpen}
            >
              {t('addNewTag')}
            </Button>
          </Box>
        </Box>

        <Box shadow="md" padding={3} mb={3}>
          <Text fontWeight="bold">{t('team')}</Text>
          <HStack
            spacing={3}
            my={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {data?.usersDisplay?.length > 0 ? (
              <SimpleGrid columns={[2, 4, 6]} gap={3}>
                {data?.usersDisplay?.map((x, i) => (
                  <Box
                    key={i}
                    py={1}
                    px={2}
                    border={'1px'}
                    borderRadius={'sm'}
                    shadow={'base'}
                    pos={'relative'}
                    borderColor={'gray.300'}
                  >
                    <HStack textTransform={'capitalize'} overflow="hidden">
                      <Text fontWeight={500} textTransform={'capitalize'}>
                        {x.name}
                      </Text>
                      <Icon
                        onClick={() => handleRemoveUser(i)}
                        cursor="pointer"
                        pos={'absolute'}
                        color={'red.300'}
                        top={-1}
                        right={-1}
                        as={MdCancel}
                        boxSize={4}
                      />
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text>{t('noTeam')}</Text>
            )}
          </HStack>
        </Box>

        {/* <Text>Default Score</Text>

        <Flex>
          <NumberInput maxW='100px' mr='2rem' min={0} max={10} value={score} onChange={handleChange}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Slider
            flex='1'
            focusThumbOnChange={false}
            value={score}
            min={0}
            max={10}
            onChange={handleChange}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb fontSize='sm' boxSize='32px' children={score} />
          </Slider>
        </Flex> */}

        <Stack alignItems={'flex-end'} justifyContent="flex-end">
          <DynamicButton
            action={'create'}
            title={t('save')}
            variant={'solid'}
            // isLoading={loading}
            onClick={() => handleSubmit()}
          />
        </Stack>
      </Stack>

      <Modal
        isOpen={modalAddFeatures.isOpen}
        onClose={modalAddFeatures.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('addNewTag')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder={t('addNewTag')} onChange={handleNewTags} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" onClick={handleAddFeature}>
              {t('add')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        // isLoading={isLoading}
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteKanban', { title: state?.title })}
        onAction={handleDeleteKanban}
      />
    </Stack>
  );
};

export default KanbanOperationEditPage;
