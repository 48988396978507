import React from 'react';
import KanbanOperationPage from '../Pages/KanbanOperation/KanbanOperationPage';
import LineEditPage from '../Pages/Operation/LineEditPage';
import LineIndexPage from '../Pages/Operation/LineIndexPage';
import LinePage from '../Pages/Operation/LinePage';
import LineTaskViewPage from '../Pages/Operation/LineTaskViewPage';
import LineViewPage from '../Pages/Operation/LineViewPage';
import KanbanOperationEditPage from '../Pages/KanbanOperation/KanbanOperationEditPage';

const KanbanOperationRouter = [
  {
    path: '/operation',
    element: <KanbanOperationPage />,
  },

  {
    path: '/operation/edit/:id',
    element: <KanbanOperationEditPage />,
  },

  {
    path: '/line',
    element: <LinePage />,
  },

  {
    path: '/operation/view/:id/:title',
    element: <LineIndexPage />,
  },

  {
    path: '/line/view/:id/:title',
    element: <LineViewPage />,
  },


  {
    path: '/line/edit/:id',
    element: <LineEditPage />,
  },

  {
    path: '/line/view/:id/:title/:taskId',
    element: <LineTaskViewPage />,
  },

];

export default KanbanOperationRouter;