import { Grid, HStack, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import ChartsBarKanban from '../../Components/Charts/ChartsBarKanban';
import ChartsPieKanban from '../../Components/Charts/ChartsPieKanban';
import { useTranslation } from 'react-i18next';

function ManagementScoreTaskDetail() {
  const {t} = useTranslation()
  const params = useParams();

  const [dataKanban, setDataKanban] = useState({});
  const toast = useToast()


  const getData = async () => {
    try {
      const result = await getSingleDocumentFirebase('files', params.id);
      setDataKanban(result);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      }
  };

  useEffect(() => {
    getData();

    return () => {
      setDataKanban({});
    };
  }, []);

  return (
    <Stack>
      <BackButtons />
      <Stack p={5} mt={5} spacing={5}>
        {dataKanban && (
          <HStack alignItems={'flex-start'} justifyContent="flex-start">
            <Stack>
              <Text
                fontSize={'2xl'}
                fontWeight={'bold'}
                textTransform="capitalize"
              >
                {dataKanban?.title}
              </Text>
              <Text textAlign="left" fontSize="2xs">
                {t('updated')}:{' '}
                {moment.unix(dataKanban?.lastUpdated?.seconds).fromNow()}
              </Text>
            </Stack>
            <Spacer />
            <Stack>
              {dataKanban?.pic?.length > 0 && (
                <Stack spacing={1}>
                  <Text fontWeight={'bold'}>{t('leader')} :</Text>
                  <Stack spacing={0}>
                    {dataKanban?.pic?.map((x, index) => {
                      return (
                        <Text fontSize={'xs'} color="gray.700" key={index}>
                          {x?.name} - {x?.email}
                        </Text>
                      );
                    })}
                  </Stack>
                </Stack>
              )}
            </Stack>

            <Stack spacing={0}>
              <Text color={'gray.500'} align={'right'}>
                {t('today')},
              </Text>
              <Text color={'gray.500'}>{moment().format('Do MMMM YYYY')}</Text>
            </Stack>
          </HStack>
        )}

        <Stack w="full">
          <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} gap={3}>
            <Stack
              overflow={'auto'}
              spacing={2}
              borderTopWidth={3}
              borderBottomWidth={3}
              borderColor="green.500"
              py={4}
              px={2}
              borderRadius="md"
              shadow="md"
            >
              <ChartsPieKanban />
            </Stack>

            <Stack
              overflow={'auto'}
              spacing={2}
              borderTopWidth={3}
              borderBottomWidth={3}
              borderColor="green.500"
              py={4}
              px={2}
              borderRadius="md"
              shadow="md"
            >
              <ChartsBarKanban />
            </Stack>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ManagementScoreTaskDetail;
