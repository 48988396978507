import * as XLSX from 'xlsx'; // Menggunakan '* as' untuk mengimpor semua dari 'xlsx'
import { getUserByUid } from './getUser';
import moment from 'moment';
export const exportToExcel = (tableHeader,dataTable,fileName ) => {
  // Prepare the data for export
  const exportData = [
    tableHeader, // Add header row
    ...dataTable.map((row) => tableHeader.map((header) => row[header] || '')) // Map data rows
  ];
  
  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      
  // Create a workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
  // untuk penamaan file
  const dateObj = new Date()
  const dateTime = `${dateObj.getHours()}${dateObj.getMinutes()}${dateObj.getSeconds()}`
  const dateName = `${dateTime}${Math.floor((Math.random().toFixed(2)*100))}`
  
  // Generate a file and download it
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
  const a = document.createElement('a');
  const url = URL.createObjectURL(data);
  a.href = url;
  a.download = `${fileName?.replace(/[%\s()]/g,'')}_${dateName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);


}

export const mapDataKanban = (data, globalState) => {
  const tableHeader = ['asignee', 'category','column','companyId','createdAt','createdBy','dueOn', 'files', 'filesId','filesName', 'id', 'label', 'lastUpdated', 'lastUpdatedBy', 'pic','projectId','score','timeFrom','timeTo', 'title', 'comments']
  const mappedData = data.flat().map((x) => {return({
    'asignee' : x?.kanbanData?.asignee?.map((uid) => {
      const user = getUserByUid(globalState?.users, uid); return user?.name}).join(', ')
    , 
    'category' : x?.kanbanData?.category,
    'column': x?.kanbanData?.column,
    'companyId': x?.kanbanData?.companyId,
    'createdAt' : moment(x?.kanbanData?.createdAt?.seconds*1000).format(
      'ddd, DD MMMM YYYY HH:mm'
    ),
    'createdBy' : getUserByUid(globalState?.users?.uid, x.kanbanData?.createdBy) ,
    'dueOn' : moment(x?.kanbanData?.dueOn*1000).format(
      'ddd, DD MMMM YYYY HH:mm'
    ), 
    'files' : x?.kanbanData?.files?.map((x) => x?.link).join(', '), 
    'filesId': x?.kanbanData?.filesId,
    'filesName':x?.kanbanData?.filesName,
    'id': x?.kanbanData?.id, 
    'label': x?.kanbanData?.label, 
    'lastUpdated' : moment(x?.kanbanData?.lastUpdated?.seconds*1000).format(
      'ddd, DD MMMM YYYY HH:mm'
    ), 
    'lastUpdatedBy':  x?.kanbanData?.lastUpdatedBy?.email, 
    'pic': x?.kanbanData?.pic?.map((uid) => {
      const user = getUserByUid(globalState?.users, uid); return user?.name}).join(', '),
    'projectId': x?.kanbanData?.projectId,
    'score': x?.kanbanData?.score,
    'timeFrom': moment(x?.kanbanData?.timeFrom*1000).format(
      'ddd, DD MMMM YYYY HH:mm'
    ),
    'timeTo' : moment(x?.kanbanData?.timeTo*1000).format(
      'ddd, DD MMMM YYYY HH:mm'
    ), 
    'title': x?.kanbanData?.title,
    'comments': x?.commentsUser?.map((x) => x?.comments).join(', ')
  })})

  return {tableHeader, mappedData}
}