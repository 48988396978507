/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import RichTextEditor from '../../Components/Quill/RichTextEditor';
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import { useTranslation } from 'react-i18next';

function ProductivityDocsCreatePage() {
  const {t} = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [modalSlides, setModalSlides] = useState(false);
  const [slideLink, setSlideLink] = useState('');

  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');

  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const toast = useToast();

  const globalState = useUserStore();

  const navigate = useNavigate();

  const handleContentChange = (value) => {
    setContent(value);
  };

  const searchCompanyName = globalState?.companies?.find(
    (x) => x.id === globalState?.currentCompany
  );

  const handleSave = async () => {
    // Gabungkan data heading, description, dan isi konten ke dalam satu objek

    const companyId = globalState.currentCompany;

    const dataToSave = {
      heading,
      title: heading,
      description,
      content,
      category: 'file',
      type: 'docs&files',
      createdAt: new Date(),
      folderId: id ? id : '',
      projectId: globalState?.currentProject,
      companyId: globalState?.currentCompany
    };

    const collectionName = 'files';

    try {
      await addDocumentFirebase(
        collectionName,
        dataToSave,
        companyId
      );

      toast({
        title: 'Success',
        description: 'Succes to add new file.',
        isClosable: true,
        duration: 9000,
        status: 'success',
      });

      navigate(-1);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });          }
  };

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setContent((prevContent) => prevContent + ` ${htmlContent}`);
    }
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openModalSlides() {
    setModalSlides(true);
  }

  function closeModalSlides() {
    setModalSlides(false);
    setSlideLink('');
  }

  const handleAddLink = () => {
    if (slideLink !== '') {
      setContent((prevContent) => prevContent + ` ${slideLink}`);
    }
    closeModalSlides();
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      {/* Tombol untuk membuka modal */}

      <HStack>
        <Stack>
          <BackButtons />
        </Stack>
        <Spacer />
        <Stack>
          <Heading size={'md'}>{t('documentation')}</Heading>
        </Stack>
      </HStack>

      <Stack
        spacing={3}
        borderRadius={'md'}
        p={[1, 1, 5]}
        minH="700px"
        shadow="md"
      >
        <Stack alignItems={'flex-end'} justifyContent="flex-end">
          <HStack>
            <Button
              onClick={openModalSlides}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('add')} Slides
            </Button>
            <Button
              onClick={openModal}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('add')} {t('file')}
            </Button>
            <Button
              onClick={handleSave}
              size="sm"
              colorScheme={'blue'}
              variant="outline"
            >
              {t('save')}
            </Button>
          </HStack>
        </Stack>
        {/* Tampilkan dan edit heading */}
        <Heading
          as="h1"
          size="xl"
          p={1}
          contentEditable
          placeholder={t('inputTitleDoc')}
          onBlur={(e) => setHeading(e.target.textContent)}
        >
          {heading ? heading : 'Untitled'}
        </Heading>
        {/* Tampilkan dan edit deskripsi */}
        <Text
          p={1}
          contentEditable
          placeholder={t('inputDescDoc')}
          onBlur={(e) => setDescription(e.target.textContent)}
        >
          {description ? description : 'Page Description (Optional)'}
        </Text>

        <Divider />

        <RichTextEditor value={content} onChange={handleContentChange} />
      </Stack>

      <Modal isOpen={modalSlides} onClose={closeModalSlides}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {' '}
            {t('add')} {t('link')} Slides
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input
              type="text"
              placeholder='Link google slides'
              onChange={(e) => setSlideLink(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleAddLink()}>
              {t('save')}
            </Button>
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${searchCompanyName.name}/docs-file`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
}

export default ProductivityDocsCreatePage;
