/* eslint-disable react/display-name */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Text,
  AvatarGroup,
  Avatar,
  Tooltip,
  Flex,
  Button,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { ViewMode, Gantt } from 'gantt-task-react';
import { ViewSwitcher } from './ViewSwitcher';
import { useNavigate } from 'react-router';
import useUserStore from '../../../Hooks/Zustand/Store';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import { getCollectionFirebaseV4 } from '../../../Api/firebaseApi';
import TooltipComponent from './TooltipComponent';
import useKanbanStore from '../../../Hooks/Zustand/kanbanStore';
import { useTranslation } from 'react-i18next';

const MyKanbanGanttComponent = ({ kanbanData, filterData }) => {
  const {t} = useTranslation()
  const globalState = useUserStore();
  const navigate = useNavigate();
  const { userDisplay } = useKanbanStore();
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const [columnsData, setColumnsData] = useState([]);
  const [columnsData2, setColumnsData2] = useState([]);
  const allData = [...columnsData, ...columnsData2];

  const [allDataLoaded, setAllDataLoaded] = useState(false);

  let columnWidth = 80;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    let unsubscribe = () => {};

    if (globalState.currentCompany && !filterData?.search) {
      let collectionRef = collection(db, 'kanban');
      collectionRef = query(
        collectionRef,
        where('asignee', 'array-contains', globalState?.uid)
      );
      collectionRef = query(
        collectionRef,
        where('companyId', '==', globalState?.currentCompany)
      );

      if (filterData?.category)
        collectionRef = query(
          collectionRef,
          where('category', '==', filterData?.category)
        );

      if (filterData?.label)
        collectionRef = query(
          collectionRef,
          where('label', '==', filterData.label)
        );

      if (filterData?.createdBy)
        collectionRef = query(
          collectionRef,
          where('createdBy', '==', filterData?.createdBy)
        );

      if (filterData?.asignee)
        collectionRef = query(
          collectionRef,
          where('asignee', 'array-contains-any', filterData.asignee)
        );

      collectionRef = query(collectionRef, orderBy('lastUpdated', 'desc'));
      collectionRef = query(collectionRef, limit(10));

      unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        const addTask = [];

        querySnapshot.forEach((doc) => {
          addTask.push({ id: doc.id, ...doc.data() });
        });
        setColumnsData(addTask);
      });
    }

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [globalState.currentCompany, filterData]);

  useEffect(() => {
    const filteredData = allData.filter((item) => item.column !== 'backlog');
    setTasks(
      filteredData.map((task) => ({
        start: new Date(
          task.time_backlog_at?.seconds
            ? task.time_backlog_at.seconds * 1000
            : task.createdAt?.seconds * 1000
        ),
        end: task.time_completed_at
          ? new Date(task.time_completed_at.seconds * 1000)
          : new Date(),
        id: task.id,
        project: kanbanData?.title,
        progress: 0,
        data: task,
        styles: {
          backgroundColor: (() => {
            switch (task.column) {
              case 'backlog':
                return '#F56565';
              case 'todos':
                return '#4299E1';
              case 'progress':
                return '#48BB78';
              case 'review':
                return '#ECC94B';
              case 'done':
                return '#A0AEC0';
              default:
                return 'white';
            }
          })(),
        },
      }))
    );
  }, [columnsData, columnsData2]);

  const handleLoad = () => {
    const conditions = [
      { field: 'asignee', operator: 'array-contains', value: globalState.uid },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 10;
    let startAfter = '';

    if (columnsData2?.length > 0) {
      startAfter = columnsData2[columnsData2?.length - 1].lastUpdated;

      if (filterData?.category)
        conditions.push({
          field: 'category',
          operator: '==',
          value: filterData?.category,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });
      if (filterData?.createdBy)
        conditions.push({
          field: 'createdBy',
          operator: '==',
          value: filterData?.createdBy,
        });

      if (filterData?.asignee)
        conditions.push({
          field: 'asignee',
          operator: 'array-contains-any',
          value: filterData?.asignee,
        });
    } else {
      startAfter = columnsData[columnsData.length - 1].lastUpdated;
      if (filterData?.category)
        conditions.push({
          field: 'category',
          operator: '==',
          value: filterData?.category,
        });

      if (filterData?.createdBy)
        conditions.push({
          field: 'createdBy',
          operator: '==',
          value: filterData?.createdBy,
        });

      if (filterData?.label)
        conditions.push({
          field: 'label',
          operator: '==',
          value: filterData?.label,
        });

      if (filterData?.asignee)
        conditions.push({
          field: 'asignee',
          operator: 'array-contains-any',
          value: filterData?.asignee,
        });
    }

    getCollectionFirebaseV4(
      'kanban',
      { conditions },
      { sortBy },
      { limitValue },
      { startAfterData: startAfter }
    ).then((x) => {
      const updateData = [...columnsData2, ...x];
      setColumnsData2(updateData);

      if (x.length === 0) {
        setAllDataLoaded(true);
      }
    });
  };

  const handleSelect = (task) => {
    navigate(`${task.id}`, { state: { type: 'existing' } });
  };

  const searchTasks = () => {
    if (filterData.search && filterData.search.trim() !== '') {
      const searchTerm = filterData.search.trim().toLowerCase();
      const filteredTasks = allData.filter((task) => {
        return task.title.toLowerCase().includes(searchTerm);
      });
      setColumnsData(filteredTasks);
    } else {
      setColumnsData(allData);
    }
  };

  useEffect(() => {
    searchTasks();
  }, [filterData?.search]);

  // const handleTaskChange = (task) => {
  //   let newTasks = tasks.map((t) => (t.id === task.id ? task : t));

  //   if (task.project) {
  //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
  //     const project =
  //       newTasks[newTasks.findIndex((t) => t.id === task.project)];
  //     if (
  //       project.start.getTime() !== start.getTime() ||
  //       project.end.getTime() !== end.getTime()
  //     ) {
  //       const changedProject = { ...project, start, end };
  //       newTasks = newTasks.map((t) =>
  //         t.id === task.project ? changedProject : t
  //       );
  //     }
  //   }
  //   setTasks(newTasks);
  // };

  // const handleTaskDelete = (task) => {
  //   const conf = window.confirm('Are you sure about ' + task.name + ' ?');
  //   if (conf) {
  //     setTasks(tasks.filter((t) => t.id !== task.id));
  //   }
  //   return conf;
  // };

  // const handleProgressChange = async (task) => {
  //   setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  //   console.log('On progress change Id:' + task.id);
  // };

  // const handleDblClick = (task) => {
  //   alert('On Double Click event Id:' + task.id);
  // };

  // const handleExpanderClick = (task) => {
  //   setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  //   console.log('On expander click Id:' + task.id);
  // };

  const TaskListHeader = () => {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        p={3}
        border={'1px'}
        borderColor={'gray.300'}
      >
        <Text fontWeight={'bold'} color={'gray.500'}>
          {t('assignee')}
        </Text>
      </Flex>
    );
  };

  const TaskListTable = memo(
    ({ tasks }) => {
      return (
        <Stack spacing={0}>
          {tasks.map((task, index) => (
            <HStack
              key={index}
              border={'1px'}
              borderColor={'gray.300'}
              p={3}
              h={'40px'}
              alignContent={'center'}
            >
              <AvatarGroup size="sm" max={1}>
                {task?.data.asignee ? (
                  task?.data.asignee?.sort()?.map((x, i) => {
                    const name = userDisplay?.find((y) => y.id === x)?.name;
                    return (
                      <Tooltip key={i} label={name} aria-label="Name">
                        <Avatar size="sm" name={name} />
                      </Tooltip>
                    );
                  })
                ) : (
                  <></>
                )}
              </AvatarGroup>
            </HStack>
          ))}
        </Stack>
      );
    },
    (prev, next) => {
      return (
        prev.tasks.length === next.tasks.length &&
        prev.tasks.every((x, i) => x.id === next.tasks[i].id)
      );
    }
  );

  return (
    <Box>
      <Box>
        <ViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />
      </Box>
      {tasks.length > 0 ? (
        <Gantt
          tasks={tasks}
          viewMode={view}
          // onDateChange={handleTaskChange}
          // onDelete={handleTaskDelete}
          // onProgressChange={handleProgressChange}
          // onDoubleClick={handleDblClick}
          // onExpanderClick={handleExpanderClick}
          onSelect={handleSelect}
          listCellWidth={isChecked ? '155px' : ''}
          columnWidth={columnWidth}
          fontFamily="Roboto"
          rowHeight={40}
          fontSize={15}
          TooltipContent={TooltipComponent}
          todayColor="#9AE6B4"
          TaskListHeader={TaskListHeader}
          TaskListTable={TaskListTable}
        />
      ) : (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Text>{t('noData')}</Text>
        </Flex>
      )}
      {!allDataLoaded &&
        (columnsData.length > 3 || columnsData2.length > 3) && (
          <Flex justifyContent={'center'} mt={5}>
            <Button
              onClick={() => handleLoad()}
              py={2}
              variant={'none'}
              bgColor="blue.300"
              color={'white'}
              fontSize="sm"
            >
              {t('LoadMore')}
            </Button>
          </Flex>
        )}
    </Box>
  );
};
export default MyKanbanGanttComponent;
