import React from 'react';
import MomEditPage from '../Pages/MinutesOfMeetings/MomEditPage';
import MomPage from '../Pages/MinutesOfMeetings/MomPage';
import MomViewPage from '../Pages/MinutesOfMeetings/MomViewPage';


const MomRouter = [
  {
    path: '/mom',
    element: <MomPage />,
  },
  {
    path: '/mom/view/:id',
    element: <MomViewPage />,
  },

  {
    path: '/mom/edit/:id',
    element: <MomEditPage />,
  },
];

export default MomRouter;
