/* eslint-disable no-undef */
const Typesense = require('typesense',);
const API = process.env.REACT_APP_TYPESENSE_API_KEY;

export const clientTypessense = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      port: '443', // For Typesense Cloud use 443
      protocol: 'https', // For Typesense Cloud use https
    },
  ],
  apiKey: API,
  connectionTimeoutSeconds: 15,
},);

export const onGetClientTypesense = async(query,type,query_by,per_page) => {

  const clientTypesense = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey: API,
    connectionTimeoutSeconds: 2,
  });

  const searchParameters = {
    q: query,
    query_by: query_by,
    per_page:per_page
  };

  // console.log(searchParameters,'ini search parameter nya...')
  try {

    const result = await clientTypesense
      .collections(type)
      .documents()
      .search(searchParameters);


    return result.hits;
  } catch (error) {
    throw new Error(error)
  }}





export const onGetClientTypesenseFront = async(query, type, query_by, filter_by,  per_page,page,sort_by) => {

  const clientTypesense = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey: API,
    connectionTimeoutSeconds: 15,
  });

  const searchParameters = {
    q: query,
    query_by: query_by,
    filter_by: filter_by,
    // filter_by_not: 'contact_status:active',
    page:page,
    per_page:per_page,
    sort_by:sort_by
  };
  try {
    const result = await clientTypesense
      .collections(type)
      .documents()
      .search(searchParameters);
    return result;
  } catch (error) {
    throw new Error(error)
  }}
