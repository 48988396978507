import React, { useEffect, useState } from 'react';
import { HStack, Select, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { Chart } from 'chart.js/auto';
import { useParams } from 'react-router-dom';
import { db } from '../../Config/firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

function ChartsPieKanban() {
  const {t} = useTranslation()
  const [chartData, setChartData] = useState({});
  const [chartInstance, setChartInstance] = useState(null);
  const [time, setTime] = useState('30');

  const globalState = useUserStore();
  const params = useParams();
  const toast = useToast()

  const getDataPie = async () => {
    const dataColumnSearch = [
      'backlog',
      'todos',
      'progress',
      'review',
      'done',
    ];
    const chartData = {
      labels: dataColumnSearch,
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)', // warna untuk 'todos'
            'rgba(75, 192, 192, 0.6)', // warna untuk 'backlog'
            'rgba(255, 206, 86, 0.6)', // warna untuk 'progress'
            'rgba(54, 162, 235, 0.6)', // warna untuk 'review'
            'rgba(153, 102, 255, 0.6)', // warna untuk 'done'
          ],
        },
      ],
    };

    const colorMapping = {
      todos: 0,
      backlog: 1,
      progress: 2,
      review: 3,
      done: 4,
    };

    const apiCalls = dataColumnSearch.map(async (column) => {
      const dataApi = {
        dateRange: time,
        filesId: params.id,
        companyId: globalState?.currentCompany,
        column: column,
      };

      let startDate

      if (dataApi.dateRange === '1') {
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
      } else if (dataApi.dateRange === '7') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0, 0);
      } else if (dataApi.dateRange === '30') {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0, 0);
      }else{
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0, 0);
      }
                 
      try {


        const collectionRef = collection(db, 'kanban');
        const snapshot = await getCountFromServer(
          query(
            collectionRef,
            where('companyId', '==', dataApi?.companyId),
            where('filesId', '==', dataApi?.filesId),
            where('column', '==', dataApi?.column),
            where('createdAt', '>=', startDate),
          ));



        const columnCounts = {};

        // Menghitung jumlah transaksi untuk setiap metode pembayaran
        columnCounts[column] = snapshot.data().count;

        const data = columnCounts
        const colorIndex = colorMapping[column];
        chartData.datasets[0].data[colorIndex] = data[column];
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        const colorIndex = colorMapping[column];
        chartData.datasets[0].data[colorIndex] = 0;
      }
    });

    await Promise.all(apiCalls);
    setChartData(chartData);
  };


  useEffect(() => {
    if (params.id) {
      getDataPie();
    }

    return () => {
      setChartData({});
    };
  }, [time]);

  useEffect(() => {
    if (chartData.labels && chartData.labels.length > 0) {
      // Hancurkan chart yang sudah ada jika ada
      if (chartInstance) {
        chartInstance.destroy();
      }

      const ctx = document.getElementById('myPieChart').getContext('2d');
      const newChartInstance = new Chart(ctx, {
        type: 'pie',
        data: chartData,
      });

      // Simpan instance chart yang baru
      setChartInstance(newChartInstance);
    }
  }, [chartData, time, globalState.currentOutlet]);

  return (
    <Stack className="pie-chart" spacing={2}>
      <HStack>
        <Text fontSize={'md'} fontWeight={500} textTransform="capitalize">
          List order {time} day
        </Text>
        <Spacer />
        <Select
          size={'sm'}
          defaultValue={time}
          borderRadius="md"
          w={'50%'}
          placeholder="Select option"
          onChange={(e) => setTime(e.target.value)}
        >
          <option value="1">1 {t('day')}</option>
          <option value="7">7 {t('day')}</option>
          <option value="30">30 {t('day')}</option>
          <option value="all">{t('all')}</option>
        </Select>
      </HStack>
      <canvas id="myPieChart" width="auto" height="auto"></canvas>
    </Stack>
  );
}

export default ChartsPieKanban;
