/* eslint-disable prefer-const */
/* eslint-disable react/react-in-jsx-scope */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  deleteDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import { addActivity } from '../../Api/logApi';
import { deleteField, doc, updateDoc } from '@firebase/firestore';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FcFile, FcFolder } from 'react-icons/fc';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { useTranslation } from 'react-i18next';

function ProductivityDocsViewFolderPage() {
  const {t} = useTranslation()
  const param = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [showMoveAlert, setShowMoveAlert] = useState(false);

  const [fileActive, setFileActive] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [dataSearched, setDataSearched] = useState([]);

  const globalState = useUserStore();

  const location = useLocation();
  const { colorMode } = useColorMode();

  const dataParam = location.state;
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 12;

  const [dataFiles, setDataFiles] = useState([]);

  const alertDelete = useDisclosure();
  const handleDeleteFolder = () => {
    alertDelete.onOpen();
  };

  const getDataFolder = async () => {
    const conditions = [
      { field: 'folderId', operator: '==', value: param.id },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue
      );
      setDataFiles(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); // Tambahkan jumlah data per halaman saat tombol "Load More" diklik
  };

  const handleLogActivity = async (data) => {
    const dataActive = {
      createdName:
        auth?.currentUser?.displayName ||
        auth?.currentUser?.email ||
        'anonymous',
      image: auth?.currentUser?.photoURL || '',
      createdBy: auth?.currentUser?.uid,
      type: 'Digital Assets',
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      action: 'Delete Docs',
      collection_path: `docs/folder/${data.id}`,
      title: data.heading,
    };

    try {
      await addActivity(dataActive); // Gunakan fungsi utilitas
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleConfirmDeleteFolder = async () => {
    const collectionName = 'folders';
    const docName = param.id;

    try {
      await deleteDocumentFirebase(collectionName, docName);
      toast({
        title: 'Success',
        description: 'Succes delete folder.',
        isClosable: true,
        duration: 9000,
        status: 'success',
      });
      handleLogActivity(dataParam);
      navigate(-1);
      alertDelete.onClose();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleMoveFile = async () => {
    try {
      // Hapus field "folderId" dari dokumen di Firebase
      await updateDoc(doc(db, 'files', fileActive.id), {
        folderId: deleteField(),
      });

      toast({
        title: 'Success',
        description: 'Succes move this file.',
        isClosable: true,
        duration: 9000,
        status: 'success',
      });

      handleCloseAlert();
      getDataFolder();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleOpenAlert = (data) => {
    setShowMoveAlert(true);
    setFileActive(data);
  };

  const handleCloseAlert = () => {
    setShowMoveAlert(false);
    setFileActive('');
  };

  const handleSearch = (text) => {
    if (text) {
      const newData = dataFiles.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setDataSearched(newData);
      setSearchInput(text);
    } else {
      setDataSearched([]);
      setSearchInput(text);
    }
  };

  useEffect(() => {
    getDataFolder();

    return () => {
      setDataFiles([]);
      setDataSearched([]);
    };
  }, [globalState?.currentCompany, startIndex]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading size={'md'} textTransform="capitalize">
          {dataParam?.heading} Files
        </Heading>
      </HStack>
      <Stack spacing={4}>
        <HStack>
          <Stack>
            <Input
              placeholder={t('search')}
              size={'sm'}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Stack>
          <Spacer />
          <HStack>
            <Button
              size={'sm'}
              colorScheme="blue"
              variant={'outline'}
              onClick={() => navigate(`/docs/create?id=${param.id}`)}
            >
              {t('newFile')}
            </Button>
            <Button size={'sm'} colorScheme="red" onClick={handleDeleteFolder}>
              {t('deleteFolder')}
            </Button>
          </HStack>
        </HStack>

        <Stack>
          <Text fontSize={'2xs'} color="red.400">
            *{t('folderNote')}
          </Text>
        </Stack>
        {dataFiles?.length > 0 && searchInput === '' ? (
          <SimpleGrid columns={[1, 2, 4]} gap={4}>
            {dataFiles.map((x, index) => {
              const dataView =
                x.category === 'file' ? (
                  <FcFile size={50} />
                ) : (
                  <FcFolder size={50} />
                );

              return (
                <Stack
                  shadow={'md'}
                  h={'150px'}
                  overflow={'hidden'}
                  overflowY="scroll"
                  key={index}
                  p={5}
                  bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}
                  borderRadius={'md'}
                  borderWidth={0.5}
                  borderColor="blackAlpha.300"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    let timer;
                    const delay = 500; // Ubah sesuai kebutuhan

                    timer = setTimeout(() => {
                      // Fungsi yang akan dijalankan saat di-klik tahan
                      handleOpenAlert(x); // Ganti dengan nama fungsi yang sesuai
                    }, delay);

                    e.target.addEventListener('mouseup', () =>
                      clearTimeout(timer)
                    );
                  }}
                  cursor="pointer"
                >
                  <Stack w={'100%'}>
                    <HStack>
                      <Text
                        fontWeight={500}
                        noOfLines={1}
                        textTransform={'capitalize'}
                      >
                        {x.heading}
                      </Text>
                    </HStack>
                  </Stack>
                  <Divider />

                  <Stack alignItems={'center'} h="full" justifyContent="center">
                    {dataView}
                  </Stack>

                  <Button
                    p={2}
                    bgColor="blue.200"
                    onClick={() => navigate(`/docs/${x.category}/${x.id}`)}
                  >
                    <Text fontSize={'sm'} color="white">
                      {t('open')}
                    </Text>
                  </Button>
                </Stack>
              );
            })}
          </SimpleGrid>
        ) : dataSearched.length > 0 ? (
          <SimpleGrid columns={[1, 2, 4]} gap={4}>
            {dataSearched.map((x, index) => {
              const dataView =
                x.category === 'file' ? (
                  <FcFile size={50} />
                ) : (
                  <FcFolder size={50} />
                );

              return (
                <Stack
                  shadow={'md'}
                  h={'150px'}
                  overflow={'hidden'}
                  overflowY="scroll"
                  key={index}
                  p={5}
                  bgColor="white"
                  borderRadius={'md'}
                  borderWidth={0.5}
                  borderColor="blackAlpha.300"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    let timer;
                    const delay = 500; // Ubah sesuai kebutuhan

                    timer = setTimeout(() => {
                      // Fungsi yang akan dijalankan saat di-klik tahan
                      handleOpenAlert(x); // Ganti dengan nama fungsi yang sesuai
                    }, delay);

                    e.target.addEventListener('mouseup', () =>
                      clearTimeout(timer)
                    );
                  }}
                  cursor="pointer"
                >
                  <Stack w={'100%'}>
                    <HStack>
                      <Text
                        fontWeight={500}
                        noOfLines={1}
                        textTransform={'capitalize'}
                      >
                        {x.heading}
                      </Text>
                    </HStack>
                  </Stack>
                  <Divider />

                  <Stack alignItems={'center'} h="full" justifyContent="center">
                    {dataView}
                  </Stack>
                  <Button
                    p={2}
                    bgColor="blue.200"
                    size="sm"
                    onClick={() => navigate(`/docs/${x.category}/${x.id}`)}
                  >
                    <Text fontSize={'sm'} color="white">
                      {t('open')}
                    </Text>
                  </Button>
                </Stack>
              );
            })}
          </SimpleGrid>
        ) : (
          <Stack minH={'80vh'} alignItems="center" justifyContent="center">
            <Text fontSize="lg" fontWeight={500} color="gray.500">
              {t('noData')}
            </Text>
          </Stack>
        )}

        <Stack alignItems={'center'} justifyContent="center">
          <Box>
            {dataFiles?.length > startIndex && (
              <Button onClick={handleLoadMore} size="sm">
                {t('loadMore')}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      {/* Modal konfirmasi */}
      <AlertDialogV2
        isOpen={alertDelete.isOpen}
        onClose={alertDelete.onClose}
        title={t('deleteFile')}
        onAction={handleConfirmDeleteFolder}
      />

      <AlertDialog isOpen={showMoveAlert} onClose={handleCloseAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('confirmMove')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('moveAlert')}</AlertDialogBody>

            <AlertDialogFooter>
              <HStack spacing={3}>
                <Button onClick={handleCloseAlert}> {t('cancel')}</Button>
                <Button colorScheme="red" onClick={() => handleMoveFile()}>
                  {t('move')}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
}

export default ProductivityDocsViewFolderPage;
