/* eslint-disable react/prop-types */
import {
  Box,
  HStack,
  Image,
  Spacer,
  Stack,
  Tag,
  Text,
  useToast,
  Divider,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FcFolder } from 'react-icons/fc';
import { FiTag } from 'react-icons/fi';
import { msToTime } from '../../Utils/timeUtil';
import SingleCalendarComponent from './SingleCalendarComponent';
import { getUserByUid } from '../../Utils/getUser';
import useUserStore from '../../Hooks/Zustand/Store';
import { useNavigate } from 'react-router-dom';

function HistoryTaskTable({ data }) {
  //   console.log(data, "ini data");
  const globalState = useUserStore();
  const [dataPIC, setDataPIC] = useState([]);

  const toast = useToast()
  const navigate = useNavigate()

  const getDataPIC = async () => {
    if (data?.pic) {
      try {
        const promises = data?.pic.map(async (x) => {
          const y = await getUserByUid(globalState?.users, x);
          return y;
        });

        const results = await Promise.all(promises);

        setDataPIC(results);
      } catch (error) {
        toast({
          title: 'Error!',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };


  //   console.log(dataPIC);

  useEffect(() => {
    getDataPIC();

    return () => {
      setDataPIC([]);
    };
  }, []);

  const handleNavigate = (data) => {
    navigate(`/kanban/view/${data?.filesId}/history/${data?.id}`)
  }


  return (
    <HStack justifyContent={'space-evenly'} spacing={4} cursor={'pointer'} onClick={() => handleNavigate(data)}>
      {data?.files?.length > 0 && (
        <Stack shadow={'md'} borderRadius="md" >
          {data?.files[data?.files?.length - 1]?.type === 'image' ? (
            <Image
              src={data?.files[data?.files?.length - 1]?.link}
              borderRadius="md"
              objectFit="contain"
              w="150px"
              h="100px"
              alt="www.google.com"
            />
          ) : (
            <Stack alignItems={'center'} justify="center">
              <FcFolder size={80} />
            </Stack>
          )}
        </Stack>
      )}
      <Stack>
        <Text fontSize="3xs">ID: {data?.id}</Text>

        <Text fontWeight={500} fontSize='sm'>{data.title}</Text>
        <HStack alignItems={'center'} >
          {data?.category && (
            <Box>

              <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
                {data?.category}
              </Tag>
            </Box>

          )}
          {data?.label ? (
            <Box>

              <Tag
                size={'sm'}
                colorScheme={
                  data?.label === 'help'
                    ? 'yellow'
                    : data?.label === 'pending'
                      ? 'green'
                      : data?.label === 'blocked'
                        ? 'red'
                        : data?.label === 'completed'
                          ? 'green'
                          : 'gray'
                }

              >
                <HStack >
                  <FiTag />
                  <Text>{data?.label}</Text>
                </HStack>
              </Tag>
            </Box>

          ) : (
            <></>
          )}
        </HStack>
        {data?.lastUpdated?.seconds ? (
          <Text textAlign="left" fontSize="2xs">
                        Updated: {moment.unix(data?.lastUpdated?.seconds).fromNow()}
          </Text>
        ) : (
          <></>
        )}
      </Stack>
      <Divider orientation='vertical' color={'white'} />

      <Spacer />


      <HStack spacing={2} alignItems={'flex-start'} justifyContent='flex-start'>

        {data?.pic?.length > 0 ? (

          <Stack>
            <Text
              textAlign="left"
              fontSize="2xs"
              fontWeight={500}
              color="gray.700"
            >
                            PIC
            </Text>
            {dataPIC?.map((x, index) => {
              //   const picName = dataPIC.find((y) => y.id === x.id);

              //   console.log(picName, "ini picname");

              return (
                <Text
                  key={index}
                  fontSize="xs"
                  fontWeight={500}
                  textTransform="capitalize"
                >
                  {x?.name ? x?.name : ''}
                </Text>
              );
            })
            }
          </Stack>
        ) : (
          <></>
        )}

        <Stack>
          <Text fontSize={'xx-small'} fontWeight={500} color="gray.700">
                        DURATION
          </Text>
          <Text fontWeight={'bold'} fontSize="sm" color={'green.600'}>
            {msToTime(
              (data?.time_completed_at?.seconds * 1000 ||
                                data?.lastUpdated?.seconds * 1000) -
                            (data?.time_backlog_at?.seconds * 1000 ||
                                data?.createdAt?.seconds * 1000)
            )}
          </Text>
        </Stack>

       
        <Stack>
          <Text fontSize={'xx-small'} fontWeight={500} color="gray.700">
                        SCORE
          </Text>
          <Stack
            w="25px"
            borderRadius={'full'}
            h="25px"
            bgColor={data?.score > 0 ? 'blue.400' : 'red.400'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
              {data?.score || 0}
            </Text>
          </Stack>
        </Stack>

        <Stack>
          <Text fontSize={'xx-small'} fontWeight={500} color="gray.700">
                        DUE ON
          </Text>
          {data?.dueOn ? (
            <SingleCalendarComponent
              data={data?.dueOn}
              date={moment.unix(data?.dueOn).format('DD')}
              month={moment.unix(data?.dueOn).format('MMM')}
            />
          ) : (
            <></>
          )}
        </Stack>





      </HStack>


    </HStack>
  );
}

export default HistoryTaskTable;
