/* eslint-disable indent */
/* eslint-disable react/prop-types */
import {
  Divider,
  Heading,
  Stack,
  Text,
  HStack,
  Avatar,
  Tooltip,
  Box,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Progress,
  Icon,
  Badge,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getUserByUid } from '../../../Utils/getUser';
import { colorSchemeNumberUtil } from '../../../Utils/colorUtil';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import { calculateStats, processDataWithCounts } from '../../../Utils/kpiCalc';
import { formatFrice, readMore } from '../../../Utils/Helper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatNumberKpi } from '../../../Utils/numberUtil';
import { FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc';

function PerformanceComponent({ nodes }) {
  const globalState = useUserStore();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  const getDataUsers = async () => {
    if (nodes.length > 0) {
      try {
        const promises = nodes.map((x) =>
          getUserByUid(globalState?.users, x.data.label)
        );
        const results = await Promise.all(promises);
        setUsers(results);
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const getDataKpi = async (userId) => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      {
        field: 'users',
        operator: 'array-contains',
        value: userId,
      },
    ];

    try {
      const x = await getCollectionFirebase('kpi', conditions);
      const dataKpi = x.map((item) => item.kpiId);

      const { counts, sums, averages } = await calculateStats(dataKpi);
      const dataWithCounts = processDataWithCounts(x, counts, sums, averages);

      setData(dataWithCounts);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleClick = (userId) => {
    getDataKpi(userId);
  };

  const calculateAverage = (data, userId) => {
    const userKpis = data.filter((kpi) => kpi.users.includes(userId));

    const totalProgress = userKpis.reduce(
      (sum, kpi) => sum + kpi.statsProgress,
      0
    );
    const averageProgress =
      userKpis.length > 0 ? totalProgress / userKpis.length : 0;

    return averageProgress;
  };

  useEffect(() => {
    getDataUsers();
  }, [nodes]);


  return (
    <Accordion p={3} overflowY={'auto'} allowToggle>
      <Heading size={'md'} textTransform={'capitalize'}>
        {t('performance')}
      </Heading>
      {users.length > 0 &&
        users.map((x, i) => (
          <>
            <AccordionItem key={i}>
              <>
                <AccordionButton onClick={() => handleClick(x?.id)}>
                  <HStack justifyContent={'space-between'} w="full">
                    <HStack>
                      <Tooltip label={x?.email}>
                        <Avatar src={x?.image} name={x?.name} />
                      </Tooltip>
                      <Stack spacing={0} alignItems={'flex-start'}>
                        <Text textTransform={'capitalize'}>
                          {readMore(x?.name)}
                        </Text>
                        <Text fontSize={'2xs'} color={'gray.500'}>
                          {x?.id}
                        </Text>
                      </Stack>
                    </HStack>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
              </>
              <AccordionPanel pb={4}>
                {data?.length > 0 ? (
                  data
                    .filter((kpi) => kpi.users.includes(x?.id))
                    .map((x, i) => (
                      <Stack key={i} spacing={0} mb={4}>
                        <HStack
                          spacing={1}
                          w={'100%'}
                          alignItems={'flex-start'}
                          justifyContent={'space-between'}
                        >
                          <Stack spacing={0}>
                            <Tooltip label={x.title} aria-label="A tooltip">
                              <Stack spacing={1}>
                                <Text
                                  cursor="pointer"
                                  fontWeight={'bold'}
                                  noOfLines={1}
                                  fontSize={'xs'}
                                  onClick={() => navigate(`/kpi/list/${x.id}`)}
                                >
                                  {x.title}
                                </Text>
                                <Text fontWeight={500} fontSize="xs">
                                  {t('assignor')}:{' '}
                                  {getUserByUid(
                                    globalState?.users,
                                    x?.createdBy
                                  )?.email || ''}
                                </Text>
                              </Stack>
                            </Tooltip>
                          </Stack>
                          <HStack>
                            {x?.achivement === 'high' ? (
                              <HStack>
                                <Icon as={FcPositiveDynamic} boxSize={8} />
                              </HStack>
                            ) : (
                              <HStack>
                                <Icon as={FcNegativeDynamic} boxSize={8} />
                              </HStack>
                            )}
                          </HStack>
                        </HStack>
                        <HStack>
                          <Box w={'80%'}>
                            <Text textAlign="right" fontSize="2xs">
                              {x?.isAvarage
                                ? `${t('average')}: ${formatNumberKpi(
                                    x.statsAverage
                                  )}`
                                : `Total: ${formatNumberKpi(
                                    x.statsTotalActual
                                  )}`}
                            </Text>
                            <Progress
                              borderRadius="md"
                              value={x.statsProgress}
                              colorScheme={colorSchemeNumberUtil(
                                x.statsProgress
                              )}
                              w="100%"
                            />
                            <Text textAlign="right" fontSize="2xs">
                              Target:{' '}
                              {x.isAvarage
                                ? formatFrice(x.target)
                                : formatFrice(x.statsTotalTarget)}
                            </Text>
                          </Box>
                          <Box w={'20%'}>
                            <Badge
                              variant={'solid'}
                              borderRadius="md"
                              w="full"
                              textAlign="center"
                              fontSize="md"
                              p="2"
                              colorScheme={colorSchemeNumberUtil(
                                x.statsProgress
                              )}
                            >
                              {x.statsProgress}%
                            </Badge>
                          </Box>
                        </HStack>
                      </Stack>
                    ))
                ) : (
                  <Text textAlign={'center'} fontWeight={'bold'}>
                    {t('noData')}
                  </Text>
                )}
                <Divider my={4} borderColor={'gray.400'} />
                <HStack>
                  <Box w={'80%'}>
                    <Text textAlign="left" fontSize="xs" fontWeight={'bold'}>
                      KPI Average
                    </Text>
                    <Progress
                      borderRadius="md"
                      value={calculateAverage(data, x?.id)}
                      colorScheme={colorSchemeNumberUtil(
                        calculateAverage(data, x?.id)
                      )}
                      w="100%"
                    />
                    <Text textAlign="left" fontSize="2xs">
                      Total Kpi: {data.length}
                    </Text>
                  </Box>
                  <Box w={'20%'}>
                    <Badge
                      variant={'solid'}
                      borderRadius="md"
                      w="full"
                      textAlign="center"
                      fontSize="md"
                      p="2"
                      colorScheme={colorSchemeNumberUtil(
                        calculateAverage(data, x?.id)
                      )}
                    >
                      {calculateAverage(data, x?.id)}%
                    </Badge>
                  </Box>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
            <Divider my={1} borderColor={'gray.400'} />
          </>
        ))}
    </Accordion>
  );
}

export default PerformanceComponent;
