/* eslint-disable no-unsafe-optional-chaining */
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Avatar,
  Text,
  Spacer,
  Button,
  Heading,
  useDisclosure,
  Stack,
  SimpleGrid,
  Flex,
  Badge,
  Center,
  Spinner,
  useToast,
  Icon,
  Divider,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import _axios from '../../Api/AxiosBarrier';
import { clientTypessense } from '../../Api/Typesense';
import useUserStore from '../../Hooks/Zustand/Store';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import { msToTime } from '../../Utils/timeUtil';
import { FaRegCalendar } from 'react-icons/fa';
import DatePicker from '../../Components/DatePicker/DatePicker';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import {
  MdGridView,
  MdList,
  MdNavigateBefore,
  MdNavigateNext,
} from 'react-icons/md';
import { TbReload } from 'react-icons/tb';
import HistoryTaskCard from '../../Components/Card/HistoryTaskCard';
import HistoryTaskTable from '../../Components/Card/HistoryTaskTable';
import { useTranslation } from 'react-i18next';
import DownloadButtonExcel from '../../Components/Buttons/DownloadButtonExcel';

function ManagementScorePeople() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [mongoData, setMongoData] = useState({});
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });
  const [page, setPage] = useState(1);
  const globalState = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyUids, setCompanyUids] = useState();
  const [searchUid, setSearchUid] = useState();
  const [searchParam, setSearchParam] = useState([]);
  const [accessHistory, setAccessHistory] = useState(false);
  const [view, setView] = useState('card');
  const toast = useToast();

  const [userSelected, setUserSelected] = useState({});

  const [selectedUid, setSelectedUid] = useState([]);

  const dates = useRef({
    startDate: moment().subtract(30, 'days').unix(),
    endDate: moment().unix(),
  });

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 6;

  const [kanbanHistory, setKanbanHistory] = useState([]);
  const [modalHistory, setModalHistory] = useState(false);

  const onDateChange = (data) => {
    dates.current = {
      startDate: moment(data.startDate).unix(),
      endDate: moment(data.endDate).unix(),
    };
  };

  const getData = async (currentPage = page, sorting = sort, searchId = '') => {
    setPage(currentPage);
    setData();
    globalState.setIsLoading(true);

    const { startDate, endDate } = dates.current;

    onClose();

    try {
      if (!globalState.currentCompany) return null;

      const params = `scoreboard-kanban?page=${currentPage}&limit=10&group=uid&sort=${
        sorting.desc ? -1 : 1
      }&companyId=${globalState.currentCompany}&sortBy=${
        sorting.sort
      }&startDate=${startDate}&endDate=${endDate}&type=kanban&search=${searchId}`;
      const res = await _axios.get(params);

      const uids = _.map(res.data, 'id');
      const searchUid = await handleTypeSense('', uids);

      const filteredData = res.data
        .map((item) => {
          const addData = searchUid.find((y) => y.id === item.id);
          return addData ? { ...addData, ...item } : null;
        })
        .filter(Boolean); // Filter out null values (items without email)

      setMongoData(res.totalPages);
      setData(filteredData);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleSearchTypeSense = (q) => {
    const userChunks = _.chunk(companyUids, 50);
    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        // console.log(combinedResults,'ini combined result')
        setSearchUid(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const handleTypeSense = (q, uids) => {
    const searchParameters = {
      q: q,
      query_by: 'name,email',
      per_page: 10,
      filter_by: `id: [${uids.join(',')}]`,
      sort_by: '_text_match:desc',
    };

    return clientTypessense
      .collections('users')
      .documents()
      .search(searchParameters)
      .then((x) => {
        // console.log(x)
        const newData = x.hits.map((y) => {
          return { ...y.document };
        });
        return newData;
      });
  };

  const handleSort = (sort, desc) => {
    setSort({ sort: sort, desc: desc });
    getData(page, { sort: sort, desc: desc });
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getData();
      const { users } = globalState?.companies?.find(
        (x) => x.id === globalState?.currentCompany
      );
      setCompanyUids(users);
    }

    return () => {
      setData();
      setPage(1);
    };
  }, [globalState?.currentCompany]);

  const handleSearchGroup = (x) => {
    setSearchParam([...searchParam, x]);
    setSelectedUid([...selectedUid, x.document.id]);
  };

  const getDataSearch = () => {
    const uidsToSearch = selectedUid.join(',');
    getData(1, true, uidsToSearch);
  };

  const handleReset = () => {
    setSearchUid();
    setSearchParam([]);
    setSelectedUid([]);
    getData();
  };

  const handleRemoveUid = (x) => {
    const updatedSelectedUid = selectedUid.filter(
      (uid) => uid !== x.document.id
    );
    setSelectedUid(updatedSelectedUid);

    const updatedSearchParam = searchParam.filter(
      (param) => param.document.id !== x.document.id
    );
    setSearchParam(updatedSearchParam);

    getData(1, true, updatedSelectedUid.join(','));
  };

  useEffect(() => {
    getDataSearch();

    return () => {};
  }, [selectedUid]);

  const handleSeeHistory = async (x) => {
    setModalHistory(true);
    setUserSelected(x);
  };

  const middleWareAccess = () => {
    toast({
      title: t('toast.alert'),
      description: t('toast.noAccess'),
      status: 'warning',
      duration: 4000,
      isClosable: true,
    });
    setAccessHistory(false);
  };

  const getDataHistory = async (x) => {
    if (
      globalState?.roleCompany !== 'owner' &&
      globalState?.uid !== x?.id &&
      globalState?.uid !== userSelected.id
    ) {
      return middleWareAccess();
    }

    const limitValue =
      userSelected?.tasks > itemsPerPage
        ? startIndex + itemsPerPage
        : userSelected?.tasks;

    const conditions = [
      {
        field: 'asignee',
        operator: 'array-contains',
        value: x?.id || userSelected.id,
      },
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'column', operator: '==', value: 'done' },
      { field: 'label', operator: '==', value: 'completed' },
      // { field: "createdAt", operator: ">=", value: dates.startDate * 1000 },
      // { field: "createdAt", operator: "<=", value: dates.endDate * 1000 },
    ];

    const sortBy = { field: 'lastUpdated', direction: 'desc' };

    try {
      const kandanRes = await getCollectionFirebase(
        'kanban',
        conditions,
        sortBy,
        limitValue
      );

      setKanbanHistory(kandanRes);
      setAccessHistory(true);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  useEffect(() => {
    if (userSelected?.id) {
      getDataHistory(userSelected.id);
    }

    // return () => {
    //   setKanbanHistory([]);
    // };
  }, [globalState.currentCompany, userSelected, startIndex]);

  const closeModalHistory = () => {
    setModalHistory(false);
    setKanbanHistory([]);
    setUserSelected({});
    setStartIndex(0);
  };

  const handleReloadFilter = () => {
    dates.current = {
      startDate: moment().subtract(30, 'days').unix(),
      endDate: moment().unix(),
    };
    getDataSearch();
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setCurrentTime(moment().format('kk : mm : ss'));
  //   }, 1000); // Update every 1 second
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <Stack spacing={5} p={[0, 1, 5]}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>{t('score-board')}</Heading>
        <Spacer />
        <DownloadButtonExcel
          size={'sm'}
          title={'Score Board People'}
          download={data}
          dates={dates.current}
          currentPage={page}
          sorting={sort}
          searchId={selectedUid.join(',')}
          currentCompany={globalState.currentCompany}
        />
      </HStack>
      <Box p="5" borderRadius="md" shadow="base">
        <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
          <Input
            w={'30%'}
            type="text"
            placeholder={t('searchUser')}
            onChange={(e) => {
              if (e.target.value) handleSearchTypeSense(e.target.value);
              else {
                setSearchUid();
              }
            }}
          />

          <Button
            onClick={onOpen}
            bgColor={'transparent'}
            // shadow="md"
            variant="outline"
            borderColor="blue.600"
            color="blue.600"
          >
            <HStack>
              <FaRegCalendar />
              <Text>{t('filterDate')}</Text>
            </HStack>
          </Button>
          <DynamicButton
            color={'blue'}
            action={'custom'}
            icon={TbReload}
            onClick={handleReloadFilter}
          />

          <Spacer />

          <Stack spacing={0}>
            <Text color={'gray.500'} align={'right'}>
              {t('today')},
            </Text>
            <Text color={'gray.500'}>{moment().format('Do MMMM YYYY')}</Text>
          </Stack>
        </Flex>

        <Flex m="2" gap={3} direction={['column-reverse', 'row', 'row']}>
          {/* <Spacer /> */}
          <HStack>
            {searchUid?.map((x, i) => {
              if (i < 3)
                return (
                  <Box
                    key={i}
                    w={['30%', '', '']}
                    bgColor="gray.50"
                    borderRadius="md"
                    p="1"
                    cursor="pointer"
                    onClick={() => {
                      handleSearchGroup(x);
                    }}
                  >
                    <HStack>
                      <Avatar
                        size="xs"
                        name={x.document.name}
                        src={x.document.image}
                      />
                      <Text noOfLines={1}>{x.document.name}</Text>
                    </HStack>
                  </Box>
                );
              else return <></>;
            })}
          </HStack>
        </Flex>

        {searchParam?.length > 0 && (
          <HStack m={2}>
            {searchParam?.map((x, index) => {
              return (
                <Box
                  key={index}
                  bgColor="gray.50"
                  borderRadius="md"
                  p="1"
                  cursor="pointer"
                  onClick={() => handleRemoveUid(x)}
                >
                  <HStack>
                    <Avatar
                      size="xs"
                      name={x.document.name}
                      src={x.document.image}
                    />
                    <Text>{x.document.name}</Text>
                  </HStack>
                </Box>
              );
            })}

            <Spacer />
            <Button onClick={handleReset}>Reset</Button>
          </HStack>
        )}

        <HStack pb={1}>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {t('dateRange')}:
          </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {moment.unix(dates.current.startDate).format('DD-MM-YYYY')}
          </Text>
          <Text color={'gray.500'} textTransform={'lowercase'}>
            {' '}
            {t('to')}{' '}
          </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {moment.unix(dates.current.endDate).format('DD-MM-YYYY')}
          </Text>
        </HStack>

        <TableContainer borderRadius={'sm'} shadow={'md'}>
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th>{t('name')}</Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('avgScore', !sort.desc);
                  }}
                >
                  {t('score')}
                  {sort.sort === 'avgScore' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('tasks', !sort.desc);
                  }}
                >
                  {t('task')}
                  {sort.sort === 'tasks' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th
                  cursor="pointer"
                  onClick={() => {
                    handleSort('avgTime', !sort.desc);
                  }}
                >
                  {t('time')}
                  {sort.sort === 'avgTime' ? (
                    sort?.desc ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
                <Th cursor="pointer">{t('action')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((x, i) => (
                <Tr key={i}>
                  <Td>
                    <HStack>
                      <Avatar
                        name={x.name}
                        src={x.image ? x.image : 'https://bit.ly/broken-link'}
                      />
                      <Stack spacing={0}>
                        <Text
                          textTransform={'capitalize'}
                          fontWeight={'bold'}
                          fontSize="sm"
                        >
                          {x.name}
                        </Text>
                        <Text fontSize="xs">{x.email}</Text>
                        <Text fontSize="3xs">
                          {t('userId')}:{x.id}
                        </Text>
                      </Stack>
                    </HStack>
                  </Td>
                  <Td>
                    <Badge
                      fontWeight={'bold'}
                      fontSize={'sm'}
                      p={1}
                      colorScheme={
                        x?.avgScore > 7
                          ? 'green'
                          : x?.avgScore > 5
                            ? 'yellow'
                            : 'red'
                      }
                    >
                      {' '}
                      {x?.avgScore?.toFixed(2)}
                    </Badge>
                  </Td>
                  <Td fontWeight={'bold'}>{x.tasks}</Td>
                  <Td>
                    <Box>
                      <Badge
                        p={1}
                        fontSize={'sm'}
                        fontWeight={'bold'}
                        colorScheme={'green'}
                      >
                        {msToTime(x?.avgTime)}
                      </Badge>
                    </Box>
                  </Td>

                  <Td>
                    <Box>
                      <DynamicButton
                        size={'sm'}
                        action="read"
                        onClick={() => handleSeeHistory(x)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <HStack justifyContent={'center'} spacing={5} pt={4}>
          {page > 1 ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => getData(page - 1)}
              isDisabled={page > 1 ? false : true}
            >
              <HStack color="white" spacing={1} pr={3}>
                <MdNavigateBefore size={23} />
                <Text>{t('prev')}</Text>
              </HStack>
            </Button>
          ) : null}

          {page < mongoData ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => getData(page + 1)}
              isDisabled={page < mongoData ? false : true}
            >
              <HStack color="white" spacing={1} pl={3}>
                <Text>{t('next')}</Text>
                <Box>
                  <MdNavigateNext size={23} />
                </Box>
              </HStack>
            </Button>
          ) : null}
        </HStack>
      </Box>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('select')} {t('date')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <DatePicker onDateChange={onDateChange} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                {t('close')}
              </Button>
              <DynamicButton
                title={'Search with filter'}
                action="read"
                variant={'solid'}
                onClick={() => getData()}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size={'3xl'} isOpen={modalHistory} onClose={closeModalHistory}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('kanbanHistory')} {userSelected?.name || userSelected?.email}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {accessHistory === true ? (
              <Stack>
                <HStack
                  p="2"
                  justifyContent={'flex-end'}
                  height="50px"
                  spacing={2}
                  cursor="pointer"
                >
                  <Divider orientation="vertical" color={'white'} />
                  <HStack
                    onClick={() => setView('card')}
                    color={view === 'card' ? 'blue.600' : 'black'}
                  >
                    <Icon as={MdGridView} w={5} h={5} cursor="pointer" />
                    <Text fontSize={'sm'} fontWeight={700}>
                      {t('calendarView')}
                    </Text>
                  </HStack>
                  <Divider orientation="vertical" color={'white'} />
                  <HStack
                    onClick={() => setView('list')}
                    cursor="pointer"
                    color={view === 'list' ? 'blue.600' : 'black'}
                  >
                    <Icon as={MdList} w={6} h={6} cursor="pointer" />
                    <Text fontSize={'sm'} fontWeight={700}>
                      {t('listView')}
                    </Text>
                  </HStack>
                </HStack>
                {kanbanHistory?.length > 0 ? (
                  <Stack>
                    {view === 'card' ? (
                      <SimpleGrid gap={3} columns={[1, 2, 3]}>
                        {kanbanHistory?.map((x, index) => {
                          return (
                            <Stack key={index}>
                              <HistoryTaskCard data={x} />
                            </Stack>
                          );
                        })}
                      </SimpleGrid>
                    ) : (
                      <Stack gap={3}>
                        {kanbanHistory?.map((x, index) => {
                          return (
                            <Stack key={index}>
                              <HistoryTaskTable data={x} />
                              <Divider />
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}

                    <Stack alignItems={'center'} justifyContent="center">
                      {userSelected?.tasks > startIndex ? (
                        <Button
                          colorScheme={'blue'}
                          fontSize="sm"
                          onClick={handleLoadMore}
                        >
                          {t('loadMore')}
                        </Button>
                      ) : null}
                    </Stack>
                  </Stack>
                ) : (
                  <Box py={10}>
                    <Center py={10}>
                      <Spinner />
                    </Center>
                  </Box>
                )}
              </Stack>
            ) : (
              <Stack alignItems={'center'} justifyContent="center" h="50vh">
                <Text fontWeight={500} color="gray.600">
                  {t('noAccess')}
                </Text>
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ManagementScorePeople;
