import {
  Avatar,
  AvatarBadge,
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';

function NotificationPage() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const navigate = useNavigate();

  const [totalDataFetched, setTotalDataFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const limitValue = startIndex + itemsPerPage;

  useEffect(() => {
    if (globalState?.currentCompany) {
      globalState.setIsLoading(true);
      const q = query(
        collection(db, 'notification'),
        where('userId', 'array-contains', globalState?.uid),
        where('companyId', '==', globalState?.currentCompany),
        limit(limitValue),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const qData = [];
        querySnapshot.forEach((doc) => {
          qData.push({ id: doc.id, ...doc.data() });
        });
        setData(qData);

        const collectionRef = collection(db, 'notification');
        const countQuery = query(
          collectionRef,
          where('userId', 'array-contains', globalState?.uid),
          where('companyId', '==', globalState?.currentCompany)
        );

        if (!totalDataFetched) {
          getCountFromServer(countQuery)
            .then((snapshot) => {
              const assessmentLength = snapshot.data().count;
              setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            })
            .catch((error) => {
              throw error;
            })
            .finally(() => {
              setTotalDataFetched(true);
              globalState.setIsLoading(false);
            });
        } else {
          globalState.setIsLoading(false);
        }
      });
      return () => unsubscribe();
    }
  }, [globalState?.currentCompany, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleNavigate = (data) => {
    if (data?.collectionField === 'kanban') {
      navigate(
        `/kanban/view/${data.parentId}/${data.parentName}/${data.collectionId}`
      );
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={10} minH="100vh">
      <Stack>
        <BackButtons />
        <Text fontWeight={'bold'} fontSize="3xl">
          🚀 {t('welcomeNotif')}
        </Text>
        <Text>{t('descNotif')}</Text>
        <Divider />
      </Stack>

      <Stack w="full">
        <Grid templateColumns={{ base: '1fr', md: '1fr' }} gap={3}>
          <Stack
            overflow={'auto'}
            h="600px"
            spacing={2}
            borderTopWidth={3}
            borderBottomWidth={3}
            borderColor="green.500"
            py={4}
            px={2}
            borderRadius="md"
            shadow="md"
          >
            <Stack>
              <Text fontSize={'md'} fontWeight={500} textTransform="capitalize">
                {t('notification')} {t('user')}
              </Text>
              <Stack gap={1}>
                {data.length > 0 ? (
                  data.map((x, index) => {
                    return (
                      <HStack
                        key={index}
                        shadow={'md'}
                        borderRadius={'md'}
                        p={4}
                        spacing={5}
                        cursor="pointer"
                        onClick={() => handleNavigate(x)}
                      >
                        <Stack>
                          <Avatar
                            cursor={'pointer'}
                            size="sm"
                            src={x?.image}
                            alt={x?.title}
                          >
                            <AvatarBadge boxSize="1.2em" bg="green.500" />
                          </Avatar>
                        </Stack>
                        <Stack spacing={0}>
                          <Text fontWeight={500} textTransform="capitalize">
                            {x.title}
                          </Text>
                          <Text fontSize={'sm'} color={'gray.600'}>
                            {x.action} <b>{x?.to || ''}</b> at{' '}
                            <b>{x?.body || ''}</b>{' '}
                          </Text>
                        </Stack>
                        <Spacer />
                        <Stack>
                          <Text
                            fontSize={'xx-small'}
                            textTransform="capitalize"
                            color={'gray.600'}
                          >
                            {moment(x.createdAt.seconds * 1000).fromNow()}
                          </Text>
                        </Stack>
                      </HStack>
                    );
                  })
                ) : (
                  <Center>
                    <Text fontSize={'lg'} fontWeight={500} color="gray.600">
                      {t('noData')}
                    </Text>
                  </Center>
                )}
              </Stack>
              <Stack alignItems={'center'} justifyContent="center" py={5}>
                {currentPage < totalPages && (
                  <Button
                    colorScheme={'blue'}
                    onClick={handleLoadMore}
                    size="sm"
                  >
                    {t('loadMore')}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default NotificationPage;
