import { jitsuAnalytics } from '@jitsu/js';

export function initializeAnalytics() {

  try {
    const analytics = jitsuAnalytics({
      host: 'https://clv71paoj00003b6rvrpgloiv.d.jitsu.com',
      writeKey: '',
    });
    return analytics; 
  } catch (error) {
    throw new Error(error.message)
  }
  
}

//fungsi untuk lacak user
export async function identifyUser(uid, dataUser) {
  try {
    const analytics = initializeAnalytics();
    await analytics.identify(uid, dataUser);
  } catch (error) {
    throw new Error(error) 
  }
 
}

// Fungsi untuk melakukan pelacakan event
export async function performTracking(key, eventData) {
  try {
    const analytics = initializeAnalytics();
    await analytics.track(key, eventData);
  } catch (error) {
    throw new Error(error) 
  }
  
}

// Fungsi untuk melakukan pelacakan halaman
export async function pageTracking(key, pageData) {
  try {
    const analytics = initializeAnalytics();
    await analytics.page(key, pageData);
  } catch (error) {
    throw new Error(error) 
  }
  
}
