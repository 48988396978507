/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  HStack,
  Spacer,
  Text,
  Stack,
  Tooltip,
  useToast,
  Image,
  Tag,
} from '@chakra-ui/react';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import { useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import axios from 'axios';
import { FiTag } from 'react-icons/fi';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../DropBox/UploadFileToDropbox';

import { useDrag } from 'react-dnd';
import moment from 'moment';
import SingleCalendarComponent from './SingleCalendarComponent';
import CardFile from '../Accounting/CardFile';
import ProgressCircle from '../Progress/ProgressCircle';
import { FcFolder } from 'react-icons/fc';
import { MdEmail, MdOutlineFlashOn } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { createHistoryCard } from '../../Utils/historyCardUtil';

function TaskCardComponent({
  task,
  index,
  columnsData,
  kanbanData,
  handleModalOpen,
  setData,
}) {
  const {t} = useTranslation()

  const { userDisplay } = useKanbanStore();

  const globalState = useUserStore();

  const [progress, setProgress] = useState(0);

  const toast = useToast();

  const middlewarePic = () => {
    toast({
      status: 'warning',
      duration: 9000,
      title: 'You don\'t have access to do this card',
      isClosable: true,
    });
  };

  const handleAddPoint = async (assignee, score, title) => {
    const seen = {};
    const result = [];

    if (!assignee || assignee.length === 0) {
      return toast({
        status: 'warning',
        duration: 9000,
        title: 'You don\'t have assignee for this point',
        isClosable: true,
      });
    }

    const uniqueAssignee = Array.from(new Set(assignee)); // Remove duplicate assignees

    const promises = uniqueAssignee?.map(async (uid) => {
      if (uid in seen) {
        return;
      }

      const userData = userDisplay?.find((user) => user?.id === uid);

      if (userData) {
        const newObj = {
          uid: uid,
          email: userData?.email,
          title: kanbanData?.title,
          task: title,
          filesId: task?.filesId,
          score: parseInt(score),
          type: 'kanban',
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          time_backlog_at: moment(
            new Date(task?.time_backlog_at?.seconds * 1000)
          ).valueOf(),
          time_completed_at: task?.time_completed_at
            ? moment(
              new Date(task?.time_completed_at?.seconds * 1000)
            ).valueOf()
            : moment(new Date()).valueOf(),
          time_from: new Date(task?.createdAt?.seconds * 1000),
          time_from_unix: moment(
            new Date(task?.createdAt?.seconds * 1000)
          ).valueOf(),
          time_to: task?.time_completed_at
            ? new Date(task?.time_completed_at?.seconds * 1000)
            : new Date(),
          time_to_unix: task?.time_completed_at
            ? moment(
              new Date(task?.time_completed_at?.seconds * 1000)
            ).valueOf()
            : moment(new Date()).valueOf(),
          createdAt: new Date(),
          status: 'done',
        };

        result.push(newObj);

        const data = {
          collection_name: 'files',
          fields: newObj,
        };

        try {
          const res = await axios.post(
            'https://new-apiv2.importir.com/api/general-log/store-deoapp',
            data,
            {
              headers: {
                token:
                  '$2y$10$bN5cOHkcoUR2QaK05xmTrOUHY1vEVmS9ne6h99Jrp7CJJKryMylri',
              },
            }
          );


          if (res?.data) {
            const collectionName = 'logs';


            try {
              await addDocumentFirebase(
                collectionName,
                data,
                globalState?.currentCompany
              );
              toast({
                status: 'success',
                duration: 2000,
                title: `${userData?.email} get ${score} score from ${title} card`,
                isClosable: true,
              });
            } catch (error) {
              toast({
                title: t('error'),
                description: `${error.message}`,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          } else {
            toast({
              status: 'error',
              duration: 9000,
              title: res?.data?.status?.message,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: 'Error!',
            description: error.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }

        seen[uid] = true;
      } else {
        toast({
          status: 'warning',
          duration: 9000,
          title: 'No matching user found for assignee with UID: ' + uid,
          isClosable: true,
        });
      }
    });

    await Promise.all(promises);
  };

  const handleMoveCard = (id, column) => {
    const picProject = kanbanData?.pic;
    const picTask = task?.pic;
    const assignee = task?.asignee;
    const score = task?.score;
    const title = kanbanData?.title;

    if (column !== 'done' && task?.column === 'done') {
      return toast({
        status: 'warning',
        duration: 9000,
        title: 'Cannot put back to review if your task has done',
        isClosable: true,
      });
    }

    if (column !== 'backlog' && column !== 'todos') {
      if (task?.time_backlog_at === undefined) {
        return toast({
          status: 'warning',
          duration: 9000,
          title:
            'Please make sure to insert your card through the todos first.',
          isClosable: true,
        });
      }
    }

    if (picTask && picTask?.length > 0) {
      const filterPic = picTask?.filter((x) => x === globalState?.uid);

      if (column === 'done' && filterPic.length === 0) {
        return middlewarePic();
      }
    }

    if (picTask?.length === 0) {
      if (picProject && picProject?.length > 0) {
        const filterPic = picProject?.filter(
          (x) => x?.uid === globalState?.uid
        );

        if (column === 'done' && filterPic?.length === 0) {
          return middlewarePic();
        }
      }
    }

    if (column === 'done' && task?.column !== 'done') {
      if (!assignee || assignee.length === 0) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have assignee for this card',
          isClosable: true,
        });
      }

      if (score === 0 || score === undefined) {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have a score for this card',
          isClosable: true,
        });
      }


      if (task?.label === undefined || task?.label === '') {
        return toast({
          status: 'warning',
          duration: 9000,
          title: 'You don\'t have label status for this card',
          isClosable: true,
        });
      }
    }

    setData([...columnsData.slice(0, index), ...columnsData.slice(index + 1)]);

    if (column === 'todos' && task?.time_backlog_at === undefined) {
      updateDocumentFirebase('kanban', id, {
        column: column,
        lastUpdated: new Date(),
        time_backlog_at: new Date(),
      });
    } else {
      updateDocumentFirebase('kanban', id, {
        column: column,
        lastUpdated: new Date(),
      });
    }

    const comments = `has moved card to ${column}`

    createHistoryCard(id, comments, globalState)

    if (
      column === 'done' &&
      task?.column !== 'done' &&
      task?.label === 'completed'
    ) {
      handleAddPoint(assignee, score, title, 'done');
    }
  };

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'column',
      item: task.id,
      end(item, monitor) {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          const isDropAllowed = dropResult.allowedDropEffect === 'move';
          if (isDropAllowed) {
            handleMoveCard(task.id, dropResult.name);
          }
        }
      },
      collect: (monitor) => ({
        opacity: monitor?.isDragging() ? 0.4 : 1,
      }),
    }),
    [task]
  );

  const onSelectFile = async (arg) => {
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );

      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = task.id;
        const field = 'files';
        const values = [dropboxLink];

        await arrayUnionFirebase(collectionName, docName, field, values);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  // useEffect(() => {
  //   setIndex(ndex);
  // }, []);

  return (
    <>
      <Box
        // height='24'
        width="full"
        shadow="base"
        p="2"
        ref={drag}
        style={{ opacity }}
        cursor="pointer"
        onClick={() => {
          handleModalOpen(task);
          // navigate(`${task?.id}`, { state: { type: 'existing' } });
          // setSomeFunction(setData);
          // setTaskData(
          //   task,
          //   index,
          //   columnsData,
          //   kanbanData,
          //   handleModalOpen,
          //   setData
          // );
        }}
      >
        {task?.files?.length > 0 ? (
          <Stack shadow={'md'} borderRadius="md">
            {task?.files[task?.files?.length - 1].type === 'image' ? (
              <Image
                src={task?.files[task?.files?.length - 1]?.link}
                borderRadius="md"
                objectFit="contain"
                w="full"
                alt="www.google.com"
                fallbackSrc='https://via.placeholder.com/150'
              />
            ) : (
              <Stack alignItems={'center'} justify="center">
                <FcFolder size={80} />
              </Stack>
            )}
          </Stack>
        ) : (
          <Stack zIndex={100} onChange={onSelectFile} display="none">
            <CardFile IsIcon={true} onChange={onSelectFile} />
          </Stack>
        )}

        <Stack spacing={1} my={2}>
          <HStack>
            {task?.category && (
              <Tag colorScheme={'blue'} size="sm" fontSize={'xs'}>
                {task?.category}
              </Tag>
            )}
            {task?.automationId && (
              <Tag colorScheme={'yellow'} size="sm" fontSize={'xs'}>
                <MdOutlineFlashOn />
              </Tag>
            )}
            {task?.email_status && (
              <Tag colorScheme={'red'} size="sm" fontSize={'xs'}>
                <MdEmail />
              </Tag>
            )}
          </HStack>
          <Text fontWeight="extrabold">{task.title}</Text>
        </Stack>

        {task.label ? (
          <Badge
            colorScheme={
              task.label === 'help'
                ? 'yellow'
                : task.label === 'pending'
                  ? 'gray'
                  : task.label === 'blocked'
                    ? 'red'
                    : task.label === 'completed'
                      ? 'green'
                      : task.label === 'priority'
                        ? 'blue'
                        : 'orange'
            }
          >
            <HStack>
              <FiTag />
              <Text>{task.label}</Text>
            </HStack>
          </Badge>
        ) : (
          <></>
        )}
        <HStack spacing={1}>
          {task.dueOn ? (
            <SingleCalendarComponent
              data={task?.dueOn}
              date={moment.unix(task?.dueOn).format('DD')}
              month={moment.unix(task?.dueOn).format('MMM')}
            />
          ) : (
            <></>
          )}
          <Stack
            w="30px"
            borderRadius={'full'}
            h="30px"
            bgColor={task?.score > 0 ? 'blue.400' : 'red.400'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Text color={'white'} fontSize="sm" fontWeight={'bold'}>
              {task?.score || 0}
            </Text>
          </Stack>

          {task?.createdBy ? (
            <Tooltip
              label={
                userDisplay?.find((z) => z.id === task?.createdBy)?.name ||
                task?.createdBy
              }
              aria-label="Name"
            >
              {task?.createdBy === 'Deoapp Automation' ? (
                <Stack
                  w="30px"
                  borderRadius={'full'}
                  h="30px"
                  bgColor={'yellow.400'}
                  alignItems={'center'}
                  justifyContent="center"
                >
                  <MdOutlineFlashOn />
                </Stack>
              ) : (
                <Avatar
                  m="0"
                  size="sm"
                  name={
                    userDisplay?.find((z) => z.id === task?.createdBy)?.name ||
                    task?.createdBy
                  }
                  image={
                    userDisplay?.find((x) => x.id === task?.createdBy)?.image
                  }
                />
              )}
            </Tooltip>
          ) : (
            <></>
          )}

          <Spacer />

          <AvatarGroup spacing={-3} size="sm" max={1}>
            {task?.asignee ? (
              task?.asignee?.sort()?.map((x, i) => {
                const name = kanbanData?.usersDisplay?.find(
                  (y) => y.id === x
                )?.name;
                return (
                  <Tooltip key={i} label={name} aria-label="Name">
                    <Avatar size="xs" name={name} />
                  </Tooltip>
                );
              })
            ) : (
              <></>
            )}
          </AvatarGroup>
        </HStack>
        {task?.pic?.length > 0 ? (
          task?.pic.map((x, index) => {
            return (
              <HStack key={index}>
                <Text
                  textAlign="left"
                  fontSize="2xs"
                  fontWeight={500}
                  color="gray.600"
                >
                  {t('pic')}
                </Text>
                <Spacer />
                <Text
                  textAlign="right"
                  fontSize="2xs"
                  fontWeight={500}
                  textTransform="capitalize"
                >
                  {userDisplay?.find((y) => y.id === x)?.name}
                </Text>
              </HStack>
            );
          })
        ) : (
          <></>
        )}
        {task?.lastUpdated?.seconds ? (
          <Text textAlign="left" fontSize="2xs">
            {t('updated')}: {moment.unix(task?.lastUpdated?.seconds).fromNow()}
          </Text>
        ) : (
          <></>
        )}
        <Text fontSize="3xs">ID: {task.id}</Text>
      </Box>

      {progress > 0 && progress < 100 ? (
        <Stack p={-10}>
          <ProgressCircle value={progress} />
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}

export default TaskCardComponent;
